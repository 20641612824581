import { customTheme } from "../../../components/atoms/CustomTheme/CustomTheme";

export const classes = {
  container: {
    position: "relative",
    display: "flex",
    flexDirection: "column",
    // width: "100%",
    // minWidth: "240px",
    width: "240px",
    padding: "8px",
    flexShrink: 0,
    [customTheme.breakpoints.down("md")]: {
      minWidth: "200px",
    },
    [customTheme.breakpoints.down("sm")]: {
      // marginTop: "16px",
    },
  },
  moreItemOptionsContainer: {
    top: "-6px",
    left: 0,
    right: 0,
    marginLeft: "auto",
    marginRight: "auto",
    width: "fit-content",
    position: "absolute",
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
    zIndex: 1,
    [customTheme.breakpoints.down("sm")]: {
      top: "-16px",
    },
  },
  horizontalMoreOptions: {
    padding: "0 16px",
    backgroundColor: "#FFE9AF",
    border: "2px solid #2A2A2A",
    borderRadius: "32px",
    cursor: "pointer",
    [customTheme.breakpoints.down("sm")]: {
      backgroundColor: "inherit",
      border: 0,
    },
  },
  imgContainer: {
    position: "relative",
    width: "100%",
    overflow: "hidden",
    borderRadius: "16px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    transition: "filter 0.2s ease-in-out",
    aspectRatio: 1,
    cursor: "pointer",
  },
  deleteContainer: {
    position: "absolute",
    zIndex: 1,
    backgroundColor: "rgba(209, 38, 38, 1)",
    transition: "0.2s ease-in-out",
    justifyContent: "center",
    alignItems: "center",
    width: "40px",
    height: "40px",
    borderRadius: "20px",
  },
  trashIcon: {
    width: "24px",
    height: "24px",
  },
  categoryImage: {
    position: "absolute",
    top: "50%",
    left: "50%",
    width: "100%",
    height: "100%",
    objectFit: "contain",
    transform: "translate(-50%, -50%)",
    borderRadius: "16px",
    backgroundColor: "#FFF",
    transition: "0.2s ease-in-out",
  },
  imgLabel: {
    display: "flex",
    flexGrow: 1,
    width: "100%",
  },
  noImageContainer: {
    flexGrow: 1,
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    gap: "8px",
    "&:hover": {
      cursor: "pointer",
    },
  },
  plusIcon: {
    width: "24px",
    height: "24px",
  },
  errorMsg: {
    color: "red",
    textAlign: "center",
    marginTop: "8px",
  },
  uploadImgTxt: {
    fontSize: "14px",
    fontWeight: "bold",
    color: "#757575",
  },
  itemInfo: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
  },
  brandTextfield: {
    marginTop: "8px",
    height: "24px", // Adjust container height if necessary
    "& .MuiInputBase-root": {
      // Target the input container
      height: "24px", // Adjust container height if necessary
    },
    "& .MuiInputBase-input": {
      // Target the input element
      height: "24px",
      padding: "0 10px", // Adjust padding to match the height
    },
    width: "100%",
  },
  brand: {
    borderRadius: "8px",
    marginTop: "8px",
    color: "#757575",
    fontWeight: "bold",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer",
    transition: "0.2s ease-in-out",
  },
  priceTextField: {
    marginTop: "8px",
    height: "36px", // Adjust container height if necessary
    "& .MuiInputBase-root": {
      // Target the input container
      height: "36px", // Adjust container height if necessary
    },
    "& .MuiInputBase-input": {
      // Target the input element
      height: "36px",
      padding: "0 10px", // Adjust padding to match the height
    },
    width: "100%",
  },
  price: {
    marginTop: "8px",
    fontWeight: "bold",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer",
    transition: "0.2s ease-in-out",
  },
  linkBtnTextfield: {
    marginTop: "8px",
    height: "48px", // Adjust container height if necessary
    "& .MuiInputBase-root": {
      // Target the input container
      height: "48px", // Adjust container height if necessary
    },
    "& .MuiInputBase-input": {
      // Target the input element
      height: "48px",
      padding: "0 10px", // Adjust padding to match the height
    },
    width: "100%",
  },
  linkBtnExists: {
    marginTop: "8px",
    fontWeight: "bold",
    width: "100%",
    transition: "0.2s ease-in-out",
  },
  editLinkBtn: {
    width: "32px",
    height: "32px",
    position: "absolute",
    right: "8px",
    borderRadius: "16px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    "&:hover": {
      backgroundColor: "#2A2A2A",
    },
  },
  editLinkIcon: {
    width: "24px",
    height: "24px",
  },
  linkBtnNoExists: {
    fontSize: "14px",
    marginTop: "8px",
    fontWeight: "bold",
    backgroundColor: "#F3F3F3",
    width: "100%",
    height: "48px",
    borderRadius: "8px",
    textAlign: "center",
    color: "#757575",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer",
    transition: "0.2s ease-in-out",
    "&:hover": {
      backgroundColor: "#ECECEC",
    },
  },

  // other fields
  otherFieldsContainer: {
    marginTop: "32px",
    width: "100%",
    position: "relative",
    [customTheme.breakpoints.down("sm")]: {
      marginTop: "16px",
    },
  },
  separator: {
    height: "1px",
    backgroundColor: "#BFBFBF",
  },
  morefieldOptionsContainer: {
    position: "absolute",
    width: "36px",
    left: "-36px",
    height: "100%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
  },
  verticalMoreOptions: {
    height: "fit-content",
    padding: "16px 0",
    backgroundColor: "#FFE9AF",
    border: "2px solid #2A2A2A",
    borderRadius: "32px",
    width: "fit-content",
    cursor: "pointer",
    [customTheme.breakpoints.down("sm")]: {
      backgroundColor: "inherit",
      border: 0,
      padding: "16px 4px",
    },
  },
  otherFieldLabelRow: {
    marginTop: "24px",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  otherFieldIcon: {
    width: "24px",
    height: "24px",
    marginRight: "8px",
  },
  otherFieldLabel: {
    fontWeight: "bold",
    height: "24px",
    transition: "0.2s ease-in-out",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    "&:hover": {
      cursor: "pointer",
      backgroundColor: "#ECECEC",
    },
  },
  otherFieldsLabelTextField: {
    height: "24px", // Adjust container height if necessary
    "& .MuiInputBase-root": {
      // Target the input container
      height: "24px", // Adjust container height if necessary
    },
    "& .MuiInputBase-input": {
      // Target the input element
      height: "24px",
      padding: "0 10px", // Adjust padding to match the height
    },
    width: "100%",
  },
  otherFieldsLabelRowPlaceholder: {
    height: "48px",
  },
  dimensionsTxt: {
    marginTop: "8px",
    cursor: "pointer",
    transition: "0.2s ease-in-out",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
  otherFieldsValueTextField: {
    marginTop: "8px",
    height: "24px", // Adjust container height if necessary
    "& .MuiInputBase-root": {
      // Target the input container
      height: "24px", // Adjust container height if necessary
    },
    "& .MuiInputBase-input": {
      // Target the input element
      height: "24px",
      padding: "0 10px", // Adjust padding to match the height
    },
    width: "100%",
  },

  // Menus
  icon: {
    width: "24px",
    height: "24px",
  },
  menuItemTxt: {
    fontWeight: 500,
  },
};
