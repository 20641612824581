import React, { useRef } from "react";
import { Box, Typography } from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";
import CardMedia from "@mui/material/CardMedia";
import Text from "../../../components/atoms/Text/Text";

// import components
import ScrollButtonLeft from "../../molecules/ScrollButtonLeft/ScrollButtonLeft";
import ScrollButtonRight from "../../molecules/ScrollButtonRight/ScrollButtonRight";

// import images
const CraftMyStyleIMG1 =
  "https://craftle-static.s3.us-east-2.amazonaws.com/landing-page/landing-cms-1.png";
const CraftMyStyleIMG2 =
  "https://craftle-static.s3.us-east-2.amazonaws.com/landing-page/landing-cms-2.png";
const CraftMyStyleIMG3 =
  "https://craftle-static.s3.us-east-2.amazonaws.com/landing-page/landing-cms-3.png";
const CraftMyStyleIMG4 =
  "https://craftle-static.s3.us-east-2.amazonaws.com/landing-page/landing-cms-4.png";
const CraftMyStyleIMG5 =
  "https://craftle-static.s3.us-east-2.amazonaws.com/landing-page/landing-cms-5.png";
const CraftMyStyleIMG6 =
  "https://craftle-static.s3.us-east-2.amazonaws.com/landing-page/landing-cms-6.png";

// import icons
import { ReactComponent as ArrowRight } from "../../../assets/icons/arrow-right.svg";

// import icons

let customTheme = createTheme({
  breakpoints: {
    values: {
      customMd: 1300,
      xl: 1536,
      lg: 1200,
      md: 900,
      sm: 600,
      xs: 0,
    },
  },
});

function LandingTrendingInstagram() {
  const ref = useRef(null);
  let navigate = useNavigate();

  return (
    <ThemeProvider theme={customTheme}>
      {/* popular furniture section */}
      <Box
        sx={{
          width: "90vw",
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
          marginTop: "48px",
          position: "relative",
        }}
      >
        <Typography
          variant="h2"
          sx={{
            color: "#2a2a2a",
            fontSize: "32px",
            fontWeight: "bold",
          }}
        >
          🤯 Craft My Style
        </Typography>

        <Typography
          sx={{
            color: "#2a2a2a",
            fontSize: "16px",
            marginTop: "16px",
          }}
        >
          Need help finding furniture? Start a project and get personalized
          results!
        </Typography>

        <ScrollButtonLeft scrollRef={ref} top="48%" />
        <Box
          ref={ref}
          sx={{
            display: "flex",
            flexDirection: "row",
            marginTop: "16px",
            width: "95vw",
            marginLeft: "-5vw",
            overflowX: "scroll",
            paddingLeft: "5vw",
            scrollBehavior: "smooth",
            "&::-webkit-scrollbar": {
              display: "none",
            },
          }}
        >
          <Box
            onClick={() => {
              navigate("/designs");
            }}
            sx={{
              marginRight: "16px",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              width: "240px",
              background: "-webkit-linear-gradient(225deg, #F2685F, #BF45D3)",
              borderRadius: "16px",
              padding: "24px",
              "&:hover": {
                cursor: "pointer",
              },
            }}
          >
            <Typography
              sx={{
                fontSize: "32px",
                lineHeight: 1.2,
                color: "#FFFFFF",
                fontWeight: "bold",
                width: "192px",
                marginBottom: "8px",
              }}
            >
              Answer some questions like...
            </Typography>
          </Box>
          <Box
            onClick={() => {
              navigate("/designs");
            }}
            sx={{
              marginRight: "16px",
              display: "flex",
              flexDirection: "row",
              backgroundColor: "#F3F0E1",
              borderRadius: "16px",
              padding: "24px 24px 0 32px",
              "&:hover": {
                cursor: "pointer",
              },
            }}
          >
            <CardMedia
              component="img"
              image={CraftMyStyleIMG1}
              alt=""
              sx={{
                // objectFit: "contain",
                width: "250px",
              }}
            />
            <Text
              sx={{
                marginTop: "25%",
                fontSize: "64px",
                // transform: "rotate(-20deg)",
              }}
            >
              🤔
            </Text>
          </Box>
          <Box
            onClick={() => {
              navigate("/designs");
            }}
            sx={{
              marginRight: "16px",
              display: "flex",
              flexDirection: "row",
              backgroundColor: "#F3F0E1",
              borderRadius: "16px",
              padding: "24px 24px 0 32px",
              position: "relative",
              "&:hover": {
                cursor: "pointer",
              },
            }}
          >
            <Text
              sx={{
                marginTop: "80%",
                fontSize: "64px",
              }}
            >
              😮
            </Text>
            <CardMedia
              component="img"
              image={CraftMyStyleIMG2}
              alt=""
              sx={{ width: "250px" }}
            />
            <CardMedia
              component="img"
              image={CraftMyStyleIMG3}
              alt=""
              sx={{
                position: "absolute",
                zIndex: 2,
                width: "280px",
                marginTop: "62px",
                marginLeft: "100px",
              }}
            />
          </Box>
          <Box
            onClick={() => {
              navigate("/designs");
            }}
            sx={{
              marginRight: "16px",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              width: "240px",
              background: "-webkit-linear-gradient(225deg, #F2685F, #BF45D3)",
              borderRadius: "16px",
              padding: "24px",
              "&:hover": {
                cursor: "pointer",
              },
            }}
          >
            <Typography
              sx={{
                fontSize: "32px",
                lineHeight: 1.2,
                color: "#FFFFFF",
                fontWeight: "bold",
                width: "192px",
                marginBottom: "8px",
              }}
            >
              Get results like...
            </Typography>
          </Box>
          <Box
            onClick={() => {
              navigate("/designs");
            }}
            sx={{
              marginRight: "16px",
              display: "flex",
              flexDirection: "row",
              backgroundColor: "#F3F0E1",
              borderRadius: "16px",
              padding: "24px 24px 0 32px",
              "&:hover": {
                cursor: "pointer",
              },
            }}
          >
            <CardMedia
              component="img"
              image={CraftMyStyleIMG4}
              alt=""
              sx={{
                width: "250px",
              }}
            />
            <Text
              sx={{
                marginTop: "25%",
                fontSize: "64px",
              }}
            >
              👀
            </Text>
          </Box>
          <Box
            onClick={() => {
              navigate("/designs");
            }}
            sx={{
              marginRight: "16px",
              display: "flex",
              flexDirection: "row",
              backgroundColor: "#F3F0E1",
              borderRadius: "16px",
              padding: "0px 24px 0 32px",
              "&:hover": {
                cursor: "pointer",
              },
            }}
          >
            <Text
              sx={{
                marginTop: "80%",
                fontSize: "64px",
              }}
            >
              😍
            </Text>
            <CardMedia
              component="img"
              image={CraftMyStyleIMG5}
              alt=""
              sx={{ width: "250px" }}
            />
          </Box>
          <Box
            onClick={() => {
              navigate("/designs");
            }}
            sx={{
              marginRight: "16px",
              display: "flex",
              flexDirection: "row",
              backgroundColor: "#F3F0E1",
              borderRadius: "16px",
              padding: "0px 24px 0 32px",
              "&:hover": {
                cursor: "pointer",
              },
            }}
          >
            <CardMedia
              component="img"
              image={CraftMyStyleIMG6}
              alt=""
              sx={{ width: "250px" }}
            />
            <Text
              sx={{
                marginTop: "25%",
                fontSize: "64px",
              }}
            >
              🥳
            </Text>
          </Box>
        </Box>
        <ScrollButtonRight scrollRef={ref} top="48%" />

        <Box
          onClick={() => {
            navigate("/designs");
          }}
          sx={{
            padding: "8px 16px",
            backgroundColor: "#FBEBEA",
            borderRadius: "1000px",
            width: "fit-content",
            marginTop: "24px",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            boxShadow: 0,
            transitionDuration: "0.4s",
            "&:hover": {
              cursor: "pointer",
              backgroundColor: "#F1EAFB",
            },
          }}
        >
          <Typography
            sx={{
              fontWeight: "bold",
              background: "-webkit-linear-gradient(right, #F2685F, #BF45D3)",
              WebkitBackgroundClip: "text",
              WebkitTextFillColor: "transparent",
            }}
          >
            Start Your Project
          </Typography>
          <Box sx={{ marginLeft: "8px" }}>
            <ArrowRight />
          </Box>
        </Box>
      </Box>
    </ThemeProvider>
  );
}

export default LandingTrendingInstagram;
