import React from "react";
import Footer from "../../components/organisms/Footer/Footer";
import CardMedia from "@mui/material/CardMedia";

// import components
import BlogParallaxRoot from "../../components/molecules/BlogParallaxRoot/BlogParallaxRoot";
import BlogParallaxLayerBack from "../../components/molecules/BlogParallaxLayerBack/BlogParallaxLayerBack";
import BlogParallaxLayerMiddle from "../../components/molecules/BlogParallaxLayerMiddle/BlogParallaxLayerMiddle";
import BlogParallaxLayerFront from "../../components/molecules/BlogParallaxLayerFront/BlogParallaxLayerFront";
import BlogContentContainer from "../../components/molecules/BlogContentContainer/BlogContentContainer";
import BlogDate from "../../components/molecules/BlogDate/BlogDate";
import BlogTitle from "../../components/molecules/BlogTitle/BlogTitle";
import BlogParagraph from "../../components/molecules/BlogParagraph/BlogParagraph";
import BlogH2 from "../../components/molecules/BlogH2/BlogH2";
import BlogH3 from "../../components/molecules/BlogH3/BlogH3";
import BlogImage from "../../components/molecules/BlogImage/BlogImage";
import BlogInlineAd1 from "../../components/molecules/BlogInlineAd1/BlogInlineAd1";
import BlogInlineAd2 from "../../components/molecules/BlogInlineAd2/BlogInlineAd2";

// import images
const bannerImage =
  "https://craftle-static.s3.us-east-2.amazonaws.com/blog/blog6/blog-6-banner.jpg";
const image1 =
  "https://craftle-static.s3.us-east-2.amazonaws.com/blog/blog6/blog-6-image-1.jpg";
const image2 =
  "https://craftle-static.s3.us-east-2.amazonaws.com/blog/blog6/blog-6-image-2.jpg";
const image3 =
  "https://craftle-static.s3.us-east-2.amazonaws.com/blog/blog6/blog-6-image-3.jpg";
const image4 =
  "https://craftle-static.s3.us-east-2.amazonaws.com/blog/blog6/blog-6-image-4.jpg";
const image5 =
  "https://craftle-static.s3.us-east-2.amazonaws.com/blog/blog6/blog-6-image-5.jpg";
const image6 =
  "https://craftle-static.s3.us-east-2.amazonaws.com/blog/blog6/blog-6-image-6.jpg";
const image7 =
  "https://craftle-static.s3.us-east-2.amazonaws.com/blog/blog6/blog-6-image-7.jpg";

function BlogPost6() {
  return (
    <BlogParallaxRoot>
      <BlogParallaxLayerBack>
        <CardMedia component="img" image={bannerImage} alt="" />
      </BlogParallaxLayerBack>

      <BlogParallaxLayerMiddle />

      <BlogParallaxLayerFront>
        <BlogContentContainer>
          <BlogDate date="May 12, 2022" />
          <BlogTitle title="Depreciation Life of Furniture: How Long Does It Keep Its Value?" />
          <BlogParagraph>
            When it comes to estimating the depreciation life of furniture,
            there is no definitive answer. Depreciation rates can vary depending
            on a number of factors, including the material used to make the
            furniture, its age, and the current state of the economy. However,
            there are some general guidelines that can help you get a sense of
            how long your furniture is likely to keep its value.
          </BlogParagraph>

          <BlogH2 title="Definition of Furniture Depreciation" />
          <BlogImage image={image1} alt="" caption="" />
          <BlogParagraph>
            Depreciation is defined as &quot;a decline in the value of an asset
            with the passage of time, due to wear and tear or
            obsolescence.&quot; When applied to furniture, depreciation occurs
            when the furniture loses value over time due to normal wear and
            tear, damage, or simply because it goes out of style.
          </BlogParagraph>

          <BlogH2 title="Factors That Impact Depreciation Rates" />
          <BlogImage image={image2} alt="" caption="" />
          <BlogParagraph>
            There are a number of factors that can impact the rate at which
            furniture depreciates. Perhaps the most important factor is the
            material from which the furniture is made. For example, furniture
            made from high-quality materials like solid wood is likely to
            depreciate more slowly than furniture made from lower-quality
            materials like particleboard.
          </BlogParagraph>
          <BlogParagraph>
            The age of the furniture also plays a role, with newer furniture
            usually depreciating more slowly than older furniture. In addition,
            the current state of the economy can impact depreciation rates.
          </BlogParagraph>
          <BlogParagraph>
            Finally, damage or other factors that decrease the value of the
            furniture can also affect depreciation rates.
          </BlogParagraph>

          <BlogH2 title="Advantages of Depreciation" />
          <BlogImage image={image3} alt="" caption="" />
          <BlogParagraph>
            While many people see depreciation as a disadvantage to owning the
            furniture, it actually provides several benefits. For one thing, it
            helps to ensure that businesses and consumers keep their equipment
            up-to-date by incentivizing them to regularly purchase new equipment
            instead of holding on to old equipment for longer than necessary.
          </BlogParagraph>
          <BlogParagraph>
            Depreciation is also useful in accounting for tax purposes;
            businesses are allowed to claim depreciation expenses related to
            certain assets throughout their useful life, which allows them to
            save money on taxes.
          </BlogParagraph>

          <BlogInlineAd1 />

          <BlogH2 title="How Does Depreciation Work?" />
          <BlogImage image={image4} alt="" caption="" />
          <BlogParagraph>
            1. Depreciation occurs when an asset loses value over time due to
            factors such as age, wear and tear, or damage.
          </BlogParagraph>
          <BlogParagraph>
            2. Depreciation rates can vary depending on a number of different
            factors, including the material from which the furniture is made,
            its age, and the state of the economy.
          </BlogParagraph>
          <BlogParagraph>
            3. Depreciation can be beneficial for businesses in that it
            incentivizes them to regularly purchase new equipment rather than
            holding on to old equipment for longer than necessary. Now that
            we’ve gone over some of the key factors that impact depreciation
            rates, let’s take a more in-depth look at how depreciation works.
            Depreciation is typically calculated using one of three methods: the
            straight-line method, the declining balance method, or the
            sum-of-the-years’-digits method.
          </BlogParagraph>
          <BlogH3 title="Straight Line Method of Depreciation" />
          <BlogParagraph>
            The straight-line method is the simplest and most commonly used
            depreciation method. Under this method, an asset is depreciated by
            an equal amount each year over its useful life. So, if you have a
            piece of furniture that you expect will have a 10-year useful life,
            you would depreciate it by 10% each year under the straight-line
            method.
          </BlogParagraph>
          <BlogH3 title="Declining Balance Method of Depreciation" />
          <BlogParagraph>
            The declining balance method is similar to the straight-line method,
            but Depreciation expense is recorded at a higher rate in the early
            years of an asset’s life and declines over time. The most common
            declining balance method used for furniture depreciation is the
            double-declining balance method, which depreciates an asset twice as
            fast as the straight-line method in the early years of its life.
          </BlogParagraph>
          <BlogH3 title="Sum-Of-The-Years’-Digits Method of Depreciation" />
          <BlogParagraph>
            The sum-of-the-years’-digits (SYD) method Depreciation expense is
            recorded in a way that assigns more Depreciation expense to the
            early years of an asset’s life and less Depreciation expense to the
            later years. The SYD method is less commonly used than the
            straight-line or declining balance methods, but it can be a good
            option if you want to Depreciate an asset quickly in its early years
            and then Depreciate it more slowly as its useful life comes to an
            end.
          </BlogParagraph>

          <BlogH2 title="Depreciation Expense" />
          <BlogImage image={image5} alt="" caption="" />
          <BlogParagraph>
            Every business has a depreciation expense for the furniture and
            equipment it uses in its daily operations. Depreciation expense is
            an important part of calculating a company’s net income, as it
            allows businesses to recover the cost of their assets over time.
          </BlogParagraph>
          <BlogParagraph>
            Depreciation expense is typically calculated using one of three
            methods: the straight-line method, declining balance method, or
            sum-of-the-years’-digits method. You can see these methods further
            defined in the above section titled &quot;How Does Depreciation
            Work?&quot;
          </BlogParagraph>

          <BlogH2 title="MACRS" />
          <BlogImage image={image6} alt="" caption="" />
          <BlogParagraph>
            In addition to these methods, businesses can also Depreciate assets
            using the Modified Accelerated Cost Recovery System (MACRS), which
            assigns Depreciation expenses based on a set schedule of asset
            classes and recovery periods.
          </BlogParagraph>
          <BlogParagraph>
            Depreciation under MACRS is usually calculated using the
            straight-line method, but some assets are assigned shorter or longer
            recovery periods that may result in Depreciation expense being
            recorded at different rates.
          </BlogParagraph>

          <BlogH2 title="Publication 946" />
          <BlogImage image={image7} alt="" caption="" />
          <BlogParagraph>
            The IRS provides detailed guidance on Depreciation Expense in
            Publication 946: How to Depreciate Property. This publication
            includes information on the different Depreciation methods, recovery
            periods, and recapture rules that businesses must follow when
            Depreciating their assets.
          </BlogParagraph>
          <BlogParagraph>
            Publication 946 can be a helpful resource for business owners who
            want to learn more about how Depreciation works and how they can
            depreciate their furniture, equipment, and other business assets for
            tax purposes.
          </BlogParagraph>

          <BlogH2 title="Wrapping It Up" />
          <BlogParagraph>
            Overall, there is no definitive answer as to how long the
            Depreciation life of furniture will last. However, by understanding
            the key factors that impact Depreciation rates, you can make an
            informed decision about when to replace your old furniture with new
            or updated items. Whether you Depreciate your furniture using the
            straight-line method, declining balance method, or
            sum-of-the-years’-digits method, it’s important to stay up-to-date
            on the latest Depreciation rules and regulations so that you can
            ensure that your Depreciation expenses accurately reflect the value
            of your assets.
          </BlogParagraph>

          <BlogInlineAd2 />
        </BlogContentContainer>
        <Footer />
      </BlogParallaxLayerFront>
    </BlogParallaxRoot>
  );
}

export default BlogPost6;
