import React from "react";
import { createRoot } from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import App from "./App";
import RootLayout from "./RootLayout";
import "./index.css";
import reportWebVitals from "./reportWebVitals";

const WidgetDivs = document.querySelectorAll(".craftle_widget");

WidgetDivs.forEach((Div) => {
  createRoot(Div).render(
    <BrowserRouter>
      {/* <React.StrictMode> */}
      <RootLayout>
        <App domElement={Div} />
      </RootLayout>
      {/* </React.StrictMode> */}
    </BrowserRouter>
  );
});

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
