import { customTheme } from "../../components/atoms/CustomTheme/CustomTheme";

export const classes = {
  container: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100vh",
  },

  loadingSection: {
    top: 0,
    position: "fixed",
    justifyContent: "center",
    alignItems: "center",
    height: "100vh",
    width: "100vw",
    zIndex: 1000,
    backgroundColor: "rgba(0, 0, 0, 0.4)",
  },
  loadingModal: {
    backgroundColor: "rgba(255, 254, 215, 1)",
    border: "solid 4px #2A2A2A",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    padding: "40px",
    borderRadius: "16px",
    gap: "16px",
    [customTheme.breakpoints.down("sm")]: {
      padding: "24px",
      textAlign: "center",
      margin: "0 16px",
    },
  },
  loaderIcon: {
    width: "65px",
    height: "65px",
  },
  loaderText: {
    fontWeight: "bold",
  },

  backgroundGradient: {
    position: "absolute",
    top: 0,
    backgroundImage:
      "linear-gradient(rgba(255, 244, 215, 1), rgba(255, 255, 255, 0))",
    height: "830px",
    width: "100%",
    zIndex: -2,
  },
  wallpaper: {
    position: "absolute",
    top: 0,
    width: "100%",
    zIndex: -3,
  },

  content: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "803px",
    padding: "0 16px",
    [customTheme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  h1: {
    fontSize: "48px",
    textAlign: "center",
    [customTheme.breakpoints.down("xs")]: {
      fontSize: "32px",
    },
  },
};
