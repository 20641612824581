import React, { useState, createContext, useEffect } from "react";
import { Box, TextField, Grid } from "@mui/material";
import CardMedia from "@mui/material/CardMedia";
// import axios from "axios";
import mixpanel from "mixpanel-browser";
import RetrieveFavoritesCallToBackend from "../profileRelated/favorites/RetrieveFavoritesCallToBackend";
import { UserContext } from "../../App";
import { AnalyticsBrowser } from "@segment/analytics-next";

mixpanel.init("d60542dff5aaf889760e7cc2f6316298", { debug: false });

// import components
import { classes } from "./LookalikeFinderStyle";
import Text from "../../components/atoms/Text/Text";
import Button from "../../components/atoms/Button/Button";
import LookalikeItem from "../../components/molecules/LookalikeItem/LookalikeItem";
import BackToTop from "../../components/molecules/BackToTop/BackToTop";
// import FeedbackBox from "../../components/molecules/FeedbackBox/FeedbackBox";
import BackgroundGradient from "../../components/molecules/BackgroundGradient/BackgroundGradient";
import Modal from "../../components/organisms/Modal/Modal";
import LookalikeFinderStoreSelector from "./LookalikeFinderStoreSelector";
import MLService from "./LookalikeFinderMLService";
import LookalikeFinderExamplesSection from "./LookalikeFinderExamplesSection";
import LookalikeFinderFilters from "./LookalikeFinderFilters";
import LookalikeFinderHeaderSection from "./LookalikeFinderHeaderSection";
// import LookalikeFinderAddToChromeSection from "./LookalikeFinderAddToChromeSection";
import PayForFeedback from "./PayForFeedback";
import LookalikeFinderLoading from "./LookalikeFinderLoading";
import LookalikeFinderLoadingInResultContainer from "./LookalikeFinderLoadingInResultContainer";
import AffiliateBlurb from "../../components/molecules/AffiliateBlurb/AffiliateBlurb";
import BrightDataCallToBackend from "./BrightDataCallToBackend";
import { segmentWriteKey } from "../../components/atoms/Segment/Segment";
import ExistingCrateAndBarrelItemCall from "./ExistingCrateAndBarrelItemCall";
import ColorClassifier from "./ColorClassifier";
import MiscellaneousClassifier from "./MiscellaneousClassifier";

export const LookalikeContext = createContext();

const analytics = AnalyticsBrowser.load({ writeKey: segmentWriteKey });

function LookalikeFinder() {
  const [searchStarted, setSearchStarted] = useState(false);
  const [wrongInputMsg, setWrongInputMsg] = useState();
  const [productInformation, setProductInformation] = useState();
  const [examplesDisplay, setExamplesDisplay] = useState("inherit");
  const [resultsContainer, setResultsContainer] = useState(null);
  const [loaderDisplay, setLoaderDisplay] = useState("none");
  const [loaderDisplayInResultContainer, setLoaderDisplayInResultContainer] =
    useState("none");
  const [urlItemInformation, setUrlItemInformation] = useState();
  const [timerFinished, setTimerFinished] = useState(false);
  const [previousURL, setPreviousURL] = useState(null);
  const [previousStringToObject, setPreviousStringToObject] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [userFavorites, setUserFavorites] = useState();

  const { user, lookalikeFinderSearchText, setLookalikeFinderSearchText } =
    React.useContext(UserContext);

  const email = user?.email || "N/A";

  // When the user presses Search
  async function StartSearch(event) {
    event.preventDefault();

    // assign the url value from the input
    const urlInput = document.getElementById("urlInput").value;

    mixpanel.track("(Lookalike Finder) Clicked on Search", {
      email: email,
      link: urlInput,
    });

    setPreviousURL(urlInput);
    setUrlItemInformation(null);
    setResultsContainer(null);
    setExamplesDisplay("none");

    if (
      urlInput === "https://www.crateandbarrel.com/wells-leather-sofa/s378692"
    ) {
      const crateAndBarrelItem = {
        product_id: "29448e96-219f-431b-80c0-8c4be135670f",
        productName: "Wells Leather Sofa",
        imageUrl:
          "https://cb.scene7.com/is/image/Crate/WellsLthrSofa84inCynSOSSF20_1x1/$web_pdp_main_carousel_high$/200720144725/wells-leather-tuxedo-sofa.jpg",
        productType: "sofas-and-couches",
        fabric: "leather",
        color: "brown",
        price: "3099",
        misc: "",
        brand: "Crate & Barrel",
        dimensions: {
          width: "84",
          depth: "35.5",
          height: "31",
        },
      };

      setWrongInputMsg(null);
      setSearchStarted(true);

      // Fetch favorites
      if (user) {
        const favorites = async () => {
          return await RetrieveFavoritesCallToBackend(user?.email);
        };
        favorites().then((favoritesList) => {
          setUserFavorites(favoritesList);
        });
      }

      analytics.track("Product Searched", {
        user_email: user?.email,
        user_name: user?.first_name,
        flow: "Lookalike Finder",
        searched_product_link: urlInput,
        searched_product_name: crateAndBarrelItem.productName,
        searched_product_price: crateAndBarrelItem.price,
        searched_product_color: crateAndBarrelItem.color,
        searched_product_length: crateAndBarrelItem.dimensions.width,
        searched_product_width: crateAndBarrelItem.dimensions.depth,
        searched_product_height: crateAndBarrelItem.dimensions.height,
      });

      setTimeout(() => {
        setProductInformation(crateAndBarrelItem);
      }, 4000);
    } else if (
      urlInput.includes("crateandbarrel.com")
      // urlInput.includes("potterybarn.com") ||
      // urlInput.includes("luluandgeorgia.com")
      // url.includes("rh.com") // Restoration Hardware
      // url.includes("westelm.com")
    ) {
      setWrongInputMsg(null);
      setSearchStarted(true);

      // Fetch favorites
      if (user) {
        const favorites = async () => {
          return await RetrieveFavoritesCallToBackend(user?.email);
        };
        favorites().then((favoritesList) => {
          setUserFavorites(favoritesList);
        });
      }

      let productInformation;

      // Call to see if the link and corresponding product is in our database
      const retrieveExisting = await ExistingCrateAndBarrelItemCall(urlInput);

      if (
        retrieveExisting?.user_token &&
        Object.keys(retrieveExisting.user_token).length > 0
      ) {
        const userToken = retrieveExisting.user_token;
        const colorAndFabric = userToken.fabric + " " + userToken.color;
        const colorClassifierObject = ColorClassifier(colorAndFabric);
        const color = colorClassifierObject?.color;
        const misc = MiscellaneousClassifier(userToken.name);

        productInformation = {
          productName: userToken.name,
          imageUrl: userToken.original_image_url,
          productType: "sofas-and-couches",
          fabric: userToken.fabric,
          color: color,
          price: userToken.cost,
          misc: misc,
          brand: "Crate & Barrel",
          dimensions: {
            width: userToken.width,
            depth: userToken.depth,
            height: userToken.height,
          },
        };

        setTimeout(() => {
          setProductInformation(productInformation);
        }, 4000);
      } else {
        // Get the html value from the url
        const innerHTML = await fetchHTML(urlInput);

        // Once we have the innerHTML, get the information on the product you're looking at
        productInformation = LookalikeFinderStoreSelector(innerHTML, urlInput);
        console.log("productInformation: ", productInformation);
        setProductInformation(productInformation);
      }

      analytics.track("Product Searched", {
        user_email: user?.email,
        user_name: user?.first_name,
        flow: "Lookalike Finder",
        searched_product_link: urlInput,
        searched_product_name: productInformation?.productName,
        searched_product_price: productInformation?.price,
        searched_product_color: productInformation?.color,
        searched_product_length: productInformation?.dimensions?.width,
        searched_product_width: productInformation?.dimensions?.depth,
        searched_product_height: productInformation?.dimensions?.height,
      });
    } else {
      setSearchStarted(false);
      setWrongInputMsg(
        <Box sx={classes.sectionResults}>
          <Text variant="h2" sx={classes.resultsTitle}>
            We can&apos;t find what you&apos;re searching for.
          </Text>
          <Box sx={classes.catalogContainer}>
            <Text sx={classes.noProductsContainer}>
              Try something like{" "}
              <b>
                &quot;https://www.crateandbarrel.com/wells-leather-sofa/s378692&quot;
              </b>
            </Text>
          </Box>
        </Box>
      );
    }
  }

  // Equivalent to StartSearch(), but if the user presses "Enter" on the keyboard
  async function handleKeyDown(event) {
    if (event.key === "Enter") {
      StartSearch(event);
    }
  }

  async function fetchHTML(url) {
    if (url == previousURL) {
      return previousStringToObject;
    }

    try {
      const userToken = await BrightDataCallToBackend(url);

      // Assuming userToken contains the HTML string we need to parse
      const parser = new DOMParser();
      const doc = parser.parseFromString(userToken, "text/html");

      setPreviousStringToObject(doc);
      return doc;
    } catch (error) {
      console.error("Error in fetchHTML:", error);
      // Handle the error appropriately
      // For example, you might return null or an error message
      // Depending on how you want to handle errors in your application
      return null; // or any appropriate error handling
    }
  }

  useEffect(() => {
    if (searchStarted) {
      setLoaderDisplay("inherit");
    } else {
      setLoaderDisplay("none");
    }

    // Tracking how long it takes to load. Will give a timeout message after 120 seconds.
    if (searchStarted) {
      setTimeout(() => {
        setTimerFinished(true);
      }, 120000);
      return () => clearTimeout();
    }
  }, [searchStarted]);

  // Give message saying no results if it timeouts after 120 seconds
  useEffect(() => {
    if (timerFinished) {
      if (!searchStarted) {
        return;
      } else {
        setSearchStarted(false);
        setResultsContainer(
          <Box sx={classes.sectionResults}>
            <Text variant="h2" sx={classes.resultsTitle}>
              Affordable Alternatives
            </Text>
            {/* <FeedbackBox /> */}
            <Box sx={classes.catalogContainer}>
              <Text sx={classes.noProductsContainer}>
                Uh oh...we couldn&apos;t find any products. Try another link!
              </Text>
            </Box>
          </Box>
        );
      }
    }
  }, [timerFinished]);

  useEffect(() => {
    if (productInformation) {
      setLoaderDisplayInResultContainer("inherit");

      if (
        productInformation?.productName == "Wells Leather Sofa" &&
        productInformation?.color == "brown" &&
        productInformation?.price == "3099"
      ) {
        setTimeout(() => {
          const recommendations = {
            product_matches: true,
            products: [
              {
                product_id: "2c58c24d-01a3-4e2d-975c-52942f7cda3b",
                item_title: `Z-hom 79" Genuine Leather Sofa, Top-Grain 3 Seater Leather Couch, Mid-Century Modern Upholstered Sofa for Living Room Bedroom Apartment Office, Cognac Tan`,
                image_url:
                  "https://m.media-amazon.com/images/I/51pU8x67lEL._AC_SL1500_.jpg",
                image_variants_url_array: [
                  "https://m.media-amazon.com/images/I/71myMFHrmbL._AC_SX679_.jpg",
                  "https://m.media-amazon.com/images/I/51uuCxjWW4L._AC_SX679_.jpg",
                  "https://m.media-amazon.com/images/I/81seKY4HCwL._AC_SX679_.jpg",
                  "https://m.media-amazon.com/images/I/71Jt+R9fg5L._AC_SX679_.jpg",
                ],
                brand: "Amazon",
                product_type: "sofas-and-couches",
                sale_price: 949.99,
                length: 31,
                width: 79,
                height: 34,
                product_page_url: "https://amzn.to/3SDa8AO",
              },
              {
                product_id: "b1e09602-7a39-46ed-a987-215c45f5d7a6",
                item_title: `Z-hom 70" Top-Grain Leather Sofa, 2-Seat Upholstered Loveseat Sofa Modern Couch, Luxury Classic for Living Room Bedroom Apartment Office`,
                image_url:
                  "https://m.media-amazon.com/images/I/61Voy+aNPgL._AC_SX679_.jpg",
                image_variants_url_array: [
                  "https://m.media-amazon.com/images/I/81x5Hl3fACL._AC_SX679_.jpg",
                  "https://m.media-amazon.com/images/I/51ASoOEyPuL._AC_SX679_.jpg",
                  "https://m.media-amazon.com/images/I/71ESRq+50eL._AC_SX679_.jpg",
                  "https://m.media-amazon.com/images/I/81RCDVL+UyL._AC_SX679_.jpg",
                ],
                brand: "Amazon",
                product_type: "sofas-and-couches",
                sale_price: 650,
                length: 30,
                width: 70,
                height: 33.7,
                product_page_url: "https://amzn.to/3G3594M",
              },
              {
                product_id: "ddc17460-8204-4468-95b6-5868ffb09f75",
                item_title: `Vonanda Faux Leather Sofa Couch, Mid-Century 73 Inch 3 Seater Leather Couch with Hand-Stitched Comfort Cushion and Bolster Pillows for Living Room,Elegant Cognac Tan`,
                image_url:
                  "https://m.media-amazon.com/images/I/81tiHGiShOL._AC_SX679_.jpg",
                image_variants_url_array: [
                  "https://m.media-amazon.com/images/I/61YM-oVLYeL._AC_SX679_.jpg",
                  "https://m.media-amazon.com/images/I/81jl0g6BgyL._AC_SX679_.jpg",
                  "https://m.media-amazon.com/images/I/81Os1fdPNUL._AC_SX679_.jpg",
                  "https://m.media-amazon.com/images/I/71jSIU3z+5L._AC_SX679_.jpg",
                ],
                brand: "Amazon",
                product_type: "sofas-and-couches",
                sale_price: 399.99,
                length: 28,
                width: 73,
                height: 31,
                product_page_url: "https://amzn.to/47xHyVG",
              },
              {
                product_id: "5f431698-746e-4af9-8701-e142da334d03",
                item_title: `Vonanda Flora Couch, Faux Leather Sofa Caramel, 3 Seater Couch with Soft Cloud Cushions, 72 Inch Couch for Durable Small Spaces, Compact Apartment, House, Living Room,Condo, Loft, Bungalow, Caramel`,
                image_url:
                  "https://m.media-amazon.com/images/I/81sNpSLBUdL._AC_SX679_.jpg",
                image_variants_url_array: [
                  "https://m.media-amazon.com/images/I/61vRIi5DwjL._AC_SX679_.jpg",
                  "https://m.media-amazon.com/images/I/71V1xI1F5aL._AC_SX679_.jpg",
                  "https://m.media-amazon.com/images/I/71QszcqXA3L._AC_SX679_.jpg",
                  "https://m.media-amazon.com/images/I/71COAAcBj6L._AC_SX679_.jpg",
                ],
                brand: "Amazon",
                product_type: "sofas-and-couches",
                sale_price: 419.99,
                length: 31.8,
                width: 31,
                height: 28.9,
                product_page_url: "https://amzn.to/3uhimVd",
              },
            ],
          };

          setLoaderDisplayInResultContainer("none");
          setResultsContainer(
            <Box sx={classes.sectionResults}>
              <Text variant="h2" sx={classes.resultsTitle}>
                Affordable alternatives
              </Text>
              {/* <FeedbackBox /> */}
              <Box sx={classes.tipContainer}>
                <Text>
                  <b>Tip:</b> Found something you like? Add it to your Amazon
                  cart so you can review it later!
                </Text>
              </Box>
              <LookalikeFinderFilters />
              <LookalikeFinderLoadingInResultContainer />
              <Box sx={classes.catalogContainer}>
                {recommendations.product_matches ? (
                  <Box>
                    {recommendations.products.map(
                      (product, recommendationKey) => {
                        if (product.sale_price != null) {
                          return (
                            <LookalikeItem
                              product={product}
                              key={recommendationKey}
                            />
                          );
                        }
                      }
                    )}
                  </Box>
                ) : recommendations.product_matches === false ? (
                  <Box>
                    <Text sx={classes.noProductsText}>
                      We couldn&apos;t find any exact results, but here are
                      other recommendations you might enjoy!
                    </Text>
                    <Text sx={classes.similarResultsText}>
                      Other Recommendations
                    </Text>
                    <Box>
                      {recommendations.products.map(
                        (product, recommendationKey) => {
                          if (product.sale_price != null) {
                            return (
                              <LookalikeItem
                                product={product}
                                key={recommendationKey}
                              />
                            );
                          }
                        }
                      )}
                    </Box>
                  </Box>
                ) : (
                  <Text sx={classes.noProductsText}>
                    Uh oh...we couldn&apos;t find any products. Try another
                    link!
                  </Text>
                )}
              </Box>
            </Box>
          );
          setSearchStarted(false);
        }, 4000);
      } else {
        // Once we have the productInformation, get the product recommendations
        const recommendationsAsyncCall = async () => {
          return await MLService(productInformation);
        };

        recommendationsAsyncCall()
          .then((recommendations) => {
            setLoaderDisplayInResultContainer("none");
            setResultsContainer(
              <Box sx={classes.sectionResults}>
                <Text variant="h2" sx={classes.resultsTitle}>
                  Affordable alternatives
                </Text>
                {/* <FeedbackBox /> */}
                <Box sx={classes.tipContainer}>
                  <Text>
                    <b>Tip:</b> Found something you like? Add it to your Amazon
                    cart so you can review it later!
                  </Text>
                </Box>
                <LookalikeFinderFilters />
                <LookalikeFinderLoadingInResultContainer />
                <Box sx={classes.catalogContainer}>
                  {recommendations.product_matches ? (
                    <Box>
                      {recommendations.products.map(
                        (product, recommendationKey) => {
                          if (product.sale_price != null) {
                            return (
                              <LookalikeItem
                                product={product}
                                key={recommendationKey}
                              />
                            );
                          }
                        }
                      )}
                    </Box>
                  ) : recommendations.product_matches === false ? (
                    <Box>
                      <Text sx={classes.noProductsText}>
                        We couldn&apos;t find any exact results, but here are
                        other recommendations you might enjoy!
                      </Text>
                      <Text sx={classes.similarResultsText}>
                        Other Recommendations
                      </Text>
                      <Box>
                        {recommendations.products.map(
                          (product, recommendationKey) => {
                            if (product.sale_price != null) {
                              return (
                                <LookalikeItem
                                  product={product}
                                  key={recommendationKey}
                                />
                              );
                            }
                          }
                        )}
                      </Box>
                    </Box>
                  ) : (
                    <Text sx={classes.noProductsText}>
                      Uh oh...we couldn&apos;t find any products. Try another
                      link!
                    </Text>
                  )}
                </Box>
              </Box>
            );
            setSearchStarted(false);
          })
          .catch((error) => {
            console.log("found error", error);
          });
      }
    }
  }, [productInformation]);

  useEffect(() => {
    if (productInformation) {
      let priceString = "";

      if (
        productInformation.price !== undefined &&
        productInformation.price !== null
      ) {
        const price =
          typeof productInformation.price === "string"
            ? productInformation.price
            : productInformation.price.toString();
        priceString = Number(price.replace(/,/g, "")).toLocaleString();
      }

      setUrlItemInformation(
        <Box sx={classes.urlItemInfoContainer}>
          <Text variant="h2" sx={classes.urlItemHeader}>
            Product from {productInformation.brand}
          </Text>
          {productInformation.imageUrl !== "" && (
            <CardMedia
              component="img"
              image={productInformation.imageUrl}
              alt="Product image from your link"
              sx={classes.urlItemImage}
            />
          )}
          <Text sx={classes.urlItemTitle}>
            {productInformation.productName}
          </Text>
          <Text sx={classes.urlItemPrice}>
            {productInformation.price && `$${priceString}`}
          </Text>
          {productInformation.dimensions.width &&
            productInformation.dimensions.depth &&
            productInformation.dimensions.height && (
              <Text sx={classes.urlItemDimensions}>
                <b>Product Dimensions:</b>
                <br />
                {productInformation.dimensions.width}&quot;L x{" "}
                {productInformation.dimensions.depth}
                &quot;W x {productInformation.dimensions.height}
                &quot;H
              </Text>
            )}
        </Box>
      );
    } else {
      setUrlItemInformation();
    }
  }, [productInformation]);

  // Retrieve favorites
  useEffect(() => {
    async function fetchData() {
      if (user) {
        const favorites = await RetrieveFavoritesCallToBackend(user?.email);
        setUserFavorites(favorites);
      }
    }
    fetchData();
  }, [user]);

  function favoriteModalOnPressPrimaryBtn() {
    document.location.href = "/sign-up";
  }

  function favoriteModalOnPressCancelButton() {
    setShowModal(false);
  }

  return (
    <LookalikeContext.Provider
      value={{
        userFavorites,
        setUserFavorites,
        showModal,
        setShowModal,
        loaderDisplay,
        setLoaderDisplay,
        loaderDisplayInResultContainer,
        setLoaderDisplayInResultContainer,
        productInformation,
        setProductInformation,
      }}
    >
      <Box sx={classes.contentContainer}>
        {showModal ? (
          <Modal
            prompt="To save to your favorites, you'll first need to create an account."
            primaryBtnTxt="Create account"
            secondaryBtnTxt="Cancel"
            onPressPrimaryBtn={favoriteModalOnPressPrimaryBtn}
            onPressCancelButton={favoriteModalOnPressCancelButton}
          />
        ) : null}

        <PayForFeedback />
        <BackToTop />

        <BackgroundGradient />

        {/* <LookalikeFinderAddToChromeSection /> */}

        <LookalikeFinderHeaderSection />

        <form onSubmit={(event) => StartSearch(event)}>
          <Box sx={classes.sectionInputForm}>
            <TextField
              label="Enter URL"
              variant="standard"
              id="urlInput"
              onKeyDown={handleKeyDown}
              value={lookalikeFinderSearchText}
              onChange={(e) => setLookalikeFinderSearchText(e.target.value)}
              sx={classes.textField}
            />
            <Button variant="primary" type="submit" sx={classes.searchButton}>
              Search
            </Button>
          </Box>
        </form>

        <AffiliateBlurb />

        {/* Result examples */}
        <Box
          sx={{
            ...classes.lookalikeFinderExamplesContainer,
            display: examplesDisplay,
          }}
        >
          <LookalikeFinderExamplesSection />
        </Box>

        <LookalikeFinderLoading />

        {wrongInputMsg}

        <Grid container spacing={2}>
          <Grid item md={4} sm={12}>
            {urlItemInformation}
          </Grid>

          <Grid item md={8} sm={12} sx={classes.resultGridItem}>
            {resultsContainer}
          </Grid>
        </Grid>
      </Box>
    </LookalikeContext.Provider>
  );
}

export default LookalikeFinder;
