import { customTheme } from "../../atoms/CustomTheme/CustomTheme";

export const classes = {
  desktopContainer: {
    // [customTheme.breakpoints.down("md")]: {
    //   display: "none",
    // },
  },
  mobileContainer: {
    display: "none",
    // [customTheme.breakpoints.down("md")]: {
    //   display: "flex",
    //   flexDirection: "row",
    //   overflowX: "scroll",
    // },
  },

  // renderFurnitureObjects styles
  renderFurnitureObjectsContainer: {
    marginTop: "24px",
    display: "inline-block",
    maxWidth: "31.3%",
    padding: "1.3%",
    [customTheme.breakpoints.down("md")]: {
      width: "30.7%",
    },
    [customTheme.breakpoints.down("sm")]: {
      width: "47%",
      maxWidth: "inherit",
    },
  },
  itemCost: {
    marginTop: "8px",
    fontSize: "24px",
    fontWeight: "bold",
    [customTheme.breakpoints.down("md")]: {
      fontSize: "16px",
    },
  },
  swapBtn: {
    marginTop: "8px",
    padding: "12px 16px",
    // width: "104px",
    width: "100%",
  },
  shopNowBtn: {
    marginTop: "8px",
    // width: "176px",
    width: "100%",
    backgroundColor: "#FFCE3C",
    color: "#2A2A2A",
    "&:hover": {
      backgroundColor: "#FFBF00",
    },
  },

  // renderOtherFurnitureObjects styles
  renderOtherFurnitureObjectsContainer: {
    marginTop: "24px",
    display: "inline-block",
    maxWidth: "30.7%",
    padding: "1.3%",
    [customTheme.breakpoints.down("md")]: {
      width: "30.7%",
    },
    [customTheme.breakpoints.down("sm")]: {
      width: "47%",
      maxWidth: "inherit",
    },
  },

  // renderFurnitureObjectsMobile styles
  renderFurnitureObjectsMobileContainer: {
    display: "inline-block",
    marginLeft: "4px",
    marginRight: "4px",
  },

  // renderOtherFurnitureObjectsMobile styles
  renderOtherFurnitureObjectsMobileContainer: {
    display: "inline-block",
    marginLeft: "4px",
    marginRight: "4px",
  },
};
