import React from "react";
import Footer from "../../components/organisms/Footer/Footer";
import CardMedia from "@mui/material/CardMedia";

// import components
import BlogParallaxRoot from "../../components/molecules/BlogParallaxRoot/BlogParallaxRoot";
import BlogParallaxLayerBack from "../../components/molecules/BlogParallaxLayerBack/BlogParallaxLayerBack";
import BlogParallaxLayerMiddle from "../../components/molecules/BlogParallaxLayerMiddle/BlogParallaxLayerMiddle";
import BlogParallaxLayerFront from "../../components/molecules/BlogParallaxLayerFront/BlogParallaxLayerFront";
import BlogContentContainer from "../../components/molecules/BlogContentContainer/BlogContentContainer";
import BlogDate from "../../components/molecules/BlogDate/BlogDate";
import BlogTitle from "../../components/molecules/BlogTitle/BlogTitle";
import BlogParagraph from "../../components/molecules/BlogParagraph/BlogParagraph";
import BlogH2 from "../../components/molecules/BlogH2/BlogH2";
import BlogH3 from "../../components/molecules/BlogH3/BlogH3";
import BlogImage from "../../components/molecules/BlogImage/BlogImage";
import BlogInlineAd1 from "../../components/molecules/BlogInlineAd1/BlogInlineAd1";
import BlogInlineAd2 from "../../components/molecules/BlogInlineAd2/BlogInlineAd2";

// import images
const bannerImage =
  "https://craftle-static.s3.us-east-2.amazonaws.com/blog/blog9/blog-9-banner.jpg";
const image1 =
  "https://craftle-static.s3.us-east-2.amazonaws.com/blog/blog9/blog-9-image-1.jpg";
const image2 =
  "https://craftle-static.s3.us-east-2.amazonaws.com/blog/blog9/blog-9-image-2.jpg";

function BlogPost9() {
  return (
    <BlogParallaxRoot>
      <BlogParallaxLayerBack>
        <CardMedia component="img" image={bannerImage} alt="" />
      </BlogParallaxLayerBack>

      <BlogParallaxLayerMiddle />

      <BlogParallaxLayerFront>
        <BlogContentContainer>
          <BlogDate date="May 22, 2022" />
          <BlogTitle title="How Long Can Head Lice Live on Furniture? Separating New Facts from Old Fiction" />
          <BlogParagraph>
            Head lice are tiny, blood-sucking parasites that may be found on
            human hair. They are most commonly found on the scalp, but can also
            live on other parts of the body, such as furniture. Head lice are
            not dangerous and do not carry any diseases, but they can be
            extremely irritating.
          </BlogParagraph>
          <BlogParagraph>
            How long head lice can live on furniture depends on a number of
            factors, such as the type of furniture and the temperature or
            humidity of the room. Let’s get into more detail on what head lice
            are and what to do about them if it gets on you or your furniture.
          </BlogParagraph>

          <BlogH2 title="What is Head Lice?" />
          <BlogImage image={image1} alt="" caption="" />
          <BlogParagraph>
            Head lice are small insects that live on the scalp and feed on human
            blood. They are most commonly found in children, but can also be
            found in adults. Head lice are not dangerous and do not carry any
            diseases, but they can be extremely irritating.
          </BlogParagraph>

          <BlogH2 title="What is the Life Cycle of Lice?" />
          <BlogParagraph>
            The life cycle of lice includes three stages: the egg stage, the
            nymph stage, and the adult stage.
          </BlogParagraph>
          <BlogH3 title="Egg Stage:" />
          <BlogParagraph>
            Head lice lay their eggs, or nits, on the hair shaft. Nits are
            small, white eggs that are glued to the hair shaft and are very
            difficult to remove.
          </BlogParagraph>
          <BlogH3 title="Nymph Stage:" />
          <BlogParagraph>
            After about 7-10 days, the nits hatch, and the nymphs emerge. Nymphs
            are small, immature lice that look like adults but are smaller.
          </BlogParagraph>
          <BlogH3 title="Adult Stag:" />
          <BlogParagraph>
            After about 2-3 weeks, the nymphs mature into adults. Adults are
            about the size of a sesame seed and are brown or black in color.
          </BlogParagraph>

          <BlogH2 title="How Do Head Lice Travel?" />
          <BlogParagraph>
            Head lice are spread through direct contact with someone who has
            head lice. They can also be spread by sharing contaminated items,
            such as hats, brushes, or towels.
          </BlogParagraph>

          <BlogInlineAd1 />

          <BlogH2 title="How Long Can Lice Live on Your Head?" />
          <BlogParagraph>
            Lice can live on your head for about 30 days. However, they will
            eventually die if they do not have access to human blood.
          </BlogParagraph>

          <BlogH2 title="How Long Can Lice Live Without Humans or Food?" />
          <BlogParagraph>
            Lice can live for about 48 hours without a human host. However, they
            will eventually die if they do not have access to human blood.
          </BlogParagraph>

          <BlogH2 title="How Long Can Head Lice Live on Furniture?" />
          <BlogImage image={image2} alt="" caption="" />
          <BlogParagraph>
            Head lice can survive for several days on furniture, but they will
            eventually die if they do not have access to human blood.
          </BlogParagraph>

          <BlogH2 title="How Do You Know if You Have Lice?" />
          <BlogParagraph>
            The most common symptom of head lice is itching. Other symptoms
            include:
          </BlogParagraph>
          <BlogParagraph>- Small, white eggs on the hair shaft</BlogParagraph>
          <BlogParagraph>- Nits that are difficult to remove</BlogParagraph>
          <BlogParagraph>- Sores on the scalp from scratching</BlogParagraph>
          <BlogParagraph>- Irritability</BlogParagraph>
          <BlogParagraph>
            If you suspect that you or your child has head lice, it is important
            to see a doctor or healthcare provider for diagnosis and treatment.
          </BlogParagraph>

          <BlogH2 title="How Do You Treat Your Hair if You Have Lice?" />
          <BlogParagraph>
            There are several over-the-counter and prescription treatments
            available for head lice. These treatments usually include a
            medicated shampoo or lotion that is applied to the hair and left on
            for a period of time. The shampoo or lotion kills the lice and their
            eggs.
          </BlogParagraph>
          <BlogParagraph>
            It is important to follow the directions on the package carefully
            and to contact your doctor or healthcare provider if you have any
            questions.
          </BlogParagraph>

          <BlogH2 title="How Do You Treat Your Home if Your Home Has Lice?" />
          <BlogParagraph>
            If you or your child has head lice, it is important to treat your
            home as well. This can be done by:
          </BlogParagraph>
          <BlogParagraph>
            - Washing all bedding, clothing, and towels in hot water
          </BlogParagraph>
          <BlogParagraph>
            - Vacuuming all carpets and upholstered furniture
          </BlogParagraph>
          <BlogParagraph>- Cleaning all combs and brushes</BlogParagraph>
          <BlogParagraph>- Disinfecting all surfaces</BlogParagraph>
          <BlogParagraph>
            If you have any questions about how to treat your home, it is
            important to contact your doctor or healthcare provider.
          </BlogParagraph>

          <BlogH2 title="How Do You Know if Lice Are Gone?" />
          <BlogParagraph>
            After treatment, it is important to check for lice every few days.
            This can be done by using a fine-toothed comb to comb through the
            hair and look for lice or nits. If you find any, it is important to
            contact your doctor or healthcare provider.
          </BlogParagraph>
          <BlogParagraph>
            It is also important to check your home for lice and nits. This can
            be done by vacuuming all carpets and upholstered furniture and by
            disinfecting all surfaces. If you have any questions about how to
            check for lice, it is important to contact your doctor or healthcare
            provider.
          </BlogParagraph>

          <BlogH2 title="Myths About Hair Lice" />
          <BlogParagraph>
            There are many myths about hair lice. Some of these myths include:
          </BlogParagraph>
          <BlogParagraph>- Lice only live on dirty hair</BlogParagraph>
          <BlogParagraph>
            - Lice can only be spread through head-to-head contact
          </BlogParagraph>
          <BlogParagraph>
            - Lice can only be spread through sharing contaminated items
          </BlogParagraph>
          <BlogParagraph>
            - Lice can only be spread through contact with an infected person
          </BlogParagraph>
          <BlogParagraph>
            The truth is that lice can live on clean or dirty hair. Lice are
            also spread through head-to-head contact, sharing contaminated
            items, or contact with an infected person
          </BlogParagraph>
          <BlogParagraph>
            If you have any questions about hair lice, it is important to
            contact your doctor or healthcare provider
          </BlogParagraph>

          <BlogH2 title="How Do You Prevent Head Lice?" />
          <BlogParagraph>
            There are several things you can do to prevent head lice, such as:
          </BlogParagraph>
          <BlogParagraph>
            - Avoiding head-to-head contact with someone who has head lice.
          </BlogParagraph>
          <BlogParagraph>
            - Not sharing hats, brushes, or other personal items with someone
            who has head lice
          </BlogParagraph>
          <BlogParagraph>
            - Washing bedding, clothing, and towels in hot water
          </BlogParagraph>
          <BlogParagraph>
            - Vacuuming all carpets and upholstered furniture
          </BlogParagraph>
          <BlogParagraph>- Cleaning all combs and brushes</BlogParagraph>
          <BlogParagraph>- Disinfecting all surfaces</BlogParagraph>
          <BlogParagraph>
            If you have any questions about how to prevent head lice, it is
            important to contact your doctor or healthcare provider.
          </BlogParagraph>

          <BlogH2 title="Conclusion" />
          <BlogParagraph>
            So if you’re ever unfortunate enough to get head lice, remember that
            they can also live on furniture. And if you think you’ve gotten rid
            of them all by treating your hair, you may be in for a nasty
            surprise the next time you sit down on the couch or bed. Better to
            take extra precautions and clean all of your furniture thoroughly
            before letting anyone else near it!
          </BlogParagraph>

          <BlogInlineAd2 />
        </BlogContentContainer>
        <Footer />
      </BlogParallaxLayerFront>
    </BlogParallaxRoot>
  );
}

export default BlogPost9;
