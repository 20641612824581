import * as React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import { CardActionArea, Box } from "@mui/material";
import PropTypes from "prop-types";
import { UserContext } from "../../../App";

// import components
import { classes } from "./ImageTextCardStyle";
import Text from "../../atoms/Text/Text";
import mixpanel from "../../atoms/Mixpanel/Mixpanel";

// import icons
import { ReactComponent as SelectedIcon } from "../../../assets/icons/selected.svg";

const propTypes = {
  label: PropTypes.string,
  labelKey: PropTypes.string,
  imageUrl: PropTypes.string,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  selected: PropTypes.bool,
  source: PropTypes.string,
};

const defaultProps = {
  label: "Living Room",
  labelKey: "living-room",
  imageUrl:
    "https://ksassets.timeincuk.net/wp/uploads/sites/56/2021/06/Small-living-room-ideas_Grey-sofa.jpg",
  onClick: () => {
    console.log("ImageTextCard presed");
  },
  disabled: false,
  selected: false,
  source: "",
};

function ImageTextCard({
  label,
  labelKey,
  imageUrl,
  onClick,
  disabled,
  selected,
  source,
}) {
  const { user } = React.useContext(UserContext);
  const email = user?.email || "N/A";

  const handleClick = () => {
    // Call the onClick function passed as a prop
    if (onClick) {
      onClick();
    }

    // Track the event with Mixpanel
    if (!selected && source !== "Select Room") {
      mixpanel.track(`(CMS) Selected Item: ${label}`, {
        email: email,
      });
    }
  };

  return (
    <Card sx={classes.container}>
      <CardActionArea onClick={handleClick} disabled={disabled}>
        {disabled && <Box sx={classes.disabledBox} />}
        <Box sx={classes.cardContainer}>
          {selected && (
            <Box sx={classes.selectedIconContainer}>
              <SelectedIcon />
            </Box>
          )}
          <CardMedia
            component="img"
            image={imageUrl}
            alt={labelKey}
            sx={classes.furnitureImage}
          />
        </Box>

        <CardContent sx={classes.labelContainer}>
          <Text sx={classes.labelText}>{label}</Text>
        </CardContent>
      </CardActionArea>
    </Card>
  );
}

ImageTextCard.propTypes = propTypes;
ImageTextCard.defaultProps = defaultProps;

export default ImageTextCard;
