import React from "react";
import { Box } from "@mui/material";
import CardMedia from "@mui/material/CardMedia";

// import components
import Text from "../../components/atoms/Text/Text";
import { customTheme } from "../../components/atoms/CustomTheme/CustomTheme";
import LookalikeFinderExample from "./LookalikeFinderExample";

// import images
const img1 =
  "https://craftle-static.s3.us-east-2.amazonaws.com/lookalike-finder-page/barrett-ii-leather-3-seat-sofa.jpg";
const img2 =
  "https://craftle-static.s3.us-east-2.amazonaws.com/lookalike-finder-page/barrett-ii-leather-3-seat-sofa-lookalike.jpg";
const img3 =
  "https://craftle-static.s3.us-east-2.amazonaws.com/lookalike-finder-page/dillon-spalted-primavera-round-wood-coffee-table.jpg";
const img4 =
  "https://craftle-static.s3.us-east-2.amazonaws.com/lookalike-finder-page/dillon-spalted-primavera-round-wood-coffee-table-lookalike.jpg";
// const img5 =
//   "https://craftle-static.s3.us-east-2.amazonaws.com/lookalike-finder-page/fabienne-sofa.jpg";
// const img6 =
//   "https://craftle-static.s3.us-east-2.amazonaws.com/lookalike-finder-page/fabienne-sofa-lookalike.jpg";
// const img7 =
//   "https://craftle-static.s3.us-east-2.amazonaws.com/lookalike-finder-page/hannah-sideboard.jpg";
// const img8 =
//   "https://craftle-static.s3.us-east-2.amazonaws.com/lookalike-finder-page/hannah-sideboard-lookalike.jpg";
const img9 =
  "https://craftle-static.s3.us-east-2.amazonaws.com/lookalike-finder-page/brooke-6-drawer-dresser.jpg";
const img10 =
  "https://craftle-static.s3.us-east-2.amazonaws.com/lookalike-finder-page/brooke-6-drawer-dresser-lookalike.jpg";
// const img11 =
//   "https://craftle-static.s3.us-east-2.amazonaws.com/lookalike-finder-page/oceanside-90-wide-arm-deep-seat-sofa.jpg";
// const img12 =
//   "https://craftle-static.s3.us-east-2.amazonaws.com/lookalike-finder-page/oceanside-90-wide-arm-deep-seat-sofa-lookalike.jpg";
const img13 =
  "https://craftle-static.s3.us-east-2.amazonaws.com/lookalike-finder-page/tottori-grey-abstract-rug-5x8.jpg";
const img14 =
  "https://craftle-static.s3.us-east-2.amazonaws.com/lookalike-finder-page/tottori-grey-abstract-rug-5x8-lookalike.jpg";
const img15 =
  "https://craftle-static.s3.us-east-2.amazonaws.com/lookalike-finder-page/imabari-sofa.jpg";
const img16 =
  "https://craftle-static.s3.us-east-2.amazonaws.com/lookalike-finder-page/imabari-sofa-lookalike.jpg";
// const img17 =
//   "https://craftle-static.s3.us-east-2.amazonaws.com/lookalike-finder-page/wells-3-piece-u-shaped-sectional-with-natural-leg-finish.jpg";
// const img18 =
//   "https://craftle-static.s3.us-east-2.amazonaws.com/lookalike-finder-page/wells-3-piece-u-shaped-sectional-with-natural-leg-finish-lookalike.jpg";
const img19 =
  "https://craftle-static.s3.us-east-2.amazonaws.com/lookalike-finder-page/lounge-deep-3-piece-u-shaped-sectional-sofa.jpg";
const img20 =
  "https://craftle-static.s3.us-east-2.amazonaws.com/lookalike-finder-page/lounge-deep-3-piece-u-shaped-sectional-sofa-lookalike.jpg";
// const img21 =
//   "https://craftle-static.s3.us-east-2.amazonaws.com/lookalike-finder-page/yukon-natural-coffee-table.jpg";
// const img22 =
//   "https://craftle-static.s3.us-east-2.amazonaws.com/lookalike-finder-page/yukon-natural-coffee-table-lookalike.jpg";

// import icons
const arrowRight =
  "https://craftle-static.s3.us-east-2.amazonaws.com/icons/arrow-right.svg";

export default function LookalikeFinderExamplesSection() {
  const classes = {
    container: {
      padding: "32px",
      borderRadius: "16px",
      backgroundColor: "#FFF",
      border: "solid 4px #2A2A2A",
    },
    title: { fontSize: "24px", fontWeight: "bold", marginBottom: "8px" },
    exampleContainer: {
      display: "flex",
      flexDirection: "row",
      marginTop: "32px",
      [customTheme.breakpoints.down("sm")]: {
        flexDirection: "column",
      },
    },
    arrowContainer: {
      height: "100%",
      marginLeft: "40px",
      marginRight: "40px",
      alignSelf: "center",
      [customTheme.breakpoints.down("md")]: {
        marginLeft: "24px",
        marginRight: "24px",
      },
      [customTheme.breakpoints.down("sm")]: {
        marginTop: "24px",
        marginBottom: "24px",
        rotate: "90deg",
      },
    },
    arrow: {
      width: "48px",
      height: "48px",
    },
    separator: {
      marginTop: "32px",
      width: "100%",
      height: "3px",
      backgroundColor: "#757575",
    },
  };

  return (
    <Box sx={classes.container}>
      <Text variant="h2" sx={classes.title}>
        Here are some examples
      </Text>
      <Box sx={classes.exampleContainer}>
        <LookalikeFinderExample
          image={img1}
          productId="aba4e414-e143-4e0f-bd23-26220ef0fec9"
          brand="Crate & Barrel"
          productName="Barrett II Leather 3 Seat Sofa"
          price="4,199.00"
          productType="sofas-and-couches"
          length="91"
          width="36"
          height="35.5"
          link="https://sovrn.co/vuwlyo4"
        />
        <Box sx={classes.arrowContainer}>
          <CardMedia
            component="img"
            image={arrowRight}
            alt="Product image"
            sx={classes.arrow}
          />
        </Box>
        <LookalikeFinderExample
          image={img2}
          productId="86b7a90c-8236-4692-b791-f53a3daa17db"
          brand="Amazon"
          productName="POLY & BARK Sorrento Sofa in Full-Grain Pure-Aniline Italian Leather (Cognac Tan)"
          price="1,895.00"
          productType="sofas-and-couches"
          length="85"
          width="35.5"
          height="34"
          link="https://amzn.to/3JcchOL"
        />
      </Box>
      <Box sx={classes.separator} />
      <Box sx={classes.exampleContainer}>
        <LookalikeFinderExample
          image={img15}
          productId="7d12b41d-3b34-4d3c-9a9f-553a705a35d3"
          brand="Lulu And Georgia"
          productName="Imabari Sofa"
          price="3,498"
          productType="sofas-and-couches"
          length="82.25"
          width="41.5"
          height="31.5"
          link="https://www.luluandgeorgia.com/products/imabari-sofa"
        />
        <Box sx={classes.arrowContainer}>
          <CardMedia
            component="img"
            image={arrowRight}
            alt="Product image"
            sx={classes.arrow}
          />
        </Box>
        <LookalikeFinderExample
          image={img16}
          productId="3a5590f1-0a47-496e-9578-6df6420c6ae8"
          brand="Amazon"
          productName="Acanva Mid Century Modern Curved Living Room Sofa, 4-Seat Boucle Fabric Couch for Bedroom, Office, Apartment, White"
          price="2,148.40"
          productType="sofas-and-couches"
          length="96.4"
          width="51.2"
          height="28.3"
          link="https://amzn.to/3YWgSJD"
        />
      </Box>
      <Box sx={classes.separator} />
      <Box sx={classes.exampleContainer}>
        <LookalikeFinderExample
          image={img13}
          productId="1c78d066-2936-47d5-bbd2-193a7b3941c2"
          brand="Crate & Barrel"
          productName="Tottori Grey Abstract Area Rug 5'x8'"
          price="949.00"
          productType="area-rugs"
          length="60"
          width="96"
          height="0.25"
          link="https://sovrn.co/eztuk6j"
        />
        <Box sx={classes.arrowContainer}>
          <CardMedia
            component="img"
            image={arrowRight}
            alt="Product image"
            sx={classes.arrow}
          />
        </Box>
        <LookalikeFinderExample
          image={img14}
          productId="d249f9ca-5453-4a38-a225-f0f384627ac1"
          brand="Amazon"
          productName="Artistic Weavers Doria Modern Abstract Area Rug,7'10&quot; x 10'3&quot;,Silver Gray"
          price="163.42"
          productType="area-rugs"
          length="123"
          width="94"
          height="0.25"
          link="https://amzn.to/3l4ui8k"
        />
      </Box>
      <Box sx={classes.separator} />
      <Box sx={classes.exampleContainer}>
        <LookalikeFinderExample
          image={img3}
          productId="ac51f98a-d510-4864-a1e7-a902b794c36a"
          brand="Crate & Barrel"
          productName="Dillon Spalted Primavera Round Wood Coffee Table"
          price="1,899.00"
          productType="coffee-tables"
          length="40"
          width="40"
          height="15"
          link="https://sovrn.co/1e9gpb7"
        />
        <Box sx={classes.arrowContainer}>
          <CardMedia
            component="img"
            image={arrowRight}
            alt="Product image"
            sx={classes.arrow}
          />
        </Box>
        <LookalikeFinderExample
          image={img4}
          productId="cfc60ccb-a11d-4af1-9eca-23b7c7040b2c"
          brand="Amazon"
          productName="POLY & BARK Gamla Coffee Table, Natural/Acacia"
          price="649.86"
          productType="coffee-tables"
          length="39"
          width="39"
          height="12"
          link="https://amzn.to/3YoPyU2"
        />
      </Box>
      <Box sx={classes.separator} />
      <Box sx={classes.exampleContainer}>
        <LookalikeFinderExample
          image={img19}
          productId="520811dd-c2a6-4a98-af4a-fad08240968e"
          brand="Crate & Barrel"
          productName="Lounge Deep 3-Piece U-Shaped Sectional Sofa"
          price="8,260.65"
          productType="sofas-and-couches"
          length="156"
          width="85"
          height="37"
          link="https://sovrn.co/xy9q5b0"
        />
        <Box sx={classes.arrowContainer}>
          <CardMedia
            component="img"
            image={arrowRight}
            alt="Product image"
            sx={classes.arrow}
          />
        </Box>
        <LookalikeFinderExample
          image={img20}
          productId="d49ddcdc-aab5-496a-8752-ed3190767653"
          brand="Amazon"
          productName="P PURLOVE Modern Large Sectional Sofa, U Shape Upholstered..."
          price="1,040.99"
          productType="sofas-and-couches"
          length="116.9"
          width="59.1"
          height="33.1"
          link="https://amzn.to/403bPZd"
        />
      </Box>
      {/* <Box sx={classes.separator} /> */}
      {/* <Box sx={classes.exampleContainer}>
        <LookalikeFinderExample
          image={img11}
          brand="Crate & Barrel"
          productName='Oceanside 90" Wide-Arm Deep-Seat Sofa'
          price="1,999.00"
          length="89"
          width="43"
          height="30"
          link="https://sovrn.co/3ojp05c"
        />
        <Box sx={classes.arrowContainer}>
          <CardMedia
            component="img"
            image={arrowRight}
            alt="Product image"
            sx={classes.arrow}
          />
        </Box>
        <LookalikeFinderExample
          image={img12}
          brand="Amazon"
          productName='Amazon Brand - Stone & Beam Westview Extra-Deep Down-Filled Sofa Couch, 89"W, Cream'
          price="654.43"
          length="88.98"
          width="42.13"
          height="31.5"
          link="https://amzn.to/3Jdtsy4"
        />
      </Box> */}
      {/* <Box sx={classes.exampleContainer}>
        <LookalikeFinderExample
          image={img5}
          brand="Lulu and Georgia"
          productName="Fabienne Sofa"
          price="3,998"
          length="90"
          width="40"
          height="34"
          link="https://www.luluandgeorgia.com/products/fabienne-sofa?variant=39521217052771"
        />
        <Box sx={classes.arrowContainer}>
          <CardMedia
            component="img"
            image={arrowRight}
            alt="Product image"
            sx={classes.arrow}
          />
        </Box>
        <LookalikeFinderExample
          image={img6}
          brand="Amazon"
          productName="Signature Design by Ashley Macleary Modern Velvet Glam Sofa with Brass Metal Legs, Green"
          price="599.99"
          length="82"
          width="36"
          height="36"
          link="https://amzn.to/3ZerG6J"
        />
      </Box> */}
      {/* <Box sx={classes.separator} /> */}
      {/* <Box sx={classes.exampleContainer}>
        <LookalikeFinderExample
          image={img7}
          brand="Lulu and Georgia"
          productName="Hannah Sideboard"
          price="1,438"
          length="73"
          width="18"
          height="31"
          link="https://www.luluandgeorgia.com/products/hannah-sideboard?variant=39503281520739"
        />
        <Box sx={classes.arrowContainer}>
          <CardMedia
            component="img"
            image={arrowRight}
            alt="Product image"
            sx={classes.arrow}
          />
        </Box>
        <LookalikeFinderExample
          image={img8}
          brand="Amazon"
          productName="AWQM Rattan Cabinet Set of 2, Sideboard Buffet Cabinet with Storage/Adjustable Shelf..."
          price="299.99"
          length="15.75"
          width="15.75"
          height="31.5"
          link="https://amzn.to/3ZZh4J5"
        />
      </Box> */}
      <Box sx={classes.separator} />
      <Box sx={classes.exampleContainer}>
        <LookalikeFinderExample
          image={img9}
          productId="bb1a3a92-372d-407d-bd41-afe9959f9a49"
          brand="Lulu and Georgia"
          productName="Brooke 6-Drawer Dresser"
          price="2,498"
          productType="dressers"
          length="60"
          width="19"
          height="33"
          link="https://www.luluandgeorgia.com/products/brooke-6-drawer-dresser-1?variant=40111260074083"
        />
        <Box sx={classes.arrowContainer}>
          <CardMedia
            component="img"
            image={arrowRight}
            alt="Product image"
            sx={classes.arrow}
          />
        </Box>
        <LookalikeFinderExample
          image={img10}
          productId="7bc89f14-f1f2-424b-b975-7941dce6e6b8"
          brand="Amazon"
          productName='Signature Design by Ashley Socalle Modern Industrial 6 Drawer 54" Dresser, Natural Beige'
          price="334.98"
          productType="dressers"
          length="54"
          width="16"
          height="29"
          link="https://www.amazon.com/dp/B08D71F9M2?th=1&psc=1&linkCode=sl1&tag=craftle-20&linkId=02ac0e92d49f8fc44095f64d4d0a6579&language=en_US&ref_=as_li_ss_tl"
        />
      </Box>
      {/* <Box sx={classes.exampleContainer}>
        <LookalikeFinderExample
          image={img21}
          brand="Crate & Barrel"
          productName="Yukon Warm Acacia Live Edge Solid Wood Coffee Table"
          price="899"
          length="54"
          width="33"
          height="18"
          link="https://sovrn.co/w7sxzj4"
        />
        <Box sx={classes.arrowContainer}>
          <CardMedia
            component="img"
            image={arrowRight}
            alt="Product image"
            sx={classes.arrow}
          />
        </Box>
        <LookalikeFinderExample
          image={img22}
          brand="Amazon"
          productName="Modus Furniture Reese Live Edge Rectangular Coffee Table, Natural Acacia..."
          price="599.99"
          length="54"
          width="30"
          height="19"
          link="https://amzn.to/3FB7xQx"
        />
      </Box> */}
    </Box>
  );
}
