import React, { useState, useEffect } from "react";
import { Box } from "@mui/material";
import CardMedia from "@mui/material/CardMedia";
import { useNavigate } from "react-router-dom";
import PropTypes from "prop-types";

// import components
import { classes } from "./AIConvoSearchStyle";
import Text from "../../../components/atoms/Text/Text";
import Textfield from "../../../components/molecules/Textfield/Textfield";
import Button from "../../../components/atoms/Button/Button";
import AISearchEngineBackendCall from "../../../pages/aiFurnitureShopper/AISearchEngineBackendCall";
import { AIFurnitureShopperContext } from "../../../pages/aiFurnitureShopper/AIFurnitureShopper";

// import icons
const arrowUpRight =
  "https://craftle-static.s3.us-east-2.amazonaws.com/icons/arrow-up-right.svg";

AIConvoSearch.propTypes = {
  showExamples: PropTypes.bool,
};

AIConvoSearch.defaultProps = {
  showExamples: false,
};

export default function AIConvoSearch(showExamples) {
  const [convoArray, setConvoArray] = useState([]);
  const [input, setInput] = useState("");
  const { setLoaderDisplay } = React.useContext(AIFurnitureShopperContext);

  let navigate = useNavigate();

  useEffect(() => {
    const lastItem = convoArray[convoArray.length - 1];
    const userQuery =
      lastItem && lastItem.content && lastItem.content.toLowerCase();

    // Only makes the call if it's a user msg
    if (lastItem && lastItem.role == "user") {
      if (
        userQuery ==
          "help me find a white curved couch like kim k's for under $3k" ||
        userQuery ==
          "help me find a white curved couch like kim k’s for under $3k"
      ) {
        setTimeout(function () {
          navigate("/ai-furniture-shopper/search/e/678");
        }, 5000);
      } else {
        const backendCall = async () => {
          return await AISearchEngineBackendCall(convoArray);
        };
        backendCall()
          .then((returnedData) => {
            setLoaderDisplay(false);
            localStorage.setItem(
              "aiSearchEngineConvo",
              JSON.stringify(returnedData)
            );
            return;
          })
          .then(() => {
            navigate("/ai-furniture-shopper/search-results");
          })
          .catch((error) => {
            console.error(error);
          });
      }
    }
  }, [convoArray]);

  async function sendMsg(event) {
    // Prevent the default form submission behavior if event is present
    if (event) {
      event.preventDefault();
    }

    setLoaderDisplay(true);

    // Assign the url value from the input
    let inputMsg = input.trim();

    if (inputMsg !== "") {
      // Add it to the conversation array
      setConvoArray((prevConvoArray) => [
        ...prevConvoArray,
        { role: "user", content: inputMsg },
      ]);
    }
  }

  function HandleKeyDown(event) {
    if (event.key === "Enter") {
      sendMsg();
    }
  }

  return (
    <Box
      sx={{
        ...classes.searchContainer,
        padding: showExamples ? "8px 8px 24px 8px" : 0,
      }}
    >
      <form
        onSubmit={(event) => {
          sendMsg(event);
        }}
      >
        <Box sx={classes.textfieldAndSearch}>
          <Textfield
            type="text"
            id="input"
            name="input"
            placeholder="Type here..."
            onChange={(event) => setInput(event.target.value)}
            value={input}
            sx={classes.textfield}
          />
          <Button
            variant="primary"
            onKeyDown={HandleKeyDown}
            value={input}
            sx={classes.searchButton}
          >
            Search
          </Button>
        </Box>
      </form>

      <Box sx={classes.exampleSearches}>
        <Text>Example Searches</Text>
        <Box
          onClick={() => {
            setInput("Help me find a beige, Modern sofa under $2000");
          }}
          sx={classes.exampleSearchRow}
        >
          <Text sx={classes.exampleSearchText}>
            Help me find a beige, Modern sofa under $2000
          </Text>
          <CardMedia
            component="img"
            image={arrowUpRight}
            alt="Try-this-search icon"
            sx={classes.arrowUpRight}
          />
        </Box>
        {/* Find me a white curved couch like kim k's for under $3k */}
      </Box>
    </Box>
  );
}
