import * as React from "react";
import { createTheme } from "@mui/material/styles";
import { Divider, Box } from "@mui/material";
import PropTypes from "prop-types";

// import components
import Text from "../../atoms/Text/Text";

const customTheme = createTheme({});

const propTypes = {
  text: PropTypes.string,
};

const defaultProps = {
  text: null,
};

TextDivider.propTypes = propTypes;
TextDivider.defaultProps = defaultProps;

export default function TextDivider({ text }) {
  return (
    <Box
      sx={{
        paddingLeft: 0,
        alignSelf: "flex-start",
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        marginBottom: "20px",
        width: "100%",
        [customTheme.breakpoints.down("sm")]: {
          marginBottom: "15px",
          flexDirection: "column",
          alignItems: "flex-start",
        },
      }}
    >
      <Divider
        light={true}
        sx={{
          position: "absolute",
          width: "100%",
          borderColor: "#E3E3E3",
          display: "block",
          marginLeft: "-16px",
          [customTheme.breakpoints.up("sm")]: {
            display: "none",
          },
        }}
      />
      <Text
        sx={{
          fontSize: 30,
          lineHeight: 1.5,
          display: "inline-block",
          [customTheme.breakpoints.down("sm")]: {
            fontSize: 16,
            fontWeight: "bold",
            marginTop: "20px",
          },
        }}
      >
        {text}
      </Text>
      <Divider
        light={true}
        sx={{
          width: 200,
          marginLeft: "20px",
          borderColor: "#D8D8D8",
          display: "inline-block",
          [customTheme.breakpoints.down("sm")]: {
            display: "none",
          },
        }}
      />
    </Box>
  );
}
