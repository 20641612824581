import React, { useState, useEffect } from "react";
import jwtDecode from "jwt-decode";
import { classes } from "./LogInStyle";
import { Box } from "@mui/material";
import PropTypes from "prop-types";

// Import components
import Text from "../../components/atoms/Text/Text";
import GoogleLoginCallToBackend from "./GoogleLoginCallToBackend";

const GoogleSignInButton = ({ redirectHref }) => {
  const [showErrorMsg, setShowErrorMsg] = useState(false);

  useEffect(() => {
    initializeGoogleSignIn();
  }, []);

  const initializeGoogleSignIn = () => {
    const clientConfig = {
      client_id:
        "510488826764-1d9dquloi17heo3nfbik17svto28i821.apps.googleusercontent.com",
    };

    if (window.google && window.google.accounts) {
      // eslint-disable-next-line no-undef
      google.accounts.id.initialize({
        client_id: clientConfig.client_id,
        callback: onSignIn,
      });

      const buttonDiv = document.getElementById("buttonDiv");
      const outerContainerWidth = window.getComputedStyle(
        buttonDiv.parentElement
      ).width;

      // eslint-disable-next-line no-undef
      google.accounts.id.renderButton(
        document.getElementById("buttonDiv"),
        {
          marginTop: "40px",
          theme: "filled_black",
          size: "large",
          shape: "pill",
          width: outerContainerWidth,
          text: "signin_with",
        } // customization attributes
      );

      // eslint-disable-next-line no-undef
      google.accounts.id.prompt();
    } else {
      console.error("Google SDK is not loaded yet!");
    }
  };

  const decodeGoogleResponse = (idToken) => {
    try {
      const decodedToken = jwtDecode(idToken);
      return decodedToken;
    } catch (error) {
      console.error("Error decoding Google response:", error);
      return null;
    }
  };

  async function saveUserToken(token) {
    return new Promise((resolve, reject) => {
      try {
        localStorage.setItem("user", JSON.stringify(token));
        resolve();
      } catch (error) {
        reject(error);
      }
    });
  }

  async function onSignIn(response) {
    // Handle the sign-in response here, e.g., save user data to state, dispatch an action, etc.
    console.log("Google sign-in success:", response);
    const id_token = response.credential;
    console.log(id_token);

    // Decode the Google response credentials to get user information
    const decodedToken = decodeGoogleResponse(id_token);
    if (decodedToken) {
      console.log("Decoded Google Token:", decodedToken);
      // You can now access user information from the decodedToken object.
      // For example:
      const { email, given_name } = decodedToken;
      let userInfo = {
        email: email,
        firstName: given_name,
      };
      console.log(userInfo);

      // Get data from the DB
      const response = await GoogleLoginCallToBackend(userInfo);

      if (response) {
        // Store the user in localStorage
        saveUserToken(response.data.body.user_token).then(() => {
          document.location.href = redirectHref;
        });
      } else {
        setShowErrorMsg(true);
      }
    }
  }

  return (
    <Box sx={{ width: "100%" }}>
      {showErrorMsg ? (
        <Text sx={classes.errorMsg}>
          We couldn&apos;t log you in using Google Sign-In. Please try again, or
          use Email and Password below.
        </Text>
      ) : null}
      <Box
        id="buttonDiv"
        sx={{ marginTop: "40px", width: "100%", "& > div": { width: "100%" } }}
      ></Box>
    </Box>
  );
};

GoogleSignInButton.propTypes = {
  redirectHref: PropTypes.string,
};

GoogleSignInButton.defaultProps = {
  redirectHref: "/",
};

export default GoogleSignInButton;
