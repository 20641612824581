import React, { useContext, useState, useEffect } from "react";
import { Box } from "@mui/material";
import PropTypes from "prop-types";
import CardMedia from "@mui/material/CardMedia";

// import components
import { classes } from "./MobileOtherFurnitureClickCommandsStyle";
import MobileCMSResultsCommand from "../../molecules/MobileCMSResultsCommand/MobileCMSResultsCommand";

// import icons
import { ReactComponent as SwapIcon } from "../../../assets/icons/swap.svg";
const closeIcon =
  "https://craftle-static.s3.us-east-2.amazonaws.com/icons/x.svg";

// import context
import { Context } from "../../../context/context";
import { CMSResultsContext } from "../../../pages/CraftMyStyle/pages/CMSResults";

const propTypes = {
  selectedFurnitureType: PropTypes.string,
  isMobile: PropTypes.bool,
  urlPlanId: PropTypes.number,
};
const defaultProps = {
  selectedFurnitureType: null,
  isMobile: false,
  urlPlanId: 0,
};

function MobileOtherFurnitureClickCommands({ selectedFurnitureType }) {
  const [globalState, globalDispatch] = useContext(Context);
  const {
    mobileShowOtherCommands,
    setMobileShowOtherCommands,
    setCurrFurnitureCommand,
  } = React.useContext(CMSResultsContext);

  const [addedOurProduct, setAddedOurProduct] = useState(false);

  useEffect(() => {
    if (selectedFurnitureType) {
      const productId = selectedFurnitureType;
      if (globalState.uploadedImages && globalState.uploadedImages[productId]) {
        const productCategory = globalState.uploadedImages[productId].category;
        if (productCategory) {
          setAddedOurProduct(true);
        } else {
          setAddedOurProduct(false);
        }
      } else {
        setAddedOurProduct(false);
      }
    }
  }, [selectedFurnitureType]);

  useEffect(() => {
    setCurrFurnitureCommand(globalState.currFurnitureCommand);
  }, [globalState.currFurnitureCommand]);

  function closeFurnitureCommands() {
    globalDispatch({ type: "RESET_FURNITURE_TYPE_SELECTED" });
    globalDispatch({
      type: "SET_FURNITURE_COMMAND",
      command: "null",
    });
    setMobileShowOtherCommands(false);
  }

  function pressSwapItem() {
    globalDispatch({
      type: "SET_FURNITURE_COMMAND",
      command: "SWAP",
    });
    setMobileShowOtherCommands(false);
  }

  /* These "other" mobile commands are hidden by default */
  return (
    <Box
      sx={{
        ...classes.mobileOtherCommands,
        bottom: mobileShowOtherCommands ? "0px" : "-100%",
      }}
    >
      <Box
        sx={classes.mobileCloseContainer}
        onClick={() => {
          closeFurnitureCommands();
        }}
      >
        <CardMedia
          component="img"
          image={closeIcon}
          alt="canvas icon"
          sx={classes.closeIcon}
        />
      </Box>

      {/* Swap button shouldn't appear for uploaded images */}
      {!globalState.uploadedImages ||
      (globalState.uploadedImages &&
        !(selectedFurnitureType in globalState.uploadedImages)) ||
      addedOurProduct ? (
        <MobileCMSResultsCommand
          icon={<SwapIcon />}
          text="Swap"
          marginBottom="24px"
          onClick={() => {
            pressSwapItem();
          }}
        />
      ) : null}
    </Box>
  );
}

MobileOtherFurnitureClickCommands.propTypes = propTypes;
MobileOtherFurnitureClickCommands.defaultProps = defaultProps;

export default MobileOtherFurnitureClickCommands;
