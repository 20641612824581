import React, { useState, useEffect, createContext } from "react";
import { Box } from "@mui/material";

// import components
import { classes } from "./TrendingProductSectionStyle";
import Text from "../../atoms/Text/Text";
import Button from "../../atoms/Button/Button";
import ModalLogInHome from "../ModalLogInHome/ModalLogInHome";
import ModalSignUpHome from "../ModalSignUpHome/ModalSignUpHome";
import HomeCatalogItem from "../../molecules/HomeCatalogItem/HomeCatalogItem";
import RetrieveFavoritesCallToBackend from "../../../pages/profileRelated/favorites/RetrieveFavoritesCallToBackend";

// import context
import { UserContext } from "../../../App";

export const TrendingProductSectionContext = createContext();

// eslint-disable-next-line react/prop-types
function TrendingProductSection({ trendingProductRef }) {
  const [showLoginModal, setShowLoginModal] = useState(false);
  const [showSignUpModal, setShowSignUpModal] = useState(false);
  const [userFavorites, setUserFavorites] = useState();

  const { user } = React.useContext(UserContext);

  const currentUrl = `${window.location.origin}${location.pathname}`;
  const trendingItems = [
    {
      // product_id is a UUID v4
      product_id: "afb27454-92a7-43f1-bfcd-c13db32cd079",
      name: `SONGMICS 30 Inches Folding Storage Ottoman Bench, Storage Chest, Foot Rest Stool, Dark Gray ULSF47K`,
      stars: 4.6,
      brand: "amazon",
      product_type: "ottomans",
      cost: 42.99,
      deal: 32.89,
      dimensions: '15"D x 30"W x 15"H',
      original_image_url:
        "https://m.media-amazon.com/images/I/81KsIDeoAVL._AC_SL1500_.jpg",
      product_page_url: "https://amzn.to/3AlhF01",
    },
    {
      product_id: "7b65a6b4-65c4-4067-ade2-b6a9c38e5243",
      name: `Furinno Luder Bookcase / Book / Storage , 5-Cube, White`,
      stars: 4.2,
      brand: "amazon",
      product_type: "storage",
      cost: 32.99,
      deal: null,
      dimensions: '9.4"D x 19.5"W x 31.5"H',
      original_image_url:
        "https://m.media-amazon.com/images/I/61oD6Jvp8TL._AC_SL1500_.jpg",
      product_page_url: "https://amzn.to/3M37ijW",
    },
    {
      product_id: "ee8b7d6e-81cc-405f-b876-3140b5c03a08",
      name: `Horshod Criss Cross Chair, Cross Legged Office Chair, Wide Comfty Desk Chair, No Wheels Armless Computer Task Chair, Swivel Fabric Vanity Home Chair, Height Adjustable`,
      stars: 4.4,
      brand: "amazon",
      product_type: "office-chairs",
      cost: 79.89,
      deal: 49.83,
      dimensions: `19.45"D x 25.98"W x 34.45"H`,
      original_image_url:
        "https://m.media-amazon.com/images/I/810fXUN4uCL._AC_SL1500_.jpg",
      product_page_url: "https://amzn.to/4dlmTaB",
    },
    {
      product_id: "9b7a09b7-0410-4704-a96b-03a55923c4fb",
      name: `upsimples 8x10 Picture Frame, Display Pictures 5x7 with Mat or 8x10 Without Mat, Wall Hanging Photo Frame, Black, 1 Pack`,
      stars: 4.6,
      brand: "amazon",
      product_type: "picture-frames",
      cost: 15.99,
      deal: 5.99,
      dimensions: '10"L x 8"W',
      original_image_url:
        "https://m.media-amazon.com/images/I/71RekxT+qML._AC_SL1500_.jpg",
      product_page_url: "https://amzn.to/3WId4MP",
    },
    {
      product_id: "0b022681-135a-4564-81c9-1ccfa5731fd0",
      name: `Utopia Bedding Throw Pillows Insert (Pack of 2, White) - 18 x 18 Inches Bed and Couch Pillows - Indoor Decorative Pillows`,
      stars: 4.6,
      brand: "amazon",
      product_type: "decorative-pillows",
      cost: 18.99,
      deal: 17.99,
      dimensions: `18x18 Inch`,
      original_image_url:
        "https://m.media-amazon.com/images/I/61Vil0HlUUL._AC_SL1500_.jpg",
      product_page_url: "https://amzn.to/3Axpsrq",
    },
  ];

  useEffect(() => {
    if (user) {
      const fetchFavorites = async () => {
        try {
          const favoritesList = await RetrieveFavoritesCallToBackend(
            user.email
          );
          setUserFavorites(favoritesList);
        } catch (error) {
          console.error("Error fetching favorites:", error);
        }
      };

      fetchFavorites();
    }
  }, [user]);

  return (
    <TrendingProductSectionContext.Provider
      value={{
        setShowLoginModal,
        setShowSignUpModal,
      }}
    >
      <Box ref={trendingProductRef} sx={classes.container}>
        {showLoginModal && <ModalLogInHome redirectHref={currentUrl} />}
        {showSignUpModal && <ModalSignUpHome redirectHref={currentUrl} />}
        <Text sx={classes.hashtag}>#trend-setter</Text>
        <Text variant="h2" sx={classes.title}>
          Trending Products
        </Text>
        <Text sx={classes.subTitle}>
          Come back every week to see new trending products.
        </Text>
        <Box sx={classes.categorySection}>
          {/* Items */}
          {trendingItems.map((item) => (
            <HomeCatalogItem
              key={item.product_id}
              itemInfo={item}
              userFavorites={userFavorites}
            />
          ))}
        </Box>
        <Button
          variant="tertiary"
          href="/living-room"
          sx={classes.seeAllProductsLink}
        >
          See All Products
        </Button>
      </Box>
    </TrendingProductSectionContext.Provider>
  );
}

export default TrendingProductSection;
