import { customTheme } from "../../../components/atoms/CustomTheme/CustomTheme";

export const classes = {
  commonStyles: {
    fontSize: "16px",
    fontWeight: "bold",
    // padding: "16px 24px",
    borderRadius: "100px",
    width: "fit-content",
    lineHeight: "16px",
    textAlign: "center",
    boxSizing: "border-box", // isn't working to keep the border/stroke inside
    MozBoxSizing: "border-box",
    WebkitBoxSizing: "border-box",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    gap: "8px",
    textDecoration: "none",
    textTransform: "none",
    cursor: "pointer",
    transition: "0.2s ease-in-out",
    [customTheme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  primaryButton: {
    color: "#FFFFFF",
    backgroundColor: "#2A2A2A",
    textDecoration: "none",
    "&:hover": {
      backgroundColor: "#3E3E3E",
    },
  },
  secondaryButton: {
    color: "#2A2A2A",
    backgroundColor: "#FFFFFF",
    border: "solid 2px #2A2A2A",
    textDecoration: "none",
    "&:hover": {
      backgroundColor: "#FDE4A2",
    },
  },
  tertiaryButton: {
    color: "#2A2A2A",
    backgroundColor: "#F3F3F3",
    textDecoration: "none",
    "&:hover": {
      backgroundColor: "#E9E9E9",
    },
  },
  fourthButton: {
    color: "#2A2A2A",
    textDecoration: "none",
    textTransform: "none",
    fontSize: "16px",
    fontWeight: "bold",
    padding: 0,
    lineHeight: 1.5,
    verticalAlign: "baseline",
    "&:hover": {
      cursor: "pointer",
      backgroundColor: "inherit",
    },
  },
  icon: {
    width: "24px",
    height: "24px",
  },
};
