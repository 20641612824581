import axios from "axios";

// import components
import { EndPoint } from "../../atoms/EndPoint/EndPoint";

const END_POINTS = {
  prod: "https://f9yspfs3rg.execute-api.us-east-2.amazonaws.com/default/craftle-shop-catalog-call",
  test: "https://f9yspfs3rg.execute-api.us-east-2.amazonaws.com/default/craftle-shop-catalog-call",
  local: "http://127.0.0.1:8080/shop/catalog",
};

export default async function ShopCatalogBackendCall(categoryID) {
  const responseFromAWS = await (async () => {
    try {
      const response = await axios.get(END_POINTS[EndPoint], {
        headers: {
          "Content-Type": "application/json",
        },
        params: {
          category_id: categoryID,
        },
      });
      return response;
    } catch (e) {
      console.log(e.message);
    }
  })();

  if (responseFromAWS?.data) {
    if (responseFromAWS.data.catalog_info) {
      return responseFromAWS.data;
    } else if (responseFromAWS.data.body) {
      const body = JSON.parse(responseFromAWS.data.body);
      return body;
    }
  }
  return null;
}
