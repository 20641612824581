import React from "react";
import Footer from "../../components/organisms/Footer/Footer";
import CardMedia from "@mui/material/CardMedia";

// import components
import BlogParallaxRoot from "../../components/molecules/BlogParallaxRoot/BlogParallaxRoot";
import BlogParallaxLayerBack from "../../components/molecules/BlogParallaxLayerBack/BlogParallaxLayerBack";
import BlogParallaxLayerMiddle from "../../components/molecules/BlogParallaxLayerMiddle/BlogParallaxLayerMiddle";
import BlogParallaxLayerFront from "../../components/molecules/BlogParallaxLayerFront/BlogParallaxLayerFront";
import BlogContentContainer from "../../components/molecules/BlogContentContainer/BlogContentContainer";
import BlogDate from "../../components/molecules/BlogDate/BlogDate";
import BlogTitle from "../../components/molecules/BlogTitle/BlogTitle";
import BlogParagraph from "../../components/molecules/BlogParagraph/BlogParagraph";
import BlogH2 from "../../components/molecules/BlogH2/BlogH2";
// import BlogH3 from "../../components/molecules/BlogH3/BlogH3";
import BlogImage from "../../components/molecules/BlogImage/BlogImage";
import BlogInlineAd1 from "../../components/molecules/BlogInlineAd1/BlogInlineAd1";
import BlogInlineAd2 from "../../components/molecules/BlogInlineAd2/BlogInlineAd2";

// import images
const bannerImage =
  "https://craftle-static.s3.us-east-2.amazonaws.com/blog/blog10/blog-10-banner.jpg";
const image1 =
  "https://craftle-static.s3.us-east-2.amazonaws.com/blog/blog10/blog-10-image-1.jpg";
const image2 =
  "https://craftle-static.s3.us-east-2.amazonaws.com/blog/blog10/blog-10-image-2.jpg";
const image3 =
  "https://craftle-static.s3.us-east-2.amazonaws.com/blog/blog10/blog-10-image-3.jpg";

function BlogPost10() {
  return (
    <BlogParallaxRoot>
      <BlogParallaxLayerBack>
        <CardMedia component="img" image={bannerImage} alt="" />
      </BlogParallaxLayerBack>

      <BlogParallaxLayerMiddle marginTop="200vh" height="200vh" />

      <BlogParallaxLayerFront>
        <BlogContentContainer>
          <BlogDate date="May 24, 2022" />
          <BlogTitle title="Fixer Upper: Do They Keep Furniture or Do They Give It Back?" />
          <BlogParagraph>
            On the popular TV show Fixer Upper, homeowners are given a stunning
            makeover of their homes. But what happens to all the furniture used
            to stage the home? Are the homeowners given the furniture as part of
            the makeover, or do they have to return it after filming wraps?
          </BlogParagraph>
          <BlogParagraph>
            Here you will find out all there is to know. Let’s get started!
          </BlogParagraph>

          <BlogH2 title="Do the People Keep the Furniture on Fixer Upper?" />
          <BlogParagraph>
            In short, the answer is no. The furniture used in the show Fixer
            Upper is not included in the show. The homeowners are required to
            either purchase the pieces from HGTV or return them after filming
            wraps.
          </BlogParagraph>

          <BlogInlineAd1 />

          <BlogH2 title="What is the show Fixer Upper?" />
          <BlogImage image={image1} alt="" caption="Jeff Jones/HGTV" />
          <BlogParagraph>
            Fixer Upper is a home renovation show hosted by husband and wife
            duo, Chip and Joanna Gaines. The couple has a company called
            Magnolia Homes, which is based in Waco, Texas.
          </BlogParagraph>
          <BlogParagraph>
            Each episode of Fixer Upper features a different family or couple
            who are in need of some serious help when it comes to their home.
            The Gaines take on a variety of projects, from small fixer-uppers to
            total gut jobs. No matter the size or scope of the project, the
            Gaines always manage to turn each home into a beautiful and stylish
            space.
          </BlogParagraph>
          <BlogParagraph>
            One of the things that make Fixer Upper so popular is that viewers
            get to see all the behind-the-scenes work that goes into each home
            renovation. This includes everything from the initial consultation
            with the homeowners to the big reveal at the end of the episode.
          </BlogParagraph>
          <BlogParagraph>
            In addition to being able to see all the hard work that goes into
            each project, viewers also get some insight into the personal lives
            of Chip and Joanna. The couple has four young children, and they
            often give viewers a glimpse into their home life.
          </BlogParagraph>

          <BlogH2 title="Who are Chip and Joanna Gaines?" />
          <BlogImage image={image2} alt="" caption="Credit: Mike Davello" />
          <BlogParagraph>
            Chip and Joanna Gaines are the hosts of Fixer Upper. They are also
            the owners of Magnolia Homes, a home renovation and design company
            based in Waco, Texas.
          </BlogParagraph>
          <BlogParagraph>
            The couple met while they were both students at Baylor University.
            They got married in 2003 and have four children together.
          </BlogParagraph>
          <BlogParagraph>
            In addition to being successful business owners and TV
            personalities, Chip and Joanna are also active in their community.
            They are involved in a number of charitable organizations, and they
            also work with Habitat for Humanity.
          </BlogParagraph>

          <BlogH2 title="Fixer Upper: Do They Get To Keep the Furniture?" />
          <BlogParagraph>
            Now that you know a little bit about the show and the hosts, you may
            be wondering what happens to all of the furniture used to stage the
            homes. Do the homeowners get to keep the furniture after filming
            wraps, or do they have to return it?
          </BlogParagraph>
          <BlogParagraph>
            The answer is that it depends. In some cases, the homeowners are
            able to purchase the furniture used in their homes from HGTV. In
            other cases, they are required to return the furniture after filming
            has wrapped.
          </BlogParagraph>

          <BlogH2 title="Wrapping It Up" />
          <BlogImage
            image={image3}
            alt=""
            caption="Joanna Gaines | Rob Kim/Getty Images"
          />
          <BlogParagraph>
            So, there you have it! The answer to the question of whether or not
            homeowners on Fixer Upper get to keep the furniture is that it
            depends on the situation. In some cases, they are able to purchase
            the pieces used in their home, while in other cases, they have to
            return the furniture after the filming has been wrapped.
          </BlogParagraph>
          <BlogParagraph>
            We hope this article was helpful in answering your question!
          </BlogParagraph>

          <BlogInlineAd2 />
        </BlogContentContainer>
        <Footer />
      </BlogParallaxLayerFront>
    </BlogParallaxRoot>
  );
}

export default BlogPost10;
