import React from "react";
import { Box } from "@mui/material";

// import components
// import Text from "../../components/atoms/Text/Text";
// import { customTheme } from "../../components/atoms/CustomTheme/CustomTheme";
import Dropdown from "../../components/molecules/Dropdown/Dropdown";
import { LookalikeContext } from "./LookalikeFinder";

export default function LookalikeFinderExamplesSection() {
  const { setProductInformation } = React.useContext(LookalikeContext);

  const countryOptions = [
    { text: "United States" },
    { text: "Canada" },
    // { text: "Mexico" },
    // { text: "Brazil" },
    // { text: "France" },
    // { text: "Germany" },
    // { text: "Italy" },
    // { text: "Spain" },
    // { text: "United Kingdom" },
  ];
  const colorOptions = [
    { text: "From searched product" }, // If you change this, also update primaryColorAction()
    { text: "Red" },
    { text: "Pink" },
    { text: "Orange" },
    { text: "Yellow" },
    { text: "Green" },
    { text: "Teal" },
    { text: "Blue" },
    { text: "Purple" },
    { text: "Black" },
    { text: "White" },
    { text: "Grey" },
    { text: "Brown" },
    { text: "Beige" },
  ];
  const starRatingOptions = [
    { text: "1 and up" },
    { text: "1.5 and up" },
    { text: "2 and up" },
    { text: "2.5 and up" },
    { text: "3 and up" },
    { text: "3.5 and up" },
    { text: "4 and up" },
    { text: "4.5 and up" },
  ];

  const classes = {
    container: {
      marginTop: "16px",
      display: "flex",
      flexDirection: "row",
      gap: "10px",
      flexWrap: "wrap",
    },
  };

  function countryAction(option) {
    const refinedText = option.text.replace(" and up", "");
    setProductInformation((productInformation) => ({
      ...productInformation,
      country: refinedText,
    }));
  }

  function primaryColorAction(option) {
    if (option.text === "From searched product") {
      setProductInformation((productInformation) => ({
        ...productInformation, // copy the existing productInformation object
        filterColor: null, // set the new filterColor value
      }));
    } else {
      setProductInformation((productInformation) => ({
        ...productInformation,
        filterColor: option.text.toLocaleLowerCase(),
      }));
    }
  }

  function starRatingAction(option) {
    const refinedText = option.text.replace(" and up", "");
    setProductInformation((productInformation) => ({
      ...productInformation,
      minCustomerRating: refinedText,
    }));
  }

  return (
    <Box sx={classes.container}>
      <Dropdown
        label="Country"
        options={countryOptions}
        width="200px"
        onClickAction={countryAction}
        footerText="We'll be adding more options soon"
      />
      <Dropdown
        label="Primary Color"
        options={colorOptions}
        width="244px"
        onClickAction={primaryColorAction}
      />
      <Dropdown
        label="Star Rating"
        options={starRatingOptions}
        width="160px"
        onClickAction={starRatingAction}
      />
    </Box>
  );
}
