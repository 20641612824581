import React, { useState, useEffect } from "react";
import { Box } from "@mui/material";
import PropTypes from "prop-types";
import isEqual from "lodash/isEqual";

// import components
import { classes } from "./AcknowledgmentStyle";
import Text from "../../atoms/Text/Text";
import Button from "../../atoms/Button/Button";
import RetrieveAcknowledgmentBackendCall from "../RetrieveAcknowledgmentBackendCall/RetrieveAcknowledgmentBackendCall";
import UpdateAcknowledgmentBackendCall from "../UpdateAcknowledgmentBackendCall/UpdateAcknowledgmentBackendCall";

Acknowledgment.propTypes = {
  userId: PropTypes.string,
  messageId: PropTypes.string,
  messageContent: PropTypes.object,
  sx: PropTypes.object,
};
Acknowledgment.defaultProps = {
  userId: "",
  messageId: "",
  messageContent: {},
  sx: {},
};

export default function Acknowledgment({
  userId,
  messageId,
  messageContent,
  sx,
}) {
  const [showMessage, setShowMessage] = useState(false);

  const updateShowMessage = (newResponse) => {
    if (!isEqual(showMessage, newResponse)) {
      setShowMessage(true);
    }
  };

  useEffect(() => {
    // Fetch user profile on component mount
    const fetchUserProfile = async () => {
      try {
        const response = await RetrieveAcknowledgmentBackendCall(
          userId,
          messageId
        );
        updateShowMessage(!response.acknowledgments[messageId]);
      } catch (error) {
        console.error("Error fetching user profile:", error);
      }
    };

    fetchUserProfile();
  }, [userId, messageId]);

  const handleAcknowledge = () => {
    const acknowledged = true;
    UpdateAcknowledgmentBackendCall(userId, messageId, acknowledged);
    setShowMessage(false);
  };

  return showMessage ? (
    <Box sx={{ ...classes.container, ...sx }}>
      <Text sx={classes.msg}>{messageContent}</Text>
      <Button variant="secondary" onClick={handleAcknowledge} sx={classes.btn}>
        Got It
      </Button>
    </Box>
  ) : null;
}
