import React, { useEffect, useRef, useState, createContext } from "react";
import { Box } from "@mui/material";
import PropTypes from "prop-types";

// import components
import { classes } from "./MoodBoardStyle";
import CreateCustomizedPlanMoodBoard from "../../../components/molecules/CreateCustomizedPlanMoodBoard/CreateCustomizedPlanMoodBoard";
import MoodboardItems from "../../../components/molecules/MoodboardItems/MoodboardItems";

export const MoodboardContext = createContext();

export default function MoodBoard({
  selectedFurniturePieces,
  selectedOtherFurniturePieces,
  furniturePositions,
  urlPlanId,
  showCreateCustomizedPlanMoodBoard,
  homePageView,
}) {
  const canvasRef = useRef(null);
  const [canvasLength, setCanvasLength] = useState(0);

  // Update the size of the canvas according to the size of the screen
  useEffect(() => {
    const updateCanvasDimensions = () => {
      const containerElement = canvasRef.current;
      const containerWidth = containerElement.offsetWidth;
      const containerHeight = containerElement.offsetHeight;
      const minDimension = Math.min(containerWidth, containerHeight);
      containerElement.style.width = `${minDimension}px`;
      containerElement.style.height = `${minDimension}px`;
      setCanvasLength(minDimension);
    };

    window.addEventListener("resize", updateCanvasDimensions);
    updateCanvasDimensions(); // Initial update

    return () => {
      window.removeEventListener("resize", updateCanvasDimensions);
    };
  }, []);

  function whichMoodBoardToDisplay() {
    if (showCreateCustomizedPlanMoodBoard) {
      return CreateCustomizedPlanMoodBoard();
    }
    return (
      <MoodboardItems
        selectedFurniturePieces={selectedFurniturePieces}
        selectedOtherFurniturePieces={selectedOtherFurniturePieces}
        furniturePositions={furniturePositions}
        planId={urlPlanId}
        homePageView={homePageView}
      />
    );
  }

  return (
    <MoodboardContext.Provider
      value={{
        canvasLength,
        setCanvasLength,
      }}
    >
      <Box
        sx={{
          ...(homePageView
            ? classes.parentBoxStyleForHomePageView
            : classes.parentBoxStyle),
        }}
      >
        <Box
          ref={canvasRef}
          sx={{
            ...(homePageView
              ? classes.canvasStyleForHomePageView
              : classes.canvasStyle),
          }}
        >
          {whichMoodBoardToDisplay()}
        </Box>
      </Box>
    </MoodboardContext.Provider>
  );
}

const propTypes = {
  selectedFurniturePieces: PropTypes.object,
  selectedOtherFurniturePieces: PropTypes.object,
  uploadedImages: PropTypes.object,
  furniturePositions: PropTypes.array,
  urlPlanId: PropTypes.number,
  showCreateCustomizedPlanMoodBoard: PropTypes.bool,
  homePageView: PropTypes.bool,
};
const defaultProps = {
  selectedFurniturePieces: {},
  selectedOtherFurniturePieces: {},
  uploadedImages: {},
  furniturePositions: [],
  urlPlanId: 0,
  showCreateCustomizedPlanMoodBoard: false,
  homePageView: false,
};

MoodBoard.propTypes = propTypes;
MoodBoard.defaultProps = defaultProps;
