import { customTheme } from "../../components/atoms/CustomTheme/CustomTheme";

export const classes = {
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    paddingBottom: "120px",
  },
  backgroundGradient: {
    backgroundImage: "linear-gradient(#FFF4D7, #FFFFFF)",
    position: "absolute",
    width: "100%",
    height: "830px",
    zIndex: -1,
  },
  content: {
    marginTop: "161px",
    maxWidth: "400px",
    width: "100%",
    padding: "0 16px",
    [customTheme.breakpoints.down("sm")]: {
      boxSizing: "border-box",
      marginTop: "81px",
    },
  },
  h1: {
    fontSize: "48px",
  },

  // Form section
  sectionInputForm: {
    marginTop: "16px",
  },
  textField: {
    marginTop: "16px",
    backgroundColor: "#FFFFFF",
    borderRadius: 50,
    width: "400px",
    height: "48px",
    marginRight: "8px",
    boxSizing: "border-box",
    "& .MuiFormLabel-root": {
      paddingLeft: "24px",
      marginTop: "-8px",
    },
    "& .MuiInputBase-root": {
      paddingLeft: "24px",
      paddingRight: "8px",
      marginTop: "8px",
    },
    "& .MuiInputBase-root:before": {
      borderBottom: 0,
    },
    "& .MuiInputBase-root:after": {
      borderBottom: 0,
      display: "none",
    },
    "& .MuiInputBase-root:hover:not(.Mui-disabled):before": {
      borderBottom: 0,
    },
    [customTheme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  errorMsg: {
    marginTop: "8px",
    color: "#D12626",
    fontWeight: "bold",
  },
  logInButton: {
    marginTop: "16px",
    width: "100%",
  },

  // Sign up section
  signUpContainer: {
    marginTop: "24px",
  },
  newtoCraftleText: {
    display: "inline",
  },
  signUpButton: {
    display: "inline",
    minWidth: 0,
  },

  // Legal notice section
  legalNotice: {
    marginTop: "16px",
    lineHeight: 1.5,
  },
  legalBtns: {
    display: "inline",
  },

  // Separator section
  orContainer: {
    display: "inline-flex",
    flexDirection: "row",
    alignItems: "center",
    marginTop: "30px",
    maxWidth: "400px",
    width: "100%",
  },
  orText: {
    height: "24px",
    margin: "0 8px",
  },
  orDivider: {
    width: "45%",
    height: "1px",
    color: "#757575",
  },
};
