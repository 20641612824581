import React from "react";
import { Box } from "@mui/material";
import PropTypes from "prop-types";
import useMediaQuery from "@mui/material/useMediaQuery";

// import components
import Text from "../../atoms/Text/Text";
import Button from "../../atoms/Button/Button";
import { customTheme } from "../../atoms/CustomTheme/CustomTheme";

// import icons
import { ReactComponent as XIcon } from "../../../assets/icons/x.svg";

const propTypes = {
  prompt: PropTypes.string,
  primaryBtnTxt: PropTypes.string,
  secondaryBtnTxt: PropTypes.string,
  onPressPrimaryBtn: PropTypes.func,
  onPressCancelButton: PropTypes.func,
};

const defaultProps = {
  onPressPrimaryBtn: () => {},
  onPressCancelButton: () => {},
};

Modal.propTypes = propTypes;
Modal.defaultProps = defaultProps;

export default function Modal({
  prompt,
  primaryBtnTxt,
  secondaryBtnTxt,
  onPressPrimaryBtn,
  onPressCancelButton,
}) {
  const isMobileOrTabletScreen = useMediaQuery(
    customTheme.breakpoints.down("md")
  );

  function DesktopVersion() {
    const classes = {
      // Background
      background: {
        width: "100vw",
        height: "100vh",
        backgroundColor: "rgba(0, 0, 0, 0.25)",
        position: "fixed",
        top: 0,
        left: 0,
        zIndex: 101,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      },

      // Modal
      modalContainer: {
        backgroundColor: "#FFFFFF",
        borderRadius: "8px",
        padding: "40px 40px 32px 40px",
        position: "relative",
        maxWidth: "480px",
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
      },
      iconContainer: {
        position: "absolute",
        right: "32px",
        top: "32px",
        cursor: "pointer",
      },
      prompt: {
        fontWeight: "bold",
        fontSize: "24px",
        lineHeight: 1.5,
        width: "90%",
      },

      // Actions section
      actionsContainer: {
        marginTop: "32px",
        display: "flex",
        flexDirection: "row",
        justifyContent: "flex-end",
        alignItems: "center",
        width: "100%",
      },
      secondaryButton: {},
      primaryButton: {
        marginLeft: "32px",
      },
    };

    return (
      // Background
      <Box sx={classes.background}>
        {/* Modal */}
        <Box sx={classes.modalContainer}>
          <Box
            sx={classes.iconContainer}
            onClick={() => {
              onPressCancelButton();
            }}
          >
            <XIcon />
          </Box>
          <Text sx={classes.prompt}>{prompt}</Text>

          <Box sx={classes.actionsContainer}>
            <Button
              variant="fourth"
              onClick={() => {
                onPressCancelButton();
              }}
              sx={classes.secondaryButton}
            >
              {secondaryBtnTxt}
            </Button>
            <Button
              variant="primary"
              onClick={() => {
                onPressPrimaryBtn();
              }}
              sx={classes.primaryButton}
            >
              {primaryBtnTxt}
            </Button>
          </Box>
        </Box>
      </Box>
    );
  }

  function MobileAndTabletVersion() {
    const classes = {
      // Background
      background: {
        width: "100vw",
        height: "100vh",
        backgroundColor: "rgba(0, 0, 0, 0.25)",
        position: "fixed",
        top: 0,
        left: 0,
        zIndex: 2,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      },

      // Modal
      modalContainer: {
        display: "none",
        [customTheme.breakpoints.down("md")]: {
          position: "fixed",
          bottom: 0,
          zIndex: 3,
          padding: "16px 32px 32px",
          backgroundColor: "#FFFFFF",
          width: "100vw",
          display: "flex",
          flexDirection: "column",
          boxSizing: "border-box",
          borderRadius: "24px 24px 0 0",
          boxShadow: "0px 0px 15px 0px rgba(0, 0, 0, 0.15)",
        },
      },
      iconContainer: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        marginBottom: "24px",
        cursor: "pointer",
      },
      prompt: {
        fontWeight: "bold",
        fontSize: "20px",
      },

      // Actions section
      actionsContainer: {
        marginTop: "24px",
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
      },
      primaryButton: {
        marginBottom: "24px",
      },
    };

    return (
      // Background
      <Box sx={classes.background}>
        {/* Modal */}
        <Box sx={classes.modalContainer}>
          <Box
            onClick={() => {
              onPressCancelButton();
            }}
            sx={classes.iconContainer}
          >
            <XIcon />
          </Box>
          <Text variant="h2" sx={classes.prompt}>
            {prompt}
          </Text>

          <Box sx={classes.actionsContainer}>
            <Button
              variant="fourth"
              onClick={() => {
                onPressPrimaryBtn();
              }}
              sx={classes.primaryButton}
            >
              {primaryBtnTxt}
            </Button>
            <Button
              variant="fourth"
              onClick={() => {
                onPressCancelButton();
              }}
            >
              {secondaryBtnTxt}
            </Button>
          </Box>
        </Box>
      </Box>
    );
  }

  return isMobileOrTabletScreen ? MobileAndTabletVersion() : DesktopVersion();
}
