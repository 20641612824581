import { customTheme } from "../../atoms/CustomTheme/CustomTheme";

export const classes = {
  container: {
    display: "flex",
    flexDirection: "column",
    width: "90vw",
    margin: "0 auto",
    padding: "80px 0 80px 0",
    [customTheme.breakpoints.down("sm")]: {
      padding: "64px 0 80px 0",
    },
  },
  // backSection: {
  //   fontWeight: "bold",
  // },
  // backButton: {
  //   width: "fit-content",
  //   "&:hover": {
  //     cursor: "pointer",
  //   },
  // },
  // back: { display: "inline-block" },
  // backHighlightedText: {
  //   display: "inline-block",
  //   color: "#EC5858",
  // },
  resultNumber: {
    padding: "24px 0 0 0",
    color: "#757575",
    fontSize: "14px",
  },
  spinnerContainer: {
    marginTop: "40px",
  },
  categorySection: {
    padding: "16px 0 0 0",
    width: "100%",
    display: "flex",
    flexWrap: "wrap",
  },
  title: {
    marginTop: "24px",
    fontSize: "48px",
    lineHeight: 1.2,
    fontWeight: "bold",
  },
  handSelectedMsg: {
    marginTop: "16px",
    backgroundColor: "#DAEBCF",
    padding: "8px 16px",
    width: "fit-content",
    borderRadius: "8px",
  },
  // sortByContainer: {
  //   padding: "40px 0 0 0",
  // },
  // dropdown: {
  //   padding: "12px 16px",
  //   fontSize: "16px",
  //   fontWeight: "bold",
  //   borderRadius: "4px",
  //   color: "#2a2a2a",
  //   // hover doesn't work here because only takes "style"
  //   // "&:hover": {
  //   //   cursor: "pointer",
  //   // },
  // },
};
