import { customTheme } from "../../atoms/CustomTheme/CustomTheme";

export const classes = {
  /*** Desktop ***/
  // Background
  background: {
    width: "100vw",
    height: "100vh",
    backgroundColor: "rgba(0, 0, 0, 0.25)",
    position: "fixed",
    top: 0,
    left: 0,
    zIndex: 101,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },

  // Modal
  modalContainer: {
    backgroundColor: "#FFFFFF",
    borderRadius: "8px",
    padding: "40px 40px 32px 40px",
    position: "relative",
    maxWidth: "480px",
    boxSizing: "border-box",
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
  },
  iconContainer: {
    position: "absolute",
    right: "32px",
    top: "32px",
    cursor: "pointer",
  },
  prompt: {
    fontWeight: "bold",
    fontSize: "24px",
    width: "90%",
    [customTheme.breakpoints.down("sm")]: {
      textAlign: "center",
      width: "100%",
    },
  },

  // Separator section
  orContainer: {
    display: "inline-flex",
    flexDirection: "row",
    alignItems: "center",
    marginTop: "30px",
    width: "100%",
  },
  orText: {
    height: "24px",
    margin: "0 8px",
  },
  orDivider: {
    width: "45%",
    height: "1px",
    color: "#757575",
  },

  googleSignUpButtonContainer: {
    marginTop: "-8px",
    width: "400px",
  },

  // Actions section
  actionsContainer: {
    marginTop: "16px",
    display: "flex",
    flexDirection: "column",
    // justifyContent: "flex-end",
    alignItems: "center",
    width: "100%",
  },

  // Form section
  sectionInputForm: {
    // marginTop: "24px",
  },
  textField: {
    marginTop: "16px",
    // backgroundColor: "#FFFFFF",
    width: "400px",
    // marginRight: "8px",
    // boxSizing: "border-box",
    "& .MuiFormLabel-root": {
      paddingLeft: "24px",
      marginTop: "-8px",
    },
    "& .MuiInputBase-root": {
      paddingLeft: "24px",
      paddingRight: "8px",
      marginTop: "8px",
    },
    "& .MuiInputBase-root:before": {
      borderBottom: 0,
    },
    "& .MuiInputBase-root:after": {
      borderBottom: 0,
      display: "none",
    },
    "& .MuiInputBase-root:hover:not(.Mui-disabled):before": {
      borderBottom: 0,
    },
    [customTheme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  errorMsg: {
    marginTop: "8px",
    color: "#D12626",
    fontWeight: "bold",
  },
  logInButton: {},

  // Sign up section
  signUpContainer: {
    marginTop: "24px",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
  },
  newtoCraftleText: {
    display: "inline",
  },
  signUpButton: {
    display: "inline",
    width: "100%",
  },

  // Legal notice section
  legalNotice: {
    marginTop: "24px",
    fontSize: "14px",
    textAlign: "center",
  },
  legalBtns: {
    display: "inline",
    fontSize: "14px",
  },

  /*** Mobile and Tablet ***/
  // Background
  mobileBackground: {
    width: "100vw",
    height: "100vh",
    backgroundColor: "rgba(0, 0, 0, 0.25)",
    position: "fixed",
    top: 0,
    left: 0,
    zIndex: 100,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },

  // Modal
  mobileModalContainer: {
    display: "none",
    [customTheme.breakpoints.down("md")]: {
      position: "fixed",
      bottom: 0,
      zIndex: 3,
      padding: "16px 16px 32px",
      backgroundColor: "#FFFFFF",
      width: "100vw",
      maxHeight: "100vh",
      overflowY: "scroll",
      display: "flex",
      flexDirection: "column",
      boxSizing: "border-box",
      borderRadius: "24px 24px 0 0",
      boxShadow: "0px 0px 15px 0px rgba(0, 0, 0, 0.15)",
    },
  },
  mobileIconContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    marginBottom: "24px",
    cursor: "pointer",
  },
  mobilePrompt: {
    fontWeight: "bold",
    fontSize: "20px",
  },
};
