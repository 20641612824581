import React, { useState, useEffect } from "react";
import { Box } from "@mui/material";
import PropTypes from "prop-types";
import CardMedia from "@mui/material/CardMedia";
import { AnalyticsBrowser } from "@segment/analytics-next";

// import components
import { classes } from "./HomeCatalogItemStyle";
import { segmentWriteKey } from "../../atoms/Segment/Segment";
import mixpanel from "../../atoms/Mixpanel/Mixpanel";
import Text from "../../atoms/Text/Text";
import FavoritingCallToBackend from "../../../pages/profileRelated/favorites/FavoritingCallToBackend";
// import ChangeFavoriteState from "../../molecules/ChangeFavoriteState/ChangeFavoriteState";

// import context
import { UserContext } from "../../../App";
import { TrendingProductSectionContext } from "../../organisms/TrendingProductSection/TrendingProductSection";

// Import icons
const unselectedHeart =
  "https://craftle-static.s3.us-east-2.amazonaws.com/icons/heart-not-filled.svg";
const selectedHeart =
  "https://craftle-static.s3.us-east-2.amazonaws.com/icons/heart-filled.svg";
const star =
  "https://craftle-static.s3.us-east-2.amazonaws.com/icons/star-yellow-filled.svg";

const analytics = AnalyticsBrowser.load({ writeKey: segmentWriteKey });

const propTypes = {
  itemInfo: PropTypes.object,
  userFavorites: PropTypes.array,
};

const defaultProps = {
  itemInfo: {},
  userFavorites: [],
};

HomeCatalogItem.propTypes = propTypes;
HomeCatalogItem.defaultProps = defaultProps;

export default function HomeCatalogItem({ itemInfo, userFavorites }) {
  const [favorited, setFavorited] = useState(false);

  const { setShowLoginModal } = React.useContext(TrendingProductSectionContext);
  const { user } = React.useContext(UserContext);

  const acquisitionSource =
    JSON.parse(localStorage.getItem("acquisitionSource")) || "N/A";

  const capitalizeWords = (string) => {
    return string
      .split("-")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(" ");
  };

  const capitalizedBrand = itemInfo.brand
    ? capitalizeWords(itemInfo.brand)
    : "";
  const capitalizedItemType = itemInfo.product_type
    ? capitalizeWords(itemInfo.product_type)
    : "";

  useEffect(() => {
    if (userFavorites) {
      const objToArr = Object.values(userFavorites);
      objToArr.map((favoriteItem) => {
        if (favoriteItem.link === itemInfo.product_page_url) {
          setFavorited(true);
        }
      });
    }
  }, [userFavorites]);

  function handleFavorited() {
    if (user) {
      toggleFavoriteState(favorited, itemInfo, user);
      setFavorited(!favorited);
    } else {
      setShowLoginModal(true);
    }
  }

  function toggleFavoriteState(favorited, itemInfo, user) {
    const dimensions = {
      length: 0,
      width: 0,
      height: 0,
    };

    FavoritingCallToBackend(
      !favorited,
      user.email,
      itemInfo.product_id,
      itemInfo.name,
      [itemInfo.original_image_url],
      itemInfo.brand,
      itemInfo.cost,
      itemInfo.product_type,
      itemInfo.product_page_url,
      dimensions.length,
      dimensions.width,
      dimensions.height,
      itemInfo.dimensions
    );
  }

  function ItemClicked() {
    analytics.track("Product-Link Opened", {
      user_email: user?.email,
      user_name: user?.first_name,
      flow: "Homepage",
      survey_acquisition_source: acquisitionSource,
      moodboard_id: "N/A",
      product_link: itemInfo?.product_page_url,
      product_name: itemInfo?.name,
      product_price: itemInfo?.cost,
      product_length: "N/A",
      product_width: "N/A",
      product_height: "N/A",
    });
    mixpanel.track(`(Home) Product-Link Opened`, {
      "Product Link": itemInfo?.product_page_url,
    });
    window.open(itemInfo.product_page_url, "_blank");
  }

  return (
    <Box sx={classes.categoryContainer}>
      <Box sx={classes.imageContainer}>
        <Box onClick={handleFavorited} sx={classes.favoriteContainer}>
          {favorited ? (
            <CardMedia
              component="img"
              image={selectedHeart}
              alt=""
              sx={classes.favoriteIcon}
            />
          ) : (
            <CardMedia
              component="img"
              image={unselectedHeart}
              alt=""
              sx={classes.favoriteIcon}
            />
          )}
        </Box>
        <Box onClick={ItemClicked}>
          <CardMedia
            component="img"
            image={itemInfo.original_image_url}
            alt=""
            sx={classes.categoryImage}
          />
          <Box sx={classes.ratingContainer}>
            <CardMedia
              component="img"
              image={star}
              alt=""
              sx={classes.starIcon}
            />
            <Text sx={classes.rating}>{itemInfo.stars}</Text>
          </Box>
        </Box>
      </Box>
      <Text sx={classes.firstRowText}>
        {capitalizedBrand} &nbsp;•&nbsp; {capitalizedItemType}
      </Text>
      <Box sx={classes.secondRowText}>
        {itemInfo.deal ? (
          <>
            <Text sx={classes.dealTxt}>${itemInfo.deal}</Text>
            <Text sx={{ ...classes.costTxt, ...classes.strikeThrough }}>
              ${itemInfo.cost}
            </Text>
          </>
        ) : (
          <Text sx={classes.costTxt}>${itemInfo.cost}</Text>
        )}
      </Box>
    </Box>
  );
}
