// import { Box, Grid, Slider } from "@mui/material";
import { Box } from "@mui/material";
// import CardMedia from "@mui/material/CardMedia";
// import { styled } from "@mui/material/styles";
import React, { useEffect, useState, createContext } from "react";
import { AnalyticsBrowser } from "@segment/analytics-next";

// import components
import mixpanel from "../../atoms/Mixpanel/Mixpanel";
import { classes } from "./LandingBudgetMoodboardStyle";
import Button from "../../atoms/Button/Button";
import Loading from "../../atoms/Loading/Loading";
import Text from "../../atoms/Text/Text";
import MoodBoard from "../../templates/MoodBoard/MoodBoard";
import ModalStartDesignHomepage from "../../../components/organisms/ModalStartDesignHomepage/ModalStartDesignHomepage";
import Textfield from "../../../components/molecules/Textfield/Textfield";
import { segmentWriteKey } from "../../../components/atoms/Segment/Segment";

// import context
import { UserContext } from "../../../App";

// import icons
// const leftArrow =
//   "https://craftle-static.s3.us-east-2.amazonaws.com/icons/arrow-left.svg";
const lightBulb =
  "https://craftle-static.s3.us-east-2.amazonaws.com/icons/light-bulb.svg";
const shoppingBag =
  "https://craftle-static.s3.us-east-2.amazonaws.com/icons/shopping-bag-white.svg";

// Import services
import getCMSBetaSuggestion from "../../../services/getCMSBetaSuggestion";
import getPreselectedPlan from "../../../services/getPreselectedPlan";

// Import constants
import { generateSampleInput } from "../../../pages/CraftMyStyle/constants";

export const ShowLoginModalContext = createContext();

const analytics = AnalyticsBrowser.load({ writeKey: segmentWriteKey });
const MIN_BUDGET = 1000;
const NUMBER_OF_PRESELECTED_VIEWS_TO_SHOW = 8;
const CREATE_PERSONALIZED_PLAN_MOODBOARD_PAGE = 6;

// eslint-disable-next-line react/prop-types
function LandingBudgetMoodboard({ budgetMoodboardRef }) {
  const [showLoginModal, setShowLoginModal] = useState(false);
  const [plans, setPlans] = useState([]);
  const [index, setIndex] = useState(0);
  const [selectedFurniturePieces, setSelectedFurniturePieces] = useState(null);
  const [selectedOtherFurniturePieces, setSelectedOtherFurniturePieces] =
    useState(null);
  const [furniturePositions, setFurniturePositions] = useState([]);
  const [budget, setBudget] = useState(3000);
  const [budgetResult, setBudgetResult] = useState(3000);
  const [planId, setPlanId] = useState(null);
  const [currResultCost, setCurrResultCost] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [numberOfPreselectedView, setNumberOfPreselectedView] = useState(0);
  const [input, setInput] = useState();
  const [shouldGetPreselectedPlans, setShouldGetPreselectedPlans] =
    useState(false);
  const [preselectedPlansViewed, setPreselectedPlansViewed] = useState([]);

  const { user } = React.useContext(UserContext);
  const email = user?.email || "N/A";

  let cmsDesigns = JSON.parse(localStorage.getItem("cmsDesigns")) || [];
  let numSavedDesign = cmsDesigns.length;

  // moodboard views
  const [
    showCreateCustomizedPlanMoodBoard,
    setShowCreateCustomizedPlanMoodBoard,
  ] = useState(false);

  useEffect(() => {
    getPreselectedPlanCall(preselectedPlansViewed);
  }, []);

  useEffect(() => {
    // Updates when a new moodboard plan is created
    if (plans.length > 0) {
      const lastIndex = plans.length - 1;
      setIndex(lastIndex);
      setPlanId(plans[lastIndex].plan_id);
      setSelectedFurniturePieces(plans[lastIndex].selected_furniture_pieces);
      setSelectedOtherFurniturePieces(
        plans[lastIndex].selected_other_furniture_pieces
      );
      setFurniturePositions(plans[lastIndex].furniture_positions);
    }
  }, [plans.length]);

  useEffect(() => {
    // Updates when a user revisits an existing moodboard plan
    // and when a new moodboard is created (it's redundant, fix later)
    if (plans.length > 0) {
      setPlanId(plans[index].plan_id);
      setSelectedFurniturePieces(plans[index].selected_furniture_pieces);
      setSelectedOtherFurniturePieces(
        plans[index].selected_other_furniture_pieces
      );
      setFurniturePositions(plans[index].furniture_positions);
    }
  }, [index]);

  useEffect(() => {
    if (selectedFurniturePieces) {
      let currResultTotal = 0;
      Object.entries(selectedFurniturePieces).forEach((item) => {
        const [, val] = item;

        currResultTotal += val.cost;
      });
      setCurrResultCost(currResultTotal);
    } else {
      setCurrResultCost(null);
    }
  }, [selectedFurniturePieces]);

  useEffect(() => {
    if (shouldGetPreselectedPlans) {
      getPreselectedPlanCall(preselectedPlansViewed);
      setShouldGetPreselectedPlans(false);
    }
  }, [shouldGetPreselectedPlans]);

  function runRandomCall() {
    const randomInput = generateSampleInput(MIN_BUDGET, budget);
    getCMSBetaSuggestionCall(randomInput);
  }

  function generateAnotherPressed() {
    setShowCreateCustomizedPlanMoodBoard(false);
    setIsLoading(true);

    // Call the API and get more designs
    if (numberOfPreselectedView < NUMBER_OF_PRESELECTED_VIEWS_TO_SHOW) {
      getPreselectedPlanCall(preselectedPlansViewed);
    } else {
      runRandomCall();
    }

    setPlanId(null);
    setSelectedFurniturePieces(null);
    setSelectedOtherFurniturePieces(null);
    setFurniturePositions([]);
  }

  function showMyMoodBoardPressed() {
    event.preventDefault();
    setIsLoading(true);
    setNumberOfPreselectedView(0);
    setPlans([]);
    setIndex(0);
    setPlanId(null);
    setSelectedFurniturePieces(null);
    setSelectedOtherFurniturePieces(null);
    setFurniturePositions([]);
    setBudgetResult(budget);
    setPreselectedPlansViewed([]);
    setShouldGetPreselectedPlans(true);
  }

  function getPreselectedPlanCall(preselectedPlansViewed) {
    getPreselectedPlan({
      visitedId: preselectedPlansViewed,
      budget: budget,
    })
      .then((response) => {
        analytics.track("Moodboard Viewed", {
          user_email: user?.email,
          user_name: user?.first_name,
          flow: "Homepage Pre-Created Moodboard",
          room: "Living Room",
        });
        mixpanel.track("(Homepage CMS) Viewed Pre-created Moodboard");

        setPreselectedPlansViewed([
          ...preselectedPlansViewed,
          response.plan_id,
        ]);
        setNumberOfPreselectedView(
          (prevNumberOfPreselectedView) => prevNumberOfPreselectedView + 1
        );
        setPlans([...plans, response]);

        setIsLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setIsLoading(false);
      });
  }

  function getCMSBetaSuggestionCall(randomInput) {
    getCMSBetaSuggestion({
      ...randomInput,
    })
      .then((response) => {
        analytics.track("Moodboard Viewed", {
          user_email: user?.email,
          user_name: user?.first_name,
          flow: "Homepage Custom Moodboard",
          room: "Living Room",
        });
        mixpanel.track("(Homepage CMS) Created Moodboard");

        setPlans([...plans, response]);
        setIsLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setIsLoading(false);
      });
  }

  function seeAndEditPlanPressed() {
    mixpanel.track(`(Homepage CMS) Clicked "Customize" moodboard`);
    if (numSavedDesign < 3 || user) {
      if (!user) {
        numSavedDesign += 1;
      }
      if (index < NUMBER_OF_PRESELECTED_VIEWS_TO_SHOW) {
        // Only duplicate preselected plans
        analytics.track("Moodboard Created", {
          user_email: user?.email,
          user_name: user?.first_name,
          flow: "Homepage Pre-Created Moodboard",
          room: "Living Room",
          budget: budget,
          style: plans[index].plan_param.selected_design_type,
          moodboard_id: planId,
        });
        window.open(`/designs/d/${planId}`, "_blank");
      } else {
        analytics.track("Moodboard Created", {
          user_email: user?.email,
          user_name: user?.first_name,
          flow: "Homepage Custom Moodboard",
          room: "Living Room",
          budget: budget,
          style: plans[index].plan_param.selected_design_type,
          moodboard_id: planId,
        });
        window.open(`/designs/e/${planId}`, "_blank");
      }
    } else {
      setShowLoginModal(true);
    }
  }

  function MoodBoardComponent() {
    return (
      <Box sx={classes.moodboardOuter}>
        {isLoading &&
        !selectedFurniturePieces &&
        !selectedOtherFurniturePieces ? (
          <Box sx={classes.moodboardLoadingContainer}>
            <Loading />
            <Text sx={classes.moodboardLoadTxt}>
              This can take a minute while we try to find the best plan for your
              budget.
            </Text>
          </Box>
        ) : (
          <Box
            onClick={() => {
              if (index === CREATE_PERSONALIZED_PLAN_MOODBOARD_PAGE) {
                window.open("/designs", "_blank", "noopener,noreferrer");
              } else {
                seeAndEditPlanPressed();
              }
            }}
            sx={classes.moodboardInner}
          >
            <MoodBoard
              selectedFurniturePieces={selectedFurniturePieces}
              selectedOtherFurniturePieces={selectedOtherFurniturePieces}
              furniturePositions={furniturePositions}
              showCreateCustomizedPlanMoodBoard={
                showCreateCustomizedPlanMoodBoard
              }
              homePageView
            />
          </Box>
        )}
      </Box>
    );
  }

  return (
    <ShowLoginModalContext.Provider
      value={{
        setShowLoginModal,
      }}
    >
      {showLoginModal && <ModalStartDesignHomepage />}
      <Box ref={budgetMoodboardRef} sx={classes.container}>
        <Text sx={classes.hashtag}>#inspo-moodboard</Text>
        <Text variant="h2" sx={classes.sectionTitle}>
          Find all your living room furniture with one click
        </Text>
        <Box sx={classes.budgetQuestionContainer}>
          <form
            onSubmit={() => {
              showMyMoodBoardPressed();
            }}
          >
            <Box sx={classes.cmsFormContainer}>
              <Text sx={classes.subtitle}>My budget is:</Text>
              <Box sx={classes.budgetContainer}>
                <Textfield
                  type="number"
                  id="input"
                  name="input"
                  placeholder="3000"
                  onChange={(event) => {
                    setInput(event.target.value);
                    setBudget(event.target.value);
                  }}
                  value={`${input}`}
                  fontSize="24px"
                  fontWeight="bold"
                  inputProps={{
                    min: 1000,
                    max: 3000,
                  }}
                  sx={{ ...classes.cmsTextField, ...classes.inputWithSymbol }}
                />
                <Button
                  value={input}
                  onClick={() => {
                    analytics.track("Budget Declared", {
                      user_email: user?.email,
                      user_name: user?.first_name,
                      flow: "Homepage Custom Moodboard",
                      budget_amount: budget,
                    });
                    mixpanel.track("(Homepage CMS) Selected Budget", {
                      email: email,
                      "Chosen Budget": budget,
                    });
                  }}
                  sx={classes.cmsSubmitButton}
                >
                  Start Search
                </Button>
              </Box>
            </Box>
          </form>
        </Box>

        <Box sx={classes.cmsResultsBackground}>
          <Text sx={classes.resultsTitle}>
            Results for a ${parseInt(budgetResult, 10).toLocaleString("en-US")}{" "}
            budget
          </Text>
          {MoodBoardComponent()}
          {!showCreateCustomizedPlanMoodBoard && (
            <Box sx={classes.totalCostContainer}>
              <Text sx={classes.totalCost}>
                {isLoading
                  ? "Calculating..."
                  : `Total cost for these products: $${parseInt(
                      currResultCost,
                      10
                    ).toLocaleString("en-US")}`}
              </Text>
            </Box>
          )}

          <Box
            sx={{
              ...classes.commandsContainer,
              marginTop: showCreateCustomizedPlanMoodBoard ? "47px" : "16px",
            }}
          >
            <Button
              variant="secondary"
              onClick={() => {
                generateAnotherPressed();
              }}
              icon={lightBulb}
              sx={{
                ...classes.generateDesignBtn,
                ...(isLoading &&
                (plans.length === 0 || index === plans.length - 1)
                  ? {
                      backgroundColor: "rgba(243,243,243)",
                      color: "#BFBFBF",
                      "&:hover": {
                        cursor: "default",
                      },
                    }
                  : {
                      cursor: "pointer",
                    }),
              }}
            >
              Generate Another Design
            </Button>

            <Button
              onClick={() => {
                currResultCost &&
                  planId &&
                  !showCreateCustomizedPlanMoodBoard &&
                  seeAndEditPlanPressed();
              }}
              icon={shoppingBag}
              sx={{
                ...classes.shopTheseProductsBtn,
                opacity:
                  currResultCost && planId && !showCreateCustomizedPlanMoodBoard
                    ? "100%"
                    : "50%",
              }}
            >
              Shop These Products
            </Button>
          </Box>
        </Box>
      </Box>
    </ShowLoginModalContext.Provider>
  );
}

export default LandingBudgetMoodboard;
