import { customTheme } from "../../atoms/CustomTheme/CustomTheme";

export const classes = {
  container: {
    backgroundColor: "#FFF",
    padding: "16px 24px",
    position: "relative",
    border: "solid 2px #BFBFBF",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    gap: "16px",
    marginBottom: "24px",
    boxSizing: "border-box",
    [customTheme.breakpoints.down("sm")]: {
      padding: "16px 16px",
    },
  },
  text: {
    fontSize: "14px",
  },
  illustration: {
    width: "48px",
  },
  closeContainer: {
    cursor: "pointer",
  },
  closeIcon: { width: "24px" },
};
