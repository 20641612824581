import React from "react";
import { Box } from "@mui/material";
import CardMedia from "@mui/material/CardMedia";
// import { useNavigate } from "react-router-dom";
import PropTypes from "prop-types";

// import components
import { classes } from "./HomeHeroSectionStyle";
import Text from "../../atoms/Text/Text";
import Button from "../../atoms/Button/Button";

// import icons
const backDecoration1 =
  "https://craftle-static.s3.us-east-2.amazonaws.com/homepage/home-hero-decoration-1.png";
const backDecoration2 =
  "https://craftle-static.s3.us-east-2.amazonaws.com/homepage/home-hero-decoration-2.png";
const heroTextHighlight =
  "https://craftle-static.s3.us-east-2.amazonaws.com/homepage/hero-text-highlight.svg";
const sparkle =
  "https://craftle-static.s3.us-east-2.amazonaws.com/homepage/sparkle-1.svg";
const arrow1 =
  "https://craftle-static.s3.us-east-2.amazonaws.com/homepage/squiggle-arrow-1.svg";
const arrow2 =
  "https://craftle-static.s3.us-east-2.amazonaws.com/homepage/squiggle-arrow-2.svg";

HomeHeroSection.propTypes = {
  budgetMoodboardRef: PropTypes.object,
  lookalikeFinderRef: PropTypes.object,
  trendingProductRef: PropTypes.object,
};

HomeHeroSection.defaultProps = {
  budgetMoodboardRef: null,
  lookalikeFinderRef: null,
  trendingProductRef: null,
};

export default function HomeHeroSection({
  budgetMoodboardRef,
  lookalikeFinderRef,
  trendingProductRef,
}) {
  const handleScroll = (ref) => {
    window.scrollTo({
      top: ref.offsetTop,
      left: 0,
      behavior: "smooth",
    });
  };

  return (
    <Box sx={classes.container}>
      <CardMedia
        component="img"
        image={backDecoration1}
        alt=""
        sx={classes.backDecoration1}
      />
      <Box sx={classes.h1Section}>
        <Text variant="h1" sx={classes.h1}>
          Furniture shopping? Let&apos;s make it HYPE!
        </Text>
        <CardMedia
          component="img"
          image={heroTextHighlight}
          alt=""
          sx={classes.heroTextHighlight}
        />
        <CardMedia
          component="img"
          image={sparkle}
          alt=""
          sx={classes.sparkle}
        />
        <CardMedia component="img" image={arrow1} alt="" sx={classes.arrow1} />
      </Box>

      <Button
        variant="secondary"
        onClick={() => {
          handleScroll(budgetMoodboardRef.current);
        }}
        sx={classes.action1}
      >
        <b>Need room inspo?</b> Our moodboard&apos;s got you 🖼️
      </Button>
      <Button
        variant="secondary"
        onClick={() => {
          handleScroll(lookalikeFinderRef.current);
        }}
        sx={classes.action2}
      >
        <b>Champagne taste on a beer budget?</b> Our Lookalike Finder&apos;s
        your BFF 🥂
      </Button>

      <Box
        onClick={() => {
          handleScroll(trendingProductRef.current);
        }}
        sx={classes.exploreSection}
      >
        <Text sx={classes.exploreText}>I just wanna explore</Text>
        <CardMedia component="img" image={arrow2} alt="" sx={classes.arrow2} />
      </Box>

      <CardMedia
        component="img"
        image={backDecoration2}
        alt=""
        sx={classes.backDecoration2}
      />
    </Box>
  );
}
