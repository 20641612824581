import * as React from "react";
import { Box } from "@mui/material";
import { createTheme } from "@mui/material/styles";
import PropTypes from "prop-types";

const propTypes = {
  children: PropTypes.object,
};

const defaultProps = {
  children: null,
};

YoutubeiFrame.propTypes = propTypes;
YoutubeiFrame.defaultProps = defaultProps;

let customTheme = createTheme({});

export default function YoutubeiFrame({ children }) {
  return (
    <Box
      sx={{
        position: "relative",
        marginTop: "40px",
        width: "100%",
        height: "389.25px",
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        [customTheme.breakpoints.down("md")]: {
          width: "100%",
          height: "315px",
        },
      }}
    >
      {children}
    </Box>
  );
}
