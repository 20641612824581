import * as React from "react";
import { Box } from "@mui/material";
import CardMedia from "@mui/material/CardMedia";
import mixpanel from "mixpanel-browser";
import PropTypes from "prop-types";

mixpanel.init("d60542dff5aaf889760e7cc2f6316298", { debug: false });

// import components
import { classes } from "./LookalikeFinderSectionStyle";
import { customTheme } from "../../atoms/CustomTheme/CustomTheme";
import Text from "../../atoms/Text/Text";
import Button from "../../atoms/Button/Button";

// import images
const arrow =
  "https://craftle-static.s3.us-east-2.amazonaws.com/homepage/squiggle-arrow-3.svg";
const sofa1 =
  "https://craftle-static.s3.us-east-2.amazonaws.com/homepage/lookalike-finder-couch-1.png";
const sofa2 =
  "https://craftle-static.s3.us-east-2.amazonaws.com/homepage/lookalike-finder-couch-2.png";
const sofa3 =
  "https://craftle-static.s3.us-east-2.amazonaws.com/homepage/lookalike-finder-couch-3.png";
const sofa4 =
  "https://craftle-static.s3.us-east-2.amazonaws.com/homepage/lookalike-finder-couch-4.png";
const sofa5 =
  "https://craftle-static.s3.us-east-2.amazonaws.com/homepage/lookalike-finder-couch-5.png";
const sofa6 =
  "https://craftle-static.s3.us-east-2.amazonaws.com/homepage/lookalike-finder-couch-6.png";

// import icons
const LookalikeFinderIcon =
  "https://craftle-static.s3.us-east-2.amazonaws.com/lookalike-finder-page/lookalike-finder-icon.svg";

LookalikeFinderSection.propTypes = {
  lookalikeFinderRef: PropTypes.object,
};

LookalikeFinderSection.defaultProps = {
  lookalikeFinderRef: null,
};

export default function LookalikeFinderSection({ lookalikeFinderRef }) {
  const handleScroll = (ref) => {
    window.scrollTo({
      top: ref.offsetTop,
      left: 0,
      behavior: "smooth",
    });
  };

  return (
    <Box ref={lookalikeFinderRef} sx={classes.container}>
      <Box
        onClick={() => {
          handleScroll(lookalikeFinderRef.current);
        }}
        sx={classes.bougieTasteBtn}
      >
        <Text sx={classes.bougieTasteTxt}>But if you have #bougie taste</Text>
        <CardMedia component="img" image={arrow} alt="" sx={classes.arrow} />
      </Box>

      {/* idSection is so that the above button can move to this section */}
      <Box sx={classes.idSection}>
        <Box sx={classes.yellowContainer}>
          <Box sx={classes.titleRow}>
            <CardMedia
              component="img"
              image={LookalikeFinderIcon}
              alt=""
              sx={classes.lookalikeFinderIcon}
            />
            <Text variant="h2" sx={classes.title}>
              Lookalike Finder
            </Text>
            <Text sx={classes.title2}>beta</Text>
          </Box>

          <Text sx={classes.description}>
            See furniture you like?
            <br /> We’ll find you <b>lookalikes that are more affordable.</b>
          </Text>

          <Button
            href={"/lookalike-finder"}
            sx={classes.cta}
            onClick={() => {
              mixpanel.track(`(Homepage) Clicked "Start Lookalike Finder"`);
            }}
          >
            Find Lookalike Furniture
          </Button>

          <Box sx={classes.howItWorksContainer}>
            <Text variant="h3" sx={classes.howItWorksTitle}>
              How Lookalike Finder Works
            </Text>
            <Text sx={classes.howItWorksSubtitle}>
              You see this expensive sofa on Crate & Barrel or another
              website...
            </Text>
            <CardMedia
              component="img"
              image={sofa1}
              alt=""
              sx={classes.sofa1}
            />
            <Text sx={classes.howItWorksSubtitle2}>
              ...and we’ll find you these cheaper alternatives:
            </Text>
            <Box sx={classes.sofasContainer}>
              <CardMedia
                component="img"
                image={sofa2}
                alt=""
                sx={classes.sofas}
              />
              <CardMedia
                component="img"
                image={sofa3}
                alt=""
                sx={{
                  ...classes.sofas,
                  [customTheme.breakpoints.down("sm")]: {
                    display: "none",
                  },
                }}
              />
              <CardMedia
                component="img"
                image={sofa4}
                alt=""
                sx={classes.sofas}
              />
              <CardMedia
                component="img"
                image={sofa5}
                alt=""
                sx={classes.sofas}
              />
              <CardMedia
                component="img"
                image={sofa6}
                alt=""
                sx={classes.sofas}
              />
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
