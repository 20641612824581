import { customTheme } from "../../atoms/CustomTheme/CustomTheme";

export const classes = {
  container: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    // height: "100vh",
    boxSizing: "border-box",
    padding: "264px 0 48px 0",
    [customTheme.breakpoints.down("md")]: {
      padding: "184px 16px 112px 16px",
    },
    [customTheme.breakpoints.down("md")]: {
      padding: "152px 16px 96px 16px",
    },
    [customTheme.breakpoints.down("sm")]: {
      padding: "136px 16px 80px 16px",
    },
  },
  backDecoration1: {
    position: "absolute",
    top: "88px",
    right: 0,
    width: "216px",
    zIndex: -1,
    [customTheme.breakpoints.down("md")]: {
      width: "176px",
    },
    [customTheme.breakpoints.down("xs")]: {
      display: "none",
    },
  },
  backDecoration2: {
    position: "absolute",
    bottom: "88px",
    left: 0,
    width: "182px",
    zIndex: -1,
    [customTheme.breakpoints.down("md")]: {
      width: "142px",
    },
    [customTheme.breakpoints.down("xs")]: {
      bottom: 0,
    },
  },
  h1Section: {
    position: "relative",
  },
  h1: {
    fontSize: "72px",
    maxWidth: "753px",
    textAlign: "center",
    [customTheme.breakpoints.down("md")]: {
      fontSize: "56px",
    },
    [customTheme.breakpoints.down("xs")]: {
      fontSize: "40px",
    },
  },
  heroTextHighlight: {
    zIndex: -1,
    width: "224px",
    position: "absolute",
    right: "40px",
    bottom: "-8px",
    [customTheme.breakpoints.down(1024)]: {
      width: "184px",
      right: "160px",
    },
    [customTheme.breakpoints.down(731)]: {
      right: "72px",
    },
    [customTheme.breakpoints.down(699)]: {
      right: "56px",
    },
    [customTheme.breakpoints.down(500)]: {
      right: "48px",
    },
    [customTheme.breakpoints.down(462)]: {
      left: 0,
      right: 0,
      margin: "auto",
    },
    [customTheme.breakpoints.down("xs")]: {
      width: "144px",
      left: "inherit",
      right: "24px",
      margin: "auto",
    },
    [customTheme.breakpoints.down(339)]: {
      left: 0,
      right: 0,
      margin: "auto",
    },
  },
  sparkle: {
    width: "48px",
    position: "absolute",
    right: "-16px",
    bottom: "40px",
    [customTheme.breakpoints.down(1024)]: {
      right: "108px",
      bottom: "32px",
    },
    [customTheme.breakpoints.down(731)]: {
      right: "32px",
    },
    [customTheme.breakpoints.down(713)]: {
      right: "16px",
    },
    [customTheme.breakpoints.down(675)]: {
      display: "none",
    },
  },
  arrow1: {
    position: "absolute",
    width: "56px",
    right: "-24px",
    bottom: "-32px",
    [customTheme.breakpoints.down(1024)]: {
      width: "54px",
      right: "80px",
      bottom: "-28px",
    },
    [customTheme.breakpoints.down(731)]: {
      width: "48px",
      right: "24px",
      bottom: "-28px",
    },
    [customTheme.breakpoints.down(713)]: {
      right: "16px",
    },
    [customTheme.breakpoints.down(699)]: {
      display: "none",
    },
  },

  action1: {
    marginTop: "32px",
    fontSize: "24px",
    fontWeight: 500,
    padding: "8px 32px",
    lineHeight: 1.2,
    display: "inline-block",
    backgroundColor: "#FFF3D7",
    [customTheme.breakpoints.down("md")]: {
      marginTop: "32px",
      fontSize: "20px",
    },
    [customTheme.breakpoints.down("sm")]: {
      fontSize: "20px",
    },
  },
  action2: {
    marginTop: "16px",
    fontSize: "24px",
    fontWeight: 500,
    padding: "8px 32px",
    lineHeight: 1.2,
    display: "inline-block",
    backgroundColor: "#FFF3D7",
    [customTheme.breakpoints.down("md")]: {
      fontSize: "20px",
    },
    [customTheme.breakpoints.down("sm")]: {
      marginTop: "8px",
      fontSize: "20px",
    },
  },

  exploreSection: {
    marginTop: "120px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    // position: "absolute",
    // bottom: "16px",
    cursor: "pointer",
    [customTheme.breakpoints.down("md")]: {
      display: "none",
    },
  },
  exploreText: {
    fontSize: "24px",
    fontWeight: "bold",
    [customTheme.breakpoints.down("md")]: {
      fontSize: "20px",
    },
    [customTheme.breakpoints.down("sm")]: {
      fontSize: "16px",
    },
  },
  arrow2: {
    width: "48px",
    [customTheme.breakpoints.down("md")]: {
      width: "40px",
    },
    [customTheme.breakpoints.down("sm")]: {
      width: "32px",
    },
  },
};
