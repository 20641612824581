import React, { createContext } from "react";
import { Box, CardMedia } from "@mui/material";
import { useNavigate } from "react-router-dom";

// import components
import { classes } from "./CompareFavoritesSectionStyle";
import Text from "../../atoms/Text/Text";
import Button from "../../atoms/Button/Button";
import mixpanel from "mixpanel-browser";

mixpanel.init("d60542dff5aaf889760e7cc2f6316298", {
  debug: false,
  track_pageview: true,
});

export const TrendingProductSectionContext = createContext();

// Import icons
const favoritesIllustration =
  "https://craftle-static.s3.us-east-2.amazonaws.com/homepage/homepage-favorites-illustration.png";

// eslint-disable-next-line react/prop-types
function CompareFavoritesSection() {
  let navigate = useNavigate();

  function viewFavoritesClicked() {
    mixpanel.track("(Home) Clicked on View Favorites");
    navigate("/log-in");
  }

  return (
    <Box sx={classes.container}>
      <CardMedia
        component="img"
        image={favoritesIllustration}
        alt=""
        sx={classes.mdUpIllustration}
      />

      <Box sx={classes.column2}>
        <Text sx={classes.hashtag}>#quick-compare</Text>
        <Text variant="h2" sx={classes.title}>
          Compare Your Favorites
        </Text>
        <Text sx={classes.subtitle}>
          Add products to your Favorites list. Compare them side-by-side to find
          the perfect fit for your space.
        </Text>
        <Button
          variant="primary"
          onClick={viewFavoritesClicked}
          href={"/favorites"}
          sx={classes.primaryBtn}
        >
          View Favorites
        </Button>
      </Box>

      <CardMedia
        component="img"
        image={favoritesIllustration}
        alt=""
        sx={classes.smIllustration}
      />
    </Box>
  );
}

export default CompareFavoritesSection;
