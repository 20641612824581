import * as React from "react";
import { Box } from "@mui/material";
import PropTypes from "prop-types";
import { createTheme } from "@mui/material/styles";

const propTypes = {
  children: PropTypes.node,
};

const defaultProps = {
  children: null,
};

BlogContentSection.propTypes = propTypes;
BlogContentSection.defaultProps = defaultProps;

let customTheme = createTheme({});

export default function BlogContentSection({ children }) {
  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        justifyContent: "center",
        padding: "180px 16px 80px 16px",
        boxSizing: "border-box",
        [customTheme.breakpoints.down("md")]: {
          padding: "120px 16px 80px 16px",
        },
        [customTheme.breakpoints.down("sm")]: {
          padding: "88px 16px 64px 16px",
        },
      }}
    >
      {children}
    </Box>
  );
}
