export const getUserContextValue = ({
  user,
  setUser,
  userGlobalContext,
  setUserGlobalContext,
  CMSResultsShowMobileCanvas,
  setCMSResultsShowMobileCanvas,
  CMSResultsSelectedFile,
  setCMSResultsSelectedFile,
  itemToDelete,
  setItemToDelete,
  showCommandBar,
  setShowCommandBar,
  cmsResultsSaving,
  setCmsResultsSaving,
  cmsAddItem,
  setCmsAddItem,
  mobileCmsOtherCommandsClicked,
  setMobileCmsOtherCommandsClicked,
  mobileShowOriginalCommands,
  setMobileShowOriginalCommands,
  lookalikeFinderSearchText,
  setLookalikeFinderSearchText,
}) => ({
  user,
  setUser,
  userGlobalContext,
  setUserGlobalContext,
  CMSResultsShowMobileCanvas,
  setCMSResultsShowMobileCanvas,
  CMSResultsSelectedFile,
  setCMSResultsSelectedFile,
  itemToDelete,
  setItemToDelete,
  showCommandBar,
  setShowCommandBar,
  cmsResultsSaving,
  setCmsResultsSaving,
  cmsAddItem,
  setCmsAddItem,
  mobileCmsOtherCommandsClicked,
  setMobileCmsOtherCommandsClicked,
  mobileShowOriginalCommands,
  setMobileShowOriginalCommands,
  lookalikeFinderSearchText,
  setLookalikeFinderSearchText,
});
