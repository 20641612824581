import React from "react";
import CardMedia from "@mui/material/CardMedia";
import { Box, Grid } from "@mui/material";

// import components
import { classes } from "./BlogPost18Style";
import Text from "../../components/atoms/Text/Text";
import Button from "../../components/atoms/Button/Button";
import BlogContentSection from "../../components/molecules/BlogContentSection/BlogContentSection";
import BlogDate from "../../components/molecules/BlogDate/BlogDate";
import BlogTitle from "../../components/molecules/BlogTitle/BlogTitle";
import BlogAppReview from "../../components/molecules/BlogAppReview/BlogAppReview";
import BlogAppFAQSubsection from "../../components/molecules/BlogAppFAQSubsection/BlogAppFAQSubsection";

// import images
const sofaGraphic =
  "https://craftle-static.s3.us-east-2.amazonaws.com/blog/blog18/sofa-graphic.png";
const craftleImage =
  "https://craftle-static.s3.us-east-2.amazonaws.com/blog/blog18/blog-18-banner.jpg";
const sketchupImage =
  "https://craftle-static.s3.us-east-2.amazonaws.com/blog/blog18/sketchup-image.jpg";
const floorplannerImage =
  "https://craftle-static.s3.us-east-2.amazonaws.com/blog/blog18/floorplanner-image.jpg";
const planner5DImage =
  "https://craftle-static.s3.us-east-2.amazonaws.com/blog/blog18/planner-5d-image.jpg";
const homeByMeImage =
  "https://craftle-static.s3.us-east-2.amazonaws.com/blog/blog18/home-by-me-image.jpg";

export default function BlogPost18() {
  // craftle variables
  const craftleAvailableOn = ["Web"];
  const craftleCost = ["Free"];
  const craftleDifficultyToLearn = ["Easy"];
  const craftlePros = [
    "Helps you visualize interior design ideas",
    "Helps you find furniture and home decor products",
  ];
  const craftleCons = [
    "Not as helpful if you’re looking to change the physical architecture of your room",
  ];

  // sketchup variables
  const sketchupAvailableOn = ["Desktop", "Web"];
  const sketchupCost = ["Free", "Pay to upgrade"];
  const sketchupDifficultyToLearn = ["Intermediate", "Advanced"];
  const sketchupPros = [
    "SketchUp has a free version for new or casual users",
    "SketchUp has a pro version for advanced users",
    "Has many robust tools and features",
  ];
  const sketchupCons = [
    "A bit overwhelming for beginners",
    "No tutorial when you create your first design",
    "The user interface appears dated",
  ];

  // floorplanner variables
  const floorplannerAvailableOn = ["Web"];
  const floorplannerCost = ["Free", "Pay to upgrade"];
  const floorplannerDifficultyToLearn = ["Intermediate", "Advanced"];
  const floorplannerPros = [
    "Has a room wizard to guide new users",
    "Can auto-generate furniture for your virtual room",
    "Virtual furniture clicks into place, making it easier to place items",
  ];
  const floorplannerCons = [
    "Limited number of purchaseable items",
    "Experienced a few minor bugs on the planning platform",
    "Exporting high-quality images is hidden behind a paywall",
  ];

  // planner5D variables
  const planner5DAvailableOn = ["Web", "iOS", "Android", "MacOS"];
  const planner5DCost = ["Free", "Pay to upgrade"];
  const planner5DDifficultyToLearn = ["Intermediate", "Advanced"];
  const planner5DPros = [
    "Has a room wizard to guide new users",
    "Can auto-generate furniture for your virtual room",
    "Has a community of designers you can get inspiration from or “battle” for render and texture prizes to use within the app",
  ];
  const planner5DCons = [
    "You cannot buy any of the furniture or home products that you use to design",
    "You’re limited to using 5% of interior items until you pay to upgrade",
    "Must pay to create high-quality, realistic renders of your design",
  ];

  // HomeByMe variables
  const homeByMeAvailableOn = ["Web", "iOS", "Android"];
  const homeByMeCost = ["Free", "Pay to upgrade"];
  const homeByMeDifficultyToLearn = ["Intermediate", "Advanced"];
  const homeByMeproductDetails =
    "HomeByMe is an online interior design tool that allows users to create and visualize 3D floor plans and design projects, experiment with different furniture and decor options, and collaborate with others on design projects.";
  const homeByMePros = [
    "User-friendly interface with drag-and-drop functionality and a wide range of design features and tools.",
    "Offers a large library of furniture and decor options, as well as the ability to import custom objects and materials.",
    "Allows users to share designs with others, collaborate on projects in real-time, and view designs in 3D.",
  ];
  const homeByMeCons = [
    "Limited customization options for colors and finishes.",
    "Some furniture and decor options may not be available in the free version of the app.",
    "The 3D rendering may be slow and laggy on older devices or slower internet connections.",
  ];

  return (
    <BlogContentSection>
      <Box sx={classes.blogContentContainer}>
        {/* top section */}
        <BlogDate date="NOVEMBER 27, 2022" />
        <BlogTitle title="5 Best Interior Design Apps of 2023" />
        <Text sx={classes.subtitle}>
          From apps that help you find the right furniture to apps that help you
          design your floor plan, we’ll help you find the best interior design
          apps for you.
        </Text>

        {/* Craftle ad */}
        <Grid container sx={classes.craftleAdContainer}>
          <Grid item sm={2} xs={12} sx={classes.craftleAdItem1}>
            <CardMedia
              component="img"
              image={sofaGraphic}
              alt=""
              sx={classes.craftleAdGraphic}
            />
          </Grid>
          <Grid item sm={7} xs={12} sx={classes.craftleAdItem2}>
            <Text sx={classes.craftleAdTitle}>
              Find the right furniture for you.
            </Text>
            <Text>
              Whether you want to buy affordable furniture or need help interior
              designing, the right furniture plan is out there. Just answer a
              few questions and we’ll help you design your room.
            </Text>
          </Grid>
          <Grid item sm={3} xs={12} sx={classes.craftleAdItem3}>
            <Button href="https://www.craftle.app/">Get Started</Button>
          </Grid>
        </Grid>

        {/* apps */}
        <BlogAppReview
          title="Craftle"
          link="https://www.craftle.app/"
          image={craftleImage}
          availableOnArray={craftleAvailableOn}
          costArray={craftleCost}
          difficultyToLearnArray={craftleDifficultyToLearn}
          productDetails="Craftle is an AI-based web tool that helps you find furniture and home products. Similar to taking a Buzzfeed quiz, Craftle asks you questions about what room your designing, your budget, helps you find your design style (e.g. mid-century modern), and more. Their AI then creates a furniture plan and moodboard design that you can edit or buy from."
          proArray={craftlePros}
          conArray={craftleCons}
        />
        <BlogAppReview
          title="SketchUp"
          link="https://www.sketchup.com/"
          image={sketchupImage}
          availableOnArray={sketchupAvailableOn}
          costArray={sketchupCost}
          difficultyToLearnArray={sketchupDifficultyToLearn}
          productDetails="SketchUp is a premier 3D design software with a robust toolset that empowers you to create and bring your ideas to life. Whether your focus is architecture, interior design, or product design, SketchUp will be the all-encompassing tool that signals to others that you know what you’re doing."
          proArray={sketchupPros}
          conArray={sketchupCons}
        />
        <BlogAppReview
          title="Floorplanner"
          link="https://floorplanner.com/"
          image={floorplannerImage}
          availableOnArray={floorplannerAvailableOn}
          costArray={floorplannerCost}
          difficultyToLearnArray={floorplannerDifficultyToLearn}
          productDetails="As the name suggests, Floorplanner helps you create floor plans for any room you’re designing. Use their online editor to create 2D blueprints or 3D images to plan, share, and create your room."
          proArray={floorplannerPros}
          conArray={floorplannerCons}
        />
        <BlogAppReview
          title="Planner 5D"
          link="https://planner5d.com/"
          image={planner5DImage}
          availableOnArray={planner5DAvailableOn}
          costArray={planner5DCost}
          difficultyToLearnArray={planner5DDifficultyToLearn}
          productDetails="Planner5D is an advanced home design tool used to create 2D and 3D models of your potential furnishings, room, and home. With this tool, you can easily share snapshots of your designs with customers, friends, and family to show off your vision and get feedback."
          proArray={planner5DPros}
          conArray={planner5DCons}
        />
        <BlogAppReview
          title="HomeByMe"
          link="https://home.by.me/en/"
          image={homeByMeImage}
          availableOnArray={homeByMeAvailableOn}
          costArray={homeByMeCost}
          difficultyToLearnArray={homeByMeDifficultyToLearn}
          productDetails={homeByMeproductDetails}
          proArray={homeByMePros}
          conArray={homeByMeCons}
        />

        {/* FAQ */}
        <Text variant="h2" sx={classes.faqTitle}>
          People also ask (FAQ)
        </Text>
        <BlogAppFAQSubsection
          title="Is Design Home a free app?"
          description="Yes, there are a number of apps to decorate your home, including
          Craftle. Peruse the apps listed above to find the one that fits your
          needs."
        />
        <BlogAppFAQSubsection
          title="Is there an app to decorate your home?"
          description="Yes, there are a number of apps to decorate your home, including Craftle. Peruse the apps listed above to find the one that fits your needs."
        />
        <BlogAppFAQSubsection
          title="Is there an app to see how furniture will look in a room?"
          description="Yes, there are a few apps that can let you see how furniture will look in a room. The Wayfair app and Amazon app are two examples that use augmented reality (AR) to let you see furniture in your room."
        />
        <BlogAppFAQSubsection
          title="Is there an app to help me decorate my living room?"
          description="Yes, there are apps to help you decorate your living room. Some examples include Craftle, SketchUp, and RoomStyler."
        />
        <BlogAppFAQSubsection
          title="What is a good free design app?"
          description="If you’re looking to interior design, Craftle is a good free design app that can help you virtually decorate your home and find home goods to buy."
        />
        <BlogAppFAQSubsection
          title="Is SketchUp good for interior design?"
          description="Yes, SketchUp is good for interior design if you need an advanced tool to plan your room. It’s more suited for intermediate to advanced individuals that need something more robust in features. If you’re looking for a more beginner-friendly tool, then you can try Craftle."
        />

        <BlogAppFAQSubsection
          title="What are interior design apps?"
          description="Interior design apps are software applications that assist users in creating or renovating the interiors of their homes, offices, or other spaces. These apps help users visualize how their space will look after a renovation or redesign, experiment with different color schemes, furniture arrangements, and decor options, and make informed decisions about their interior design projects."
        />
        <BlogAppFAQSubsection
          title="What are the benefits of using interior design apps?"
          description="Interior design apps offer several benefits, including the ability to experiment with different design ideas and concepts without committing to a purchase, the convenience of having design tools and resources at your fingertips, the ability to share designs and collaborate with others, and the ability to save time and money by avoiding costly mistakes and missteps."
        />
        <BlogAppFAQSubsection
          title="What features should I look for in an interior design app?"
          description="The features of an interior design app may vary, but some common ones to look for include a 3D modeling tool, a wide range of furniture and decor options, the ability to customize colors and finishes, a user-friendly interface, the ability to save and share designs, and the ability to import and export files to other design software programs."
        />
        <BlogAppFAQSubsection
          title="Can interior design apps be used by professional designers?"
          description="Yes, many interior design apps can be used by professional designers to create and present design concepts to clients, as well as to collaborate with other team members on design projects. However, some apps may not offer the advanced features and functionality required for complex or large-scale projects, and may not integrate with other design software programs."
        />
        <BlogAppFAQSubsection
          title="How accurate are interior design apps in visualizing a space?"
          description="The accuracy of interior design apps in visualizing a space may vary depending on the quality of the app and the level of detail provided by the user. However, most apps offer a 3D modeling tool that can provide a realistic representation of the space, as well as the ability to customize colors, finishes, and decor options to create a more accurate depiction of the space."
        />
      </Box>
    </BlogContentSection>
  );
}
