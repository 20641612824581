import React, { createContext, useReducer } from "react";
import PropTypes from "prop-types";

// importing constants
import {
  furnitureOptions,
  decorSoftFurnishingsOptions,
  sofasCouchesOptions,
} from "../pages/CraftMyStyle/constants";

// This file is for Craft My Style (CMS)

const Reducer = (state, action) => {
  switch (action.type) {
    case "SET_WORKFLOW_PROP_VALUE": {
      /**
       * action is the shape of:
       * action: {
       *   payload: {
       *      propName: oneOf[],
       *      value: value,
       *   }
       * }
       */
      return {
        ...state,
        [action.payload.propName]: action.payload.value,
      };
    }
    case "SET_WORKFLOW_RESULT": {
      /**
       * action is the shape of:
       * action: {
       *   payload: {
       *      planResult: from api result, plan_result,
       *      otherRecommendations: from api result, other_recommendations,
       *      selectedFurniturePieces: from api result, selected_furniture_pieces,
       *      selectedOtherFurniturePieces: from api result, selected_other_furniture_pieces,
       *      uploadedImages: from api result, selected_uploaded_images,
       *   }
       * }
       */
      const planResult = action.payload.planResult;
      const otherRecommendations = action.payload.otherRecommendations;

      // New
      const selectedFurniturePieces = action.payload.selectedFurniturePieces;
      const selectedOtherFurniturePieces =
        action.payload.selectedOtherFurniturePieces;
      const uploadedImages = action.payload.uploadedImages;
      const furniturePositions = action.payload.furniturePositions;

      // Calculate current total price
      let currResultTotal = 0;
      Object.entries(selectedFurniturePieces).forEach((item) => {
        const [, val] = item;

        currResultTotal += val.cost;
      });

      return {
        ...state,
        result: planResult,
        otherRecommendations: otherRecommendations,
        currResultTotal: currResultTotal,
        selectedFurniturePieces: selectedFurniturePieces,
        selectedOtherFurniturePieces: selectedOtherFurniturePieces,
        uploadedImages: uploadedImages,
        furniturePositions: furniturePositions,
      };
    }
    case "UPDATE_UPLOADED_IMAGES": {
      /**
       * action is the shape of:
       * action: {
       *    payload: {
       *      uploadedImages: value,
       *    }
       * }
       */
      return {
        ...state,
        uploadedImages: {
          ...state.uploadedImages,
          ...action.payload.uploadedImages,
        },
      };
    }
    case "DELETE_UPLOADED_IMAGE": {
      const newUploadedImages = { ...state.uploadedImages };
      delete newUploadedImages[action.payload.imageId];
      return {
        ...state,
        uploadedImages: newUploadedImages,
      };
    }
    case "UPDATE_FURNITURE_SELECTED_CHECKBOX_VALUE": {
      /**
       * action is the shape of:
       * action: {
       *    furnitureKey: value,
       * }
       */
      return {
        ...state,
        furnitureOptionsChecked: {
          ...state.furnitureOptionsChecked,
          [action.furnitureKey]:
            !state.furnitureOptionsChecked[action.furnitureKey],
        },
      };
    }
    case "TRANSFORM_FURNITURE_SELECTED_CHECKBOX_INTO_BACKEND": {
      const res = [];

      for (const [key, value] of Object.entries(
        state.furnitureOptionsChecked
      )) {
        if (value) {
          res.push(key);
        }
      }

      return {
        ...state,
        furnitureOptions: res,
      };
    }
    case "UPDATE_DESIGN_WEIGHTS_VALUE": {
      /**
       * action is the shape of:
       * action: {
       *    designKey: value,
       *    liked: bool,
       * }
       */
      const currWeight = state.designWeights[action.designKey];
      const newWeight = action.liked ? currWeight + 2 : currWeight - 1;

      return {
        ...state,
        designWeights: {
          ...state.designWeights,
          [action.designKey]: newWeight,
        },
      };
    }
    case "SET_CURRENT_FURNITURE_SELECTION_IN_RESULT": {
      /**
       * action is the shape of:
       * action: {
       *    payload: {
       *      furnitureKey: value,
       *      furnitureInfo: value,
       *      index: value,
       *    }
       * }
       */
      const furnitureKey = action.payload.furnitureKey;
      const furnitureInfo = action.payload.furnitureInfo;

      if (furnitureKey in state.selectedFurniturePieces) {
        let currResultTotal = 0;
        Object.entries(state.selectedFurniturePieces).forEach((item) => {
          const [key, val] = item;

          if (key === furnitureKey) {
            currResultTotal += furnitureInfo.cost;
          } else {
            currResultTotal += val.cost;
          }
        });

        return {
          ...state,
          currResultTotal: currResultTotal,
          selectedFurniturePieces: {
            ...state.selectedFurniturePieces,
            [furnitureKey]: furnitureInfo,
          },
        };
      } else if (furnitureKey in state.selectedOtherFurniturePieces) {
        return {
          ...state,
          selectedOtherFurniturePieces: {
            ...state.selectedOtherFurniturePieces,
            [furnitureKey]: furnitureInfo,
          },
        };
      } else if (furnitureKey in state.uploadedImages) {
        return {
          ...state,
          uploadedImages: {
            ...state.uploadedImages,
            [furnitureKey]: furnitureInfo,
          },
        };
      }

      return state;
    }
    case "UPDATE_FURNITURE_TYPE_SELECTED": {
      /**
       * action is the shape of:
       * action: {
       *    furnitureTypeSelected
       * }
       */
      return {
        ...state,
        currFurnitureTypeSelected: action.furnitureTypeSelected,
      };
    }
    case "RESET_FURNITURE_TYPE_SELECTED": {
      // Closes the furniture command options
      return {
        ...state,
        currFurnitureTypeSelected: null,
      };
    }
    case "SET_FURNITURE_COMMAND": {
      let command = action.command;
      if (command === state.currFurnitureCommand) {
        command = null;
      }

      return {
        ...state,
        currFurnitureCommand: command,
      };
    }
    case "UPDATE_AND_SWAP": {
      let command = action.command;
      if (command === state.currFurnitureCommand) {
        command = null;
      }

      return {
        ...state,
        currFurnitureTypeSelected: action.furnitureTypeSelected,
        currFurnitureCommand: "SWAP",
      };
    }
    case "RESET_FURNITURE_COMMAND": {
      return {
        ...state,
        currFurnitureCommand: null,
      };
    }
    case "NEXT_PAGE": {
      return {
        ...state,
        workflowPage: state.workflowPage + 1,
      };
    }
    case "PREVIOUS_PAGE": {
      if (state.workflowPage < 1) {
        return state;
      }

      return {
        ...state,
        workflowPage: state.workflowPage - 1,
      };
    }
    case "SET_ERROR": {
      /**
       * action is the shape of:
       * action: {
       *    error: string,
       * }
       */
      return {
        ...state,
        error: action.error,
      };
    }
    case "SET_IS_LOADING": {
      return {
        ...state,
        isLoading: !state.isLoading,
      };
    }
    default:
      return state;
  }
};

const defaultCategoriesWeights = {
  bohemian: 1,
  eclectic: 1,
  industrial: 1,
  "mid-century-modern": 1,
  modern: 1,
  rustic: 1,
  scandinavian: 1,
  traditional: 1,
};

let livingRoomFurnitureOptions = {};
furnitureOptions.forEach((item) => {
  livingRoomFurnitureOptions[item.labelKey] = false;
});
decorSoftFurnishingsOptions.forEach((item) => {
  livingRoomFurnitureOptions[item.labelKey] = false;
});
sofasCouchesOptions.forEach((item) => {
  livingRoomFurnitureOptions[item.labelKey] = false;
});

const initialState = {
  planId: null,
  // Workflow pages
  workflowPage: 0,
  // Workflow fields
  roomType: null,
  furnitureOptionsChecked: livingRoomFurnitureOptions,
  furnitureOptions: [],
  designWeights: defaultCategoriesWeights,
  budget: 650,
  // Workflow results
  result: {},
  uploadedImages: {},
  otherRecommendations: {},
  selectedFurniturePieces: {},
  selectedOtherFurniturePieces: {},
  currFurnitureTypeSelected: null,
  currResultTotal: 0,
  // Furniture Commands
  currFurnitureCommand: null,
  // Misc.
  error: null,
  isLoading: false,
  completed: false,
  showCMSResultsTooltip: true,
  showCMSSavedDesignsPage: true,
  hasCookies: false,
  // Moodboard items
};

const Store = ({ children }) => {
  const [state, dispatch] = useReducer(Reducer, initialState);

  return (
    <Context.Provider value={[state, dispatch]}>{children}</Context.Provider>
  );
};

Store.propTypes = {
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.elementType])
    .isRequired,
};

export const Context = createContext(initialState);
// Global Store Wrapped around the whole app
export default Store;
