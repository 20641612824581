import React from "react";
import { Box } from "@mui/material";
import { createTheme } from "@mui/material/styles";
import PropTypes from "prop-types";

// import components
import ImageTextGrid from "../../../components/organisms/ImageTextGrid/ImageTextGrid";

// import constants
import { roomCards } from "../constants";

let customTheme = createTheme({});

function CMSRoomSelect({ propName, onNextPress }) {
  const cards = roomCards.map((card) => {
    return {
      ...card,
      onClick: () => onNextPress(propName, card.labelKey),
    };
  });

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        paddingTop: "130px",
        [customTheme.breakpoints.down("md")]: {
          paddingTop: "50px",
        },
        [customTheme.breakpoints.down("sm")]: {
          paddingTop: "20px",
        },
      }}
    >
      <ImageTextGrid cardArray={cards} />
    </Box>
  );
}

const propTypes = {
  propName: PropTypes.string.isRequired,
  onNextPress: PropTypes.func.isRequired,
  callAlert: PropTypes.func,
};

const defaultProps = {
  callAlert: () => {},
};

CMSRoomSelect.propTypes = propTypes;
CMSRoomSelect.defaultProps = defaultProps;

export default CMSRoomSelect;
