import { customTheme } from "../../../components/atoms/CustomTheme/CustomTheme";

export const classes = {
  container: {
    position: "sticky",
    top: 0,
    zIndex: 10,
    width: "100%",
    backgroundColor: "#2a2a2a",
  },
  innerContainer: {
    margin: "0 auto",
    height: 80,
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    maxWidth: "1440px",
    padding: "0 16px",
    position: "relative",
    [customTheme.breakpoints.down("sm")]: {
      height: "59px",
    },
  },
  backButtonContainer: {
    margin: 0,
    width: "104px",
    [customTheme.breakpoints.down("sm")]: {
      width: "85px",
    },
  },
  backButton: {
    textDecoration: "none",
    display: "flex",
    alignItems: "center",
  },
  backArrowIcon: {
    fontSize: 14,
    color: "#FFFFFF",
  },
  backButtonTxt: {
    fontSize: 20,
    marginLeft: "10px",
    fontWeight: "bold",
    color: "#FFFFFF",
    [customTheme.breakpoints.down("sm")]: {
      fontSize: "16px",
    },
  },
  craftleButton: {
    "&:hover": {
      cursor: "pointer",
    },
  },
  logoTxt: {
    color: "#FFFFFF",
    fontSize: "32px",
    [customTheme.breakpoints.down("sm")]: {
      fontSize: "24px",
    },
  },
  topRightText: {
    fontSize: 20,
    color: "#FFFFFF",
    [customTheme.breakpoints.down("sm")]: {
      fontSize: "16px",
    },
  },
};
