import React, { useRef } from "react";
import { Box, Typography } from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";
// import CardMedia from "@mui/material/CardMedia";

// import components
import LandingFurniture from "../../molecules/LandingFurnitureHighlighted/LandingFurnitureHighlighted";
// import LandingSeeMore from "../../molecules/LandingSeeMore/LandingSeeMore";
import ScrollButtonLeft from "../../molecules/ScrollButtonLeft/ScrollButtonLeft";
import ScrollButtonRight from "../../molecules/ScrollButtonRight/ScrollButtonRight";

// import icons

// import images
const MeanGirls1 =
  "https://craftle-static.s3.us-east-2.amazonaws.com/landing-page/mean-girls-1.jpeg";
const MeanGirls2 =
  "https://craftle-static.s3.us-east-2.amazonaws.com/landing-page/mean-girls-2.jpeg";
const MeanGirls3 =
  "https://craftle-static.s3.us-east-2.amazonaws.com/landing-page/mean-girls-3.jpeg";
const MeanGirls4 =
  "https://craftle-static.s3.us-east-2.amazonaws.com/landing-page/mean-girls-4.jpeg";
const MeanGirls5 =
  "https://craftle-static.s3.us-east-2.amazonaws.com/landing-page/mean-girls-5.jpeg";
const MeanGirls6 =
  "https://craftle-static.s3.us-east-2.amazonaws.com/landing-page/mean-girls-6.jpeg";
const MeanGirls7 =
  "https://craftle-static.s3.us-east-2.amazonaws.com/landing-page/mean-girls-7.jpeg";
const MeanGirlsProfile1 =
  "https://craftle-static.s3.us-east-2.amazonaws.com/landing-page/mean-girls-profile-1.png";
const MeanGirlsProfile2 =
  "https://craftle-static.s3.us-east-2.amazonaws.com/landing-page/mean-girls-profile-2.png";
const MeanGirlsProfile3 =
  "https://craftle-static.s3.us-east-2.amazonaws.com/landing-page/mean-girls-profile-3.png";
const MeanGirlsProfile4 =
  "https://craftle-static.s3.us-east-2.amazonaws.com/landing-page/mean-girls-profile-4.png";
const MeanGirlsProfile5 =
  "https://craftle-static.s3.us-east-2.amazonaws.com/landing-page/mean-girls-profile-5.png";
const MeanGirlsProfile6 =
  "https://craftle-static.s3.us-east-2.amazonaws.com/landing-page/mean-girls-profile-6.png";
const MeanGirlsProfile7 =
  "https://craftle-static.s3.us-east-2.amazonaws.com/landing-page/mean-girls-profile-7.png";

let customTheme = createTheme({
  breakpoints: {
    values: {
      customMd: 1300,
      xl: 1536,
      lg: 1200,
      md: 900,
      sm: 600,
      xs: 0,
    },
  },
});

function LandingMeanGirls() {
  const ref = useRef(null);

  const classes = {
    funCardContainer: {
      marginRight: "16px",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      [customTheme.breakpoints.down("sm")]: {
        alignItems: "flex-start",
      },
    },
    funCard: {
      padding: "0 16px",
      borderRadius: "16px",
      boxSizing: "border-box",
      marginTop: "40px",
      height: "100%",
      width: "240px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      [customTheme.breakpoints.down("sm")]: {
        width: "200px",
      },
    },
    funCardText: {
      fontSize: "32px",
      lineHeight: 1.2,
      fontWeight: "bold",
      color: "#FFFFFF",
    },
  };

  return (
    <ThemeProvider theme={customTheme}>
      {/* popular furniture section */}
      <Box
        sx={{
          background: "#FFDFF1",
          marginTop: "48px",
          width: "100vw",
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          padding: "40px 0",
          position: "relative",
        }}
      >
        <Box
          sx={{
            width: "90vw",
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
          }}
        >
          <Typography
            variant="h2"
            sx={{
              color: "#2a2a2a",
              fontSize: "32px",
              fontWeight: "bold",
            }}
          >
            💋 Mean Girls
          </Typography>

          <Typography
            sx={{
              color: "#2a2a2a",
              fontSize: "16px",
              marginTop: "16px",
              // fontWeight: "bold",
            }}
          >
            We saw what furniture piece they would pick in the burn book.
            Scandalous! (Pics from Paramount Pictures)
          </Typography>

          <ScrollButtonLeft scrollRef={ref} left="5.5vw" top="56%" />
          <Box
            ref={ref}
            sx={{
              display: "flex",
              flexDirection: "row",
              marginTop: "16px",
              width: "95vw",
              marginLeft: "-5vw",
              overflowX: "scroll",
              paddingLeft: "5vw",
              scrollBehavior: "smooth",
              "&::-webkit-scrollbar": {
                display: "none",
              },
            }}
          >
            <Box
              style={classes.funCardContainer}
              sx={{
                [customTheme.breakpoints.down("sm")]: {
                  alignItems: "flex-start !important",
                },
              }}
            >
              <Box
                style={classes.funCard}
                sx={{
                  backgroundColor: "#E04F51",
                  [customTheme.breakpoints.down("sm")]: {
                    width: "200px !important",
                  },
                }}
              >
                <Typography style={classes.funCardText}>
                  “Grool! I meant to say great but then I started to say cool.”
                </Typography>
              </Box>
            </Box>
            <LandingFurniture
              path="/furniture/zuri/mora-lounge-chair"
              name="Cady"
              profile={MeanGirlsProfile1}
              image={MeanGirls1}
              brand="Zuri"
              type="Loung Chair"
              price="1,665"
              url="https://www.zurifurniture.com/living/mora-swivel-chair?gclid=Cj0KCQjwwJuVBhCAARIsAOPwGATLTtLf5nzv3tf4dQ5_UCKuVfXjkfNLZl5pHg86BgqWvi_6xqmOIloaAmHAEALw_wcB"
            />
            <Box
              style={classes.funCardContainer}
              sx={{
                [customTheme.breakpoints.down("sm")]: {
                  alignItems: "flex-start !important",
                },
              }}
            >
              <Box
                style={classes.funCard}
                sx={{
                  backgroundColor: "#E98ECB",
                  [customTheme.breakpoints.down("sm")]: {
                    width: "200px !important",
                  },
                }}
              >
                <Typography style={classes.funCardText}>
                  “Get in, loser. We&apos;re going shopping.”
                </Typography>
              </Box>
            </Box>
            <LandingFurniture
              path="/furniture/wayfair/stellar-39-5-wide-velvet-lounge-chair"
              name="Regina"
              profile={MeanGirlsProfile2}
              image={MeanGirls2}
              brand="House of Ham..."
              type="Lounge Chair"
              price="349.99"
              url="https://www.wayfair.com/furniture/pdp/house-of-hampton-steller-395-wide-velvet-lounge-chair-w004193535.html?piid=291204119"
            />
            <Box
              style={classes.funCardContainer}
              sx={{
                [customTheme.breakpoints.down("sm")]: {
                  alignItems: "flex-start !important",
                },
              }}
            >
              <Box
                style={classes.funCard}
                sx={{
                  backgroundColor: "#B61E27",
                  [customTheme.breakpoints.down("sm")]: {
                    width: "200px !important",
                  },
                }}
              >
                <Typography style={classes.funCardText}>
                  “That&apos;s so fetch.”
                </Typography>
              </Box>
            </Box>
            <LandingFurniture
              path="/furniture/lamps-plus/uptown-ancient-stewart-red-fabric-swoop-armchair"
              name="Gretchen"
              profile={MeanGirlsProfile3}
              image={MeanGirls3}
              brand="Lamps Plus"
              type="Accent Chair"
              price="577.98"
              url="https://www.lampsplus.com/sfp/12R66/?cm_mmc=GOO-SH-_-NA-_-NA-_-12R66&sourceid=DFGPD12R66&gclid=Cj0KCQjwwJuVBhCAARIsAOPwGAT-ieK3XBR4i4nrXl6csx3TZhFsCdqgu8z-t7uvSeMDgBNdFlpko9gaAne5EALw_wcB"
            />
            <Box
              style={classes.funCardContainer}
              sx={{
                [customTheme.breakpoints.down("sm")]: {
                  alignItems: "flex-start !important",
                },
              }}
            >
              <Box
                style={classes.funCard}
                sx={{
                  backgroundColor: "#F07CE3",
                  [customTheme.breakpoints.down("sm")]: {
                    width: "200px !important",
                  },
                }}
              >
                <Typography style={classes.funCardText}>
                  “It&apos;s like I have ESPN or something.”
                </Typography>
              </Box>
            </Box>
            <LandingFurniture
              path="/furniture/wayfair/shurtz-67-velvet-curved-loveseat"
              name="Karen"
              profile={MeanGirlsProfile4}
              image={MeanGirls4}
              brand="Willa Arlo™ Interiors"
              type="Loveseat"
              price="1,189.99"
              url="https://www.wayfair.com/furniture/pdp/willa-arlo-interiors-shurtz-67-velvet-curved-loveseat-w005270346.html?piid=630631664"
            />
            <Box
              style={classes.funCardContainer}
              sx={{
                [customTheme.breakpoints.down("sm")]: {
                  alignItems: "flex-start !important",
                },
              }}
            >
              <Box
                style={classes.funCard}
                sx={{
                  backgroundColor: "#2A2A2A",
                  [customTheme.breakpoints.down("sm")]: {
                    width: "200px !important",
                  },
                }}
              >
                <Typography style={classes.funCardText}>
                  “Your mom&apos;s chest hair!”
                </Typography>
              </Box>
            </Box>
            <LandingFurniture
              path="/furniture/zuri/malaga-swing-chair-black"
              name="Janice"
              profile={MeanGirlsProfile5}
              image={MeanGirls5}
              brand="Zuri"
              type="Swing Chair"
              price="661"
              url="https://www.zurifurniture.com/outdoor/malaga-swing-chair-black"
            />
            <Box
              style={classes.funCardContainer}
              sx={{
                [customTheme.breakpoints.down("sm")]: {
                  alignItems: "flex-start !important",
                },
              }}
            >
              <Box
                style={classes.funCard}
                sx={{
                  backgroundColor: "#6FC1D2",
                  [customTheme.breakpoints.down("sm")]: {
                    width: "200px !important",
                  },
                }}
              >
                <Typography style={classes.funCardText}>
                  “SHE DOESN’T EVEN GO HERE”
                </Typography>
              </Box>
            </Box>
            <LandingFurniture
              path="/furniture/wayfair/koopman-54-recessed-arm-loveseat"
              name="Damian"
              profile={MeanGirlsProfile6}
              image={MeanGirls6}
              brand="House of Hampton®"
              type="Loveseat"
              price="829.99"
              url="https://www.wayfair.com/furniture/pdp/house-of-hampton-koopman-54-recessed-arm-loveseat-hcrd1473.html?piid=27103993"
            />
            <Box
              style={classes.funCardContainer}
              sx={{
                [customTheme.breakpoints.down("sm")]: {
                  alignItems: "flex-start !important",
                },
              }}
            >
              <Box
                style={classes.funCard}
                sx={{
                  backgroundColor: "#5C5598",
                  [customTheme.breakpoints.down("sm")]: {
                    width: "200px !important",
                  },
                }}
              >
                <Typography style={classes.funCardText}>
                  “Your face smells like peppermint!”
                </Typography>
              </Box>
            </Box>
            <LandingFurniture
              path="/furniture/mojow/yomi-outdoor-metal-patio-sofa"
              name="Aaron Samuels"
              profile={MeanGirlsProfile7}
              image={MeanGirls7}
              brand="Mojow"
              type="Sofa"
              price="820"
              url="https://www.perigold.com/Mojow--Yomi-59-Wide-Outdoor-Patio-Sofa-with-Cushions-CYC-L118-K~MOJW1044.html?refid=GX583870985161-MOJW1044_66655388_66655389&device=c&ptid=1637972322378&targetid=aud-1644276160773:pla-1637972322378&ireid=187245989&PiID%5B%5D=66655388&PiID%5B%5D=66655389&gclid=Cj0KCQjwwJuVBhCAARIsAOPwGASzjDMXtbIBnWhqTv5TeHc2xySHiiMDlWgzVPbf8kfEfU7t2-_T5BgaAnVUEALw_wcB"
            />
          </Box>
          <ScrollButtonRight scrollRef={ref} right="5.5vw" top="56%" />

          {/* <LandingSeeMore
            text="See more Mean Girls"
            mixpanelTracking="See more Mean Girls"
          /> */}
        </Box>
      </Box>
    </ThemeProvider>
  );
}

export default LandingMeanGirls;
