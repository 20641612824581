import React, { useRef } from "react";
import { Box, Typography } from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";

// import components
import LandingFurniture from "../../molecules/LandingFurniture/LandingFurniture";
// import LandingSeeMore from "../../molecules/LandingSeeMore/LandingSeeMore";
import ScrollButtonLeft from "../../molecules/ScrollButtonLeft/ScrollButtonLeft";
import ScrollButtonRight from "../../molecules/ScrollButtonRight/ScrollButtonRight";
// import Button from "../../atoms/Button/Button";

// import images
const PopularIMG2 =
  "https://craftle-static.s3.us-east-2.amazonaws.com/landing-page/popular-2.jpeg";
const PopularIMG3 =
  "https://craftle-static.s3.us-east-2.amazonaws.com/landing-page/popular-3.jpeg";
const PopularIMG4 =
  "https://craftle-static.s3.us-east-2.amazonaws.com/landing-page/popular-4.jpeg";
const PopularIMG1 =
  "https://craftle-static.s3.us-east-2.amazonaws.com/landing-page/popular-1.jpeg";
const PopularIMG5 =
  "https://craftle-static.s3.us-east-2.amazonaws.com/landing-page/popular-5.jpeg";
const PopularIMG6 =
  "https://craftle-static.s3.us-east-2.amazonaws.com/landing-page/popular-6.jpeg";
const PopularIMG7 =
  "https://craftle-static.s3.us-east-2.amazonaws.com/landing-page/popular-7.jpeg";
const PopularIMG8 =
  "https://craftle-static.s3.us-east-2.amazonaws.com/landing-page/popular-8.jpeg";
const PopularIMG9 =
  "https://craftle-static.s3.us-east-2.amazonaws.com/landing-page/popular-9.jpeg";
const PopularIMG10 =
  "https://craftle-static.s3.us-east-2.amazonaws.com/landing-page/popular-10.jpeg";
const PopularIMG11 =
  "https://craftle-static.s3.us-east-2.amazonaws.com/landing-page/popular-11.jpeg";
const PopularIMG12 =
  "https://craftle-static.s3.us-east-2.amazonaws.com/landing-page/popular-12.jpeg";
const PopularIMG13 =
  "https://craftle-static.s3.us-east-2.amazonaws.com/landing-page/popular-13.jpeg";
const PopularIMG14 =
  "https://craftle-static.s3.us-east-2.amazonaws.com/landing-page/popular-14.jpeg";
const PopularIMG15 =
  "https://craftle-static.s3.us-east-2.amazonaws.com/landing-page/popular-15.jpeg";

// import icons

let customTheme = createTheme({
  breakpoints: {
    values: {
      customMd: 1300,
      xl: 1536,
      lg: 1200,
      md: 900,
      sm: 600,
      xs: 0,
    },
  },
});

function LandingPopularFurniture() {
  const ref = useRef(null);

  return (
    <ThemeProvider theme={customTheme}>
      {/* popular furniture section */}
      <Box
        sx={{
          width: "90vw",
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
          marginTop: "64px",
          position: "relative",
          [customTheme.breakpoints.down("sm")]: {
            marginTop: "80px",
          },
        }}
      >
        <Typography
          variant="h2"
          sx={{
            color: "#2a2a2a",
            fontSize: "32px",
            fontWeight: "bold",
          }}
        >
          😍 Popular Furniture
        </Typography>

        <Typography
          sx={{
            color: "#2a2a2a",
            fontSize: "16px",
            marginTop: "16px",
          }}
        >
          These seem to be really in right now. Join in on the in because––well,
          you&apos;re in the in and in on the in...or something.
        </Typography>

        <ScrollButtonLeft scrollRef={ref} />
        <Box
          ref={ref}
          sx={{
            position: "relative",
            display: "flex",
            flexDirection: "row",
            marginTop: "16px",
            width: "95vw",
            marginLeft: "-5vw",
            overflowX: "scroll",
            paddingLeft: "5vw",
            scrollBehavior: "smooth",
            "&::-webkit-scrollbar": {
              display: "none",
            },
          }}
        >
          <LandingFurniture
            image={PopularIMG2}
            brand="Hashtag Home"
            type="Accent Chair"
            price="326.99"
            url="https://www.wayfair.com/furniture/pdp/hashtag-home-araceli-30-wide-tufted-armchair-w006110246.html?piid=82920358#seemoreoptions-jlkuqy4a7fo"
            path="/furniture/wayfair/araceli-30-wide-tufted-armchair"
          />
          <LandingFurniture
            image={PopularIMG3}
            brand="West Elm"
            type="Accent Chair"
            price="699"
            url="https://www.westelm.com/products/savannah-rattan-chair-h7846/?pkey=cliving-room-chairs"
            path="/furniture/westelm/savannah-rattan-chair"
          />
          <LandingFurniture
            image={PopularIMG4}
            brand="West Elm"
            type="Accent Chair"
            price="1,748"
            url="https://www.westelm.com/products/cozy-swivel-chair-h3797/?cm_src=WsiSubCategory1&recstrat=Shop-Recs-2%7CB-CAT-GRP-TOPN-REV"
            path="/furniture/westelm/cozy-swivel-chair"
          />
          <LandingFurniture
            image={PopularIMG1}
            brand="Etta Avenue™"
            type="Accent Chair"
            price="239.99"
            url="https://www.wayfair.com/furniture/pdp/etta-avenue-cleo-26-wide-velvet-armchair-w005012489.html?piid=370804791"
            path="/furniture/wayfair/ettaavenue-cleo-26-wide-velvet-armchair"
          />
          <LandingFurniture
            image={PopularIMG5}
            brand="Crate & Barrel"
            type="Accent Chair"
            price="1,299"
            url="https://www.crateandbarrel.com/harper-shearling-accent-chair/s233494"
            path="/furniture/crateandbarrel/harper-shearling-accent-chair"
          />
          <LandingFurniture
            image={PopularIMG6}
            brand="West Elm"
            type="Coffee Table"
            price="699"
            url="https://www.westelm.com/products/volume-round-storage-coffee-table-h8796/?pkey=ccoffee-tables"
            path="/furniture/westelm/volume-round-storage-drum-coffee-table"
          />
          <LandingFurniture
            image={PopularIMG7}
            brand="West Elm"
            type="Coffee Table"
            price="259"
            url="https://www.westelm.com/products/naomi-rattan-coffee-table-h10814/?pkey=ccoffee-tables"
            path="/furniture/westelm/naomi-rattan-coffee-table"
          />
          <LandingFurniture
            image={PopularIMG8}
            brand="Latitude Run®"
            type="Coffee Table"
            price="199.99"
            url="https://www.wayfair.com/furniture/pdp/latitude-run-black-lift-top-extendable-coffee-table-with-storage-w006989343.html?piid=1318643237"
            path="/furniture/wayfair/black-lift-top-extendable-coffee-table-with-storage"
          />
          <LandingFurniture
            image={PopularIMG9}
            brand="Crate & Barrel"
            type="Coffee Table"
            price="1,399.20"
            url="https://www.crateandbarrel.com/dillon-spalted-primavera-round-wood-coffee-table/s336379"
            path="/furniture/crateandbarrel/dillon-spalted-primavera-round-wood-coffee-table"
          />
          <LandingFurniture
            image={PopularIMG10}
            brand="Crate & Barrel"
            type="Coffee Table"
            price="999"
            url="https://www.crateandbarrel.com/dev-coffee-table/s157763"
            path="/furniture/crateandbarrel/dev-coffee-table"
          />
          <LandingFurniture
            image={PopularIMG11}
            brand="Wrought Studio™"
            type="Coffee Table"
            price="191.99"
            url="https://www.wayfair.com/furniture/pdp/wrought-studio-almarin-frame-coffee-table-w005888046.html"
            path="/furniture/wayfair/almarin-frame-coffee-table"
          />
          <LandingFurniture
            image={PopularIMG12}
            brand="AllModern"
            type="Sofa"
            price="710"
            url="https://www.wayfair.com/furniture/pdp/allmodern-kearney-60-round-arm-loveseat-w001243679.html?piid=414931010"
            path="/furniture/wayfair/kearny-60-63-round-arm-loveseat"
          />
          <LandingFurniture
            image={PopularIMG13}
            brand="VANTEN"
            type="Sofa"
            price="589.99"
            url="https://www.wayfair.com/furniture/pdp/vanten-murtagh-514-round-arm-curved-loveseat-lajw1291.html?piid=66413255"
            path="/furniture/wayfair/murtagh-51-4-round-arm-curved-loveseat"
          />
          <LandingFurniture
            image={PopularIMG14}
            brand="Kelly Clarkson Home"
            type="Sofa"
            price="1,339.99"
            url="https://www.wayfair.com/furniture/pdp/kelly-clarkson-home-843-velvet-recessed-arm-sofa-w003380032.html?piid=1046298821"
            path="/furniture/wayfair/84-3-velvet-recessed-arm"
          />
          <LandingFurniture
            image={PopularIMG15}
            brand="Crate & Barrel"
            type="Sofa"
            price="2,899"
            url="https://www.crateandbarrel.com/wells-leather-sofa/s376304?oc=2,2142"
            path="/furniture/crateandbarrel/wells-leather-sofa"
          />
        </Box>
        <ScrollButtonRight scrollRef={ref} />

        {/* <LandingSeeMore
          text="See more Popular Furniture"
          mixpanelTracking="See More Popular Furniture"
        /> */}
      </Box>
    </ThemeProvider>
  );
}

export default LandingPopularFurniture;
