import React, { createContext, useState, useEffect } from "react";
import { Box } from "@mui/material";
import CardMedia from "@mui/material/CardMedia";
// import { useNavigate } from "react-router-dom";

// import components
import { classes } from "./AIFurnitureShopperResultsStyle";
import Text from "../../components/atoms/Text/Text";
// import Button from "../../components/atoms/Button/Button";
import AIConvoSearchInResults from "../../components/organisms/AIConvoSearchInResults/AIConvoSearchInResults";

// import icons
// const externalLink =
//   "https://craftle-static.s3.us-east-2.amazonaws.com/icons/external-link.svg";

// import gifs
const loaderIcon =
  "https://craftle-static.s3.us-east-2.amazonaws.com/animations/loader-dual-ring.gif";

export const AIFurnitureShopperResultsContext = createContext();

export default function AIFurnitureShopperResults() {
  const [loaderDisplay, setLoaderDisplay] = useState(false);
  const [boxDimensions, setBoxDimensions] = useState({});

  const imageRef = React.useRef(null);

  const aiSearchEngineConvo =
    JSON.parse(localStorage.getItem("aiSearchEngineConvo")) || null;

  console.log("aiSearchEngineConvo:", aiSearchEngineConvo);
  console.log(
    "aiSearchEngineConvo.items.length:",
    aiSearchEngineConvo.items.length
  );

  function toTitleCase(str) {
    return str.replace(/\w\S*/g, function (txt) {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
  }

  const updateDimensions = () => {
    if (imageRef.current) {
      const mediaWidth = imageRef.current.offsetWidth;
      console.log(
        "`calc(${mediaWidth} - 2px)`:",
        `calc(${mediaWidth}px - 2px)`
      );
      setBoxDimensions({ height: `calc(${mediaWidth}px - 2px)` });
    }
  };

  const handleImageLoad = () => {
    updateDimensions();
  };

  useEffect(() => {
    window.addEventListener("resize", updateDimensions);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener("resize", updateDimensions);
    };
  }, []);

  return (
    <AIFurnitureShopperResultsContext.Provider value={{ setLoaderDisplay }}>
      <Box sx={classes.container}>
        {/* Loader */}
        {loaderDisplay && (
          <Box sx={{ ...classes.loadingSection, display: "inherit" }}>
            <Box sx={classes.loadingModal}>
              <CardMedia
                component="img"
                image={loaderIcon}
                alt=""
                sx={classes.loaderIcon}
              />
              <Text sx={classes.loaderText}>
                Searching for the answer you seak. Hang tight!
              </Text>
            </Box>
          </Box>
        )}

        <Box sx={classes.topSeparator} />

        <Box sx={classes.content}>
          <Box sx={classes.leftColumn}>
            <Box sx={classes.sticky}>
              {/* Commands */}
              {/* <Box
                sx={{
                  ...classes.commandsContainer,
                  padding:
                    aiSearchEngineConvo.items.length > 0
                      ? "16px 40px 0 0"
                      : "16px 0 0 0",
                }}
              >
                <Button
                  onClick={() => {
                    navigate("/ai-furniture-shopper");
                  }}
                  variant="tertiary"
                >
                  Start Over
                </Button>
              </Box> */}
              <Box sx={classes.leftColumnContent}>
                {aiSearchEngineConvo.items.length > 0 ? (
                  <Box sx={classes.matchingItemsContainer}>
                    <Text sx={classes.matchingItemsText}>
                      {aiSearchEngineConvo.items.length}{" "}
                      {aiSearchEngineConvo.items.length == 1
                        ? "matching item"
                        : "matching items"}
                    </Text>
                  </Box>
                ) : null}
                <Text variant="h1" sx={classes.title}>
                  {aiSearchEngineConvo.items.length === 0
                    ? "I don't see anything in our catalog for that 👀"
                    : "I found a few options you might like 🎉"}
                </Text>
                <Text sx={classes.subtitle}>
                  {aiSearchEngineConvo.items.length === 0
                    ? "But don't worry! We're adding new products every day. So check again soon!"
                    : aiSearchEngineConvo.content}
                </Text>

                <AIConvoSearchInResults />
              </Box>
            </Box>
          </Box>

          {aiSearchEngineConvo.items.length > 0 ? (
            <Box sx={classes.separator} />
          ) : null}

          <Box sx={classes.rightColumn}>
            {aiSearchEngineConvo.items.map((item, index) => (
              <Box
                key={index}
                sx={classes.itemContainer}
                onClick={() => {
                  window.open(
                    item.M ? item.M.product_page_url.S : item.product_page_url.S
                  );
                }} // item.M is only true if a hand-selected item and NOT an Amzn item
              >
                <Box
                  ref={imageRef}
                  onLoad={handleImageLoad}
                  sx={{ ...classes.imageContainer, ...boxDimensions }}
                >
                  <CardMedia
                    component="img"
                    image={
                      item.M
                        ? item.M.original_image_url.S
                        : item.original_image_url.S
                    }
                    alt="Product image"
                    sx={classes.itemPicture}
                  />
                </Box>
                {item && item.M && item.M.brand && item.M.brand.S && (
                  <Text sx={classes.itemStyle}>
                    {item.M && toTitleCase(item.M.style.S)}
                  </Text>
                )}
                <Text sx={classes.itemBrand}>
                  {item.M ? toTitleCase(item.M.brand.S) : "Amazon"}
                </Text>
                <Text sx={classes.itemPrice}>
                  $
                  {Number(
                    item.M ? item.M.cost.S : item.cost.N
                  ).toLocaleString()}
                </Text>
                {item && item.M && item.M.gpt_reason && item.M.gpt_reason.S && (
                  <Text sx={classes.itemDescription}>
                    {item.M.gpt_reason.S}
                  </Text>
                )}
                {/* <Button
                  variant="secondary"
                  icon={externalLink}
                  onClick={() => {
                    window.open(
                      item.M
                        ? item.M.product_page_url.S
                        : item.product_page_url.S
                    );
                  }}
                  sx={classes.checkItOutButton}
                >
                  Shop Now
                </Button> */}
              </Box>
            ))}
          </Box>
        </Box>
      </Box>
    </AIFurnitureShopperResultsContext.Provider>
  );
}
