import * as React from "react";
import PropTypes from "prop-types";

const propTypes = {
  title: PropTypes.string,
  link: PropTypes.link,
};

const defaultProps = {
  title: null,
  link: null,
};

BlogH3.propTypes = propTypes;
BlogH3.defaultProps = defaultProps;

export default function BlogH3({ title, link }) {
  return (
    <h3
      style={{
        marginTop: "72px",
      }}
    >
      <a href={link}>{title}</a>
    </h3>
  );
}
