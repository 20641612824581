import React, { useState } from "react";
import { Box } from "@mui/material";

// import components
import { classes } from "./BackToTopStyle";
import Button from "../../atoms/Button/Button";

// import icons
const arrowUp =
  "https://craftle-static.s3.us-east-2.amazonaws.com/icons/arrow-up-white.svg";

function BackToTop() {
  const [buttonDisplay, setButtonDisplay] = useState("none");

  function scrollFunction() {
    if (
      document.body.scrollTop > 1000 ||
      document.documentElement.scrollTop > 1000
    ) {
      setButtonDisplay("block");
    } else {
      setButtonDisplay("none");
    }
  }

  // When the user scrolls down 20px from the top of the document, show the button
  window.onscroll = function () {
    scrollFunction();
  };

  // When the user clicks on the button, scroll to the top of the document
  function topFunction() {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  }

  return (
    <Box
      sx={{
        ...classes.container,
        display: buttonDisplay,
      }}
    >
      <Button icon={arrowUp} onClick={topFunction} sx={classes.button}>
        Top
      </Button>
    </Box>
  );
}

export default BackToTop;
