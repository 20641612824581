import React, { useState, useEffect, useContext } from "react";
import { Box } from "@mui/material";
import Card from "@mui/material/Card";
import CardMedia from "@mui/material/CardMedia";
// import { Box } from "@mui/material";
import PropTypes from "prop-types";
import { AnalyticsBrowser } from "@segment/analytics-next";

// import components
// import Text from "../../atoms/Text/Text";
import { classes } from "./ImageTextCardCMSResultsStyle";
// import { createObjectURL } from "../ConvertToImgSource/ConvertToImgSource";
import FavoritingCallToBackend from "../../../pages/profileRelated/favorites/FavoritingCallToBackend";
import { segmentWriteKey } from "../../../components/atoms/Segment/Segment";
import mixpanel from "../../atoms/Mixpanel/Mixpanel";
// import { customTheme } from "../../../components/atoms/CustomTheme/CustomTheme";

// import context
import { Context } from "../../../context/context";
import { UserContext } from "../../../App";
import { CMSResultsContext } from "../../../pages/CraftMyStyle/pages/CMSResults";

// Import icons
const unselectedHeart =
  "https://craftle-static.s3.us-east-2.amazonaws.com/icons/heart-not-filled.svg";
const selectedHeart =
  "https://craftle-static.s3.us-east-2.amazonaws.com/icons/heart-filled.svg";

const propTypes = {
  // furnitureKey: PropTypes.string.isRequired,
  cardDetail: PropTypes.object,
};
const defaultProps = {
  cardDetail: {},
};

const analytics = AnalyticsBrowser.load({ writeKey: segmentWriteKey });

function ImageTextCardCMSResults({ cardDetail }) {
  const [favorited, setFavorited] = useState(false);
  const { user } = React.useContext(UserContext);
  const { userFavorites, setShowLoginModal } =
    React.useContext(CMSResultsContext);
  const [globalState] = useContext(Context);

  const acquisitionSource =
    JSON.parse(localStorage.getItem("acquisitionSource")) || "N/A";

  // function ImageTextCardCMSResults({ furnitureKey, cardDetail }) {
  // const [globalState, globalDispatch] = useContext(Context);
  // const {
  //   //   setCMSResultsShowMobileCanvas,
  //   setShowCommandBar,
  //   //   setCMSResultsSelectedFile,
  //   //   setCmsAddItem,
  //   setMobileShowOriginalCommands,
  // } = React.useContext(UserContext);

  function itemClicked() {
    analytics.track("Product-Link Opened", {
      user_email: user?.email,
      user_name: user?.first_name,
      flow: "Craft My Style",
      survey_acquisition_source: acquisitionSource,
      moodboard_id: globalState.planId,
      product_link: cardDetail?.product_page_url,
      product_name: cardDetail?.name,
      product_price: cardDetail?.cost,
      product_length: "N/A",
      product_width: "N/A",
      product_height: "N/A",
    });
    mixpanel.track(`(CMS) Clicked "View Item"`, {
      "Product Link": cardDetail?.product_page_url,
    });
    window.open(cardDetail?.product_page_url);

    // // Commented out section is for adding a new item...
    // // ...instead of selecting an existing one from the canvas:
    // // cardDetail.ourProducts = true;
    // // cardDetail.category = furnitureKey;
    // // setCMSResultsSelectedFile(cardDetail);
    // // setCmsAddItem((prevState) => !prevState);
    // // setCMSResultsShowMobileCanvas("none");

    // setMobileShowOriginalCommands(true);

    // globalDispatch({
    //   type: "UPDATE_FURNITURE_TYPE_SELECTED",
    //   furnitureTypeSelected: furnitureKey,
    // });
    // globalDispatch({
    //   type: "SET_FURNITURE_COMMAND",
    //   command: "null",
    // });
    // setShowCommandBar("flex");
  }

  useEffect(() => {
    if (userFavorites) {
      const objToArr = Object.values(userFavorites);
      objToArr.map((favoriteItem) => {
        if (favoriteItem.link === cardDetail.product_page_url) {
          setFavorited(true);
        }
      });
    }
  }, [userFavorites]);

  function handleFavorited() {
    if (user) {
      toggleFavoriteState(favorited, cardDetail, user);
      setFavorited(!favorited);
    } else {
      setShowLoginModal(true);
    }
  }

  function getFirstNonNullImageUrl(cardDetail) {
    return [
      cardDetail.transparent_image_url,
      cardDetail.image_url,
      cardDetail.original_image_url,
    ].find((url) => url);
  }

  function toggleFavoriteState(favorited, cardDetail, user) {
    const dimensions = {
      length: 0,
      width: 0,
      height: 0,
    };

    FavoritingCallToBackend(
      !favorited,
      user.email,
      cardDetail.product_id,
      cardDetail.name,
      [getFirstNonNullImageUrl(cardDetail)],
      cardDetail.brand,
      cardDetail.cost,
      cardDetail.product_type,
      cardDetail.product_page_url,
      dimensions.length,
      dimensions.width,
      dimensions.height,
      cardDetail.dimensions
    );
  }

  return (
    Object.keys(cardDetail).length !== 0 && (
      <Box sx={classes.container}>
        <Box onClick={handleFavorited} sx={classes.favoriteContainer}>
          {favorited ? (
            <CardMedia
              component="img"
              image={selectedHeart}
              alt=""
              sx={classes.favoriteIcon}
            />
          ) : (
            <CardMedia
              component="img"
              image={unselectedHeart}
              alt=""
              sx={classes.favoriteIcon}
            />
          )}
        </Box>
        <Card onClick={itemClicked} sx={classes.imageContainer}>
          <CardMedia
            component="img"
            image={
              cardDetail.transparent_image_url ||
              cardDetail.image_url ||
              cardDetail.original_image_url
            }
            alt="furniture image"
            sx={classes.imageStyle}
          />
        </Card>
      </Box>
    )
  );
}

ImageTextCardCMSResults.propTypes = propTypes;
ImageTextCardCMSResults.defaultProps = defaultProps;

export default ImageTextCardCMSResults;
