import * as React from "react";
import { Box } from "@mui/material";
import PropTypes from "prop-types";

const propTypes = {
  children: PropTypes.node,
};

const defaultProps = {
  children: null,
};

BlogParallaxLayerBack.propTypes = propTypes;
BlogParallaxLayerBack.defaultProps = defaultProps;

export default function BlogParallaxLayerBack({ children }) {
  return (
    <Box
      sx={{
        transform: "translateZ(-5px) scale(6)",
        position: "absolute",
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
      }}
    >
      <Box
        sx={{
          width: "100%",
          backgroundSize: "cover",
        }}
      >
        {children}
      </Box>
    </Box>
  );
}
