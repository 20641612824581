import { useState, useEffect } from "react";

function IsMobileOrTablet() {
  const [isMobileOrTablet, setIsMobileOrTablet] = useState(false);

  useEffect(() => {
    const checkDevice = () => {
      const userAgent = navigator.userAgent;

      if (/windows phone/i.test(userAgent)) {
        return true;
      }

      if (/android/i.test(userAgent)) {
        return true;
      }

      if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
        return true;
      }

      return false;
    };

    const handleResize = () => {
      const isMobile = checkDevice();
      const isSmallScreen = window.innerWidth <= 768;
      setIsMobileOrTablet(isMobile || isSmallScreen);
    };

    handleResize(); // Check on initial render
    window.addEventListener("resize", handleResize); // Check on window resize

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return isMobileOrTablet;
}

export default IsMobileOrTablet;
