import React, { useState, useEffect } from "react";
import { Box } from "@mui/material";
import { useNavigate } from "react-router-dom";

// import components
import { classes } from "./AIConvoSearchInResultsStyle";
import AISearchEngineBackendCall from "../../../pages/aiFurnitureShopper/AISearchEngineBackendCall";
import Textfield from "../../molecules/Textfield/Textfield";
import Button from "../../atoms/Button/Button";
import { AIFurnitureShopperResultsContext } from "../../../pages/aiFurnitureShopper/AIFurnitureShopperResults";

export default function AIConvoSearch() {
  const [convoArray, setConvoArray] = useState([]);
  const [chatCount, setChatCount] = useState(0);
  const [input, setInput] = useState("");
  const { setLoaderDisplay } = React.useContext(
    AIFurnitureShopperResultsContext
  );

  let navigate = useNavigate();

  useEffect(() => {
    const lastItem = convoArray[convoArray.length - 1];
    const userQuery =
      lastItem && lastItem.content && lastItem.content.toLowerCase();

    setChatCount(chatCount + 1);

    // Only makes the call if it's a user msg
    if (lastItem && lastItem.role == "user") {
      // Ends the chat if it's longer than 8 msgs
      if (chatCount < 9) {
        if (
          userQuery ==
            "help me find a white curved couch like kim k's for under $3k" ||
          userQuery ==
            "help me find a white curved couch like kim k’s for under $3k"
        ) {
          setTimeout(function () {
            navigate("/ai-furniture-shopper/search/e/678");
          }, 5000);
        } else {
          const backendCall = async () => {
            return await AISearchEngineBackendCall(convoArray);
          };
          backendCall()
            .then((returnedData) => {
              setLoaderDisplay(false);
              localStorage.setItem(
                "aiSearchEngineConvo",
                JSON.stringify(returnedData)
              );
              return;
            })
            .then(() => {
              navigate("/ai-furniture-shopper/search-results");
            })
            .catch((error) => {
              console.error(error);
            });
        }
      } else {
        setConvoArray((convoArray) => [
          ...convoArray,
          {
            role: "assistant",
            content:
              "Thanks for using me this far! You've reached my chat limit. But don't worry, once I get an upgrade, you can have longer conversations. So stay tuned!",
          },
        ]);
      }
    }
  }, [convoArray]);

  async function sendMsg(event) {
    // Prevent the default form submission behavior if event is present
    if (event) {
      event.preventDefault();
    }

    setLoaderDisplay(true);

    // Assign the url value from the input
    let inputMsg = input.trim();

    if (inputMsg !== "") {
      // Add it to the conversation array
      setConvoArray((prevConvoArray) => [
        ...prevConvoArray,
        { role: "user", content: inputMsg },
      ]);
    }
  }

  function HandleKeyDown(event) {
    if (event.key === "Enter") {
      sendMsg();
    }
  }

  return (
    <Box sx={classes.container}>
      <form
        onSubmit={(event) => {
          sendMsg(event);
        }}
      >
        <Box sx={classes.textfieldAndSearch}>
          <Textfield
            type="text"
            id="input"
            name="input"
            placeholder="Try another search..."
            onChange={(event) => setInput(event.target.value)}
            value={input}
            sx={classes.textfield}
          />
          <Button
            variant="primary"
            onKeyDown={HandleKeyDown}
            value={input}
            sx={classes.searchButton}
          >
            Search
          </Button>
        </Box>
      </form>
    </Box>
  );
}
