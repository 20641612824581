import React from "react";
import { Box } from "@mui/material";
import { createTheme } from "@mui/material/styles";
import PropTypes from "prop-types";
import CardMedia from "@mui/material/CardMedia";

// import icons
const ArrowLeft =
  "https://craftle-static.s3.us-east-2.amazonaws.com/icons/arrow-left.svg";

let customTheme = createTheme({
  breakpoints: {
    values: {
      customMd: 1300,
      xl: 1536,
      lg: 1200,
      md: 900,
      sm: 600,
      xs: 0,
    },
  },
});

const propTypes = {
  scrollRef: PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
  left: PropTypes.string,
  top: PropTypes.string,
  position: PropTypes.string,
};
const defaultProps = {
  top: "40%",
  left: "0.5vw",
  position: "absolute",
};

ScrollButtonLeft.defaultProps = defaultProps;
ScrollButtonLeft.propTypes = propTypes;

export default function ScrollButtonLeft({ scrollRef, left, top, position }) {
  const scroll = (scrollOffset) => {
    scrollRef.current.scrollLeft += scrollOffset;
  };

  return (
    <Box
      onClick={() => scroll(-512)}
      sx={{
        position: { position },
        left: { left },
        top: { top },
        zIndex: 10,
        transitionDuration: "0.2s",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        "&:hover": {
          cursor: "pointer",
          opacity: "80%",
        },
        // width: "56px",
        // height: "56px",
        // borderRadius: "100px",
        // backgroundColor: "#FFFFFF",
        // display: "flex",
        // justifyContent: "center",
        // alignItems: "center",
        // opacity: "90%",
        // border: "1px solid #BFBEBE",
        [customTheme.breakpoints.down("md")]: {
          display: "none",
        },
      }}
    >
      <Box
        sx={{
          width: "56px",
          height: "56px",
          borderRadius: "100px",
          backgroundColor: "#FFFFFF",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          opacity: "90%",
          border: "1px solid #BFBEBE",
        }}
      >
        {/* <ArrowLeft /> */}
        <CardMedia
          component="img"
          image={ArrowLeft}
          alt="Left arrow button"
          sx={{ width: "24px", height: "24px" }}
        />
      </Box>
    </Box>
  );
}
