import React, { useEffect, useRef } from "react";
import { Box } from "@mui/material";
// import { useGrowthBook } from "@growthbook/growthbook-react";

// import components
// import { customTheme } from "../components/atoms/CustomTheme/CustomTheme";
import HomeHeroSection from "../components/organisms/HomeHeroSection/HomeHeroSection";
import TrendingProductSection from "../components/organisms/TrendingProductSection/TrendingProductSection";
import CompareFavoritesSection from "../components/organisms/CompareFavoritesSection/CompareFavoritesSection";
import LandingBudgetMoodboard from "../components/organisms/LandingBudgetMoodboard/LandingBudgetMoodboard";
import LookalikeFinderSection from "../components/molecules/LookalikeFinderSection/LookalikeFinderSection";
// import GetStarted from "../components/molecules/HomeGetStarted/HomeGetStarted";
// import HeroSection from "../components/molecules/HomeHeroSection/HomeHeroSection";
// import HowItWorks from "../components/molecules/HomeHowItWorks/HomeHowItWorks";
// import LookalikeFinderSection from "../components/molecules/LookalikeFinderSection/LookalikeFinderSection";
import MixpanelTracking from "../services/mixpanel";

function Home() {
  // const growthbook = useGrowthBook();
  // const craftleHomeVariant = growthbook.getFeatureValue(
  //   "craftle-app-home-variant",
  //   null
  // );

  const trendingProductRef = useRef(null);
  const budgetMoodboardRef = useRef(null);
  const lookalikeFinderRef = useRef(null);

  useEffect(() => {
    MixpanelTracking.getInstance().pageViewed("home");
  }, []);

  return (
    <>
      {/* body container */}
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          marginBottom: "120px",
        }}
      >
        {/* Hero section */}
        <HomeHeroSection
          budgetMoodboardRef={budgetMoodboardRef}
          lookalikeFinderRef={lookalikeFinderRef}
          trendingProductRef={trendingProductRef}
        />

        {/* Trending products section */}
        <TrendingProductSection trendingProductRef={trendingProductRef} />

        {/* Compare favorites section */}
        <CompareFavoritesSection />

        {/* budget-only moodboard section */}
        <LandingBudgetMoodboard budgetMoodboardRef={budgetMoodboardRef} />

        {/* Lookalike Finder section */}
        <LookalikeFinderSection lookalikeFinderRef={lookalikeFinderRef} />

        {/* hero section */}
        {/* <HeroSection /> */}

        {/* how it works section */}
        {/* {craftleHomeVariant === "primary" && <HowItWorks />} */}
        {/* <HowItWorks /> */}

        {/* get started section */}
        {/* <GetStarted craftleHomeVariant={craftleHomeVariant} /> */}
      </Box>
    </>
  );
}

export default Home;
