import { customTheme } from "../../atoms/CustomTheme/CustomTheme";

export const classes = {
  container: {
    position: "fixed",
    width: "fit-content",
    top: "80px",
    left: 0,
    right: 0,
    marginLeft: "auto",
    marginRight: "auto",
    color: "#333",
    padding: "8px 24px",
    borderRadius: "100px",
    boxShadow: "0px 0px 10px rgba(0,0,0,0.1)",
    display: "flex",
    alignItems: "center",
    gap: "10px",
    zIndex: 1000,
    backgroundColor: "#FFFFFF",
    [customTheme.breakpoints.down("sm")]: {
      top: "64px",
      padding: "8px 16px",
    },
  },
};
