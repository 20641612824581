import * as React from "react";
import { Box, Grid } from "@mui/material";
import CardMedia from "@mui/material/CardMedia";
import Text from "../../atoms/Text/Text";
import { Link } from "react-router-dom";
import { createTheme } from "@mui/material/styles";
// import PropTypes from "prop-types";

// import components

const Image =
  "https://craftle-static.s3.us-east-2.amazonaws.com/blog/ad-images/ad-img-1.png";

let customTheme = createTheme({});

export default function BlogInlineAd1() {
  return (
    <Box
      sx={{
        marginTop: "64px",
        marginBottom: "64px",
        [customTheme.breakpoints.down("sm")]: {
          marginTop: "60px",
        },
      }}
    >
      <Link
        to="/designs"
        style={{
          textDecoration: "none",
        }}
      >
        {/* Below image only appears in tablet and mobile */}
        <Box
          sx={{
            [customTheme.breakpoints.down("sm")]: {
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              position: "relative",
              zIndex: 0,
            },
          }}
        >
          <CardMedia
            component="img"
            image={Image}
            alt=""
            sx={{
              display: "none",
              [customTheme.breakpoints.down("sm")]: {
                display: "inline",
                width: "auto",
              },
            }}
          />
        </Box>
        <Box
          sx={{
            width: "100%",
            padding: "12px 14px",
            backgroundColor: "#FFE9AF",
            borderRadius: "10px",
            boxSizing: "border-box",
            [customTheme.breakpoints.down("sm")]: {
              marginTop: "-110px",
              position: "relative",
              zIndex: 1,
            },
          }}
        >
          <Box
            sx={{
              width: "100%",
              borderStyle: "solid",
              borderColor: "#2A2A2A",
              borderRadius: "6px",
              padding: "62px 0",
              [customTheme.breakpoints.down("sm")]: {
                padding: "24px 0",
              },
            }}
          >
            <Grid container>
              <Grid
                item
                sm={5}
                sx={{
                  position: "relative",
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                }}
              >
                <CardMedia // Image only appears in desktop
                  component="img"
                  image={Image}
                  alt=""
                  sx={{
                    position: "absolute",
                    maxWidth: "260px",
                    bottom: "-83px",
                    [customTheme.breakpoints.down("sm")]: {
                      display: "none",
                    },
                  }}
                />
              </Grid>
              <Grid
                item
                sm={7}
                xs={12}
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  textAlign: "center",
                  padding: "0 8px",
                }}
              >
                <Text
                  sx={{
                    color: "#2A2A2A",
                    fontSize: "24px",
                    lineHeight: 1.5,
                    fontWeight: "bold",
                  }}
                >
                  Answer a few questions, get a free furniture plan!
                </Text>
                <Box
                  sx={{
                    maxWidth: "240px",
                    width: "100%",
                    height: "50px",
                    backgroundColor: "#2a2a2a",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    marginTop: "30px",
                    borderRadius: "25px",
                    fontSize: "16px",
                    fontWeight: "bold",
                    color: "#FFF",
                  }}
                >
                  Get Started
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Link>
    </Box>
  );
}
