import React from "react";
import { Box, Typography } from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import PropTypes from "prop-types";
import CardMedia from "@mui/material/CardMedia";

// import components
import mixpanel from "../../atoms/Mixpanel/Mixpanel";

// import images

// import icons

let customTheme = createTheme({
  breakpoints: {
    values: {
      customMd: 1300,
      xl: 1536,
      lg: 1200,
      md: 900,
      sm: 600,
      xs: 0,
    },
  },
});

const propTypes = {
  name: PropTypes.string,
  brand: PropTypes.string,
  type: PropTypes.string,
  price: PropTypes.string,
  description: PropTypes.string,
  url: PropTypes.string,
  productImg1: PropTypes.node,
  productImg1Credit1: PropTypes.string,
  productImg2: PropTypes.node,
  productImg2Credit2: PropTypes.string,
  description2: PropTypes.string,
};

const defaultProps = {
  name: null,
  brand: null,
  type: null,
  price: null,
  description: null,
  url: null,
  productImg1: null,
  productImg1Credit1: null,
  productImg2: null,
  productImg2Credit2: null,
  description2: null,
};

ProductPage.propTypes = propTypes;
ProductPage.defaultProps = defaultProps;

function ProductPage({
  name,
  brand,
  type,
  price,
  description,
  url,
  productImg1,
  productImg1Credit1,
  productImg2,
  productImg2Credit2,
  description2,
}) {
  // const isSmall = useMediaQuery(customTheme.breakpoints.down("sm"));
  // const isMedium = useMediaQuery(customTheme.breakpoints.down("md"));
  // const isLarge = useMediaQuery(customTheme.breakpoints.down("lg"));
  // var displayThis = "flex";

  function display() {
    // This function returns stylings if Images or text is passed
    // None by default
    // Flex if exists
    let displayThis = "none";

    if (
      productImg1 ||
      productImg1Credit1 ||
      productImg2 ||
      productImg2Credit2 ||
      description2
    ) {
      displayThis = "flex";
    }

    return displayThis;
  }

  return (
    <ThemeProvider theme={customTheme}>
      <Typography
        variant="h1"
        sx={{
          fontSize: "40px",
          lineHeight: 1.2,
          fontWeight: "bold",
          [customTheme.breakpoints.down("md")]: {
            textAlign: "center",
          },
          [customTheme.breakpoints.down("sm")]: {
            fontSize: "32px",
          },
        }}
      >
        {name}
      </Typography>

      <Box
        sx={{
          marginTop: "16px",
          [customTheme.breakpoints.down("md")]: { textAlign: "center" },
        }}
      >
        <Box
          sx={{
            backgroundColor: "#F2F2F2",
            padding: "8px 16px",
            display: "inline-block",
            borderRadius: "8px",
            marginRight: "8px",
          }}
        >
          {brand}
        </Box>

        <Box
          sx={{
            backgroundColor: "#F2F2F2",
            padding: "8px 16px",
            display: "inline-block",
            borderRadius: "8px",
          }}
        >
          {type}
        </Box>
      </Box>

      <Typography
        sx={{
          fontSize: "32px",
          fontWeight: "bold",
          marginTop: "16px",
          [customTheme.breakpoints.down("md")]: { textAlign: "center" },
        }}
      >
        ${price}
      </Typography>

      <Typography
        sx={{
          marginTop: "24px",
          maxWidth: "600px",
          [customTheme.breakpoints.down("md")]: { maxWidth: "inherit" },
        }}
      >
        {description}
      </Typography>

      <Box
        sx={{
          marginTop: "32px",
          [customTheme.breakpoints.down("md")]: {
            display: "flex",
            flexDirection: "row,",
            justifyContent: "center",
          },
        }}
      >
        <Box
          onClick={() => {
            window.open(url);
            mixpanel.track(`(Explore) Clicked "Visit Product"`);
          }}
          target="_blank"
          sx={{
            backgroundColor: "#2a2a2a",
            padding: "16px 40px",
            display: "inline-block",
            borderRadius: "100px",
            "&:hover": {
              cursor: "pointer",
              backgroundColor: "#494949",
            },
          }}
          rel="noreferrer"
        >
          <Typography
            sx={{
              color: "#FFFFFF",
              fontWeight: "bold",
            }}
          >
            Visit Product
          </Typography>
        </Box>
      </Box>

      <Box
        sx={{
          // maxWidth: "532px",
          border: "1px solid #E3E3E3",
          borderRadius: "16px",
          padding: "16px",
          marginTop: "32px",
          width: "100%",
          // display: "flex",
          flexDirection: "column",
          alignItems: "center",
          display: display(),
          // {iconRender(theme)}
        }}
      >
        <Typography
          sx={{ textAlign: "center", color: "#757575", marginBottom: "8px" }}
        >
          Inspired By:
        </Typography>
        <CardMedia
          component="img"
          image={productImg1}
          alt=""
          sx={{
            position: "relative",
            borderRadius: "16px",
            width: "100%",
            maxWidth: "500px",
          }}
        />
        <Typography
          sx={{ textAlign: "center", color: "#757575", marginTop: "8px" }}
        >
          {productImg1Credit1}
        </Typography>
        <CardMedia
          component="img"
          image={productImg2}
          alt=""
          sx={{
            position: "relative",
            width: "100%",
            borderRadius: "16px",
            marginTop: "8px",
            maxWidth: "500px",
          }}
        />
        <Typography
          sx={{ textAlign: "center", color: "#757575", marginTop: "8px" }}
        >
          {productImg2Credit2}
        </Typography>
        <Typography
          sx={{ marginTop: "24px", textAlign: "center", maxWidth: "500px" }}
        >
          {description2}
        </Typography>
      </Box>
    </ThemeProvider>
  );
}

export default ProductPage;
