import { customTheme } from "../../../components/atoms/CustomTheme/CustomTheme";

export const classes = {
  container: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#FFF9F3",
    borderRadius: "16px",
    height: "100%",
    [customTheme.breakpoints.down("md")]: {
      minHeight: "60vw",
      padding: "8px",
    },
  },
  cardMediaStyle: {
    width: "auto",
    marginRight: "-64px",
    [customTheme.breakpoints.down("xl")]: {
      width: "300px",
    },
    [customTheme.breakpoints.down("lg")]: {
      width: "300px",
    },
    [customTheme.breakpoints.down(1200)]: {
      width: "240px",
      marginTop: "-20px",
    },
    [customTheme.breakpoints.down(1000)]: {
      width: "auto",
    },
    [customTheme.breakpoints.down("md")]: {
      width: "300px",
    },
    [customTheme.breakpoints.down(550)]: {
      width: "240px",
    },
    [customTheme.breakpoints.down(475)]: {
      width: "200px",
    },
    [customTheme.breakpoints.down(410)]: {
      width: "160px",
    },
  },
  personalizedPlanTextStyle: {
    fontSize: "24px",
    fontWeight: "bold",
    marginTop: "-16px",
    [customTheme.breakpoints.down("xl")]: {
      marginTop: "-24px",
    },
    [customTheme.breakpoints.down(1200)]: {
      marginTop: "-16px",
      fontSize: "20px",
    },
  },
  buttonContainer: {
    marginTop: "24px",
    color: "#FFFFFF",
    padding: "0 24px",
    height: "48px",
    borderRadius: "30px",
    backgroundColor: "#2a2a2a",
    boxShadow: 0,
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    transitionDuration: "0.4s",
    fontWeight: "bold",
    "&:hover": {
      cursor: "pointer",
      opacity: 0.9,
    },
    [customTheme.breakpoints.down("md")]: {
      marginTop: "16px",
    },
    [customTheme.breakpoints.down("sm")]: {
      padding: "0 20px",
    },
  },
  iconContainer: {
    marginRight: "8px",
  },
};
