import React from "react";
import { Route } from "react-router-dom";
import { Helmet } from "react-helmet";

// import components
import { Footer } from "../components/organisms";
import {
  AIFurnitureShopperResults,
  AIFurnitureShopperResults1,
  AIFurnitureShopperResults2,
} from "../pages/index.jsx";
import WebAppBar from "../components/organisms/WebAppBar/WebAppBar";

const aiFurnitureShopperRoutes = (navLinks) => (
  <>
    {/* AI furniture shopper demo - search results */}
    <Route
      path="/ai-furniture-shopper/search-results"
      element={
        <>
          <Helmet>
            <title>Craftle • AI Shopper Results</title>
            <meta
              name="description"
              content="Your furniture shopping results."
            />
          </Helmet>
          <WebAppBar urls={navLinks} />
          <AIFurnitureShopperResults />
          <Footer />
        </>
      }
    />

    {/* AI furniture shopper demo - results 1 */}
    <Route
      path="/ai-furniture-shopper/search/e/678"
      element={
        <>
          <Helmet>
            <title>Craftle • AI Shopper Results</title>
            <meta
              name="description"
              content="Your AI furniture shopping results."
            />
          </Helmet>
          <WebAppBar urls={navLinks} />
          <AIFurnitureShopperResults1 />
          <Footer />
        </>
      }
    />

    {/* AI furniture shopper demo - results 2 */}
    <Route
      path="/ai-furniture-shopper/search/e/679"
      element={
        <>
          <Helmet>
            <title>Craftle • AI Shopper Results</title>
            <meta
              name="description"
              content="Your AI furniture shopping results."
            />
          </Helmet>
          <WebAppBar urls={navLinks} />
          <AIFurnitureShopperResults2 />
          <Footer />
        </>
      }
    />
  </>
);

export default aiFurnitureShopperRoutes;
