const fabric_mappings = {
  douglas: {
    gray: ["nickel"],
    "light gray": ["ice"],
  },
  mystic: {
    beige: ["frost"],
  },
  monet: {
    beige: ["frost"],
  },
  nordic: {
    white: ["frost"],
  },
  como: {
    black: ["nickel"],
    "olive green": ["olive"],
  },
  cascade: {
    beige: ["natural"],
  },
  bliss: {
    beige: ["natural"],
    "light gray": ["pewter"],
  },
  opus: {
    beige: ["natural", "pearl"],
    "olive green": ["olive"],
    gray: ["charcoal"],
  },
  link: {
    beige: ["natural", "ivory"],
  },
  mojo: {
    beige: ["pearl"],
  },
  icon: {
    beige: ["pearl"],
    gray: ["metal"],
  },
  taft: {
    beige: ["pearl"],
  },
  delight: {
    beige: ["ivory"],
  },
  winward: {
    beige: ["snow"],
  },
  vail: {
    beige: ["snow"],
  },
  shelly: {
    "off-white": ["ice"],
  },
  galaxy: {
    white: ["ice"],
  },
  wooly: {
    beige: ["oatmeal"],
  },
  synergy: {
    "light beige": ["oatmeal"],
  },
  oasis: {
    beige: ["shell"],
  },
  ivers: {
    brown: ["buff"],
  },
  ascend: {
    cream: ["ivory"],
    "dark gray": ["night"],
  },
  lukas: {
    "iron gray": ["iron"],
  },
  tribute: {
    beige: ["gravel"],
    "light gray": ["carbon"],
    white: ["quartz"],
  },
  covet: {
    beige: ["camel"],
    "olive green": ["olive"],
  },
  lynx: {
    brown: ["toffee"],
  },
  thrive: {
    beige: ["sand", "quartz"],
  },
  tahoe: {
    "light gray": ["blizzard"],
  },
  butler: {
    cognac: ["ale"],
  },
  libby: {
    "dark brown": ["sumatra"],
    "light brown": ["camel"],
  },
  sexton: {
    brown: ["sable"],
  },
  "old town": {
    "dark brown": ["cocoa"],
    "light brown": ["cayenne"],
  },
  "performance chateau": {
    gray: ["blue"],
  },
  view: {
    "dark green": ["pewter"],
  },
  "montura leather": {
    "dark gray": ["olivia"],
  },
  "renew vegan leather": {
    black: ["carbon"],
    gray: ["shrub"],
  },
  "arroyo leather": {
    "light brown": ["stone"],
  },
};

const color_mappings = {
  red: ["red", "garnet", "grenache", "paprika"],
  pink: ["pink"],
  orange: ["orange"],
  yellow: ["yellow", "citron", "lemon", "goldenrod"],
  green: ["green", "hunter", "pine", "forest", "seafoam"],
  "dark green": ["moss", "balsam", "jade", "loden"],
  sage: ["sage"],
  olive: ["olive"],
  blue: [
    "blue",
    "seafoam",
    "lagoon",
    "capri",
    "sky",
    "royal",
    "chambray",
    "indigo",
  ],
  "dark blue": ["lake", "royal"],
  indigo: ["indigo"],
  navy: ["navy", "admiral"],
  teal: ["teal"],
  turquoise: ["turquoise"],
  purple: ["purple"],
  black: [
    "black",
    "espresso",
    "ink",
    "nile",
    "nickel",
    "smoke",
    "charcoal",
    "metal",
    "midnight",
  ],
  white: [
    "white",
    "lace",
    "ice",
    "cloud",
    "quartz",
    "pearl",
    "dune",
    "winter white",
    "snow",
    "frost",
    "creme",
    "gravel",
    "salt",
  ],
  gray: [
    "grey",
    "gray",
    "granite",
    "slate",
    "steel",
    "taupe",
    "heather",
    "linen",
    "dune",
    "raffia",
    "fog",
    "gravel",
    "pewter",
    "latte",
    "sand",
    "ecru",
    "desert",
    "stone",
    "snow",
    "frost",
    "blizzard",
    "putty",
    "nimbus",
    "dusk",
    "fog",
    "ash",
    "shadow",
    "asphalt",
    "nickel",
    "seal",
    "metal",
    "ice",
    "silver",
    "mist",
    "sterling",
    "chrome",
    "carbon",
    "granite",
    "dark grey",
    "dusk",
    "iron",
    "shadow",
    "night",
    "smoke",
    "flannel",
    "onyx",
    "hunter",
    "marsh",
    "haze",
    "spa",
    "mineral",
    "sky",
    "heather",
    "indigo",
    "ink",
    "sea",
    "harbor",
    "eclipse",
    "opus",
    "bliss",
    "onxy",
    "platinum",
    "chambray",
    "otter",
    "fossil",
    "felt grey",
  ],
  "light gray": ["light gray", "light grey", "pebble", "dove"],
  "dark gray": [
    "dark gray",
    "dark grey",
    "graphite",
    "charcoal",
    "storm",
    "mink",
    "cobalt",
    "night",
    "truffle",
  ],
  brown: [
    "brown",
    "brandy",
    "java",
    "spice",
    "ginger",
    "nutmeg",
    "sepia",
    "ocher",
    "ochre",
    "peat",
    "coffee",
    "cayenne",
    "ale",
    "molasses",
    "caramel",
    "camel",
    "rust",
    "terracotta",
    "chestnut",
    "cigar",
    "honey",
    "butterscotch",
  ],
  cognac: ["cognac"],
  "dark brown": [
    "walnut",
    "blackwood",
    "cocoa",
    "toffee",
    "nailheads",
    "birch",
  ],
  "light brown": ["apricot", "brie", "nude"],
  beige: [
    "beige",
    "bisque",
    "ivory",
    "cement",
    "pearl",
    "wheat",
    "sand",
    "latte",
    "champagne",
    "taupe",
    "linen",
    "ecru",
    "nut",
    "pumice",
    "maize",
    "oyster",
    "oatmeal",
    "shell",
    "powder",
    "fog",
    "natural",
    "flax",
    "stripe",
    "mushroom",
    "vanilla",
  ],
  bronze: ["bronze"],
  silver: ["silver"],
  gold: ["gold"],
  brass: ["brass"],
};

function escapeRegExp(string) {
  return string.replace(/[.*+?^${}()|[\]\\]/g, "\\$&"); // $& means the whole matched string
}

function replaceAll(str, find, replace) {
  return str.replace(new RegExp(escapeRegExp(find), "g"), replace);
}

function ColorClassifier(str) {
  const strLowerCase = str.toLocaleLowerCase();
  const words = strLowerCase.replace(/[,()"-]/g, " ").split(/\s+/);

  // Check for fabric first
  for (const [fabric, colorMap] of Object.entries(fabric_mappings)) {
    if (strLowerCase.includes(fabric)) {
      for (const [color, relatedStrings] of Object.entries(colorMap)) {
        if (
          relatedStrings.some((relatedString) => words.includes(relatedString))
        ) {
          const hyphenatedFabric = fabric.includes(" ")
            ? replaceAll(fabric, " ", "-")
            : fabric;

          // Return object with hyphenatedFabric and color
          return { hyphenatedFabric, color };
        }
      }
    }
  }

  // Check for color next
  for (const [color, relatedStrings] of Object.entries(color_mappings)) {
    if (relatedStrings.some((relatedString) => words.includes(relatedString))) {
      // Return object with color only
      return { color };
    }
  }

  return {};
}

export default ColorClassifier;
