import * as React from "react";
import { Box } from "@mui/material";
import PropTypes from "prop-types";

const propTypes = {
  date: PropTypes.string,
};

const defaultProps = {
  date: "",
};

BlogDate.propTypes = propTypes;
BlogDate.defaultProps = defaultProps;

export default function BlogDate({ date }) {
  return (
    <Box
      sx={{
        fontSize: "14px",
        fontWeight: "500",
        color: "#757575",
      }}
    >
      {date}
    </Box>
  );
}
