import React from "react";
import { Box } from "@mui/material";
import { createTheme } from "@mui/material/styles";

// import components
import Text from "../../../components/atoms/Text/Text";
import Loading from "../../../components/atoms/Loading/Loading";

// import graphics
import DrawerChestGraphic from "../../../assets/graphics/loading-drawer-chest.png";
import CabinetGraphic from "../../../assets/graphics/loading-cabinet.png";
import MirrorGraphic from "../../../assets/graphics/loading-mirror.png";
import DeskGraphic from "../../../assets/graphics/loading-desk.png";
import SofaGraphic from "../../../assets/graphics/loading-sofa.png";

const customTheme = createTheme({});

function LoadingResults() {
  const imageTheme = {
    [customTheme.breakpoints.down("sm")]: {
      width: "15%",
    },
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
        [customTheme.breakpoints.down("md")]: {
          paddingLeft: "20px",
          paddingRight: "20px",
        },
      }}
    >
      <Box sx={{ marginBottom: "20px" }}>
        <Loading />
      </Box>

      {/*
      <Box
        sx={{
          width: "100%",
          maxWidth: "500px",
          height: "15px",
          backgroundColor: "#E3E3E3",
          borderRadius: "7.5px",
          marginBottom: "30px",
        }}
      >
        <Box
          sx={{
            width: "20%",
            maxWidth: "500px",
            height: "15px",
            backgroundColor: "#2A2A2A",
            borderRadius: "7.5px",
          }}
        ></Box>
      </Box>
      */}
      <Text
        sx={{
          fontWeight: "bold",
          marginBottom: "5px",
          fontSize: "20px",
          textAlign: "center",
        }}
      >
        Generating your personalized plan
      </Text>
      <Text sx={{ color: "#757575", marginBottom: "20px" }}>
        (this can take a minute)
      </Text>

      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-evenly",
          alignItems: "flex-end",
          width: "100%",
          maxWidth: "758px",
        }}
      >
        <Box component="img" sx={imageTheme} src={DeskGraphic} alt="" />
        <Box component="img" sx={imageTheme} src={MirrorGraphic} alt="" />
        <Box component="img" sx={imageTheme} src={SofaGraphic} alt="" />
        <Box component="img" sx={imageTheme} src={DrawerChestGraphic} alt="" />
        <Box component="img" sx={imageTheme} src={CabinetGraphic} alt="" />
      </Box>
    </Box>
  );
}

export default LoadingResults;
