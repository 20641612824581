import { customTheme } from "../../components/atoms/CustomTheme/CustomTheme";

export const classes = {
  container: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "center",
    width: "100vw",
    padding: "81px 32px 0px 32px",
    boxSizing: "border-box",
    [customTheme.breakpoints.down("md")]: {
      padding: "81px 16px 0px 16px",
    },
  },
  loadingSection: {
    top: 0,
    position: "fixed",
    justifyContent: "center",
    alignItems: "center",
    height: "100vh",
    width: "100vw",
    zIndex: 1000,
    backgroundColor: "rgba(0, 0, 0, 0.4)",
  },
  loadingModal: {
    backgroundColor: "rgba(255, 254, 215, 1)",
    border: "solid 4px #2A2A2A",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    padding: "40px",
    borderRadius: "16px",
    gap: "16px",
    [customTheme.breakpoints.down("sm")]: {
      padding: "24px",
      textAlign: "center",
      margin: "0 16px",
    },
  },
  loaderIcon: {
    width: "65px",
    height: "65px",
  },
  loaderText: {
    fontWeight: "bold",
  },

  topSeparator: {
    width: "100vw",
    height: "1px",
    backgroundColor: "#F3F3F3",
  },

  content: {
    display: "flex",
    flexDirection: "row",
    // maxWidth: "1293px",
    justifyContent: "center",
    [customTheme.breakpoints.down("md")]: {
      flexDirection: "column",
      alignItems: "center",
    },
  },

  // Left column
  leftColumn: {
    maxWidth: "569px",
    minWidth: "400px",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    boxSizing: "border-box",
    padding: "0 24px 80px 0",
    [customTheme.breakpoints.down("md")]: {
      maxWidth: "inherit",
      padding: "0 0 64px 0",
    },
    [customTheme.breakpoints.down("sm")]: {
      minWidth: "auto",
    },
  },
  sticky: {
    position: "sticky",
    top: "121px",
    [customTheme.breakpoints.down("md")]: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    },
  },
  commandsContainer: {
    display: "flex",
    flexDirection: "row",
    gap: "8px",
  },
  commandButton: {
    padding: "16px 24px",
    backgroundColor: "#F3F3F3",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    gap: "8px",
    borderRadius: "100px",
    cursor: "pointer",
    transitionDuration: "0.3s",
    "&:hover": {
      backgroundColor: "rgba(191, 191, 191, 0.4)",
    },
  },
  commandText: {
    fontWeight: "bold",
    lineHeight: "16px",
  },
  leftColumnContent: {
    marginTop: "40px",
    [customTheme.breakpoints.down("md")]: {
      display: "flex",
      flexDirection: "column",
      maxWidth: "800px",
    },
  },
  matchingItemsContainer: {
    padding: "8px 16px",
    backgroundColor: "#DAEBCF",
    width: "fit-content",
    borderRadius: "4px",
  },
  matchingItemsText: {
    fontSize: "14px",
    fontWeight: "bold",
  },
  title: {
    marginTop: "16px",
    fontSize: "32px",
  },
  subtitle: {
    marginTop: "16px",
    fontSize: "18px",
  },
  searchContainer: {
    marginTop: "24px",
    display: "flex",
    flexDirection: "row",
    gap: "8px",
  },
  textfield: {
    width: "680px",
    "& .MuiFormLabel-root": {
      paddingLeft: "24px",
      marginTop: "-8px",
    },
    "& .MuiInputBase-root": {
      paddingLeft: "24px",
      paddingRight: "8px",
    },
    "& .MuiInputBase-root:before": {
      borderBottom: 0,
    },
    "& .MuiInputBase-root:after": {
      borderBottom: 0,
      display: "none",
    },
    "& .MuiInputBase-root:hover:not(.Mui-disabled):before": {
      borderBottom: 0,
    },
  },
  tipsContainer: {
    marginTop: "32px",
    display: "flex",
    flexDirection: "column",
    padding: "16px 24px",
    backgroundColor: "#FFF4D7",
    borderRadius: "8px",
  },
  tipsTitleRow: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    cursor: "pointer",
  },
  tipsTitleRowLeft: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    gap: "8px",
  },
  tipsIcon: {
    width: "24px",
  },
  tipsTitleText: {
    fontWeight: "bold",
    fontSize: "18px",
  },
  minimizeIcon: {
    width: "14px",
    height: "3px",
  },
  tipsContent: {
    marginTop: "24px",
  },
  tipsMoreButton: {
    marginTop: "24px",
    fontWeight: "bold",
    cursor: "pointer",
  },

  // Separator
  separator: {
    width: "1px",
    backgroundColor: "#F3F3F3",
    [customTheme.breakpoints.down("md")]: {
      display: "none",
    },
  },

  // Right column
  rightColumn: {
    marginTop: "40px",
    padding: "0 0 80px 24px",
    display: "grid",
    gap: "40px 24px",
    maxWidth: "calc(100vw - 569px)",
    gridTemplateColumns: "repeat(auto-fit, minmax(320px, 1fr))",
    [customTheme.breakpoints.down("md")]: {
      marginTop: 0,
      padding: "0 0 80px 0",
      gap: "40px 16px",
      maxWidth: "fit-content",
      width: "100%",
      gridTemplateColumns: "repeat(auto-fit, minmax(200px, 1fr))",
    },
    [customTheme.breakpoints.down("xs")]: {
      // gridTemplateColumns: "repeat(auto-fit, minmax(160px, 1fr))",
      // padding: "0 8px 80px 8px",
    },
  },
  itemContainer: {
    cursor: "pointer",
  },
  imageContainer: {
    border: "solid 1px #F3F3F3",
    borderRadius: "16px",
    position: "relative",
    width: "fit-content",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  itemPicture: {
    borderRadius: "16px",
  },
  favoritesContainer: {
    position: "absolute",
    top: "16px",
    right: "16px",
    padding: "8px",
    backgroundColor: "rgba(191, 191, 191, 0.5)",
    borderRadius: "20px",
    cursor: "pointer",
    transitionDuration: "0.3s",
    "&:hover": {
      backgroundColor: "rgba(191, 191, 191, 0.4)",
    },
  },
  favoriteIcon: {
    width: "24px",
  },
  itemStyle: {
    padding: "8px 16px",
    backgroundColor: "#F3F3F3",
    width: "fit-content",
    borderRadius: "4px",
    marginTop: "8px",
    fontWeight: "bold",
  },
  itemBrand: {
    marginTop: "8px",
    fontSize: "18px",
    fontWeight: "bold",
  },
  itemPrice: {
    fontSize: "18px",
  },
  itemDescription: {
    marginTop: "8px",
  },
  checkItOutButton: {
    marginTop: "16px",
    backgroundColor: "#FFE9AF",
  },
};
