import React from "react";
import Footer from "../../components/organisms/Footer/Footer";
import CardMedia from "@mui/material/CardMedia";

// import components
import BlogParallaxRoot from "../../components/molecules/BlogParallaxRoot/BlogParallaxRoot";
import BlogParallaxLayerBack from "../../components/molecules/BlogParallaxLayerBack/BlogParallaxLayerBack";
import BlogParallaxLayerMiddle from "../../components/molecules/BlogParallaxLayerMiddle/BlogParallaxLayerMiddle";
import BlogParallaxLayerFront from "../../components/molecules/BlogParallaxLayerFront/BlogParallaxLayerFront";
import BlogContentContainer from "../../components/molecules/BlogContentContainer/BlogContentContainer";
import BlogDate from "../../components/molecules/BlogDate/BlogDate";
import BlogTitle from "../../components/molecules/BlogTitle/BlogTitle";
import BlogParagraph from "../../components/molecules/BlogParagraph/BlogParagraph";
import BlogH2 from "../../components/molecules/BlogH2/BlogH2";
// import BlogH3 from "../../components/molecules/BlogH3/BlogH3";
import BlogImage from "../../components/molecules/BlogImage/BlogImage";
import BlogInlineAd1 from "../../components/molecules/BlogInlineAd1/BlogInlineAd1";
import BlogInlineAd2 from "../../components/molecules/BlogInlineAd2/BlogInlineAd2";

// import images
const bannerImage =
  "https://craftle-static.s3.us-east-2.amazonaws.com/blog/blog8/blog-8-banner.jpg";
const image1 =
  "https://craftle-static.s3.us-east-2.amazonaws.com/blog/blog8/blog-8-image-1.jpg";
const image2 =
  "https://craftle-static.s3.us-east-2.amazonaws.com/blog/blog8/blog-8-image-2.jpg";

function BlogPost8() {
  return (
    <BlogParallaxRoot>
      <BlogParallaxLayerBack>
        <CardMedia component="img" image={bannerImage} alt="" />
      </BlogParallaxLayerBack>

      <BlogParallaxLayerMiddle />

      <BlogParallaxLayerFront>
        <BlogContentContainer>
          <BlogDate date="May 18, 2022" />
          <BlogTitle title="Dates for High Point Furniture Market This Year" />
          <BlogParagraph>
            In this post, we cover what the High Point Market is, what High
            Point Market Authority is, and the dates for the High Point
            furniture market this year.
          </BlogParagraph>

          <BlogH2 title="What is The High Point Market?" />
          <BlogImage image={image1} alt="" caption="" />
          <BlogParagraph>
            The High Point furniture market is one of the largest and most
            important trade shows for the furniture industry. Each year,
            thousands of manufacturers, retailers, and designers from around the
            globe come together in April and October to showcase the latest
            trends in furniture design and manufacturing. Whether you are
            looking for new pieces for your home or business, or simply want to
            keep up with the latest design trends, the High Point furniture
            market is an event not to be missed. So mark your calendars and plan
            a visit to this exciting event in April or October!
          </BlogParagraph>

          <BlogH2 title="High Point Market Authority" />
          <BlogImage image={image1} alt="" caption="" />
          <BlogParagraph>
            The High Point Market Authority is a non-profit organization that
            organizes and hosts the annual High Point furniture market. As one
            of the largest trade shows for the furniture industry, it has become
            an important platform for manufacturers, retailers, and designers to
            showcase their latest products and connect with potential clients.
            With its focus on innovation, trendsetting design, and networking
            opportunities, the High Point Market Authority plays a vital role in
            supporting the furniture industry.
          </BlogParagraph>

          <BlogInlineAd1 />

          <BlogH2 title="When is the next High Point Market?" />
          <BlogImage image={image2} alt="" caption="" />
          <BlogParagraph>
            The dates for the next High Point furniture market are October
            22-26, 2022 (Saturday through Wednesday) in High Point.
          </BlogParagraph>
          <BlogParagraph>
            Plan to attend the event now that you know about it!
          </BlogParagraph>

          <BlogH2 title="Wrapping It Up" />
          <BlogImage image={image2} alt="" caption="" />
          <BlogParagraph>
            The High Point Market is one of the most important events in the
            furniture industry, bringing together thousands of manufacturers,
            retailers, and designers from around the globe to showcase the
            latest trends in design and manufacturing. Whether you are looking
            for new pieces for your home or business, or simply want to stay
            up-to-date with the latest design trends, the High Point Market is
            an event not to be missed.
          </BlogParagraph>
          <BlogParagraph>
            The High Point Market Authority supports this important industry
            through its focus on innovation, trendsetting design, and networking
            opportunities. The dates for the next High Point Market are October
            22-26, 2022, so mark your calendars and plan to attend this exciting
            event! Whether you are a seasoned furniture professional or simply a
            design enthusiast, the High Point Market is sure to have something
            for you.
          </BlogParagraph>

          <BlogInlineAd2 />
        </BlogContentContainer>
        <Footer />
      </BlogParallaxLayerFront>
    </BlogParallaxRoot>
  );
}

export default BlogPost8;
