import React from "react";
import { useNavigate } from "react-router-dom";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import { CardActionArea, Box } from "@mui/material";
import { createTheme } from "@mui/material/styles";
import PropTypes from "prop-types";

// import components
import Text from "../../atoms/Text/Text";

// import icons
import { ReactComponent as TrashIcon } from "../../../assets/icons/trash.svg";

import { convertDateToReadableFormat } from "../../../utils/utils";

let customTheme = createTheme({});

const imageUrl =
  "https://craftle-static.s3.us-east-2.amazonaws.com/design-style-images/" +
  "bohemian/1.png";

const propTypes = {
  planId: PropTypes.number.isRequired,
  planThumbnail: PropTypes.string,
  lastUpdated: PropTypes.string,
  onPressDeleteButton: PropTypes.func,
};

const currDate = new Date();

const defaultProps = {
  planThumbnail: imageUrl,
  lastUpdated: currDate,
  onPressDeleteButton: () => {},
};

function ImageTextCardAllMyDesigns({
  planId,
  planThumbnail,
  lastUpdated,
  onPressDeleteButton,
}) {
  let navigate = useNavigate();

  function planClicked() {
    navigate("/designs/e/" + planId);
  }

  return (
    <CardActionArea
      onClick={() => {
        planClicked();
      }}
    >
      <Card
        sx={{
          boxShadow: "none",
          borderRadius: 0,
          borderStyle: "solid",
          borderColor: "#D8D8D8",
          borderWidth: "1px",
          [customTheme.breakpoints.down("sm")]: {
            borderRadius: "10px",
          },
        }}
      >
        <Box sx={{ position: "relative" }}>
          <CardMedia
            component="img"
            sx={{ height: "223px" }}
            image={planThumbnail}
            alt="living room"
          />
        </Box>
        <CardContent
          sx={{
            paddingLeft: "20px",
            paddingRight: "20px",
            paddingTop: "15px",
            paddingBottom: "15px",
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            height: "100%",
            "&:last-child": {
              paddingBottom: "15px",
            },
          }}
        >
          <Text variant="body" sx={{}}>
            {convertDateToReadableFormat(lastUpdated)}
          </Text>
          <CardActionArea
            sx={{ width: "fit-content" }}
            onMouseDown={(event) => event.stopPropagation()}
            onClick={(event) => {
              event.stopPropagation();
              event.preventDefault();
              onPressDeleteButton(true, planId);
            }}
          >
            <TrashIcon />
          </CardActionArea>
        </CardContent>
      </Card>
    </CardActionArea>
  );
}

ImageTextCardAllMyDesigns.propTypes = propTypes;
ImageTextCardAllMyDesigns.defaultProps = defaultProps;

export default ImageTextCardAllMyDesigns;
