import React from "react";
import { Box } from "@mui/material";
import PropTypes from "prop-types";
import { createTheme } from "@mui/material/styles";

// import components
import Text from "../../atoms/Text/Text";
import PeopleAlsoAsk from "../../molecules/PeopleAlsoAsk/PeopleAlsoAsk";

let customTheme = createTheme({
  breakpoints: {
    values: {
      customMd: 1300,
      xl: 1536,
      lg: 1280,
      md: 1000,
      sm: 600,
      xs: 0,
    },
  },
});

const propTypes = {
  title: PropTypes.string,
  categoryHelpfulInfo: PropTypes.node,
  categoryPeopleAlsoAsk: PropTypes.array,
};

const defaultProps = {
  title: "",
  categoryHelpfulInfo: null,
  categoryPeopleAlsoAsk: null,
};

ItemCategoryHelpfulInfoSection.propTypes = propTypes;
ItemCategoryHelpfulInfoSection.defaultProps = defaultProps;

function ItemCategoryHelpfulInfoSection({
  title,
  categoryHelpfulInfo,
  categoryPeopleAlsoAsk,
}) {
  const classes = {
    container: {
      marginTop: "64px",
      maxWidth: "740px",
      width: "100%",
      border: "solid 4px #2A2A2A",
      borderRadius: "16px",
      padding: "32px",
      boxSizing: "border-box",
      [customTheme.breakpoints.down("sm")]: {
        padding: "32px 8px",
      },
    },
    title: {
      fontSize: "40px",
      [customTheme.breakpoints.down("sm")]: {
        fontSize: "32px",
      },
    },
    outerGridContainer: {
      position: "relative",
    },
    paragraph: {
      marginTop: "24px",
      whiteSpace: "pre-line",
    },
    gridColumn2: {
      marginTop: "24px",
      display: "flex",
      flexDirection: "row",
      justifyContent: "flex-end",
    },
  };

  return (
    <Box sx={classes.container}>
      <Text variant="h2" sx={classes.title}>
        {title}
      </Text>
      <Box sx={classes.outerGridContainer}>
        <Box>
          <Text sx={classes.paragraph}>{categoryHelpfulInfo}</Text>
        </Box>
        <Box sx={classes.gridColumn2}>
          <PeopleAlsoAsk
            peopleAlsoAsk={categoryPeopleAlsoAsk}
            upperCategory={true}
          />
        </Box>
      </Box>
    </Box>
  );
}

export default ItemCategoryHelpfulInfoSection;
