import getColor from "./ColorClassifier";
import getFurnitureType from "./FurnitureTypeClassifier";

export function WestElmService(pageHTML) {
  function getProductDimensions(dom) {
    const productDimensionsWrapper = dom.querySelector(
      ".flex.justify-between.content-start > div > ul"
    );

    const productDimensionElements =
      productDimensionsWrapper?.querySelectorAll("li");

    console.log("first element: ", productDimensionElements[0]); // remove later

    const productDimensionElement =
      productDimensionElements[1].innerText.toLowerCase();

    console.log("productDimensionElement: ", productDimensionElement);

    let width = 0,
      depth = 0,
      height = 0;

    let widthStringEnd = 0;
    let depthStringEnd = 0;
    let oldDepthStringEnd = 0;
    let heightStringEnd = 0;

    // getting the ending position of each dimension type in the productDimensionElement string
    for (let i = 0; i < productDimensionElement.length; i++) {
      if (productDimensionElement[i] === "w") {
        widthStringEnd = i - 1;
        width = parseFloat(
          productDimensionElement.substring(0, widthStringEnd)
        );
      }
      if (productDimensionElement[i] === "d") {
        depthStringEnd = i - 1;
        if (oldDepthStringEnd === 0) {
          depth = parseFloat(
            productDimensionElement.substring(
              widthStringEnd + 6,
              depthStringEnd
            )
          );
        }
        if (oldDepthStringEnd > 0) {
          height = parseFloat(
            productDimensionElement.substring(
              oldDepthStringEnd + 6,
              depthStringEnd
            )
          );
        }
        oldDepthStringEnd = depthStringEnd;
      }
      if (productDimensionElement[i] === "h") {
        heightStringEnd = i - 1;
        height = parseInt(
          productDimensionElement.substring(depthStringEnd + 6, heightStringEnd)
        );
      }
    }

    const dimensions = { width: width, depth: depth, height: height };

    return dimensions;
  }

  const dom = pageHTML;
  const retrieveInnerHTML = dom.getElementsByTagName("body")[0].innerHTML;
  let productDetails = {};

  // Get product name
  const productName = dom.querySelector(
    ".design-platform > .link-breadcrumb > li > h1"
  ).innerText;
  console.log("productName: ", productName);

  // Get image
  const imageUrl = dom
    .querySelector(
      ".hooper > .hooper-list > .hooper-track > .hooper-slide > .thumbnailWidget > div > img"
    )
    ?.getAttribute("src");
  console.log("imageURL: ", imageUrl);

  // Get product description
  // const productDescription = dom.querySelector( // not updated
  //   ".product-summary-copy"
  // )?.innerText;
  // console.log("productDescription: ", productDescription);

  // Get dimensions
  const dimensions = getProductDimensions(dom);
  console.log("dimensions: ", dimensions);

  // Get furniture type
  let title = [];
  title.push(productName.trim());
  const furnitureType = getFurnitureType(title);
  console.log("furnitureType: ", furnitureType);

  // Get price
  const priceStringStart = retrieveInnerHTML.substring(
    retrieveInnerHTML.indexOf(`"amount">`)
  );
  const priceString = priceStringStart.substring(
    9,
    priceStringStart.indexOf(`</span>`)
  );
  const price = parseFloat(priceString);

  // Get color
  const color = getColor(
    dom.querySelector(".image-caption > p")?.innerText.trim()
  )[0];

  // Add brand
  const brand = "West Elm";

  // combine all variables into an object
  productDetails = {
    productName: productName,
    imageURL: imageUrl,
    // description: productDescription,
    furnitureType: furnitureType,
    width: dimensions.width,
    depth: dimensions.depth,
    height: dimensions.height,
    color: color,
    price: price,
    brand: brand,
  };

  return productDetails;
}
