import React, { useState } from "react";
import { Box, Divider } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import PropTypes from "prop-types";
import { AnalyticsBrowser } from "@segment/analytics-next";

// import components
import Text from "../../atoms/Text/Text";
import Button from "../../atoms/Button/Button";
import { classes } from "./ModalSignUpHomeStyle";
import { customTheme } from "../../atoms/CustomTheme/CustomTheme";
import { TrendingProductSectionContext } from "../../organisms/TrendingProductSection/TrendingProductSection";
import SignUpCallToBackend from "../../../pages/profileRelated/SignUpCallToBackend";
import Textfield from "../../molecules/Textfield/Textfield";
import GoogleSignUpButton from "../../../pages/profileRelated/GoogleSignUpButton";
import { segmentWriteKey } from "../../atoms/Segment/Segment";

// import icons
import { ReactComponent as XIcon } from "../../../assets/icons/x.svg";

const analytics = AnalyticsBrowser.load({ writeKey: segmentWriteKey });

export default function ModalSignUpShop({ redirectHref }) {
  const [showFirstNameError, setShowFirstNameError] = useState(false);
  const [showEmailError, setShowEmailError] = useState(false);
  const [showPasswordError, setShowPasswordError] = useState(false);
  const [showExistingAccountError, setShowExistingAccountError] =
    useState(false);
  const [firstName, setFirstName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const { setShowLoginModal, setShowSignUpModal } = React.useContext(
    TrendingProductSectionContext
  );
  const isMobileOrTabletScreen = useMediaQuery(
    customTheme.breakpoints.down("sm")
  );

  async function saveUserToken(token) {
    return new Promise((resolve, reject) => {
      try {
        localStorage.setItem("user", JSON.stringify(token));
        resolve();
      } catch (error) {
        reject(error);
      }
    });
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    setShowFirstNameError(false);
    setShowEmailError(false);
    setShowPasswordError(false);

    const currentDateTime = new Date(); // Current to the user's timezone

    // Assign the values from the inputs
    let userInfo = {
      firstName: document.getElementById("firstName").value || "",
      email: document.getElementById("email").value || "",
      password: document.getElementById("password").value || "",
      accountCreatedDateTime: currentDateTime.toLocaleString() || "",
    };

    if (userInfo.firstName === "") {
      setShowFirstNameError(true);
    }
    if (userInfo.email === "") {
      setShowEmailError(true);
    }
    if (userInfo.password === "") {
      setShowPasswordError(true);
    }

    if (
      userInfo.firstName !== "" &&
      userInfo.email !== "" &&
      userInfo.password !== ""
    ) {
      const signUp = async () => {
        // Send data to the DB
        return await SignUpCallToBackend(userInfo);
      };
      signUp().then((backendResponse) => {
        if (backendResponse) {
          analytics.track("Account Created", {
            user_email: userInfo.email,
            user_name: userInfo.firstName,
          });
          // Store the user in localStorage
          saveUserToken(backendResponse.data.body.user_token).then(() => {
            document.location.href = redirectHref;
          });
        } else {
          // User already exists
          setShowExistingAccountError(true);
        }
      });
    }
  };

  // Equivalent to StartSearch(), but if the user presses "Enter" on the keyboard
  async function handleKeyDown(event) {
    if (event.key === "Enter") {
      handleSubmit();
    }
  }

  function DesktopVersion() {
    return (
      // Background
      <Box sx={classes.background}>
        {/* Modal */}
        <Box sx={classes.modalContainer}>
          <Box
            sx={classes.iconContainer}
            onClick={() => {
              setShowSignUpModal(false);
            }}
          >
            <XIcon />
          </Box>
          <Text variant="h2" sx={classes.prompt}>
            Sign up to start new designs
          </Text>

          <GoogleSignUpButton redirectHref={redirectHref} />

          <Box sx={classes.orContainer}>
            <Divider sx={classes.orDivider} />
            <Text sx={classes.orText}>OR</Text>
            <Divider sx={classes.orDivider} />
          </Box>

          <Box sx={classes.actionsContainer}>
            <form onSubmit={handleSubmit} style={classes.sectionInputForm}>
              <Textfield
                label="(required) First Name"
                variant="standard"
                id="firstName"
                value={firstName}
                onChange={(event) => setFirstName(event.target.value)}
                onKeyDown={handleKeyDown}
                placeholder="e.g. John"
                sx={classes.textField}
              />
              <Textfield
                label="(required) Email"
                type="email"
                variant="standard"
                id="email"
                value={email}
                onChange={(event) => setEmail(event.target.value)}
                onKeyDown={handleKeyDown}
                placeholder="name@example.com"
                sx={classes.textField}
              />
              <Textfield
                label="(required) Password"
                type="password"
                variant="standard"
                id="password"
                value={password}
                onChange={(event) => setPassword(event.target.value)}
                onKeyDown={handleKeyDown}
                sx={classes.textField}
              />
              {showFirstNameError ? (
                <Text sx={classes.errorMsg}>Your first name is required.</Text>
              ) : null}
              {showEmailError ? (
                <Text sx={classes.errorMsg}>Your email is required.</Text>
              ) : null}
              {showPasswordError ? (
                <Text sx={classes.errorMsg}>Your password is required.</Text>
              ) : null}
              {showExistingAccountError ? (
                <Text sx={classes.errorMsg}>
                  An account associated with this email already exists. Log in
                  below to access your account.
                </Text>
              ) : null}
              <Box sx={{ marginBottom: "24px" }} />
              <Button type="submit" sx={classes.signUpButton}>
                Sign Up
              </Button>
            </form>
          </Box>
          <Box sx={classes.signUpContainer}>
            <Text sx={classes.newtoCraftleText}>Already have an account?</Text>
            <Button
              variant="fourth"
              onClick={() => {
                setShowLoginModal(true);
                setShowSignUpModal(false);
              }}
              sx={classes.logInButton}
            >
              Log in
            </Button>
          </Box>

          <Text sx={classes.legalNotice}>
            By continuing, you agree to Craftle&apos;s{" "}
            <Button
              variant="fourth"
              href="/terms"
              target="_blank"
              sx={classes.legalBtns}
            >
              Terms of Use
            </Button>
            . Read our{" "}
            <Button
              variant="fourth"
              href="/policy"
              target="_blank"
              sx={classes.legalBtns}
            >
              Privacy Policy
            </Button>
            .
          </Text>
        </Box>
      </Box>
    );
  }

  function MobileAndTabletVersion() {
    return (
      // Background
      <Box sx={classes.mobileBackground}>
        {/* Modal */}
        <Box sx={classes.mobileModalContainer}>
          <Box
            onClick={() => {
              setShowSignUpModal(false);
            }}
            sx={classes.mobileIconContainer}
          >
            <XIcon />
          </Box>
          <Text variant="h2" sx={classes.prompt}>
            Log in to start new designs
          </Text>

          <GoogleSignUpButton redirectHref={redirectHref} />

          <Box sx={classes.orContainer}>
            <Divider sx={classes.orDivider} />
            <Text sx={classes.orText}>OR</Text>
            <Divider sx={classes.orDivider} />
          </Box>

          <Box sx={classes.sectionInputForm}>
            <form onSubmit={handleSubmit} style={classes.sectionInputForm}>
              <Textfield
                label="(required) First Name"
                variant="standard"
                id="firstName"
                value={firstName}
                onChange={(event) => setFirstName(event.target.value)}
                onKeyDown={handleKeyDown}
                sx={classes.textField}
              />
              <Textfield
                label="(required) Email"
                type="email"
                variant="standard"
                id="email"
                value={email}
                onChange={(event) => setEmail(event.target.value)}
                onKeyDown={handleKeyDown}
                sx={classes.textField}
              />
              <Textfield
                label="(required) Password"
                type="password"
                variant="standard"
                id="password"
                value={password}
                onChange={(event) => setPassword(event.target.value)}
                onKeyDown={handleKeyDown}
                sx={classes.textField}
              />
              {showFirstNameError ? (
                <Text sx={classes.errorMsg}>Your first name is required.</Text>
              ) : null}
              {showEmailError ? (
                <Text sx={classes.errorMsg}>Your email is required.</Text>
              ) : null}
              {showPasswordError ? (
                <Text sx={classes.errorMsg}>Your password is required.</Text>
              ) : null}
              {showExistingAccountError ? (
                <Text sx={classes.errorMsg}>
                  An account associated with this email already exists. Log in
                  below to access your account.
                </Text>
              ) : null}
              <Box sx={{ marginBottom: "24px" }} />
              <Button type="submit" sx={classes.signUpButton}>
                Sign Up
              </Button>
            </form>
          </Box>

          <Box sx={classes.signUpContainer}>
            <Text sx={classes.newtoCraftleText}>Already have an account?</Text>
            <Button
              variant="fourth"
              onClick={() => {
                setShowLoginModal(true);
                setShowSignUpModal(false);
              }}
              sx={classes.logInButton}
            >
              Log in
            </Button>
          </Box>

          <Text sx={classes.legalNotice}>
            By continuing, you agree to Craftle&apos;s{" "}
            <Button variant="fourth" href="/terms" sx={classes.legalBtns}>
              Terms of Use
            </Button>
            . Read our{" "}
            <Button variant="fourth" href="/policy" sx={classes.legalBtns}>
              Privacy Policy
            </Button>
            .
          </Text>
        </Box>
      </Box>
    );
  }

  return isMobileOrTabletScreen ? MobileAndTabletVersion() : DesktopVersion();
}

ModalSignUpShop.propTypes = {
  redirectHref: PropTypes.string,
};

ModalSignUpShop.defaultProps = {
  redirectHref: "/",
};
