import { customTheme } from "../../atoms/CustomTheme/CustomTheme";

export const classes = {
  container: {
    position: "fixed",
    bottom: "40px",
    right: "40px",
    zIndex: "10",
  },
  button: {
    position: "fixed",
    bottom: "20px",
    right: "30px",
    zIndex: "99",
    backgroundColor: "#2A2A2A",
    [customTheme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
};
