import React from "react";
import { Box } from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import PropTypes from "prop-types";
import mixpanel from "mixpanel-browser";
import CardMedia from "@mui/material/CardMedia";

mixpanel.init("d60542dff5aaf889760e7cc2f6316298", { debug: false });

// import components

// import images

// import icons

let customTheme = createTheme({
  breakpoints: {
    values: {
      customMd: 1300,
      xl: 1536,
      lg: 1200,
      md: 900,
      sm: 600,
      xs: 0,
    },
  },
});

const propTypes = {
  url: PropTypes.string,
  image: PropTypes.node,
  price: PropTypes.string,
};

const defaultProps = {
  url: null,
  image: null,
  price: null,
};

MoodboardPageItem.propTypes = propTypes;
MoodboardPageItem.defaultProps = defaultProps;

function MoodboardPageItem({ url, image, price }) {
  return (
    <ThemeProvider theme={customTheme}>
      <Box sx={{ display: "inline-block" }}>
        <Box
          onClick={() => {
            window.open(url);
            mixpanel.track(`(Explore) Clicked "Visit Product"`);
          }}
          sx={{
            position: "relative",
            border: "1px solid #E3E3E3",
            width: "15vw",
            height: "15vw",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            cursor: "pointer",
            margin: "4px",
            [customTheme.breakpoints.down("md")]: {
              width: "26vw",
              height: "26vw",
            },
            [customTheme.breakpoints.down("sm")]: {
              width: "40vw",
              height: "40vw",
              borderRadius: "10px",
            },
          }}
        >
          <Box
            sx={{
              position: "absolute",
              left: 0,
              bottom: "12px",
              padding: "8px 6px",
              backgroundColor: "rgba(255, 255, 255, 0.75)",
            }}
          >
            ${price}
          </Box>
          <CardMedia
            component="img"
            image={image}
            alt=""
            sx={{
              maxWidth: "100%",
              maxHeight: "100%",
              width: "auto",
            }}
          />
        </Box>
      </Box>
    </ThemeProvider>
  );
}

export default MoodboardPageItem;
