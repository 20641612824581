import React from "react";
import Footer from "../../components/organisms/Footer/Footer";
import CardMedia from "@mui/material/CardMedia";

// import components
import BlogParallaxRoot from "../../components/molecules/BlogParallaxRoot/BlogParallaxRoot";
import BlogParallaxLayerBack from "../../components/molecules/BlogParallaxLayerBack/BlogParallaxLayerBack";
import BlogParallaxLayerMiddle from "../../components/molecules/BlogParallaxLayerMiddle/BlogParallaxLayerMiddle";
import BlogParallaxLayerFront from "../../components/molecules/BlogParallaxLayerFront/BlogParallaxLayerFront";
import BlogContentContainer from "../../components/molecules/BlogContentContainer/BlogContentContainer";
import BlogDate from "../../components/molecules/BlogDate/BlogDate";
import BlogTitle from "../../components/molecules/BlogTitle/BlogTitle";
import BlogParagraph from "../../components/molecules/BlogParagraph/BlogParagraph";
import BlogH2 from "../../components/molecules/BlogH2/BlogH2";
// import BlogH3 from "../../components/molecules/BlogH3/BlogH3";
import BlogImage from "../../components/molecules/BlogImage/BlogImage";
import BlogInlineAd1 from "../../components/molecules/BlogInlineAd1/BlogInlineAd1";
import BlogInlineAd2 from "../../components/molecules/BlogInlineAd2/BlogInlineAd2";

// import images
const bannerImage =
  "https://craftle-static.s3.us-east-2.amazonaws.com/blog/blog12/blog-12-banner.jpg";
const image1 =
  "https://craftle-static.s3.us-east-2.amazonaws.com/blog/blog12/blog-12-image-1.jpg";
const image2 =
  "https://craftle-static.s3.us-east-2.amazonaws.com/blog/blog12/blog-12-image-2.jpg";
const image3 =
  "https://craftle-static.s3.us-east-2.amazonaws.com/blog/blog12/blog-12-image-3.jpg";
const image4 =
  "https://craftle-static.s3.us-east-2.amazonaws.com/blog/blog12/blog-12-image-4.jpg";
const image5 =
  "https://craftle-static.s3.us-east-2.amazonaws.com/blog/blog12/blog-12-image-5.jpg";
const image6 =
  "https://craftle-static.s3.us-east-2.amazonaws.com/blog/blog12/blog-12-image-6.jpg";
const image7 =
  "https://craftle-static.s3.us-east-2.amazonaws.com/blog/blog12/blog-12-image-7.jpg";
const image8 =
  "https://craftle-static.s3.us-east-2.amazonaws.com/blog/blog12/blog-12-image-8.jpg";
const image9 =
  "https://craftle-static.s3.us-east-2.amazonaws.com/blog/blog12/blog-12-image-9.jpg";
const image10 =
  "https://craftle-static.s3.us-east-2.amazonaws.com/blog/blog12/blog-12-image-10.jpg";
const image11 =
  "https://craftle-static.s3.us-east-2.amazonaws.com/blog/blog12/blog-12-image-11.jpg";
const image12 =
  "https://craftle-static.s3.us-east-2.amazonaws.com/blog/blog12/blog-12-image-12.jpg";
const image13 =
  "https://craftle-static.s3.us-east-2.amazonaws.com/blog/blog12/blog-12-image-13.jpg";
const image14 =
  "https://craftle-static.s3.us-east-2.amazonaws.com/blog/blog12/blog-12-image-14.jpg";
const image15 =
  "https://craftle-static.s3.us-east-2.amazonaws.com/blog/blog12/blog-12-image-15.jpg";
const image16 =
  "https://craftle-static.s3.us-east-2.amazonaws.com/blog/blog12/blog-12-image-16.jpg";
const image17 =
  "https://craftle-static.s3.us-east-2.amazonaws.com/blog/blog12/blog-12-image-17.jpg";
const image18 =
  "https://craftle-static.s3.us-east-2.amazonaws.com/blog/blog12/blog-12-image-18.jpg";
const image19 =
  "https://craftle-static.s3.us-east-2.amazonaws.com/blog/blog12/blog-12-image-19.jpg";
const image20 =
  "https://craftle-static.s3.us-east-2.amazonaws.com/blog/blog12/blog-12-image-20.jpg";
const image21 =
  "https://craftle-static.s3.us-east-2.amazonaws.com/blog/blog12/blog-12-image-21.jpg";
const image22 =
  "https://craftle-static.s3.us-east-2.amazonaws.com/blog/blog12/blog-12-image-22.jpg";
const image23 =
  "https://craftle-static.s3.us-east-2.amazonaws.com/blog/blog12/blog-12-image-23.jpg";

function BlogPost11() {
  return (
    <BlogParallaxRoot>
      <BlogParallaxLayerBack>
        <CardMedia component="img" image={bannerImage} alt="" />
      </BlogParallaxLayerBack>

      <BlogParallaxLayerMiddle marginTop="200vh" height="200vh" />

      <BlogParallaxLayerFront>
        <BlogContentContainer>
          <BlogDate date="June 10, 2022" />
          <BlogTitle title="Interior Design Types of Styles" />
          <BlogParagraph>
            There are many different types of interior design styles to choose
            from when considering a new look for your home. These style types
            can be used individually or in combination with one another to
            create the perfect atmosphere for your space. Here is a look at some
            of the most popular interior design styles:
          </BlogParagraph>

          <BlogH2 title="1. Contemporary" />
          <BlogImage image={image1} alt="" caption="" />
          <BlogParagraph>
            Contemporary style is characterized by clean lines, simple forms,
            and a minimalistic approach. This style is often associated with a
            &quot;less is more&quot; philosophy, and can be used to create a
            sleek and sophisticated look in your home.
          </BlogParagraph>

          <BlogH2 title="2. Industrial" />
          <BlogImage image={image2} alt="" caption="" />
          <BlogParagraph>
            Industrial style is characterized by raw, unfinished materials such
            as exposed brick or metal pipes. Those who wish to create a
            one-of-a-kind and attractive environment will like this edgy style.
          </BlogParagraph>

          <BlogH2 title="3. Rustic" />
          <BlogImage image={image3} alt="" caption="" />
          <BlogParagraph>
            The rustic style is perfect for those who want to create a cozy and
            inviting atmosphere in their home. This style is often characterized
            by natural materials such as wood or stone and can be used to create
            a warm and cozy feel.
          </BlogParagraph>

          <BlogH2 title="4. Scandinavian" />
          <BlogImage image={image4} alt="" caption="" />
          <BlogParagraph>
            Scandinavian interior design is characterized by a focus on
            simplicity and functionality. This style is often associated with
            clean lines, minimalism, and a neutral color palette. Scandinavian
            design is perfect for those who want to create a modern and stylish
            space. The key to achieving the perfect Scandinavian look is to keep
            things simple and uncluttered. Choose furniture and decor that are
            functional and stylish, and opt for a neutral color palette to
            create a sense of calm in your home.
          </BlogParagraph>

          <BlogH2 title="5. Eclectic" />
          <BlogImage image={image5} alt="" caption="" />
          <BlogParagraph>
            Eclectic interior design is characterized by a mix of different
            design elements. This fun and unique style is perfect for those who
            want to express their personality through their home décor. The
            eclectic style can be achieved by mixing different furniture styles,
            colors, and textures. This style is perfect for those who want to
            create a one-of-a-kind space that is full of personality.
          </BlogParagraph>

          <BlogH2 title="6. Traditional" />
          <BlogImage image={image6} alt="" caption="" />
          <BlogParagraph>
            Traditional interior design is characterized by its formal and
            elegant look. This style is often associated with luxurious fabrics,
            rich wood tones, and antique furniture. Traditional design is
            perfect for those who want to create a classic and sophisticated
            space in their home.
          </BlogParagraph>

          <BlogInlineAd1 />

          <BlogH2 title="7. Transitional" />
          <BlogImage image={image7} alt="" caption="" />
          <BlogParagraph>
            Transitional interior design is characterized by its ability to
            blend different styles together. This style is perfect for those who
            want to create a seamless look in their home. Transitional design
            can be achieved by mixing traditional and contemporary elements
            together. Choosing furniture and decor that are adaptable and may be
            utilized in a variety of ways is the key to getting the ideal
            transitional look.
          </BlogParagraph>

          <BlogH2 title="8. Coastal" />
          <BlogImage image={image8} alt="" caption="" />
          <BlogParagraph>
            Coastal interior design is characterized by its casual and relaxed
            look. This style is often associated with light and airy colors,
            natural materials, and a focus on comfort. Coastal design is perfect
            for those who want to create a space that feels like a vacation
            getaway. The key to achieving the perfect coastal look is to choose
            furniture and décor that are casual and relaxed. Choose light, airy
            tones for a sense of openness in your house.
          </BlogParagraph>

          <BlogH2 title="9. Mid-century modern" />
          <BlogImage image={image9} alt="" caption="" />
          <BlogParagraph>
            Mid-century modern interior design is characterized by its retro
            feel. This style is often associated with furniture and décor from
            the mid-20th century. Mid-century modern design is perfect for those
            who want to create a vintage-inspired space in their home. The key
            to achieving the perfect mid-century modern look is to choose
            furniture and décor that are representative of the era.
          </BlogParagraph>

          <BlogH2 title="10. Hollywood glam" />
          <BlogImage
            image={image10}
            alt=""
            caption="Source: Ellie Design Studio"
          />
          <BlogParagraph>
            Hollywood glam interior design is characterized by its luxurious and
            glamorous look. This style is often associated with Hollywood
            celebrities and film sets. Hollywood glam design is perfect for
            those who want to create a space that is full of luxury and style.
            The key to achieving the perfect Hollywood glam look is to choose
            furniture and décor that are both stylish and luxurious. Opt for a
            glamorous color palette, rich fabrics, and sparkling accessories to
            create a truly Hollywood-inspired space.
          </BlogParagraph>

          <BlogH2 title="11. Bohemian" />
          <BlogImage image={image11} alt="" caption="" />
          <BlogParagraph>
            Bohemian interior design is characterized by its eclectic and
            colorful look. This style is often associated with artists and free
            spirits. Bohemian design is perfect for those who want to create a
            space that is full of personality. The key to achieving the perfect
            bohemian look is to choose furniture and décor that are colorful and
            unique. Add texture to your space with rugs, throws, and cushions.
          </BlogParagraph>

          <BlogH2 title="12. Modern Farmhouse" />
          <BlogImage image={image12} alt="" caption="Source: Elle Decor" />
          <BlogParagraph>
            Modern farmhouse interior design is characterized by its clean and
            simple look. This style is often associated with country homes and
            barns. Modern farmhouse design is perfect for those who want to
            create a space that is both stylish and comfortable. The key to
            achieving the perfect modern farmhouse look is to choose furniture
            and décor that are both functional and stylish. Opt for a neutral
            color palette and add pops of color with accessories and artwork.
          </BlogParagraph>

          <BlogH2 title="13. Urban Modern" />
          <BlogImage image={image13} alt="" caption="Source: Decoraid" />
          <BlogParagraph>
            Urban modern interior design is characterized by its sleek and
            contemporary look. This style is often associated with city
            apartments and lofts. Urban modern design is perfect for those who
            want to create a space that is both stylish and sophisticated. The
            key to achieving the perfect urban modern look is to choose
            furniture and décor that are both chic and trendy. Choose a
            monochromatic color scheme with pops of color, such as accessories
            and artwork.
          </BlogParagraph>

          <BlogH2 title="14. Shabby Chic" />
          <BlogImage image={image14} alt="" caption="Source: Boca Do Lobo" />
          <BlogParagraph>
            Shabby chic interior design is characterized by its distressed and
            feminine look. This style is often associated with antique furniture
            and vintage décor. Shabby chic design is perfect for those who want
            to create a space that is both stylish and cozy. The key to
            achieving the perfect shabby chic look is to choose furniture and
            décor that are both old and new. Add texture to your space with
            rugs, throws, and cushions. Choose a pastel color palette for a
            truly shabby chic look.
          </BlogParagraph>

          <BlogH2 title="15. Mediterranean" />
          <BlogImage image={image15} alt="" caption="Source: Shutterstock" />
          <BlogParagraph>
            Mediterranean interior design is characterized by its relaxed and
            comfortable look. This style is often associated with coastal homes
            and villas. Mediterranean design is perfect for those who want to
            create a space that feels like a vacation. The key to achieving the
            perfect Mediterranean look is to choose furniture and décor that are
            both comfortable and stylish. Choose a color scheme that is neutral
            and use accessories and artwork to add bursts of color.
          </BlogParagraph>

          <BlogH2 title="16. Hollywood Regency" />
          <BlogImage
            image={image16}
            alt=""
            caption="Source: Inspiration Design Books"
          />
          <BlogParagraph>
            Hollywood regency interior design is characterized by its glamorous
            and chic look. This style is often associated with celebrities and
            film sets. Hollywood regency design is perfect for those who want to
            create a space that is both stylish and luxurious. The key to
            achieving the perfect Hollywood regency look is to choose furniture
            and décor that are both fashionable and functional. Choose a neutral
            color scheme and use accessories and artwork to provide interest.
          </BlogParagraph>

          <BlogH2 title="17. French Country" />
          <BlogImage image={image17} alt="" caption="Source: Country Living" />
          <BlogParagraph>
            French country interior design is characterized by its rustic and
            elegant look. This style is often associated with country homes and
            villas. French country design is perfect for those who want to
            create a space that is both stylish and cozy. The key to achieving
            the perfect French country look is to choose furniture and décor
            that is both old and new.
          </BlogParagraph>

          <BlogH2 title="18. Modern Country" />
          <BlogImage image={image18} alt="" caption="Source: Decoraid" />
          <BlogParagraph>
            Modern country interior design is characterized by its clean and
            simple look. This style is often associated with country homes and
            barns. Modern country design is perfect for those who want to create
            a space that is both stylish and comfortable. The key to achieving
            the perfect modern country look is to choose furniture and décor
            that are both functional and stylish. Paint the walls a neutral tone
            and use accessories, decorations, and artwork to liven things up.
          </BlogParagraph>

          <BlogH2 title="19. Art Deco" />
          <BlogImage image={image19} alt="" caption="Source: Future" />
          <BlogParagraph>
            Art deco interior design is characterized by its stylish and
            luxurious look. This style is often associated with celebrities and
            film sets. Art deco design is perfect for those who want to create a
            space that is both glamorous and chic. The key to achieving the
            perfect art deco look is to choose furniture and décor that are both
            fashionable and functional. Opt for a neutral color palette and add
            pops of color with accessories and artwork.
          </BlogParagraph>

          <BlogH2 title="20. Asian Zen" />
          <BlogImage image={image20} alt="" caption="Source: Sergei Davidoff" />
          <BlogParagraph>
            Asian Zen interior design is characterized by its minimalist and
            tranquil look. This style is often associated with spas and
            meditation rooms. Asian Zen design is perfect for those who want to
            create a space that is both relaxing and stylish. The key to
            achieving the perfect Asian Zen look is to choose furniture and
            décor that is both comfortable and chic. Use accessories and art to
            add interest to the table, not including blocks or other building
            materials.
          </BlogParagraph>

          <BlogH2 title="21. Southwestern" />
          <BlogImage image={image21} alt="" caption="Source: LovetoKnow" />
          <BlogParagraph>
            Southwestern interior design is characterized by its rustic and
            colorful look. This style is often associated with Native American
            homes and villages. Southwestern design is perfect for those who
            want to create a space that is both stylish and cozy. The key to
            achieving the perfect southwestern look is to choose furniture and
            décor that is both old and new. Add a pop of color with accessories
            and artwork to really make the space your own.
          </BlogParagraph>

          <BlogH2 title="22. Feng Shui" />
          <BlogImage image={image22} alt="" caption="Source: LeFreddo" />
          <BlogParagraph>
            Feng shui interior design is characterized by a beautiful and
            pleasant aesthetic. This style is commonly associated with Asian
            homes and enterprises. Feng shui design is ideal for individuals
            looking to create a relaxing yet fashionable environment. The key to
            getting the correct feng shui look is to choose furnishings and
            decor that are both useful and attractive. Stick to a light color
            palette and use accessories sparingly to achieve the perfect
            balance.
          </BlogParagraph>

          <BlogH2 title="23. Minimalist" />
          <BlogImage image={image23} alt="" caption="Source: Spacejoy" />
          <BlogParagraph>
            Minimalist interior design is characterized by its clean and simple
            look. This style is often associated with contemporary homes and
            apartments. Minimalist design is perfect for those who want to
            create a space that is both stylish and comfortable. The key to
            achieving the perfect minimalist look is to choose furniture and
            décor that is both functional and stylish. Opt for a neutral color
            palette and add pops of color with accessories and artwork. Keep
            your space uncluttered and tidy to create a truly minimalist
            aesthetic.
          </BlogParagraph>

          <BlogH2 title="Wrapping It Up" />
          <BlogParagraph>
            Choosing the right interior design style for your home is a personal
            decision. Consider your own personal style, and what atmosphere you
            want to create in your space. Then, use these tips to help you
            choose the perfect interior design style for your home.
          </BlogParagraph>

          {/* Banner image credit */}
          <BlogParagraph>
            (Banner Photo Credit: Designer: Butterfly Studio)
          </BlogParagraph>

          <BlogInlineAd2 />
        </BlogContentContainer>
        <Footer />
      </BlogParallaxLayerFront>
    </BlogParallaxRoot>
  );
}

export default BlogPost11;
