import getColor from "./ColorClassifier";
import getFurnitureType from "./FurnitureTypeClassifier";

export function IkeaService(pageHTML) {
  function getProductDimensions(dom) {
    const productDimensionsWrapper = dom.querySelector(
      ".pip-product-dimensions__dimensions-container"
    );
    const productDimensionElements = productDimensionsWrapper?.querySelectorAll(
      ".pip-product-dimensions__measurement-wrapper"
    );

    if (!productDimensionElements) {
      throw "product dimensions not on page";
    }

    console.log("first element: ", productDimensionElements[0]); // remove later

    let heights = [],
      widths = [],
      depths = [],
      lengths = [];

    console.log("length: ", productDimensionElements.length); // remove later

    for (let i = 0; i < productDimensionElements.length; i++) {
      const productDimensionString =
        productDimensionElements[i].innerText.toLowerCase();
      const dimensionValue = getDimensionValueFromString(
        productDimensionString
      );

      console.log("dimensionValue: ", productDimensionString); // remove later

      if (!dimensionValue) {
        continue;
      }
      if (productDimensionString.includes("depth")) {
        depths.push(dimensionValue);
      }
      if (productDimensionString.includes("width")) {
        widths.push(dimensionValue);
      }
      if (productDimensionString.includes("height")) {
        heights.push(dimensionValue);
      }
      if (productDimensionString.includes("length")) {
        lengths.push(dimensionValue);
      }
    }

    console.log("height: ", heights); // remove later

    return {
      depth: depths.length ? Math.max(...depths) / 100 : undefined,
      width: widths.length ? Math.max(...widths) / 100 : undefined,
      height: heights.length ? Math.max(...heights) / 100 : undefined,
      length: lengths.length ? Math.max(...lengths) / 100 : undefined,
    };
  }

  const dom = pageHTML;
  let productDetails = {};

  const imageUrl = dom.querySelector(".pip-image")?.getAttribute("src");
  const productDescription = dom.querySelector(
    ".pip-product-summary__description"
  )?.innerText;
  const dimensions = getProductDimensions(dom);
  const furnitureType = getFurnitureType(
    Array.prototype.map.call(
      dom.querySelectorAll(".bc-breadcrumb__list-item"),
      (element) => element.innerText.trim()
    )
  );
  const price = Number(
    dom
      .querySelector(".pip-temp-price__sr-text")
      ?.innerText.trim()
      .replace(/[^0-9.-]+/g, "")
  );
  const color = getColor(
    dom.querySelector(".pip-header-section__description-text")?.innerText.trim()
  );

  productDetails = {
    imageURL: imageUrl,
    description: productDescription,
    furnitureType: furnitureType,
    dimensions: dimensions,
    color: color,
    price: price,
  };

  return productDetails;
}

export function getDimensionValueFromString(dimensionString) {
  for (let i = dimensionString.length - 1; i > 0; i--) {
    if (dimensionString[i] == ":") {
      const dimensionValue = parseInt(
        dimensionString.substring(i + 1, dimensionString.length)
      );
      return dimensionValue;
    }
  }
  return null;
}
