import React, { useState } from "react";
import { Box, Divider } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useNavigate } from "react-router-dom";

// import components
import Text from "../../atoms/Text/Text";
import Button from "../../atoms/Button/Button";
import { classes } from "./ModalStartDesignLimitStyle";
import { customTheme } from "../../atoms/CustomTheme/CustomTheme";
import { ShowLoginModalContext } from "../../../pages/CraftMyStyle/pages/AllMyDesigns";
import LogInCallToBackend from "../../../pages/profileRelated/LogInCallToBackend";
import Textfield from "../../molecules/Textfield/Textfield";
import GoogleSignInButton from "../../../pages/profileRelated/GoogleSignInButton";

// import icons
import { ReactComponent as XIcon } from "../../../assets/icons/x.svg";

export default function ModalStartDesignLimit() {
  const [showErrorMsg, setShowErrorMsg] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const { setShowLoginModal } = React.useContext(ShowLoginModalContext);
  const isMobileOrTabletScreen = useMediaQuery(
    customTheme.breakpoints.down("sm")
  );

  let navigate = useNavigate();

  async function saveUserToken(token) {
    return new Promise((resolve, reject) => {
      try {
        localStorage.setItem("user", JSON.stringify(token));
        resolve();
      } catch (error) {
        reject(error);
      }
    });
  }

  async function startLogIn(event) {
    if (event) {
      event.preventDefault();
    }

    let userInfo = {
      email: email,
      password: password,
    };

    // Assign the values from the inputs
    userInfo.email = document.getElementById("email").value;
    userInfo.password = document.getElementById("password").value;

    // Get data from the DB
    const response = await LogInCallToBackend(userInfo);

    if (response) {
      // Store the user in localStorage
      saveUserToken(response.data.body.user_token).then(() => {
        document.location.href = "/";
      });
    } else {
      setShowErrorMsg(true);
    }
  }

  // Equivalent to StartSearch(), but if the user presses "Enter" on the keyboard
  async function handleKeyDown(event) {
    if (event.key === "Enter") {
      startLogIn();
    }
  }

  function DesktopVersion() {
    return (
      // Background
      <Box sx={classes.background}>
        {/* Modal */}
        <Box sx={classes.modalContainer}>
          <Box
            sx={classes.iconContainer}
            onClick={() => {
              setShowLoginModal(false);
            }}
          >
            <XIcon />
          </Box>
          <Text variant="h2" sx={classes.prompt}>
            Log in to start new designs
          </Text>

          <GoogleSignInButton />

          <Box sx={classes.orContainer}>
            <Divider sx={classes.orDivider} />
            <Text sx={classes.orText}>OR</Text>
            <Divider sx={classes.orDivider} />
          </Box>

          <Box sx={classes.actionsContainer}>
            <form
              onSubmit={(e) => {
                e.preventDefault();
                startLogIn();
              }}
            >
              <Textfield
                label="Email"
                type="text"
                id="email"
                value={email}
                onChange={(event) => setEmail(event.target.value)}
                placeholder="example@gmail.com"
                onKeyDown={handleKeyDown}
                autoComplete="email"
                sx={classes.textField}
              />
              <Textfield
                label="Password"
                type="password"
                id="password"
                value={password}
                onChange={(event) => setPassword(event.target.value)}
                onKeyDown={handleKeyDown}
                autoComplete="current-password"
                sx={classes.textField}
              />
              {showErrorMsg ? (
                <Text sx={classes.errorMsg}>
                  We couldn&apos;t find this account. Please try again, or go to
                  &quot;Sign up&quot; below.
                </Text>
              ) : null}
              <Button
                variant="primary"
                onClick={startLogIn}
                sx={classes.logInButton}
              >
                Log In
              </Button>
            </form>
          </Box>
          <Box sx={classes.signUpContainer}>
            <Text sx={classes.newtoCraftleText}>New to Craftle? </Text>
            <Button
              variant="fourth"
              onClick={() => {
                navigate("/sign-up");
              }}
              sx={classes.signUpButton}
            >
              Sign up
            </Button>
          </Box>
        </Box>
      </Box>
    );
  }

  function MobileAndTabletVersion() {
    return (
      // Background
      <Box sx={classes.mobileBackground}>
        {/* Modal */}
        <Box sx={classes.mobileModalContainer}>
          <Box
            onClick={() => {
              setShowLoginModal(false);
            }}
            sx={classes.mobileIconContainer}
          >
            <XIcon />
          </Box>
          <Text variant="h2" sx={classes.prompt}>
            Log in to start new designs
          </Text>

          <GoogleSignInButton />

          <Box sx={classes.orContainer}>
            <Divider sx={classes.orDivider} />
            <Text sx={classes.orText}>OR</Text>
            <Divider sx={classes.orDivider} />
          </Box>

          <Box sx={classes.sectionInputForm}>
            <form
              onSubmit={(e) => {
                e.preventDefault();
                startLogIn();
              }}
            >
              <Textfield
                label="Email"
                type="text"
                id="email"
                value={email}
                onChange={(event) => setEmail(event.target.value)}
                placeholder="example@gmail.com"
                onKeyDown={handleKeyDown}
                autoComplete="email"
                sx={classes.textField}
              />
              <Textfield
                label="Password"
                type="password"
                id="password"
                value={password}
                onChange={(event) => setPassword(event.target.value)}
                onKeyDown={handleKeyDown}
                autoComplete="current-password"
                sx={classes.textField}
              />
              {showErrorMsg ? (
                <Text sx={classes.errorMsg}>
                  We couldn&apos;t find this account. Please try again, or go to
                  &quot;Sign up&quot; below.
                </Text>
              ) : null}
              <Button
                variant="primary"
                onClick={startLogIn}
                sx={classes.logInButton}
              >
                Log In
              </Button>
            </form>
          </Box>
          <Box sx={classes.signUpContainer}>
            <Text sx={classes.newtoCraftleText}>New to Craftle? </Text>
            <Button
              variant="fourth"
              onClick={() => {
                navigate("/sign-up");
              }}
              sx={classes.signUpButton}
            >
              Sign up
            </Button>
          </Box>
          {/* <Text sx={classes.legalNotice}>
            By continuing, you agree to Craftle&apos;s{" "}
            <Button variant="fourth" href="/terms" sx={classes.legalBtns}>
              Terms of Use
            </Button>
            . Read our{" "}
            <Button variant="fourth" href="/policy" sx={classes.legalBtns}>
              Privacy Policy
            </Button>
            .
          </Text> */}
        </Box>
      </Box>
    );
  }

  return isMobileOrTabletScreen ? MobileAndTabletVersion() : DesktopVersion();
}
