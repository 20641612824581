import * as React from "react";
import { Box } from "@mui/material";
import PropTypes from "prop-types";
import { createTheme } from "@mui/material/styles";
import { Grid } from "@mui/material";
import CardMedia from "@mui/material/CardMedia";
import { Link } from "react-router-dom";

const propTypes = {
  link: PropTypes.string.isRequired,
  bannerImage: PropTypes.node.isRequired,
  date: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  snippet: PropTypes.string.isRequired,
};

const defaultProps = {
  children: null,
};

OtherBlogPost.propTypes = propTypes;
OtherBlogPost.defaultProps = defaultProps;

let customTheme = createTheme({});

export default function OtherBlogPost({
  link,
  bannerImage,
  date,
  title,
  snippet,
}) {
  const styles = {
    otherPostsLink: {
      textDecoration: "none",
      color: "#2a2a2a",
    },
    otherPostsItem: { marginBottom: "#757575" },
    otherBannerImages: {
      borderRadius: "10px",
      maxHeight: "320px",
      width: "100%",
      [customTheme.breakpoints.down("md")]: {
        // fontSize: "50px",
      },
    },
    otherDates: {
      fontSize: "14px",
      marginTop: "16px",
      color: "#757575",
    },
    otherTitles: {
      fontSize: "32px",
      marginTop: "8px",
      fontWeight: "bold",
      lineHeight: 1.5,
    },
    otherSnippets: {
      fontSize: "16px",
      lineHeight: 1.5,
      marginTop: "8px",
    },
  };

  return (
    <Grid item md={6} sm={12} sx={{ ...styles.otherPostsItem }}>
      <Link to={link} style={{ ...styles.otherPostsLink }}>
        <Box sx={{ ...styles.bannerImage }}>
          <CardMedia
            component="img"
            image={bannerImage}
            alt=""
            sx={{
              ...styles.otherBannerImages,
            }}
          />
        </Box>
        <Box sx={{ ...styles.otherDates }}>{date}</Box>
        <Box sx={{ ...styles.otherTitles }}>{title}</Box>
        <Box sx={{ ...styles.otherSnippets }}>{snippet}</Box>
      </Link>
    </Grid>
  );
}
