import React, { useState, useEffect } from "react";
import { Box, CardMedia } from "@mui/material";
import { UserContext } from "../../App";

// import components
import Text from "../../components/atoms/Text/Text";
import Button from "../../components/atoms/Button/Button";
import { customTheme } from "../../components/atoms/CustomTheme/CustomTheme";
import DeleteAccountCallToBackend from "./DeleteAccountCallToBackend";
import Modal from "../../components/organisms/Modal/Modal";

// Import icons
const titleIcon =
  "https://craftle-static.s3.us-east-2.amazonaws.com/profile/profile-title-icon.svg";

export default function Profile() {
  const { user } = React.useContext(UserContext);
  const [userId, setUserId] = useState(null);
  const [showConfirmDeletePrompt, setShowConfirmDeletePrompt] = useState(false);

  useEffect(() => {
    setUserId(user?.user_id);
  }, [user]);

  let classes = {
    container: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      paddingBottom: "120px",
    },
    backgroundGradient: {
      backgroundImage: "linear-gradient(#FFF4D7, #FFFFFF)",
      position: "absolute",
      width: "100%",
      height: "830px",
      zIndex: -1,
    },
    content: {
      marginTop: "161px",
      maxWidth: "1440px",
      padding: "0 16px",
    },
    titleRow: {
      display: "flex",
      flexDirection: "row",
      [customTheme.breakpoints.down("sm")]: {
        flexDirection: "column",
      },
    },
    titleIcon: {
      width: "56px",
      height: "56px",
      marginRight: "16px",
    },
    h1: {
      fontSize: "48px",
    },

    // Delete account
    deleteAccount: {
      marginTop: "80px",
      fontSize: "20px",
      fontWeight: 400,
    },
  };

  if (!user) {
    return null;
  }

  function onPressPrimaryBtn() {
    console.log("confirming delete");
    setShowConfirmDeletePrompt(false);

    // Get data from the DB
    DeleteAccountCallToBackend(userId).then(() => {
      // Remove user data from localStorage
      localStorage.removeItem("user");

      document.location.href = "/";
    });
  }

  function onPressCancelButton() {
    console.log("cancel delete pressed");
    setShowConfirmDeletePrompt(false);
  }

  async function StartDeleteAccount() {
    setShowConfirmDeletePrompt(true);
  }

  return (
    <Box sx={classes.container}>
      {showConfirmDeletePrompt ? (
        <Modal
          prompt="Are you sure you want to delete your account?"
          primaryBtnTxt="Yes, delete my account"
          secondaryBtnTxt="No, don't delete"
          onPressPrimaryBtn={onPressPrimaryBtn}
          onPressCancelButton={onPressCancelButton}
        />
      ) : null}
      <Box sx={classes.backgroundGradient} />
      <Box sx={classes.content}>
        <Box sx={classes.titleRow}>
          <CardMedia
            component="img"
            image={titleIcon}
            alt=""
            sx={classes.titleIcon}
          />
          <Text variant="h1" sx={classes.h1}>
            {user.first_name}&apos;s Profile
          </Text>
        </Box>
        <Button
          variant="fourth"
          onClick={() => {
            StartDeleteAccount();
          }}
          sx={classes.deleteAccount}
        >
          Delete Account
        </Button>
      </Box>
    </Box>
  );
}
