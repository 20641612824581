import React from "react";
import { Button } from "@mui/material";
import PropTypes from "prop-types";
import CardMedia from "@mui/material/CardMedia";

// Import Components
import { classes } from "./ButtonStyle";

CraftleButton.propTypes = {
  children: PropTypes.node,
  variant: PropTypes.oneOf([
    "primary",
    "secondary",
    "tertiary",
    "fourth",
    null,
  ]),
  href: PropTypes.string,
  target: PropTypes.string,
  sx: PropTypes.object,
  onClick: PropTypes.func,
  style: PropTypes.object,
  icon: PropTypes.string,
  type: PropTypes.string,
};

CraftleButton.defaultProps = {
  children: "",
  variant: "primary",
  href: null,
  target: null,
  sx: {},
  onClick: () => {},
  style: {},
  icon: null,
  type: "",
};

export default function CraftleButton({
  children,
  variant,
  href,
  sx,
  onClick,
  target,
  style,
  icon,
  type,
}) {
  if (icon) {
    classes.commonStyles.padding = "12px 24px";
  } else {
    classes.commonStyles.padding = "16px 24px";
  }

  function ButtonWithVariant() {
    if (variant === "primary") {
      return (
        <Button
          onClick={onClick}
          href={href}
          target={target}
          sx={[classes.commonStyles, classes.primaryButton, sx]}
          type={type}
        >
          {icon ? (
            <CardMedia component="img" image={icon} alt="" sx={classes.icon} />
          ) : null}
          {children}
        </Button>
      );
    } else if (variant === "secondary") {
      return (
        <Button
          onClick={onClick}
          href={href}
          target={target}
          sx={[classes.commonStyles, classes.secondaryButton, sx]}
          type={type}
        >
          {icon ? (
            <CardMedia component="img" image={icon} alt="" sx={classes.icon} />
          ) : null}
          {children}
        </Button>
      );
    } else if (variant === "tertiary") {
      return (
        <Button
          onClick={onClick}
          href={href}
          target={target}
          sx={[classes.commonStyles, classes.tertiaryButton, sx]}
          type={type}
        >
          {icon ? (
            <CardMedia component="img" image={icon} alt="" sx={classes.icon} />
          ) : null}
          {children}
        </Button>
      );
    } else if (variant === "fourth") {
      return (
        <Button
          onClick={onClick}
          href={href}
          target={target}
          sx={[classes.fourthButton, sx]}
          type={type}
        >
          {icon ? (
            <CardMedia component="img" image={icon} alt="" sx={classes.icon} />
          ) : null}
          {children}
        </Button>
      );
    } else {
      return;
    }
  }

  return (
    <ButtonWithVariant
      variant={variant}
      href={href}
      target={target}
      onClick={onClick}
      style={style}
      sx={sx}
      icon={icon}
    >
      {children}
    </ButtonWithVariant>
  );
}
