import { customTheme } from "../../../components/atoms/CustomTheme/CustomTheme";
// import IsMobileOrTablet from "../../../components/atoms/IsMobileOrTablet/IsMobileOrTablet";

export const classes = {
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    paddingBottom: "120px",
  },
  backgroundGradient: {
    backgroundImage: "linear-gradient(#FFF4D7, #FFFFFF)",
    position: "absolute",
    width: "100%",
    height: "830px",
    zIndex: -1,
  },

  // Content Section
  content: {
    marginTop: "161px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    boxSizing: "border-box",
    transition: "0.2s",
    width: "100%",
    [customTheme.breakpoints.down("sm")]: {
      marginTop: "76px",
    },
  },
  titleRow: {
    display: "flex",
    flexDirection: "row",
    [customTheme.breakpoints.down("sm")]: {
      flexDirection: "column",
      alignItems: "center",
      textAlign: "center",
    },
  },
  titleIcon: {
    width: "56px",
    height: "56px",
    marginRight: "16px",
    [customTheme.breakpoints.down("sm")]: {},
  },
  h1: {
    fontSize: "48px",
    [customTheme.breakpoints.down("sm")]: {
      fontSize: "40px",
    },
  },
  spinnerContainer: {
    marginTop: "24px",
    display: "block",
  },

  // No favorites
  noFavoritesSection: {
    marginTop: "24px",
    textAlign: "center",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  noFavoritesText1: {
    fontSize: "20px",
    textAlign: "center",
    display: "block",
    fontWeight: "bold",
  },
  noFavoritesText2: {
    fontSize: "20px",
    textAlign: "center",
    display: "inline",
  },
  addFirstCategoryContainer: {
    marginTop: "24px",
    maxWidth: "736px",
    width: "100%",
    height: "240px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer",
    transition: "0.2s",
    borderRadius: "16px",
    border: "0",
    padding: "1px", // To avoid content overlapping the border
    backgroundImage: `
      linear-gradient(to right, #757575 33%, rgba(255, 255, 255, 0) 0%), 
      linear-gradient(to bottom, #757575 33%, rgba(255, 255, 255, 0) 0%), 
      linear-gradient(to left, #757575 33%, rgba(255, 255, 255, 0) 0%), 
      linear-gradient(to top, #757575 33%, rgba(255, 255, 255, 0) 0%)
    `,
    backgroundSize: "20px 1px, 1px 20px, 20px 1px, 1px 20px", // Adjust the lengths
    backgroundPosition: "top, right, bottom, left",
    backgroundRepeat: "repeat-x, repeat-y, repeat-x, repeat-y",
    "&:hover": {
      backgroundColor: "#F3F3F3",
    },
  },
  icon: {
    width: "24px",
    height: "24px",
  },
  addFirstCategoryTxt: {
    fontWeight: "bold",
    color: "#757575",
    fontSize: "14px",
  },
  shopLink: {
    fontSize: "20px",
    display: "inline",
    textDecoration: "underline",
    textAlign: "center",
  },
  logInLink: {
    fontSize: "20px",
    display: "inline",
    textDecoration: "underline",
    textAlign: "center",
  },
  boldFont: {
    fontWeight: "bold",
  },

  // Shown favorites section
  contentContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    width: "100%",
    boxSizing: "border-box",
    [customTheme.breakpoints.down("sm")]: {
      paddingLeft: 0,
    },
  },
  shownFavoritesAcknowledgment: {
    alignSelf: "center",
  },
  productTypeTextfieldContainer: {
    paddingLeft: "72px",
    width: "fit-content",
    height: "40px", // Adjust container height if necessary
    "& .MuiInputBase-root": {
      // Target the input container
      height: "40px", // Adjust container height if necessary
    },
    "& .MuiInputBase-input": {
      // Target the input element
      height: "40px",
      padding: "0 10px", // Adjust padding to match the height
    },
    [customTheme.breakpoints.down("sm")]: {
      paddingLeft: "16px",
    },
  },
  productTypeContainer: {
    width: "fit-content",
    paddingLeft: "72px",
    position: "relative",
    [customTheme.breakpoints.down("sm")]: {
      paddingLeft: "16px",
    },
  },
  verticalMoreOptions: {
    position: "absolute",
    left: "40px",
    top: "-8.5px",
    padding: "16px 0",
    backgroundColor: "#FFE9AF",
    border: "2px solid #2A2A2A",
    borderRadius: "32px",
    width: "fit-content",
    cursor: "pointer",
    [customTheme.breakpoints.down("sm")]: {
      marginTop: "1px",
      left: "16px",
      backgroundColor: "inherit",
      border: 0,
      padding: "8px",
    },
  },
  productTypeTxtContainer: {
    cursor: "pointer",
    [customTheme.breakpoints.down("sm")]: {
      marginLeft: "40px",
    },
  },
  productTypeTxt: {
    fontSize: "32px",
    boxSizing: "border-box",
    height: "40px",
    [customTheme.breakpoints.down("sm")]: {
      fontSize: "24px",
    },
  },
  favoriteCategoryContainer: {
    marginTop: "40px",
    display: "flex",
    flexDirection: "column",
  },
  favoriteItemsRow: {
    display: "flex",
    flexDirection: "row",
    maxWidth: "100vw",
    padding: "16px 72px 0",
    boxSizing: "border-box",
    overflowX: "auto", // Add horizontal scrolling
    whiteSpace: "nowrap", // Prevent wrapping
    [customTheme.breakpoints.down("sm")]: {
      padding: "16px 32px",
    },
  },
  addProductBtn: {
    marginLeft: "8px",
    width: "80px",
    backgroundColor: "#F3F3F3",
    borderRadius: "16px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
    gap: "4px",
    flexShrink: "0",
    cursor: "pointer",
    transition: "0.2s ease-in-out",
    "&:hover": {
      backgroundColor: "#ECECEC",
    },
  },
  addProductTxt: {
    color: "#757575",
    fontWeight: "bold",
    fontSize: "14px",
  },
  addRowBtnOuter: {
    marginTop: "8px",
    height: "64px",
    borderRadius: "16px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
    gap: "8px",
    padding: "0 72px",
    boxSizing: "border-box",
    [customTheme.breakpoints.down("sm")]: {
      padding: "0 40px",
    },
  },
  addRowBtnInner: {
    width: "100%",
    height: "100%",
    backgroundColor: "#F3F3F3",
    borderRadius: "inherit",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
    gap: "4px",
    cursor: "pointer",
    transition: "0.2s ease-in-out",
    "&:hover": {
      backgroundColor: "#ECECEC",
    },
  },
  addRowTxt: {
    color: "#757575",
    fontWeight: "bold",
    fontSize: "14px",
  },

  // Separator section
  orContainer: {
    display: "inline-flex",
    flexDirection: "row",
    alignItems: "center",
    marginTop: "30px",
    maxWidth: "400px",
    width: "100%",
  },
  orText: {
    height: "24px",
    margin: "0 8px",
  },
  orDivider: {
    width: "45%",
    height: "1px",
    color: "#757575",
  },
  signUpTxt: {
    marginTop: "24px",
    fontSize: "24px",
    fontWeight: "bold",
  },
  googleSignUpButtonOuterContainer: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  googleSignUpButtonContainer: {
    marginTop: "-24px",
    width: "400px",
    [customTheme.breakpoints.down("sm")]: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      width: "400px",
    },
    [customTheme.breakpoints.down(486)]: {
      width: "100%",
    },
  },

  // Add row styling
  addRowMenu: {
    borderRadius: "8px",
    padding: "0 8px",
  },
  menuItemTxt: {
    fontWeight: 500,
  },
};
