import React, { useState, useRef, useEffect } from "react";
import { Box, Grid } from "@mui/material";
import PropTypes from "prop-types";
import CardMedia from "@mui/material/CardMedia";
import { UserContext } from "../../../App";
import { LookalikeContext } from "../../../pages/lookalikeFinder/LookalikeFinder";
import { AnalyticsBrowser } from "@segment/analytics-next";

// import components
import { classes } from "./LookalikeItemStyle";
import mixpanel from "../../atoms/Mixpanel/Mixpanel";
import Text from "../../atoms/Text/Text";
import Button from "../../atoms/Button/Button";
import Carousel, { CarouselItem } from "../../molecules/Carousel/Carousel";
import FavoritingCallToBackend from "../../../pages/profileRelated/favorites/FavoritingCallToBackend";
import { segmentWriteKey } from "../../../components/atoms/Segment/Segment";

// import icons
const externalLink =
  "https://craftle-static.s3.us-east-2.amazonaws.com/icons/external-link.svg";
const unselectedHeart =
  "https://craftle-static.s3.us-east-2.amazonaws.com/icons/heart-not-filled.svg";
const selectedHeart =
  "https://craftle-static.s3.us-east-2.amazonaws.com/icons/heart-filled.svg";

const analytics = AnalyticsBrowser.load({ writeKey: segmentWriteKey });

const propTypes = {
  product: PropTypes.object,
};

const defaultProps = {
  product: {},
};

LookalikeItem.propTypes = propTypes;
LookalikeItem.defaultProps = defaultProps;

export default function LookalikeItem({ product }) {
  const [favorited, setFavorited] = useState(false);
  const { user } = React.useContext(UserContext);
  const { userFavorites } = React.useContext(LookalikeContext);
  const { setShowModal } = React.useContext(LookalikeContext);
  const ref = useRef(null);
  const priceString = "$" + product.sale_price.toLocaleString();

  const email = user?.email || "N/A";

  const acquisitionSource =
    JSON.parse(localStorage.getItem("acquisitionSource")) || "N/A";

  // Combine the main image and all other images to one array
  const allImages = [product.image_url].concat(
    product.image_variants_url_array
  );

  let width = 0;
  let length = 0;

  if (product.width >= product.length) {
    width = product.length;
    length = product.width;
  } else {
    width = product.width;
    length = product.length;
  }

  useEffect(() => {
    if (userFavorites) {
      const objToArr = Object.values(userFavorites);
      objToArr.map((favoriteItem) => {
        if (favoriteItem.link === product.product_page_url) {
          setFavorited(true);
        }
      });
    }
  }, [userFavorites]);

  function handleFavorited() {
    if (user) {
      toggleFavoriteState(favorited, product, user);
      setFavorited(!favorited);
    } else {
      setShowModal(true);
    }
  }

  function toggleFavoriteState(favorited, product, user) {
    FavoritingCallToBackend(
      !favorited,
      user.email,
      product.product_id,
      product.item_title,
      allImages,
      product.brand,
      product.sale_price,
      product.product_type,
      product.product_page_url,
      product.length,
      product.width,
      product.height
    );
  }

  // async function ChangeFavoriteState(state) {
  //   await setFavorited(state);
  //   return;
  // }

  // function Favoriting() {
  //   if (user) {
  //     if (favorited) {
  //       ChangeFavoriteState(false).then(() => {
  //         FavoritingCallToBackend(
  //           false,
  //           user.email,
  //           product.item_title,
  //           allImages,
  //           product.brand,
  //           product.sale_price,
  //           product.length,
  //           product.width,
  //           product.height,
  //           product.product_page_url
  //         );
  //       });
  //     } else {
  //       ChangeFavoriteState(true).then(() => {
  //         FavoritingCallToBackend(
  //           true,
  //           user.email,
  //           product.item_title,
  //           allImages,
  //           product.brand,
  //           product.sale_price,
  //           product.length,
  //           product.width,
  //           product.height,
  //           product.product_page_url
  //         );
  //       });
  //     }
  //   } else {
  //     setShowModal(true);
  //   }
  //   return;
  // }

  return (
    <Box sx={{ ...classes.categoryContainer }}>
      <Grid container spacing={3}>
        <Grid item md={7} sm={12}>
          <Box sx={classes.imgContainer}>
            <Box onClick={handleFavorited} sx={classes.favoriteContainer}>
              {favorited ? (
                <CardMedia
                  component="img"
                  image={selectedHeart}
                  alt=""
                  sx={classes.favoriteIcon}
                />
              ) : (
                <CardMedia
                  component="img"
                  image={unselectedHeart}
                  alt=""
                  sx={classes.favoriteIcon}
                />
              )}
            </Box>
            {allImages != [] ? (
              <Carousel>
                {allImages.map((image, imageKey) => {
                  return (
                    <CarouselItem key={imageKey}>
                      <CardMedia
                        component="img"
                        image={image}
                        alt=""
                        ref={ref}
                        sx={{ ...classes.categoryImage }}
                      />
                    </CarouselItem>
                  );
                })}
              </Carousel>
            ) : (
              <Text sx={classes.noImagesContainer}>
                Uh oh...we couldn&apos;t find any images.
              </Text>
            )}
          </Box>
        </Grid>
        <Grid item md={5} sm={12} sx={classes.itemInfo}>
          <Text sx={classes.brand}>{product.brand}</Text>
          <Text sx={classes.title}>{product.item_title}</Text>
          <Text sx={classes.price}>{priceString}</Text>
          <Text sx={classes.dimensions}>
            <b>Product Dimensions:</b> {length}&quot;L x {width}
            &quot;W x {product.height}&quot;H
          </Text>
          <Button
            variant="secondary"
            icon={externalLink}
            onClick={() => {
              analytics.track("Product-Link Opened", {
                user_email: user?.email,
                user_name: user?.first_name,
                flow: "Lookalike Finder",
                survey_acquisition_source: acquisitionSource,
                moodboard_id: "N/A",
                product_link: product.product_page_url,
                product_name: product.item_title,
                product_price: product.sale_price,
                product_length: product.length,
                product_width: product.width,
                product_height: product.height,
              });
              mixpanel.track("(Lookalike Finder) Clicked on Product", {
                email: email,
                link: product.product_page_url,
              });
              window.open(product.product_page_url);
            }}
            sx={{ ...classes.button }}
          >
            Shop Now
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
}
