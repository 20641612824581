import React from "react";
import { Box } from "@mui/material";
import CardMedia from "@mui/material/CardMedia";

// import components
import Text from "../../components/atoms/Text/Text";
import { customTheme } from "../../components/atoms/CustomTheme/CustomTheme";

// import contexts
import { UserContext } from "../../App";

// import icons
const LookalikeFinderIcon =
  "https://craftle-static.s3.us-east-2.amazonaws.com/lookalike-finder-page/lookalike-finder-icon.svg";

export default function LookalikeFinderHeaderSection() {
  const { setLookalikeFinderSearchText } = React.useContext(UserContext);

  let classes = {
    sectionHeader: {
      marginTop: "160px",
      [customTheme.breakpoints.down("md")]: {
        marginTop: "88px",
      },
    },
    titleRow: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "center",
      alignItems: "flex-end",
      flexWrap: "wrap",
      [customTheme.breakpoints.down("sm")]: {
        justifyContent: "flex-start",
      },
    },
    lookalikeFinderIcon: {
      width: "56px",
      marginRight: "16px",
    },
    title: {
      fontSize: "48px",
      textAlign: "center",
      [customTheme.breakpoints.down("md")]: {
        fontSize: "40px",
      },
      [customTheme.breakpoints.down("sm")]: {
        textAlign: "left",
        marginRight: "8px",
      },
    },
    title2: {
      fontSize: "16px",
      fontWeight: "bold",
      marginLeft: "8px",
      paddingBottom: "6px",
      [customTheme.breakpoints.down("sm")]: {
        marginLeft: "0",
      },
    },
    subtitle: {
      marginTop: "24px",
      fontSize: "20px",
      textAlign: "center",
      maxWidth: "864px",
      [customTheme.breakpoints.down("sm")]: {
        textAlign: "inherit",
      },
    },
    exampleRow: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "center",
      [customTheme.breakpoints.down("sm")]: {
        display: "inherit",
      },
    },
    tryText: {
      fontSize: "20px",
      display: "inline-block",
      marginRight: "5px",
    },
    exampleLink: {
      textDecoration: "underline",
      cursor: "pointer",
      // fontWeight: "bold",
      fontSize: "20px",
      color: "#0000EE",
      // maxWidth: "864px",
      lineHeight: "1.5",
      display: "inline-block",
      [customTheme.breakpoints.down("sm")]: {
        textAlign: "inherit",
      },
    },
  };

  return (
    <Box sx={classes.sectionHeader}>
      <Box sx={classes.titleRow}>
        <CardMedia
          component="img"
          image={LookalikeFinderIcon}
          alt=""
          sx={classes.lookalikeFinderIcon}
        />
        <Text variant="h1" sx={classes.title}>
          Lookalike Finder
        </Text>
        <Text sx={classes.title2}>beta</Text>
      </Box>
      <Text sx={classes.subtitle}>
        <b>
          Enter a furniture product link and we’ll show you affordable
          lookalikes.
        </b>
        <br />
        (You can only search for{" "}
        <a
          href="https://www.crateandbarrel.com/furniture/sofas/1"
          target="_blank"
          rel="noreferrer"
        >
          <b>sofas from Crate & Barrel</b>
        </a>{" "}
        for now.)
        <br />
      </Text>
      <Box sx={classes.exampleRow}>
        <Text sx={classes.tryText}>Try </Text>
        <Box
          onClick={() => {
            setLookalikeFinderSearchText(
              "https://www.crateandbarrel.com/wells-leather-sofa/s378692"
            );
          }}
          sx={classes.exampleLink}
        >
          https://www.crateandbarrel.com/wells-leather-sofa/s378692
        </Box>
      </Box>
    </Box>
  );
}
