import React, { useState, useEffect } from "react";
import { Box } from "@mui/material";
import CardMedia from "@mui/material/CardMedia";
import mixpanel from "mixpanel-browser";
import PropTypes from "prop-types";
import { AnalyticsBrowser } from "@segment/analytics-next";

mixpanel.init("d60542dff5aaf889760e7cc2f6316298", { debug: false });

// import components
import { classes } from "./LookalikeFinderExampleStyle";
import Text from "../../components/atoms/Text/Text";
import Button from "../../components/atoms/Button/Button";
import FavoritingCallToBackend from "../profileRelated/favorites/FavoritingCallToBackend";
import { segmentWriteKey } from "../../components/atoms/Segment/Segment";

// import context
import { UserContext } from "../../App";
import { LookalikeContext } from "./LookalikeFinder";

// import icons
const externalLink =
  "https://craftle-static.s3.us-east-2.amazonaws.com/icons/external-link.svg";
const unselectedHeart =
  "https://craftle-static.s3.us-east-2.amazonaws.com/icons/heart-not-filled.svg";
const selectedHeart =
  "https://craftle-static.s3.us-east-2.amazonaws.com/icons/heart-filled.svg";

const analytics = AnalyticsBrowser.load({ writeKey: segmentWriteKey });

const propTypes = {
  productId: PropTypes.string,
  image: PropTypes.string,
  brand: PropTypes.string,
  productName: PropTypes.string,
  price: PropTypes.string,
  productType: PropTypes.string,
  length: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
  link: PropTypes.string,
};

const defaultProps = {
  productId: "",
  image: "",
  brand: "",
  productName: "",
  price: "",
  productType: "",
  length: "",
  width: "",
  height: "",
  link: "",
};

LookalikeFinderExample.propTypes = propTypes;
LookalikeFinderExample.defaultProps = defaultProps;

export default function LookalikeFinderExample({
  productId,
  image,
  brand,
  productName,
  price,
  productType,
  length,
  width,
  height,
  link,
}) {
  const [favorited, setFavorited] = useState(false);
  const { user } = React.useContext(UserContext);
  const { userFavorites } = React.useContext(LookalikeContext);
  const { setShowModal } = React.useContext(LookalikeContext);

  const acquisitionSource =
    JSON.parse(localStorage.getItem("acquisitionSource")) || "N/A";

  const email = user?.email || "N/A";
  const allImages = [image];

  useEffect(() => {
    if (userFavorites) {
      const objToArr = Object.values(userFavorites);
      objToArr.map((favoriteItem) => {
        if (favoriteItem.link === link) {
          setFavorited(true);
        }
      });
    }
  }, [userFavorites]);

  function handleFavorited() {
    if (user) {
      toggleFavoriteState(favorited, user);
      setFavorited(!favorited);
    } else {
      setShowModal(true);
    }
  }

  function toggleFavoriteState(favorited, user) {
    FavoritingCallToBackend(
      !favorited,
      user.email,
      productId,
      productName,
      allImages,
      brand,
      price,
      productType,
      link,
      length,
      width,
      height
    );
  }

  return (
    <Box sx={classes.container}>
      <Box sx={classes.imgContainer}>
        <Box onClick={handleFavorited} sx={classes.favoriteContainer}>
          {favorited ? (
            <CardMedia
              component="img"
              image={selectedHeart}
              alt=""
              sx={classes.favoriteIcon}
            />
          ) : (
            <CardMedia
              component="img"
              image={unselectedHeart}
              alt=""
              sx={classes.favoriteIcon}
            />
          )}
        </Box>
        <CardMedia
          component="img"
          image={image}
          alt="Product image"
          sx={classes.img}
        />
      </Box>
      <Text sx={classes.brand}>{brand}</Text>
      <Text sx={classes.name}>{productName}</Text>
      <Text sx={classes.price}>${price}</Text>
      <Text sx={classes.dimensions}>
        <b>Product Dimensions:</b> {length}&quot;L x {width}
        &quot;W x {height}&quot;H
      </Text>
      <Button
        variant="secondary"
        icon={externalLink}
        onClick={() => {
          analytics.track("Product-Link Opened", {
            user_email: user?.email,
            user_name: user?.first_name,
            flow: "Lookalike Finder",
            survey_acquisition_source: acquisitionSource,
            moodboard_id: "N/A",
            product_link: link,
            product_name: productName,
            product_price: price,
            product_length: length,
            product_width: width,
            product_height: height,
          });
          mixpanel.track("(Lookalike Finder) Clicked on Example Product", {
            email: email,
            link: { link },
          });
          window.open(link);
        }}
        sx={classes.button}
      >
        Shop Now
      </Button>
    </Box>
  );
}
