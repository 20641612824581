import React from "react";
import Footer from "../../components/organisms/Footer/Footer";
import CardMedia from "@mui/material/CardMedia";

// import components
import BlogParallaxRoot from "../../components/molecules/BlogParallaxRoot/BlogParallaxRoot";
import BlogParallaxLayerBack from "../../components/molecules/BlogParallaxLayerBack/BlogParallaxLayerBack";
import BlogParallaxLayerMiddle from "../../components/molecules/BlogParallaxLayerMiddle/BlogParallaxLayerMiddle";
import BlogParallaxLayerFront from "../../components/molecules/BlogParallaxLayerFront/BlogParallaxLayerFront";
import BlogContentContainer from "../../components/molecules/BlogContentContainer/BlogContentContainer";
import BlogDate from "../../components/molecules/BlogDate/BlogDate";
import BlogTitle from "../../components/molecules/BlogTitle/BlogTitle";
import BlogParagraph from "../../components/molecules/BlogParagraph/BlogParagraph";
import BlogH2 from "../../components/molecules/BlogH2/BlogH2";
// import BlogH3 from "../../components/molecules/BlogH3/BlogH3";
import BlogImage from "../../components/molecules/BlogImage/BlogImage";
import BlogInlineAd1 from "../../components/molecules/BlogInlineAd1/BlogInlineAd1";
import BlogInlineAd2 from "../../components/molecules/BlogInlineAd2/BlogInlineAd2";

// import images
const bannerImage =
  "https://craftle-static.s3.us-east-2.amazonaws.com/blog/blog11/blog-11-banner.jpg";
const image1 =
  "https://craftle-static.s3.us-east-2.amazonaws.com/blog/blog11/blog-11-image-1.jpg";
const image2 =
  "https://craftle-static.s3.us-east-2.amazonaws.com/blog/blog11/blog-11-image-2.jpg";
const image3 =
  "https://craftle-static.s3.us-east-2.amazonaws.com/blog/blog11/blog-11-image-3.jpg";
const image4 =
  "https://craftle-static.s3.us-east-2.amazonaws.com/blog/blog11/blog-11-image-4.jpg";
const image5 =
  "https://craftle-static.s3.us-east-2.amazonaws.com/blog/blog11/blog-11-image-5.jpg";
const image6 =
  "https://craftle-static.s3.us-east-2.amazonaws.com/blog/blog11/blog-11-image-6.jpg";
const image7 =
  "https://craftle-static.s3.us-east-2.amazonaws.com/blog/blog11/blog-11-image-7.jpg";
const image8 =
  "https://craftle-static.s3.us-east-2.amazonaws.com/blog/blog11/blog-11-image-8.jpg";
const image9 =
  "https://craftle-static.s3.us-east-2.amazonaws.com/blog/blog11/blog-11-image-9.jpg";
const image10 =
  "https://craftle-static.s3.us-east-2.amazonaws.com/blog/blog11/blog-11-image-10.jpg";
const image11 =
  "https://craftle-static.s3.us-east-2.amazonaws.com/blog/blog11/blog-11-image-11.jpg";
const image12 =
  "https://craftle-static.s3.us-east-2.amazonaws.com/blog/blog11/blog-11-image-12.jpg";
const image13 =
  "https://craftle-static.s3.us-east-2.amazonaws.com/blog/blog11/blog-11-image-13.jpg";
const image14 =
  "https://craftle-static.s3.us-east-2.amazonaws.com/blog/blog11/blog-11-image-14.jpg";
const image15 =
  "https://craftle-static.s3.us-east-2.amazonaws.com/blog/blog11/blog-11-image-15.jpg";
const image16 =
  "https://craftle-static.s3.us-east-2.amazonaws.com/blog/blog11/blog-11-image-16.jpg";
const image17 =
  "https://craftle-static.s3.us-east-2.amazonaws.com/blog/blog11/blog-11-image-17.jpg";
const image18 =
  "https://craftle-static.s3.us-east-2.amazonaws.com/blog/blog11/blog-11-image-18.jpg";
const image19 =
  "https://craftle-static.s3.us-east-2.amazonaws.com/blog/blog11/blog-11-image-19.jpg";
const image20 =
  "https://craftle-static.s3.us-east-2.amazonaws.com/blog/blog11/blog-11-image-20.jpg";
const image21 =
  "https://craftle-static.s3.us-east-2.amazonaws.com/blog/blog11/blog-11-image-21.jpg";
const image22 =
  "https://craftle-static.s3.us-east-2.amazonaws.com/blog/blog11/blog-11-image-22.jpg";
const image23 =
  "https://craftle-static.s3.us-east-2.amazonaws.com/blog/blog11/blog-11-image-23.jpg";
const image24 =
  "https://craftle-static.s3.us-east-2.amazonaws.com/blog/blog11/blog-11-image-24.jpg";
const image25 =
  "https://craftle-static.s3.us-east-2.amazonaws.com/blog/blog11/blog-11-image-25.jpg";
const image26 =
  "https://craftle-static.s3.us-east-2.amazonaws.com/blog/blog11/blog-11-image-26.jpg";
const image27 =
  "https://craftle-static.s3.us-east-2.amazonaws.com/blog/blog11/blog-11-image-27.jpg";
const image28 =
  "https://craftle-static.s3.us-east-2.amazonaws.com/blog/blog11/blog-11-image-28.jpg";
const image29 =
  "https://craftle-static.s3.us-east-2.amazonaws.com/blog/blog11/blog-11-image-29.jpg";
const image30 =
  "https://craftle-static.s3.us-east-2.amazonaws.com/blog/blog11/blog-11-image-30.jpg";
const image31 =
  "https://craftle-static.s3.us-east-2.amazonaws.com/blog/blog11/blog-11-image-31.jpg";
const image32 =
  "https://craftle-static.s3.us-east-2.amazonaws.com/blog/blog11/blog-11-image-32.jpg";
const image33 =
  "https://craftle-static.s3.us-east-2.amazonaws.com/blog/blog11/blog-11-image-33.jpg";
const image34 =
  "https://craftle-static.s3.us-east-2.amazonaws.com/blog/blog11/blog-11-image-34.jpg";
const image35 =
  "https://craftle-static.s3.us-east-2.amazonaws.com/blog/blog11/blog-11-image-35.jpg";
const image36 =
  "https://craftle-static.s3.us-east-2.amazonaws.com/blog/blog11/blog-11-image-36.jpg";
const image37 =
  "https://craftle-static.s3.us-east-2.amazonaws.com/blog/blog11/blog-11-image-37.jpg";

function BlogPost11() {
  return (
    <BlogParallaxRoot>
      <BlogParallaxLayerBack>
        <CardMedia component="img" image={bannerImage} alt="" />
      </BlogParallaxLayerBack>

      <BlogParallaxLayerMiddle marginTop="200vh" height="200vh" />

      <BlogParallaxLayerFront>
        <BlogContentContainer>
          <BlogDate date="May 26, 2022" />
          <BlogTitle title="Interior Design for Minecraft: 19 Ideas to Make Your Friends Jealous" />
          <BlogParagraph>
            Your home is your castle, and in Minecraft, you can make it as
            grandiose or as humble as you want. But why settle for a basic
            design when you can go above and beyond and create something truly
            special?
          </BlogParagraph>
          <BlogParagraph>
            In this comprehensive guide, we’ll show you 19 awe-inspiring ideas
            for designing the interior of your Minecraft home. Whether you’re
            looking for ideas for a new build, or ways to spruce up an existing
            home, there’s something here for everyone.
          </BlogParagraph>
          <BlogParagraph>
            So without further ado, let’s get started!
          </BlogParagraph>

          <BlogH2 title="19 Interior Design Ideas for Minecraft" />
          <BlogParagraph fontWeight="bold">1. Modern Mansion</BlogParagraph>
          <BlogImage image={image1} alt="" caption="Credit: Per Landin" />
          <BlogImage image={image2} alt="" caption="Credit: Per Landin" />
          <BlogParagraph>
            This interior design is perfect for those who want to live in luxury
            within their Minecraft home. A modern mansion features sleek lines,
            large windows, and an overall open and airy feel. To get this look,
            use white walls and floors with pops of color throughout. For
            furniture, choose clean-lined pieces in bold hues.
          </BlogParagraph>

          <BlogParagraph fontWeight="bold">2. Rustic Cabin</BlogParagraph>
          <BlogImage image={image3} alt="" caption="Credit: Skiftersteven4" />
          <BlogImage image={image4} alt="" caption="Credit: Skiftersteven4" />
          <BlogParagraph>
            If you’re looking for a cozier interior design, try out a rustic
            cabin. This style is all about being warm and inviting, with a focus
            on natural materials. To get the look, use wood paneling for your
            walls and floors. For furniture, choose pieces made from wood or
            stone. Add in some cozy fabrics and you’re all set!
          </BlogParagraph>

          <BlogParagraph fontWeight="bold">3. Victorian Manor</BlogParagraph>
          <BlogImage image={image5} alt="" caption="Credit: _Master" />
          <BlogImage image={image6} alt="" caption="Credit: _Master" />
          <BlogParagraph>
            For a truly regal interior design, go for a Victorian manor. This
            style is all about opulence, with rich colors and luxurious
            materials. To get the look, use dark woods and velvets for your
            floors and walls. For furniture, choose pieces that are ornate and
            detailed. Add in some antique touches for an authentic feel.
          </BlogParagraph>

          <BlogParagraph fontWeight="bold">4. Art Deco Apartment</BlogParagraph>
          <BlogImage image={image7} alt="" caption="Credit: enesyasa" />
          <BlogImage image={image8} alt="" caption="Credit: enesyasa" />
          <BlogParagraph>
            For a glamorous interior design, try out an art deco apartment. This
            style is all about glitz and glamour, with bold colors and geometric
            patterns. To get the look, use bright colors for your walls and
            floors. For furniture, choose pieces that are sleek and modern. Add
            in some art deco-inspired details for a truly unique space.
          </BlogParagraph>

          <BlogParagraph fontWeight="bold">5. Country Cottage</BlogParagraph>
          <BlogImage image={image9} alt="" caption="Credit: FahdQaiser" />
          <BlogImage image={image10} alt="" caption="Credit: FahdQaiser" />
          <BlogParagraph>
            If you’re looking for an interior design that’s cozy and inviting,
            go for a country cottage. This style is all about being warm and
            welcoming, with a focus on natural materials. To get the look, use
            stone or wood floors and walls. For furniture, choose pieces that
            are comfortable and inviting. Add in some floral patterns for a
            touch of whimsy.
          </BlogParagraph>

          <BlogParagraph fontWeight="bold">
            6. Midcentury Modern Home
          </BlogParagraph>
          <BlogImage image={image11} alt="" caption="Credit: Dopaminn" />
          <BlogImage image={image12} alt="" caption="Credit: Dopaminn" />
          <BlogParagraph>
            For an interior design that’s chic and stylish, try out a
            mid-century modern home. This style is all about being clean and
            modern, with a focus on functionality. To get the look, use white
            walls and floors. For furniture, choose pieces that are simple and
            sleek. Add in some midcentury modern-inspired details for a truly
            unique space.
          </BlogParagraph>

          <BlogParagraph fontWeight="bold">7. Industrial Loft</BlogParagraph>
          <BlogImage image={image13} alt="" caption="Credit: bunnyleaf" />
          <BlogImage image={image14} alt="" caption="Credit: bunnyleaf" />
          <BlogParagraph>
            If you’re looking for an interior design that’s edgy and cool, try
            out an industrial loft. This style is all about being raw and urban,
            with a focus on exposed materials. To get the look, use concrete or
            brick walls and floors. For furniture, choose pieces that are
            utilitarian and industrial. Add in some vintage touches for an
            authentic feel.
          </BlogParagraph>

          <BlogParagraph fontWeight="bold">8. Nordic Home</BlogParagraph>
          <BlogImage image={image15} alt="" caption="Credit: BlueNerd" />
          <BlogImage
            image={image16}
            alt=""
            caption="Credit: Minecraft Building Inc"
          />
          <BlogParagraph>
            If you’re going for an old-timey look, try out the Nordic style.
            Think of Vikings and medieval times with this one. Due to its
            historical ties, the Nordic interior design style uses a lot of wood
            and stone. Your place might look a little dark with these materials,
            so use torches and fire to light up your home.
          </BlogParagraph>

          <BlogParagraph fontWeight="bold">9. Japanese Tea House</BlogParagraph>
          <BlogImage image={image17} alt="" caption="Credit: BlueBits" />
          <BlogImage image={image18} alt="" caption="Credit: sevenstars" />
          <BlogParagraph>
            If you’re looking for an interior design that’s tranquil, try out a
            Japanese tea house. This style is all about being calming and
            relaxing, with a focus on nature. To get the look, use tatami mats
            for your floors and shoji screens for your walls. For furniture,
            choose pieces that are low to the ground and made from natural
            materials. Add in some greenery for a touch of nature.
          </BlogParagraph>

          <BlogParagraph fontWeight="bold">10. Moroccan Riad</BlogParagraph>
          <BlogImage image={image19} alt="" caption="Credit: follow_the_halo" />
          <BlogImage image={image20} alt="" caption="Credit: follow_the_halo" />
          <BlogParagraph>
            For an interior design that’s exotic and mysterious, try out a
            Moroccan riad. This style is all about being opulent and luxurious,
            with a focus on rich colors and patterns. To get the look, use
            mosaic tiles for your floors and walls. For furniture, choose pieces
            that are ornate and detailed. Add in some Moroccan-inspired details
            for a truly unique space.
          </BlogParagraph>

          <BlogParagraph fontWeight="bold">11. Mexican Hacienda</BlogParagraph>
          <BlogImage image={image21} alt="" caption="Credit: Prismmo" />
          <BlogImage image={image22} alt="" caption="Credit: Prismmo" />
          <BlogParagraph>
            If you’re looking for an interior design that’s colorful and
            vibrant, try out a Mexican hacienda. This style is all about being
            bright and cheerful, with a focus on bold colors and patterns. To
            get the look, use brightly colored tiles for your floors and walls.
            For furniture, choose pieces that are rustic and handmade. Add in
            some Mexican-inspired details for a truly unique space.
          </BlogParagraph>

          <BlogInlineAd1 />

          <BlogParagraph fontWeight="bold">12. Beach House</BlogParagraph>
          <BlogImage image={image23} alt="" caption="Credit: Zaypixel" />
          <BlogImage
            image={image24}
            alt=""
            caption="Credit: Minecraft Gallery"
          />
          <BlogParagraph>
            If you’re looking for an interior design that’s breezy and relaxed,
            go for a beach house. This style is all about being airy and light,
            with a focus on natural materials. To get the look, use pale woods
            or bamboo for your floors and walls. For furniture, choose pieces
            that are comfortable and casual. Add in some nautical details for a
            touch of the coast.
          </BlogParagraph>

          <BlogParagraph fontWeight="bold">
            13. Hollywood Glamour Home
          </BlogParagraph>
          <BlogImage
            image={image25}
            alt=""
            caption="Credit: Minecraft Building Inc"
          />
          <BlogImage
            image={image26}
            alt=""
            caption="Credit: Minecraft Building Inc"
          />
          <BlogParagraph>
            For an interior design that’s chic and glamorous, try out a
            Hollywood glamour home. This style is all about being luxurious and
            extravagant, with a focus on opulent furnishings and details. To get
            the look, use rich woods or marble for your floors and walls. For
            furniture, choose pieces that are ornate and detailed. Add in some
            shimmering accents for a truly glamorous space.
          </BlogParagraph>

          <BlogParagraph fontWeight="bold">14. Modern Farmhouse</BlogParagraph>
          <BlogImage image={image27} alt="" caption="Credit: u/OtamaTheWorld" />
          <BlogImage image={image28} alt="" caption="Credit: EnesOkumus" />
          <BlogParagraph>
            If you’re looking for an interior design that’s cozy and inviting,
            try out a modern farmhouse. This style is all about being warm and
            welcoming, with a focus on natural materials and rustic details. To
            get the look, use wood floors and walls. For furniture, choose
            pieces that are comfortable and inviting. Add in some
            farmhouse-inspired details for an authentic feel.
          </BlogParagraph>

          <BlogParagraph fontWeight="bold">15. Traditional Home</BlogParagraph>
          <BlogImage image={image29} alt="" caption="Credit: SRVDkamp" />
          <BlogImage image={image30} alt="" caption="Credit: SRVDkamp" />
          <BlogParagraph>
            If you’re looking for an interior design that’s classic and
            timeless, go for a traditional home. This style is all about being
            elegant and refined, with a focus on classic furnishings and
            details. To get the look, use rich woods or marble for your floors
            and walls. For furniture, choose pieces that are classic and
            timeless. Add in some traditional details for an elegant space.
          </BlogParagraph>

          <BlogParagraph fontWeight="bold">16. Boho Chic Home</BlogParagraph>
          <BlogImage image={image31} alt="" caption="Credit: curiously mari" />
          <BlogParagraph>
            If you’re looking for an interior design that’s colorful and
            eclectic, go for a boho-chic home. This style is all about being
            bright and beautiful, with a focus on bold colors and patterns. To
            get the look, use brightly colored tiles or rugs for your floors and
            walls. For furniture, choose pieces that are comfortable and
            inviting. Add in some bohemian-inspired details for a truly unique
            space.
          </BlogParagraph>

          <BlogParagraph fontWeight="bold">
            17. French Country Home
          </BlogParagraph>
          <BlogImage image={image32} alt="" caption="Credit: -ThyJoimees" />
          <BlogImage image={image33} alt="" caption="Credit: -ThyJoimees" />
          <BlogParagraph>
            For an interior design that’s chic and elegant, try out a French
            country home. This style is all about being refined and
            sophisticated, with a focus on classic furnishings and details. To
            get the look, use stone or tile floors and walls. For furniture,
            choose pieces that are classic and timeless. Add in some
            French-inspired details for a truly chic space.
          </BlogParagraph>

          <BlogParagraph fontWeight="bold">
            18. British Colonial Home
          </BlogParagraph>
          <BlogImage image={image34} alt="" caption="Credit: -FahdQaiser" />
          <BlogImage image={image35} alt="" caption="Credit: -FahdQaiser" />
          <BlogParagraph>
            If you’re looking for an interior design that’s regal and stately,
            try out a British colonial home. This style is all about being grand
            and luxurious, with a focus on opulent furnishings and details. To
            get the look, use dark woods or marble for your floors and walls.
            For furniture, choose pieces that are ornate and detailed. Add in
            some British-inspired details for a truly regal space.
          </BlogParagraph>

          <BlogParagraph fontWeight="bold">19. Urban Loft</BlogParagraph>
          <BlogImage
            image={image36}
            alt=""
            caption="Credit: UstinJay and BrainBuilder"
          />
          <BlogImage
            image={image37}
            alt=""
            caption="Credit: UstinJay and BrainBuilder"
          />
          <BlogParagraph>
            For an interior design that’s cool and edgy, try out an urban loft.
            This style is all about being hip and trendy, with a focus on
            exposed brick and industrial details. To get the look, use brick or
            concrete for your floors and walls. For furniture, choose pieces
            that are comfortable and stylish. Add in some urban-inspired details
            for a truly edgy space.
          </BlogParagraph>

          {/* Banner image credit */}
          <BlogParagraph>
            (Banner Photo Credit: JUNS MAB Architecture Tutorial)
          </BlogParagraph>

          <BlogInlineAd2 />
        </BlogContentContainer>
        <Footer />
      </BlogParallaxLayerFront>
    </BlogParallaxRoot>
  );
}

export default BlogPost11;
