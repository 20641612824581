import { customTheme } from "../components/atoms/CustomTheme/CustomTheme";

export const classes = {
  container: {
    width: "100%",
    paddingTop: "100px",
    paddingBottom: "100px",
    backgroundColor: "#FAF3EE",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    [customTheme.breakpoints.down("lg")]: {
      width: "inherit",
      paddingLeft: "16px",
      paddingRight: "16px",
    },
    [customTheme.breakpoints.down("sm")]: {
      paddingTop: "88px",
      paddingBottom: "72px",
    },
  },

  // Hero section
  heroGridContainer: {
    maxWidth: "1137px",
    width: "100%",
  },
  heroGridItem1: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
  h1: {
    fontSize: "48px",
    fontWeight: "bold",
    paddingBottom: "24px",
    [customTheme.breakpoints.down("sm")]: {
      fontSize: "30px",
    },
  },
  heroGridItem2: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    [customTheme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  bannerImage: {
    borderRadius: "50px",
  },

  // Team section
  teamSectionContainer: {
    maxWidth: "1137px",
    width: "100%",
    margin: "auto",
    paddingTop: "100px",
    paddingBottom: "150px",
    [customTheme.breakpoints.down(900)]: {
      paddingBottom: "50px",
    },
  },
  teamTitle: {
    textAlign: "center",
    fontSize: "40px",
    lineHeight: 1.3,
    fontWeight: "bold",
    marginBottom: "50px",
    [customTheme.breakpoints.down(998)]: {
      fontSize: "30px",
    },
  },
  teamGridContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
  },
  teamGridItem: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    [customTheme.breakpoints.down(998)]: {
      marginBottom: "20px",
    },
  },
  teamPhoto: {
    width: "150px",
    marginBottom: "20px",
  },
  teamName: {
    fontSize: "24px",
    lineHeight: 1.2,
    textAlign: "center",
    marginBottom: "15px",
    fontWeight: 500,
  },
  teamPosition: {
    fontSize: "20px",
    lineHeight: 1.2,
    textAlign: "center",
    fontWeight: 400,
    color: "#4A4A4A",
  },
  linkedIn: {
    marginTop: "16px",
    fontWeight: "bold",
  },
};
