import React from "react";
import { Box, Grid } from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";

let customTheme = createTheme({
  breakpoints: {
    values: {
      xl: 1536,
      lg: 1200,
      md: 900,
      sm: 600,
      xs: 0,
    },
  },
});

function Terms() {
  return (
    <ThemeProvider theme={customTheme}>
      <Grid
        container
        // spacing={3}
        sx={{
          maxWidth: "692px",
          width: "100%",
          margin: "auto",
          marginTop: "100px",
          marginBottom: "100px",
          [customTheme.breakpoints.down("md")]: {
            paddingLeft: "24px",
            paddingRight: "24px",
            marginTop: "50px",
          },
        }}
      >
        <Grid
          item
          md={12}
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          <Box
            sx={{
              fontSize: "50px",
              lineHeight: 1.3,
              fontWeight: "bold",
              textAlign: "center",
              paddingBottom: "30px",
            }}
          >
            Terms of Service
          </Box>
          <Box sx={{ fontSize: "16px", lineHeight: 1.5 }}>
            These Terms of Use (the &quot;<b>Terms</b>&quot; or the &quot;
            <b>Agreement</b>&quot;) set forth the binding legal agreement
            between you and Craftle Inc. The Agreement governs your use of
            www.craftle.app and all of the related websites, mobile apps,
            products and services offered by Craftle, including our plug-ins and
            browser extensions (collectively, the &quot;<b>Craftle Platform</b>
            &quot;).
          </Box>
          <Box sx={{ height: "24px" }}></Box>
          <Box sx={{ fontSize: "16px", lineHeight: 1.5 }}>
            We refer to the e-commerce marketplace on the Craftle Platform as
            the &quot;Shop.&quot; If you visit or make a purchase or sale
            through the Shop through www.craftle.app or the related mobile app
            experience, or if you create an account on the Craftle Platform,
            then this Agreement is also between you and Craftle Inc. If you
            participate in the Craftle Shop, you agree to the Terms of Sale and
            Terms of Use. Craftle Inc. is collectively referred to as &quot;
            <b>Craftle</b>,&quot; &quot;<b>we</b>,&quot; or &quot;<b>us</b>
            &quot; in this Agreement.
          </Box>
          <Box sx={{ height: "24px" }}></Box>
          <Box
            sx={{ fontSize: "16px", lineHeight: 1.5 }}
            style={{ fontWeight: "bold" }}
          >
            THESE TERMS REQUIRE THE USE OF ARBITRATION ON AN INBoxIDUAL BASIS TO
            RESOLVE DISPUTES, RATHER THAN JURY TRIALS OR CLASS ACTIONS, AND ALSO
            LIMIT THE REMEDIES AVAILABLE TO YOU IN THE EVENT OF A DISPUTE. SEE
            SECTION 9 FOR ADDITIONAL INFORMATION.
          </Box>
          <Box sx={{ height: "24px" }}></Box>
          <Box sx={{ fontSize: "16px", lineHeight: 1.5 }}>
            We encourage you to review this Agreement carefully. By accessing or
            using the Craftle Platform in any way, including browsing
            www.craftle.app, you are agreeing to these Terms in their entirety.
            If you do not agree to any of the Terms, you may not use the Craftle
            Platform.
          </Box>
          <Box
            sx={{
              fontSize: "20px",
              lineHeight: 1.5,
              fontWeight: "bold",
              marginTop: "50px",
              marginBottom: "20px",
            }}
          >
            Accepting these Terms
          </Box>
          <Box sx={{ fontSize: "16px", lineHeight: 1.5 }}>
            If you access or use the Service, it means you agree to be bound by
            all of the terms below. So, before you use the Service, please read
            all of the terms. If you don’t agree to all of the terms below,
            please do not use the Service. Also, if a term does not make sense
            to you, please let us know.
          </Box>
          <Box
            sx={{
              fontSize: "20px",
              lineHeight: 1.5,
              fontWeight: "bold",
              marginTop: "50px",
              marginBottom: "20px",
            }}
          >
            Changes to these Terms
          </Box>
          <Box sx={{ fontSize: "16px", lineHeight: 1.5 }}>
            We reserve the right to change this Terms of Service from time to
            time. For example, we may need to change these Terms if we come out
            with a new feature. If we make changes, we will notify you by
            revising the date at the top of the policy and, in some cases,
            provide you with additional notice (such as adding a statement to
            our homepage or sending you an email notification). We encourage you
            to review the Terms of Service periodically to stay informed about
            our practices. If you continue to use the Service after the revised
            Terms have been posted, then you have accepted the changes to these
            Terms. Whenever we make changes to these Terms, they are effective
            when the revised Terms are posted. If you continue to use the
            Service after the revised Terms have been posted, then you have
            accepted the changes to these Terms.
          </Box>
          <Box
            sx={{
              fontSize: "20px",
              lineHeight: 1.5,
              fontWeight: "bold",
              marginTop: "50px",
              marginBottom: "20px",
            }}
          >
            Privacy Policy
          </Box>
          <Box sx={{ fontSize: "16px", lineHeight: 1.5 }}>
            For information about how we collect and use information about users
            of the Service, please refer to our Privacy Policy.
          </Box>
          <Box
            sx={{
              fontSize: "20px",
              lineHeight: 1.5,
              fontWeight: "bold",
              marginTop: "50px",
              marginBottom: "20px",
            }}
          >
            1. Using the Craftle Platform.
          </Box>
          <Box sx={{ fontSize: "16px", lineHeight: 1.5 }}>
            <ul>
              <li>
                <b>Who can use it.</b> You must be at least the age of majority
                in the state where you live to use the Craftle Platform. Use of
                the Craftle Platform by anyone under 16 years of age is strictly
                prohibited. Do not use Craftle in any manner that could
                interfere with, disrupt, negatively affect or inhibit other
                Craftle users or that could damage, disable, overburden or
                impair the functioning of Craftle; Disclose employer
                confidential or proprietary information; Share personal
                information about private people (like their email addresses,
                addresses, phone numbers, etc.) unless with the consent of the
                inBoxidual; Collect any personal information about other users
                for the purposes of intimidation or harassment; Threaten other
                Craftle users; Circumvent or attempt to circumvent any features
                designed to protect Craftle, Craftle users, or third parties.
              </li>
              <li>
                <b>Registration.</b> Access to certain functionalities of the
                Craftle Platform will require you to register with us and to
                create a profile on our Platform. If you register with Craftle,
                you agree to provide us with accurate information and update it
                as needed for accuracy. We will treat personal information you
                provide as part of registration in accordance with our Privacy
                Policy. You also may have the option to register for an account
                by linking your Facebook, Google, or Apple account.
              </li>
              <li>
                <b>Termination.</b> You may close your account at any time by
                going to account settings and deactivating your account. We may
                permanently or temporarily suspend your use of the Craftle
                Platform at any time for any reason, without any notice or
                liability to you. We may terminate your account at any time for
                any or no reason, including if you violate any Craftle policy.
                Upon termination of your use of the Craftle Platform, certain
                provisions will survive termination.
              </li>
              <li>
                <b>Feedback.</b> We welcome your feedback and suggestions about
                how to improve the Craftle Platform. Feel free to submit
                feedback at https://help.craftle.app. By submitting feedback in
                this or in any other manner to us, you grant us the right, at
                our discretion, to use, disclose and otherwise exploit the
                feedback, in whole or part, without any restriction or
                compensation to you.
              </li>
              <li>
                <b>Reserving the Rights to Change.</b> We change these Terms of
                Service every so often. If we make changes, we will notify you
                by revising the date at the top of the policy and, in some
                cases, provide you with additional notice (like on our homepage
                or over email).
              </li>
            </ul>
          </Box>
          <Box
            sx={{
              fontSize: "20px",
              lineHeight: 1.5,
              fontWeight: "bold",
              marginTop: "50px",
              marginBottom: "20px",
            }}
          >
            2. Your Content.
          </Box>
          <Box sx={{ fontSize: "16px", lineHeight: 1.5 }}>
            <ul>
              <li>
                <b>Definition of Your Content.</b> The Craftle Platform enables
                you to post materials, including without limitation photos,
                videos, profile pictures, messages, and comments. You may also
                post reviews of third-party service providers, third-party
                products, or third-party services. All materials that you post
                on the Craftle Platform will be referred to collectively as
                &quot;
                <b>Your Content.</b>&quot;
              </li>
              <li>
                <b>Prohibited Content.</b> Don’t post, link or otherwise make
                available on or through Craftle any of the following: Content
                that is illegal or unlawful; Content that may infringe or
                violate any rights of any party; Viruses, corrupted data or
                other harmful, disruptive or destructive files or code.
              </li>
              <li>
                <b>License and Permission to Use Your Content.</b> You hereby
                grant to us and our affiliates, licensees and sublicensees,
                without compensation to you or others, a non-exclusive,
                perpetual, irrevocable, royalty-free, fully paid-up, worldwide
                license (including the right to sublicense through multiple
                tiers) to use, reproduce, process, adapt, publicly perform,
                publicly display, modify, prepare derivative works, publish,
                transmit and distribute Your Content, or any portion thereof,
                throughout the world in any format, media or distribution method
                (whether now known or hereafter created) for the duration of any
                copyright or other rights in Your Content. Such permission will
                be perpetual and may not be revoked for any reason, to the
                maximum extent permitted by law. Further, to the extent
                permitted under applicable law, you waive and release and
                covenant not to assert any moral rights that you may have in
                Your Content. If you identify yourself by name or provide a
                picture or audio or video recording of yourself, you further
                authorize us and our affiliates, licensees and sublicensees,
                without compensation to you or others, to reproduce, print,
                publish and disseminate in any format or media (whether now
                known or hereafter created) your name, voice and likeness
                throughout the world, and such permission will be perpetual and
                cannot be revoked for any reason, except as required by
                applicable law. You further agree that we may use Your Content
                in any manner that we deem appropriate or necessary.
              </li>
              <li>
                <b>Ownership.</b> We acknowledge and agree that you, or your
                licensors, as applicable, retain ownership of any and all
                copyrights in Your Content, subject to the non-exclusive rights
                granted to us in the paragraph above, and that no ownership of
                such copyrights is transferred to us under this Agreement.
                Further, with respect to Your Content in the form of photos, and
                subject to Craftle product and user experience considerations:
                (a) we will use commercially reasonable efforts to maintain the
                attribution of such photos as submitted by you, and (b) we will
                not license or sublicense to third parties inBoxidual photos or
                collections of photos, except in each case for Craftle Business
                Purposes. &quot;Craftle Business Purposes&quot; means any use in
                connection with a Craftle-branded or co-branded website,
                application, publication or service, or any use which
                advertises, markets or promotes the Craftle Platform, the
                services or the information it contains, Craftle, or its
                affiliates. Craftle Business Purpose specifically includes the
                use of Your Content on the Craftle Platform in connection with
                features and functions offered by Craftle to our users that
                enable them to view and interact with Your Content (such as
                Sketch, View in My Room and the embed tool).
              </li>
              <li>
                <b>Your Responsibilities for Your Content.</b> By posting,
                uploading, or submitting Your Content on the Craftle Platform,
                you represent and warrant to us that you have the ownership
                rights, or you have obtained all necessary licenses or
                permissions from any relevant parties, to use Your Content in
                this manner. This includes obtaining the right to grant us the
                rights to use Your Content in accordance with this Agreement.
                You are in the best position to judge whether Your Content is in
                violation of intellectual property or personal rights of any
                third-party.
                <b>
                  You accept full responsibility for avoiding infringement of
                  the intellectual property or personal rights of others in
                  connection with Your Content.
                </b>
                You are responsible for ensuring that Your Content does not
                violate Craftle&apos;s policies, or any applicable law or
                regulation. You agree to pay all royalties, fees, and any other
                monies owed to any person by reason of Your Content.
              </li>
              <li>
                <b>Limits.</b> We reserve the right to remove Your Content, in
                whole or part, for any reason (which may include a reported
                violation of our policies). We reserve the right to remove Your
                Content, in whole or in part, submitted by you for any reason
                without notice. We do not guarantee that we will publish all of
                Your Content. If you seek to publish a review, the requirements
                will be governed by our review guideline.
              </li>
            </ul>
          </Box>
          <Box
            sx={{
              fontSize: "20px",
              lineHeight: 1.5,
              fontWeight: "bold",
              marginTop: "50px",
              marginBottom: "20px",
            }}
          >
            3. Our Content and Materials.
          </Box>
          <Box sx={{ fontSize: "16px", lineHeight: 1.5 }}>
            <ul>
              <li>
                <b>Definition of Our Content and Materials.</b> All intellectual
                property in or related to the Craftle Platform (specifically
                including, but not limited to, our software, the Craftle marks,
                the Craftle logo, and Craftle buttons, badges, and widgets, but
                excluding Your Content), is the property of Craftle Inc., its
                subsidiaries and affiliates or its licensors (&quot;
                <b>Our Content and Materials</b>&quot;).
              </li>
              <li>
                <b>Our License to You.</b> Subject to these Terms of Use,
                including the restrictions below, we grant you a limited
                non-exclusive license to use and access Our Content and
                Materials in connection with your use of the Craftle Platform.
                Except as expressly agreed to otherwise by us (such as your
                eligibility for creation of a Professional Profile or your
                entering into a vendor, seller, advertiser, Site Designer, or
                other agreement with us), your use of the Craftle Platform must
                be limited to personal non-commercial use. We may terminate this
                license at any time for any reason. Except for the rights and
                license granted in these terms, we reserve all other rights and
                grant no other rights or licenses, implied or otherwise.
              </li>
              <li>
                <b>No Endorsement or Verification.</b> Please note that the
                Craftle Platform enables access to third-party content,
                products, and services, and it offers interactions with
                third-parties that we do not control. We assume no
                responsibility for, nor do we endorse or verify the content,
                offerings or conduct of third-parties (including but not limited
                to the products or services offered by third-parties or the
                descriptions of the products or services offered by
                third-parties). For example, Craftle does not conduct background
                checks or otherwise vet the professionals listed on the Craftle
                Platform. Participation or availability on the Craftle Platform
                does not amount to endorsement or verification by us. We make no
                warranties or representations with respect to the accuracy,
                completeness or timeliness of any content posted on the Craftle
                Platform by anyone.
              </li>
              <li>
                <b>Restrictions.</b> Except as expressly provided in these
                Terms, you agree not to use, modify, reproduce, distribute,
                sell, license, reverse engineer, decompile, or otherwise exploit
                Our Content and Materials without our express written
                permission. Craftle&apos;s permission to you for your use of the
                Craftle Platform expressly excludes commercial use by you of any
                information concerning product descriptions or professional
                listings for the benefit of another merchant. You are expressly
                prohibited from any use of data mining, robots, or similar data
                gathering and extraction tools in your use of the Craftle
                Platform. You may view and print a reasonable number of copies
                of web pages located on the Craftle Platform for your own
                personal use, provided that you retain all proprietary notices
                contained in the original materials, including attribution to
                Craftle.
              </li>
              <li>
                <b>Ownership.</b> You acknowledge and agree that the Craftle
                Platform and Craftle marks will remain the property of Craftle.
                The content, information and services made available on the
                Craftle Platform are protected by U.S. and international
                copyright, trademark, and other laws, and you acknowledge that
                these rights are valid and enforceable. You acknowledge that you
                do not acquire any ownership rights by using the Craftle
                Platform.
              </li>
              <li>
                <b>Craftle Buttons, Links and Widgets.</b> You have permission
                to use the Craftle buttons, links and widgets, subject to these
                Terms of Use (including the disclaimers and limitations of
                liability) and the further understanding that: (a) your use of
                such buttons, links and widgets link only to the Craftle
                Platform; (b) you will not modify such buttons, links, widgets
                or associated code in any manner; (c) you will not use any such
                buttons, links, widgets in any manner which implies or suggests
                that we endorse, sponsor, or recommend the website on which such
                buttons, links and widgets are used; and (d) the use of such
                buttons, links and widgets, and the website on which such
                buttons, links and widgets are used do not violate the
                Craftle&apos;s policies.
              </li>
            </ul>
          </Box>

          <Box
            sx={{
              fontSize: "20px",
              lineHeight: 1.5,
              fontWeight: "bold",
              marginTop: "50px",
              marginBottom: "20px",
            }}
          >
            4. Other Offerings on the Craftle Platform.
          </Box>
          <Box sx={{ fontSize: "16px", lineHeight: 1.5 }}>
            <ul>
              <li>
                <b>Purchase of Goods through the Craftle Shop.</b> Craftle Shop
                and third-party sellers offer home goods, furniture, and other
                products for sale on the Craftle Platform. If you purchase
                products on the Craftle Platform, your purchase is subject to
                the Craftle Shop Terms of Sale. The availability of products
                sold by third-parties on the Craftle Platform does not imply our
                endorsement or verification of the products or their
                descriptions.
              </li>
              <li>
                <b>Plant and Seed Exchange.</b> You may be offered the ability
                to exchange plants or seeds with other users in the exchange
                forums. Seed and plant exchanges are regulated by government
                laws. By participating in these forums, you agree that you will
                familiarize yourself with and follow all applicable local and
                national laws in your country. Additionally, you agree to follow
                our policy that: 1) you may not charge for seed or plant
                exchanges, but requiring pre-paid postage is permitted; and 2)
                you may not use the forum to seek exchange of plants or seeds
                with someone from another country. We expressly disclaim any
                liability with respect to plants or seeds offered through
                exchanges.
              </li>
              <li>
                <b>Embed Tool.</b> If you are a user of the embed tool, which
                enables you to link to content on the Craftle Platform, you
                understand and agree that we cannot guarantee that the content,
                which originates from third-parties, is non-infringing or will
                be free from claims about infringement. Such third-party content
                may be subject to takedown by us at any time, in accordance with
                our policies, if we receive a notice of infringement.
              </li>
              <li>
                <b>Third-Party Services.</b> You may be provided the opportunity
                on the Craftle Platform to purchase services that are offered by
                third parties (collectively &quot;<b>Third-Party Services</b>
                &quot;), including those offered by professionals registered
                with Professional Profiles on the Craftle Platform. The
                availability of any Third-Party Services on the Craftle Platform
                does not imply our endorsement of the Third-Party Services.
              </li>
              <li>
                <b>Third-Party Sites.</b> The Craftle Platform may contain links
                to other websites (the &quot;<b>Third-Party Sites</b>&quot;) for
                your convenience. We do not control the linked websites or the
                content provided through such Third-Party Sites. Your use of
                Third-Party Sites is subject to the privacy practices and terms
                of use established by the specific linked Third-Party Site, and
                we disclaim all liability for such use. The availability of such
                links does not indicate any approval or endorsement by us.
              </li>
            </ul>
          </Box>

          <Box
            sx={{
              fontSize: "20px",
              lineHeight: 1.5,
              fontWeight: "bold",
              marginTop: "50px",
              marginBottom: "20px",
            }}
          >
            5. Craftle Shop Terms of Sale.
          </Box>
          <Box sx={{ fontSize: "16px", lineHeight: 1.5 }}>
            <b>
              Those provisions apply in full, in addition to the following
              terms:
            </b>
          </Box>
          <Box sx={{ fontSize: "16px", lineHeight: 1.5 }}>
            <ul>
              <li>
                <b>No Endorsement or Verification.</b> The availability of any
                Products on the Craftle Platform or in the Craftle Shop does not
                imply our endorsement of the Products. We do not represent,
                warrant or guarantee that the Product descriptions are accurate,
                complete, reliable, current, or error-free. Depictions of
                Products in features such as Add to Room are for reference only
                and may not always accurately depict every aspect of the
                Product. For example, re-sizing an object in Add to Room for
                staging purposes does not change the dimensions of the Product.
              </li>
              <li>
                <b>Product Reviews.</b> Product listings may contain ratings and
                reviews obtained from third parties. Product reviews posted by
                Craftle users are solely the opinions of the posters. None of
                the Product reviews contain or reflect any opinions or views of
                Craftle. Ratings given in connection with purchases verified by
                Craftle may be given greater weight than ratings not verified by
                Craftle.
              </li>
              <li>
                <b>Order Acceptance and Cancellation Right.</b> The receipt of
                an order number or an email order confirmation does not
                constitute acceptance of an order or a confirmation of an offer
                to sell. We reserve the right to limit the order quantity on any
                item and/or to refuse service to any customer for any reason.
                Verification of information may be required prior to the
                acceptance of an order. Prices and availability of Products on
                the Craftle Platform or through the Craftle Shop are subject to
                change. Errors will be corrected when discovered, and we reserve
                the right to revoke any stated offer and to correct any error,
                inaccuracy, or omission. We reserve the right, at our sole
                discretion, to refuse or cancel any order for any reason.
              </li>
              <li>
                <b>Buyer Obligations.</b> Without limiting any other provision
                of these Terms of Sale or the Terms of Use, you are responsible
                for (a) reading the entire Product listing before placing an
                order; (b) maintaining correct and up to date payment, contact,
                and shipping information; and (c) honoring any return, refund or
                shipping arrangement entered into with Craftle Shop or any
                Seller.
              </li>
              <li>
                <b>Products Sold by Third Parties.</b>
                <ol type="a">
                  <li>
                    <b>Pricing.</b> Prices for Products sold by Sellers are set
                    by the Seller.
                  </li>
                  <li>
                    <b>Payments.</b> Craftle Shop acts as a payments processor
                    on behalf of the Seller and does not act as your agent with
                    respect to any payments for Products submitted via the
                    Craftle Platform. To the contrary, Craftle Shop acts as the
                    Seller’s payment collection agent for the limited purpose of
                    accepting payments for Products from you on behalf of the
                    Seller. Upon your payment of funds to Craftle Shop, your
                    payment obligation to Seller for the listed amount is
                    complete, and Craftle Shop is responsible for remitting the
                    funds to Seller. In the event that Craftle Shop does not
                    remit any such amounts to the Seller, the Seller will not
                    have any recourse against you directly.
                  </li>
                  <li>
                    <b>Risk of Loss.</b> For Products purchased from Sellers,
                    the risk of loss and title for purchased Products pass from
                    the Seller to buyer upon delivery. Craftle Shop does not
                    transfer legal ownership of any Products from Seller to you.
                  </li>
                  <li>
                    <b>Returns and Refunds.</b> Products sold by Sellers are
                    eligible for returns and refunds if and as indicated by the
                    terms on the Product listing.
                  </li>
                  <li>
                    <b>Third-Party Warranty Information.</b> For Products sold
                    by Sellers, warranty information is listed under the seller
                    and shipping information for that Product, typically under
                    the “View Return Policy” link. The obligation to satisfy any
                    indicated warranty for a Product is between you and the
                    Seller or the manufacturer or supplier, and not with Craftle
                    Shop.
                  </li>
                  <li>
                    <b>Customer Service Limitations.</b> While we may help
                    facilitate the resolution of disputes through our customer
                    service team or various programs, Craftle has no control
                    over and does not guarantee the existence, quality, safety
                    or legality of items advertised, the truth or accuracy of
                    Product listings, or that a seller, manufacturer or supplier
                    will satisfactorily honor the warranty offered by that party
                    for a specific Product. Neither Craftle nor any Seller will
                    be responsible for any damage caused by installation or
                    attempted installation of any Product by you or any
                    third-party.
                  </li>
                  <li>
                    <b>3D Models.</b> Product listings include three-dimensional
                    model (“3D Model”) of the Product for reference. 3D Models
                    may not represent every Product feature and should not be
                    relied upon in making purchasing decisions. You are
                    responsible for reviewing the Product specifications,
                    description, and photos before buying a Product.
                  </li>
                </ol>
              </li>
            </ul>
          </Box>

          <Box
            sx={{
              fontSize: "20px",
              lineHeight: 1.5,
              fontWeight: "bold",
              marginTop: "50px",
              marginBottom: "20px",
            }}
          >
            6. Reporting Violations of Your Intellectual Property Rights,
            Craftle Policies, or Applicable Laws.
          </Box>
          <Box sx={{ fontSize: "16px", lineHeight: 1.5 }}>
            We have a special process for reporting violations of your
            intellectual property rights or other violations of Craftle policies
            or applicable laws.
          </Box>
          <Box sx={{ fontSize: "16px", lineHeight: 1.5 }}>
            <ul>
              <li>
                <b>Copyright and Trademark Policy.</b> We expect users of the
                Craftle Platform to respect the intellectual property of others.
                Users who have uploaded content have represented and warranted
                that they have the rights to permit Craftle to use it on the
                Craftle Platform. If you infringe copyrights or other
                intellectual property rights of others, your Content may be
                removed or suspended in whole or part. It is our policy, in
                appropriate circumstances and our discretion, to disable and/ or
                terminate accounts of users who repeatedly infringe or are
                charged repeatedly with infringing the copyrights or other
                intellectual property rights of others. If you believe content
                on the Craftle Platform infringes your intellectual property
                rights, please submit your complaint at
                https://help.craftle.app.
              </li>
              <li>
                <b>Reports of Other Violations.</b> If you believe content on
                the Craftle Platform violates Craftle&apos;s policies, or
                otherwise violates applicable law, you may submit the complaint
                at https://help.craftle.app (for claims other than copyright or
                trademark).
              </li>
              <li>
                <b>Accounts and Registration.</b> To access most features of the
                Service, you must register for an account. When you register for
                an account, you may be required to provide us with some
                information about yourself, such as your name, employer, email
                address, or other contact information. You agree that the
                information you provide to us is accurate and that you will keep
                it accurate and up-to-date at all times. You are solely
                responsible for maintaining the confidentiality of your account,
                and you accept responsibility for all activities that occur
                under your account. If you believe that your account is no
                longer secure, then you must immediately notify us at
                contact@craftle.app. Craftle disclaims any liability for third
                party actions made under your account.
              </li>
              <li>
                <b>Suspension / Termination.</b> Craftle may suspend and/or
                terminate your rights with respect to the Service for any reason
                or for no reason at all and with or without notice at Craftle’s
                sole discretion. Suspension and/or termination may include
                restricting access to and use of the App. If your rights with
                respect to the Service are suspended and/or terminated, you
                agree to make no further use of the Service during suspension or
                after termination. All of the terms of these Terms (excluding
                the license grant) will survive any termination or suspension.
                If Craftle believes a Post you have made violates these Terms,
                Craftle may make that Post invisible to other users without
                notifying you.
              </li>
            </ul>
          </Box>

          <Box
            sx={{
              fontSize: "20px",
              lineHeight: 1.5,
              fontWeight: "bold",
              marginTop: "50px",
              marginBottom: "20px",
            }}
          >
            7. Disclaimers and Limitations of Liability.
          </Box>
          <Box sx={{ fontSize: "16px", lineHeight: 1.5 }}>
            <b>
              PLEASE READ THIS SECTION CAREFULLY SINCE IT LIMITS THE LIABILITY
              OF CRAFTLE PLATFORM TO YOU.
            </b>
          </Box>
          <Box sx={{ fontSize: "16px", lineHeight: 1.5 }}>
            <ul>
              <li>
                We are providing you the Craftle app, website, services,
                information, products, product descriptions, and third-party
                content on an “as is” and “as available” basis, without warranty
                of any kind, express or implied. Without limiting the foregoing,
                the Craftle Platform expressly disclaim any and all warranties
                and conditions of merchantability, title, accuracy and
                completeness, uninterrupted or error-free service, fitness for a
                particular purpose, quiet enjoyment, and non-infringement, and
                any warranties arising out of course of dealing or trade usage.
              </li>
              <li>
                The Craftle entities make no promises with respect to, and
                expressly disclaim all liability, to the maximum extent
                permitted by law, for: (i) content posted by any third-party on
                the Craftle platform, (ii) the product descriptions or products,
                (iii) third-party sites and any third-party product or service
                listed on or accessible to you through the site, (iv) plants or
                seeds from the exchanges, and (v) the quality or conduct of any
                third-party you encounter in connection with your use of the
                site.
              </li>
              <li>
                You agree that under the maximum extent permitted by law, the
                Craftle entities will not be liable to you under any theory of
                liability. Without limiting the foregoing, you agree that the
                Craftle entities specifically will not be liable for (i) any
                indirect, incidental, consequential, special, or exemplary
                damages, loss of profits, business interruption, reputational
                harm, or loss of data (even if the Craftle entities have been
                advised of the possibility of such damages or such damages are
                foreseeable) arising out of and in any way connected with your
                use of, or inability to use, the Craftle platform or products or
                (ii) any amount, in the aggregate, in excess of the greater of
                (A) one-hundred dollars (USD$100) or (B) the amounts paid or
                payable by you to Craftle in connection with the Craftle
                platform in the twelve (12) months period preceding the
                applicable claim. Your use of the Craftle platform, products,
                information, or services is at your sole risk.
              </li>
            </ul>
          </Box>

          <Box
            sx={{
              fontSize: "20px",
              lineHeight: 1.5,
              fontWeight: "bold",
              marginTop: "50px",
              marginBottom: "20px",
            }}
          >
            8. Indemnification.
          </Box>
          <Box sx={{ fontSize: "16px", lineHeight: 1.5 }}>
            You agree to fully indemnify, defend, and hold the Craftle Entities
            and their directors, officers, employees, consultants, and other
            representatives, harmless from and against any and all claims,
            damages, losses, costs (including reasonable attorneys&apos; fees),
            and other expenses that arise directly or indirectly out of or from:
            (a) your breach of any part of this Agreement, including but not
            limited to our policies; (b) any allegation that any materials you
            submit to us or transmit to the Craftle Platform infringe or
            otherwise violate the copyright, patent, trademark, trade secret, or
            other intellectual property or other rights of any third party; (c)
            your activities in connection with the Craftle Platform or other
            websites to which the Craftle Platform is linked; and/or (d) your
            negligent or willful misconduct.
          </Box>

          <Box
            sx={{
              fontSize: "20px",
              lineHeight: 1.5,
              fontWeight: "bold",
              marginTop: "50px",
              marginBottom: "20px",
            }}
          >
            9. Dispute Resolution.
          </Box>
          <Box sx={{ fontSize: "16px", lineHeight: 1.5 }}>
            If you have a dispute with Craftle, you agree to contact us through
            https://help.craftle.app to attempt to resolve the issue informally
            first. If we are not able to resolve the dispute informally, then
            this section will govern any legal dispute that relates to the
            Craftle Platform or involves our services.
          </Box>
          <Box sx={{ fontSize: "16px", lineHeight: 1.5 }}>
            <ul>
              <li>
                <b>Binding Arbitration.</b> You and Craftle agree that any
                dispute, claim or controversy arising out of or relating to this
                Agreement or to your use of the Craftle Platform (collectively
                &quot;
                <b>Disputes</b>&quot;) will be settled by binding arbitration,
                except that each party retains the right to seek injunctive or
                other equitable relief in a court of competent jurisdiction to
                prevent the actual or threatened infringement, misappropriation,
                or violation of a party&apos;s copyrights, trademarks, trade
                secrets, patents, or other intellectual property rights.
                <b>
                  This means that you and Craftle both agree to waive the right
                  to a trial by jury
                </b>
                . Notwithstanding the foregoing, you may bring a claim against
                Craftle in &quot;small claims&quot; court, instead of by
                arbitration, but only if the claim is eligible under the rules
                of the small claims court and is brought in an inBoxidual,
                non-class, and non-representative basis, and only for so long as
                it remains in the small claims court and in an inBoxidual,
                non-class, and non-representative basis.
              </li>
              <li>
                <b>Class Action Waiver.</b> You and Craftle agree that any
                proceedings to resolve Disputes will be conducted on an
                inBoxidual basis and not in a class, consolidated, or
                representative action.
                <b>
                  This means that, in connection with any Dispute, you and
                  Craftle both agree to waive the right to participate as a
                  plaintiff as a class member in any class action proceeding.
                  Further, unless you and Craftle agree otherwise in writing,
                  the arbitrator in any Dispute may not consolidate more than
                  one person&apos;s claims and may not preside over any form of
                  class action proceeding.
                </b>
              </li>
              <li>
                <b>Arbitration Administration and Rules.</b> The arbitration
                will be administered by the American Arbitration Association
                (&quot;
                <b>AAA</b>&quot;) in accordance with the Consumer Arbitration
                Rules (if you use the Craftle Platform as a consumer) or
                Commercial Arbitration Rules (if you are a professional, vendor,
                seller or other business), as applicable and then in effect (the
                &quot;
                <b>AAA Rules</b>&quot;), except as modified by this
                &quot;Dispute Resolution&quot; section. (The AAA Rules are
                available at http://www.adr.org).
              </li>
              <li>
                <b>Arbitration Process.</b> A party who desires to initiate the
                arbitration must provide the other party with a written Demand
                for Arbitration as specified in the AAA Rules. The arbitrator
                will be either a retired judge or an attorney licensed to
                practice law and will be selected by the parties from the
                AAA&apos;s roster of arbitrators with relevant experience. If
                the parties are unable to agree upon an arbitrator within 7 days
                of delivery of the Demand for Arbitration, then the AAA will
                appoint the arbitrator in accordance with AAA Rules.
              </li>
              <li>
                <b>Arbitration Location and Procedure.</b> The seat of the
                arbitration shall be in San Francisco, California, unless you
                and Craftle agree otherwise or the AAA Rules or AAA Consumer Due
                Process Protocol, as applicable, provide otherwise. If your
                claim does not exceed USD$10,000, then the arbitration will be
                conducted solely on the basis of documents you and Craftle
                submit to the arbitrator, unless you request a hearing and the
                arbitrator then determines that a hearing is necessary. If your
                claim exceeds USD$10,000, your right to a hearing will be
                determined by AAA Rules. Subject to AAA Rules, the arbitrator
                will have the discretion to direct a reasonable exchange of
                information by the parties, consistent with the expedited nature
                of the arbitration. Hearings may be conducted by telephone or
                video conference, if requested and agreed to by the parties.
              </li>
              <li>
                <b>Arbitrator&apos;s Decision and Governing Law.</b> The
                arbitrator shall apply California law consistent with the
                Federal Arbitration Act and applicable statutes of limitations,
                and shall honor claims of privilege recognized by law. The
                arbitrator will render an award within the timeframe specified
                in the AAA Rules. Judgment on the arbitration may be entered in
                any court having jurisdiction thereof. Any award of damages by
                an arbitrator must be consistent with the &quot;Disclaimers and
                Limitations of Liability&quot; section above. The arbitrator may
                award declaratory or injunctive relief in favor of the claimant
                only to the extent necessary to provide relief warranted by the
                claimant&apos;s inBoxidual claim.
              </li>
              <li>
                <b>Fees.</b> Each party&apos;s responsibility to pay the
                arbitration filing, administrative and arbitrator fees will
                depend on the circumstances of the arbitration and are set forth
                in the AAA Rules.
              </li>
              <li>
                <b>Opt-Out.</b> You have the right to opt out of the arbitration
                provisions of Section 8 by sending a written notice of your
                decision to opt out to the following address: 285 Hamilton
                Avenue, Palo Alto, CA 94301 USA Attention: Craftle Legal
                Department. The notice must be postmarked within 30 days of the
                later of: (i) the date that you first agreed to the Terms; and
                (ii) the date that you became subject to this provision under
                Section 8(h). You must include all of the following in the
                written notice: (1) your name and mailing address; (2) the email
                address associated with your account; and (3) a clear statement
                that you want to opt out of this Agreement’s arbitration
                agreement.
              </li>
            </ul>
          </Box>
          <Box sx={{ fontSize: "16px", lineHeight: 1.5 }}>
            If you have a dispute with another user on the Craftle Platform or
            with any third party, you agree that Craftle is under no obligation
            to become involved. In the event that you have a dispute with one or
            more other users, you release Craftle, its directors, officers,
            employees, agents, and successors from claims, demands, and damages
            of every kind or nature, known or unknown, suspected or unsuspected,
            disclosed or undisclosed, arising out of or in any way related to
            such disputes and/or the Craftle Platform.
            <b>
              As part of this release, you expressly waive any protections
              (whether statutory or otherwise) that would otherwise limit this
              release to only include those claims that you may know or suspect
              to exist in your favor at the time of agreeing to this release.
            </b>
          </Box>

          <Box
            sx={{
              fontSize: "20px",
              lineHeight: 1.5,
              fontWeight: "bold",
              marginTop: "50px",
              marginBottom: "20px",
            }}
          >
            10. Communications.
          </Box>
          <Box sx={{ fontSize: "16px", lineHeight: 1.5 }}>
            <b>
              You are not required to agree to receive promotional text
              messages, calls or pre-recorded messages as a condition of using
              the Craftle Platform
            </b>
            . By electing to submit your phone number to us and agreeing to
            these Terms, you agree to receive communications from the Craftle
            Entities, including via text messages, calls, pre-recorded messages,
            and push notifications, any of which may be generated by automatic
            telephone dialing systems. These communications include, for
            example, operational communications concerning your account or use
            of the Craftle Platform, updates concerning new and existing
            features on the Craftle Platform, communications concerning
            promotions run by us or third parties, and news relating to the
            Craftle Platform and industry developments. Standard text message
            charges applied by your telephone carrier may apply to text messages
            we send. If you submit someone else’s phone number or email address
            to us to receive communications from the Craftle Entities, you
            represent and warrant that each person for whom you provide a phone
            number or email address has consented to receive communications from
            Craftle.
            <Box sx={{ height: "24px" }}></Box>
            <b>
              If you wish to stop receiving promotional emails or promotional
              text messages, we provide the following methods for you to opt-out
              or unsubscribe: (a) follow the instructions we provide in the
              email or initial text message for that category of promotional
              emails or text messages or (b) if you have an account on the
              Craftle Platform, you may opt-out or unsubscribe using your
              settings.
            </b>
          </Box>

          <Box
            sx={{
              fontSize: "20px",
              lineHeight: 1.5,
              fontWeight: "bold",
              marginTop: "50px",
              marginBottom: "20px",
            }}
          >
            11. Miscellaneous.
          </Box>
          <Box sx={{ fontSize: "16px", lineHeight: 1.5 }}>
            <ul>
              <li>
                <b>Notice for California Users.</b> Under California Civil Code
                Section 1789.3, California users of the Craftle Platform are
                entitled to the following specific consumer rights notice: The
                services are provided by Craftle Inc., 285 Hamilton Avenue, Palo
                Alto, CA 94301. If you have a question or complaint regarding
                the Service, please contact Craftle at help@craftle.app or by
                writing to our mailing address. California residents may reach
                the Consumer Assistance Unit of the Consumer Information
                Boxision of the California Department of Consumer Affairs, which
                may be contacted in writing at 1625 N. Market Blvd., Suite N
                112, Sacramento, California 95834, or by telephone at (800)
                952-5210 or hearing impaired persons may dial 711,
                1-800-735-2929 (TTY), or 1-800-735-2922 (Voice) for California
                Relay Service.
              </li>
              <li>
                <b>Supplemental Terms for Certain Services.</b> Certain services
                offered on the Craftle Platform may require you to enter into a
                separate agreement and/or be subject to additional terms. For
                example: placement of advertisements, participation in brand
                services, offering products for sale in the Craftle Shop, or use
                of Site Designer each require you to enter into a separate
                agreement with terms specific to that service. In the event of
                any conflict between this Agreement and the terms of that
                separate agreement, the terms of this Agreement will control.
              </li>
              <li>
                <b>Application Provider Terms.</b> If you access the Craftle
                Platform through a Craftle application, you acknowledge that
                this Agreement is between you and Craftle only, and not with
                another application service or application platform provider
                (such as Apple, Inc. or Google Inc.), which may provide you the
                application subject to its own terms.
              </li>
              <li>
                <b>Controlling Law and Jurisdiction.</b> This Agreement will be
                interpreted in accordance with the laws of the State of
                California and the United States of America, without regard to
                their conflict-of-law provisions. You and we agree to submit to
                the personal jurisdiction of a federal or state court located in
                San Francisco, California for any actions for which the
                arbitration provision, as set forth in Section 8, does not
                apply.
              </li>
              <li>
                <b>Export.</b> The Craftle Platform is controlled and operated
                from our United States offices in California. Craftle software
                is further subject to United States export controls. No software
                for Craftle may be downloaded or otherwise exported or
                re-exported in violation of any applicable laws or regulations.
                You also represent that you are not (1) located in a country
                that is subject to a U.S. government embargo, and (2) listed on
                any U.S. government list of prohibited or restricted parties.
              </li>
              <li>
                <b>Changes.</b> We reserve the right to:
                <ol type="i">
                  <li>
                    change the terms of this Agreement, consistent with
                    applicable law;
                  </li>
                  <li>
                    change the Craftle Platform, including eliminating or
                    discontinuing any information or services or other features
                    in whole or in part; and
                  </li>
                  <li>
                    deny or terminate your Craftle account, or use of and access
                    to the Craftle Platform.
                  </li>
                </ol>
                If we make material changes to the Terms, we will notify you
                through the Craftle Platform, by email, or by other means, to
                offer you an opportunity to review the changes before they
                become effective. You agree that your continued use of the
                Craftle Platform after such changes become effective constitutes
                your acceptance of the changes. If you do not agree with any
                updates to this Agreement, you may not continue to use the
                Craftle Platform. Be sure to return to this page periodically to
                ensure your familiarity with the most current version of the
                Terms of Use. Any changes to the Terms will be effective on a
                going forward basis.
              </li>
              <li>
                <b>Languages.</b> The English version of this Agreement will be
                the binding version and all communications, notices,
                arbitrations and other actions and proceedings relating to this
                Agreement will be made and conducted in English, even if we
                choose to provide translations of this Agreement into the native
                languages in certain countries. To the extent allowed by law,
                any inconsistencies among the different translations will be
                resolved in favor of the English version.
              </li>
              <li>
                <b>Assignment.</b> No terms of this Agreement, nor any right,
                obligation, or remedy hereunder is assignable, transferable,
                delegable, or sublicensable by you except with Craftle&apos;s
                prior written consent, and any attempted assignment, transfer,
                delegation, or sublicense shall be null and void. Craftle may
                assign, transfer, or delegate this Agreement or any right or
                obligation or remedy hereunder in its sole discretion.
              </li>
              <li>
                <b>Waiver.</b> Our failure to assert a right or provision under
                this Agreement will not constitute a waiver of such right or
                provision.
              </li>
              <li>
                <b>Headings.</b> Any heading, caption, or section title
                contained is inserted only as a matter of convenience and in no
                way defines or explains any section or provision hereof.
              </li>
              <li>
                <b>Further Assurances.</b> You agree to execute a hard copy of
                this Agreement and any other documents, and take any actions at
                our expense that we may request to confirm and effect the intent
                of this Agreement and any of your rights or obligations under
                this Agreement.
              </li>
              <li>
                <b>Entire Agreement and Severability.</b> This Agreement
                supersedes all prior terms, agreements, discussions and writings
                regarding the Craftle Platform and constitutes the entire
                agreement between you and us regarding the Craftle Platform. If
                any part of this Agreement is found to be unenforceable, then
                that part will not affect the enforceability of the remaining
                parts of the Agreement, which will remain in full force and
                effect.
              </li>
              <li>
                <b>Survival.</b> The following provisions will survive
                expiration or termination of this Agreement: Section 2 (Your
                Content), Section 3(d)(Restrictions) and 3(e)(Ownership),
                Section 6 (Disclaimers and Limitations of Liability), Section 7
                (Indemnification), Section 8 (Dispute Resolution) and Section 10
                (Miscellaneous).
              </li>
              <li>
                <b>Contact.</b> Feel free to contact us by visiting
                https://help.craftle.app with any questions about this
                Agreement.
              </li>
            </ul>
          </Box>

          <Box sx={{ fontSize: "16px", lineHeight: 1.5 }}>Craftle Inc.</Box>
        </Grid>
      </Grid>
    </ThemeProvider>
  );
}

export default Terms;
