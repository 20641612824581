import axios from "axios";

// import components
import { EndPoint } from "../../../components/atoms/EndPoint/EndPoint";

const END_POINTS = {
  prod: "https://bftmyfoam8.execute-api.us-east-2.amazonaws.com/default/craftle-favorites-call",
  test: "https://bftmyfoam8.execute-api.us-east-2.amazonaws.com/default/craftle-favorites-call",
  local: "http://127.0.0.1:8080/favorites",
};

export default async function UploadFavImgCallToBackend(formData) {
  try {
    formData.append("purpose", "upload_fav_img");

    const endPoint = END_POINTS[EndPoint] || END_POINTS.prod;
    const response = await axios.post(endPoint, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });

    if (response.data.success) {
      return response;
    } else {
      return null;
    }
  } catch (e) {
    console.log(e.message);
    return null;
  }
}
