import React, { useState, useContext } from "react";
import { Box } from "@mui/material";
import CardMedia from "@mui/material/CardMedia";
import PropTypes from "prop-types";

// import components
import { classes } from "./CMSSwipeImagesStyle";
import mixpanel from "../../../components/atoms/Mixpanel/Mixpanel";
import ContinueSection from "../../../components/molecules/ContinueSection/ContinueSection";
import Button from "../../../components/atoms/Button/Button";
import Text from "../../../components/atoms/Text/Text";
import { UserContext } from "../../../App";

// importing assets
import { ReactComponent as XIconWhite } from "../../../assets/icons/x-white.svg";
import { ReactComponent as HeartIcon } from "../../../assets/icons/heart.svg";
// import { ReactComponent as ExpandIcon } from "../../../assets/icons/expand.svg";
import { ReactComponent as ThumbsUpIcon } from "../../../assets/icons/thumbs-up-white.svg";

// import context
import { Context } from "../../../context/context";

// import utils
import { shuffleArray } from "../../../utils/utils";

// import constants
import { baseUrl } from "../constants";

const labelKey = [
  "bohemian",
  "eclectic",
  "industrial",
  "mid-century-modern",
  "modern",
  "rustic",
  "scandinavian",
  "traditional",
];
const imageNameRange = [...Array(4).keys()];
imageNameRange.shift(); // We don't have an image name at 0 index

function getDistributedDesignStyleImages() {
  /**
   * This function returns a randomized list of images
   */
  let res = [];
  const labelKeyIndexRandomMap = {};

  const shuffledLabelKeys = shuffleArray(labelKey);
  shuffledLabelKeys.forEach((labelName) => {
    const shuffledRangeVals = shuffleArray(imageNameRange);
    labelKeyIndexRandomMap[labelName] = [...shuffledRangeVals];
  });

  [...Array(3).keys()].forEach((idx) => {
    shuffledLabelKeys.forEach((designName) => {
      const currRandomIndex = labelKeyIndexRandomMap[designName][idx];
      let imageUrl =
        baseUrl + designName + "/" + currRandomIndex.toString() + ".png";
      res.push({
        imageUrl: imageUrl,
        label: designName + currRandomIndex.toString(),
        labelKey: designName,
      });
    });
  });

  return res;
}
const images = getDistributedDesignStyleImages();

function CMSSwipeImages({ onNextPress }) {
  const [, globalDispatch] = useContext(Context);
  const [currIndex, setIndex] = useState(0);
  const [numberOfSwipe, updateNumberOfSwipe] = useState(0);
  const [showInfo, toggleShowInfo] = useState(true);

  const { user } = React.useContext(UserContext);
  const email = user?.email || "N/A";

  function likedClicked(labelKey, liked) {
    globalDispatch({
      type: "UPDATE_DESIGN_WEIGHTS_VALUE",
      designKey: labelKey,
      liked: liked,
    });
  }

  function enoughCardsSwiped() {
    return (
      <Box
        // "WE HAVE ENOUGH" CARD NOTIFICATION STARTS HERE
        sx={classes.enoughCardsContainer}
      >
        <Box sx={classes.enoughCardsInnerContainer}>
          <CardMedia
            component="img"
            sx={{ ...classes.cardImageMediaStyle, backgroundColor: "#4CC349" }}
          />
          <Box sx={classes.enoughCardsContentContainer}>
            <Box sx={classes.thumbsUpContainer}>
              <ThumbsUpIcon />
            </Box>
            <Text color="secondary" sx={classes.weHaveEnoughText}>
              We have enough to create your plan! <br />
              You can still rate designs or press Continue to move forward.
            </Text>
            <Button
              onClick={() => {
                toggleShowInfo(false);
              }}
              sx={classes.selectMoreButton}
            >
              I want to select more
            </Button>
          </Box>
        </Box>
      </Box>
    );
  }

  return (
    <Box sx={classes.container}>
      <Box
        // CARDS START HERE
        sx={classes.cardContainer}
      >
        {/** This is the image box for the swipe */}
        {currIndex < images.length && (
          <Box sx={classes.imageBox}>
            {numberOfSwipe == 10 && showInfo ? (
              enoughCardsSwiped()
            ) : (
              <>
                <CardMedia
                  component="img"
                  sx={classes.cardImageMediaStyle}
                  image={images[currIndex].imageUrl}
                  alt={images[currIndex].labelKey}
                />

                <Box sx={classes.contentPositioning}>
                  <Box
                    sx={classes.xIconButton}
                    onClick={() => {
                      setIndex(currIndex + 1);
                      likedClicked(images[currIndex].labelKey, false);
                      updateNumberOfSwipe(numberOfSwipe + 1);
                    }}
                  >
                    <XIconWhite />
                  </Box>

                  <Box
                    sx={classes.heartIconBtn}
                    onClick={() => {
                      setIndex(currIndex + 1);
                      likedClicked(images[currIndex].labelKey, true);
                      updateNumberOfSwipe(numberOfSwipe + 1);
                    }}
                  >
                    <HeartIcon />
                  </Box>
                </Box>
              </>
            )}
          </Box>
        )}
      </Box>

      <ContinueSection
        position="sticky"
        onClick={() => {
          mixpanel.track("(CMS) Selected Images", {
            email: email,
          });
          onNextPress(null, null);
        }}
        buttonText={
          numberOfSwipe < 10
            ? `Must Select ${10 - numberOfSwipe} More Times`
            : "Continue"
        }
        disabled={numberOfSwipe < 10}
      />
    </Box>
  );
}

const propTypes = {
  propName: PropTypes.string.isRequired,
  onNextPress: PropTypes.func.isRequired,
  callAlert: PropTypes.func,
};

const defaultProps = {
  callAlert: () => {},
};

CMSSwipeImages.propTypes = propTypes;
CMSSwipeImages.defaultProps = defaultProps;

export default CMSSwipeImages;
