import { customTheme } from "../../atoms/CustomTheme/CustomTheme";

export const classes = {
  categoryContainer: {
    marginTop: "24px",
    [customTheme.breakpoints.down("md")]: {},
    [customTheme.breakpoints.down("sm")]: {},
  },
  imgContainer: {
    position: "relative",
  },
  favoriteContainer: {
    width: "40px",
    height: "40px",
    backgroundColor: "rgba(191, 191, 191, 0.5)",
    borderRadius: "20px",
    position: "absolute",
    zIndex: 1,
    right: "16px",
    top: "16px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer",
    "&:hover": {
      backgroundColor: "rgba(191, 191, 191, 0.4)",
    },
  },
  favoriteIcon: {
    width: "24px",
    height: "24px",
  },
  categoryImage: {
    borderRadius: "16px",
    maxHeight: "700px",
  },
  itemInfo: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
  brand: {
    marginTop: "8px",
    color: "#757575",
    fontWeight: "bold",
  },
  title: {
    marginTop: "8px",
    fontWeight: 500,
    fontSize: "24px",
    lineHeight: "120%",
  },
  price: {
    marginTop: "8px",
    fontSize: "32px",
    fontWeight: "bold",
  },
  dimensions: {
    marginTop: "8px",
  },
  button: {
    marginTop: "16px",
    fontWeight: "bold",
    backgroundColor: "#FFE9AF",
  },
};
