import * as React from "react";
import { Box } from "@mui/material";
import PropTypes from "prop-types";
import { createTheme } from "@mui/material/styles";

const propTypes = {
  marginTop: PropTypes.string,
  height: PropTypes.string,
};

const defaultProps = {
  marginTop: "260vh",
  height: "300vh",
};

BlogParallaxLayerMiddle.propTypes = propTypes;
BlogParallaxLayerMiddle.defaultProps = defaultProps;

let customTheme = createTheme({});

export default function BlogParallaxLayerMiddle({ marginTop, height }) {
  return (
    <Box
      sx={{
        position: "absolute",
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
        transform: "translateZ(-1px) scale(2)",
        backgroundColor: "#FFFFFF",
        zIndex: 3,
        marginTop: { marginTop },
        height: { height },
        [customTheme.breakpoints.down("md")]: {
          display: "none",
        },
      }}
    />
  );
}
