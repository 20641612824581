import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { Box, CircularProgress } from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import "./SaveNotification.css";
import { classes } from "./SaveNotificationStyle";

const SaveNotification = ({ message, type }) => {
  const notificationBoxRef = useRef(null);
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    if (type === "saving" || type === "saved") {
      setVisible(true);
    }
  }, [type]);

  useEffect(() => {
    const notificationBox = notificationBoxRef.current;

    if (!notificationBox) return;

    const handleAnimationEnd = () => {
      if (type === "end") {
        setVisible(false);
      } else if (type === "saved") {
        notificationBox.style.animation = "";
      }
    };

    notificationBox.addEventListener("animationend", handleAnimationEnd);

    if (type === "saving") {
      notificationBox.style.animation = "slideDown 0.5s ease-out";
    } else if (type === "end") {
      notificationBox.style.animation = "slideUp 0.5s ease-out";
    }

    return () => {
      notificationBox.removeEventListener("animationend", handleAnimationEnd);
    };
  }, [type, visible]);

  if (!visible) return null;

  return (
    <Box
      ref={notificationBoxRef}
      sx={{
        backgroundColor: type === "saving" ? "#fffae6" : "#e6fffa",
        ...classes.container,
      }}
    >
      {type === "saving" ? (
        <CircularProgress size={20} />
      ) : (
        <CheckCircleIcon
          fontSize="small"
          style={{ fontSize: 20, color: "green" }}
        />
      )}
      <span>{message}</span>
    </Box>
  );
};

SaveNotification.propTypes = {
  message: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
};

export default SaveNotification;
