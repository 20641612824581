import React from "react";
import Footer from "../../components/organisms/Footer/Footer";
import CardMedia from "@mui/material/CardMedia";

// import components
import BlogParallaxRoot from "../../components/molecules/BlogParallaxRoot/BlogParallaxRoot";
import BlogParallaxLayerBack from "../../components/molecules/BlogParallaxLayerBack/BlogParallaxLayerBack";
import BlogParallaxLayerMiddle from "../../components/molecules/BlogParallaxLayerMiddle/BlogParallaxLayerMiddle";
import BlogParallaxLayerFront from "../../components/molecules/BlogParallaxLayerFront/BlogParallaxLayerFront";
import BlogContentContainer from "../../components/molecules/BlogContentContainer/BlogContentContainer";
import BlogDate from "../../components/molecules/BlogDate/BlogDate";
import BlogTitle from "../../components/molecules/BlogTitle/BlogTitle";
import BlogParagraph from "../../components/molecules/BlogParagraph/BlogParagraph";
import BlogH2 from "../../components/molecules/BlogH2/BlogH2";
import BlogImage from "../../components/molecules/BlogImage/BlogImage";
import BlogInlineAd1 from "../../components/molecules/BlogInlineAd1/BlogInlineAd1";
import BlogInlineAd2 from "../../components/molecules/BlogInlineAd2/BlogInlineAd2";

// import images
const bannerImage =
  "https://craftle-static.s3.us-east-2.amazonaws.com/blog/blog3/blog-3-banner.jpg";
const image1 =
  "https://craftle-static.s3.us-east-2.amazonaws.com/blog/blog3/blog-3-image-1.jpg";
const image2 =
  "https://craftle-static.s3.us-east-2.amazonaws.com/blog/blog3/blog-3-image-2.jpg";
const image3 =
  "https://craftle-static.s3.us-east-2.amazonaws.com/blog/blog3/blog-3-image-3.jpg";
const image4 =
  "https://craftle-static.s3.us-east-2.amazonaws.com/blog/blog3/blog-3-image-4.jpg";
const image5 =
  "https://craftle-static.s3.us-east-2.amazonaws.com/blog/blog3/blog-3-image-5.jpg";
const image6 =
  "https://craftle-static.s3.us-east-2.amazonaws.com/blog/blog3/blog-3-image-6.jpg";
const image7 =
  "https://craftle-static.s3.us-east-2.amazonaws.com/blog/blog3/blog-3-image-7.jpg";
const image8 =
  "https://craftle-static.s3.us-east-2.amazonaws.com/blog/blog3/blog-3-image-8.jpg";
const image9 =
  "https://craftle-static.s3.us-east-2.amazonaws.com/blog/blog3/blog-3-image-9.jpg";

function BlogPost3() {
  return (
    <BlogParallaxRoot>
      <BlogParallaxLayerBack>
        <CardMedia component="img" image={bannerImage} alt="" />
      </BlogParallaxLayerBack>

      <BlogParallaxLayerMiddle marginTop="440vh" height="660vh" />

      <BlogParallaxLayerFront>
        <BlogContentContainer>
          <BlogDate date="May 6, 2022" />
          <BlogTitle title="Interior Design vs Interior Architect: What's the Difference" />
          <BlogParagraph>
            Interior design and interior architecture are two very different
            disciplines when it comes to creating a functional and aesthetically
            pleasing space.
          </BlogParagraph>
          <BlogParagraph>
            Interior designers usually focus on the aesthetics of a space, while
            interior architects tend to focus more on the functionality and how
            the spaces can be used. Let’s take a closer look at the differences
            between these two professions.
          </BlogParagraph>

          <BlogImage image={image1} alt="" caption="" />
          <BlogH2 title="What is Interior Design?" />
          <BlogParagraph>
            Design is both an art and a discipline. It’s all about creating an
            aesthetically-pleasing interior of a building while also making the
            interior functional to use or be inside.
          </BlogParagraph>
          <BlogParagraph>
            There’s a lot that goes into interior design, including choosing the
            right colors, textures, materials, textiles, and sizes for the
            furniture and objects in a room. Not only that, but nowadays the
            sustainability of materials is also an important factor. And along
            with needing to take into account the architecture of the building,
            building, and safety requirements, interior design is no easy
            subject matter to master.
          </BlogParagraph>
          <BlogParagraph>
            Interior designing is often a collaborative process. Not only does
            it involve interior designers, but those interior designers need to
            work with other people, such as architects, engineers, contractors,
            businesses, and homeowners.
          </BlogParagraph>

          <BlogImage image={image2} alt="" caption="" />
          <BlogH2 title="Difference Between Interior Designer and Decorator" />
          <BlogParagraph>
            Interior designers and interior decorators are often confused with
            each other, but there are some key differences that make a
            difference between the two.
          </BlogParagraph>
          <BlogParagraph>
            Interior designers need to know about a building’s architecture.
            That’s because they’re helping to design not only the furniture and
            decorations of a room but also the structure of the room itself.
            This can mean moving, adding, or subtracting columns, windows,
            walls, ceilings, and more. They may work with an architect to
            coordinate the process. On top of that, interior designers pick and
            choose all the pieces that will go inside the room.
          </BlogParagraph>
          <BlogParagraph>
            Unlike interior designers, an interior decorator doesn’t get
            involved with changing the structure of a room. Their only focus is
            to decorate the room as best as possible. This means finding
            artwork, furniture, and accessories for the room.
          </BlogParagraph>
          <BlogParagraph>
            To put it a different way, an interior designer will do the job of
            an interior decorator and then some more.
          </BlogParagraph>

          <BlogInlineAd1 />

          <BlogImage image={image3} alt="" caption="" />
          <BlogH2 title="Interior Design Consultant vs Interior Designer" />
          <BlogParagraph>
            An interior design consultant is not an interior designer. They’re
            different because they offer the same services as an interior
            designer but do not have the same level of knowledge and experience
            in the field.
          </BlogParagraph>
          <BlogParagraph>
            An interior designer is a professional who specializes in the design
            of interiors, space planning, furniture arranging, and selection.
            Interior designers work with clients to create environments that
            aesthetically satisfy their needs. They might also collaborate with
            architects and contractors on the construction of a new building or
            the remodeling of an existing one.
          </BlogParagraph>
          <BlogParagraph>
            A residential interior designer can design space for a home or
            develop a new one, depending on the client’s needs. They might also
            design and execute designs for restaurants, hotels, retail stores,
            and other commercial locations. Professionals who work in these
            fields are often known as interior specialists or space planners.
          </BlogParagraph>
          <BlogParagraph>
            On the other hand, an interior design consultant can help people
            plan their remodeling projects and provide inspiration for the
            design of their homes. They can also help people find style ideas
            and solutions for their lifestyle and budget. Interior design
            consultants often work with architects and designers on a full-time
            basis for a business or for their clients. They might also work
            part-time as independent contractors or occasionally as in-house
            employees at luxury hotels, private residences, and corporate
            headquarters.
          </BlogParagraph>

          <BlogInlineAd1 />

          <BlogImage image={image4} alt="" caption="" />
          <BlogH2 title="Interior Architecture" />
          <BlogParagraph>
            Interior architecture is the art and science of designing spaces
            that are both functional and aesthetically pleasing.
          </BlogParagraph>
          <BlogParagraph>
            Interior architects are responsible for the design of a building’s
            interior, paying particular attention to detail in decoration,
            furniture arrangement, lighting, color schemes, and textures. They
            work with clients to specify the scope of work and make sure that
            the design meets their needs.
          </BlogParagraph>

          <BlogImage image={image5} alt="" caption="" />
          <BlogH2 title="Architectural Designer vs Interior Architect" />
          <BlogParagraph>
            An architectural designer is a professional who designs the overall
            structure of a building. They take into account the appearance,
            function, and safety of the building when they create their designs.
          </BlogParagraph>
          <BlogParagraph>
            An interior architect, on the other hand, focuses on the design of
            the interior space of a building.
          </BlogParagraph>

          <BlogImage image={image6} alt="" caption="" />
          <BlogH2 title="Interior Design vs Interior Architect" />
          <BlogParagraph>
            At its most basic level, interior design and interior architecture
            are both focused on creating functional, aesthetically pleasing
            spaces. While there are some important differences between the two
            fields, they do have several key elements in common. For example,
            both require a deep understanding of various materials and building
            techniques, as well as a keen eye for aesthetics.
          </BlogParagraph>
          <BlogParagraph>
            However, interior designers typically focus more on style and
            conceptual design, while architects tend to be more concerned with
            issues like structural integrity and code compliance. Additionally,
            interior designers may work primarily with commercial or residential
            clients, while architects often focus on complex institutional or
            public buildings.
          </BlogParagraph>
          <BlogParagraph>
            Ultimately, though there are differences between interior design and
            interior architecture, these fields share the common goal of
            creating beautiful living spaces that are functional as well as
            aesthetically pleasing.
          </BlogParagraph>

          <BlogImage image={image7} alt="" caption="" />
          <BlogH2 title="Can You Call Yourself an Interior Designer Without a Degree?" />
          <BlogParagraph>
            Whether or not you need a degree to become an interior designer is a
            topic of much debate in the design world. Some professionals believe
            that formal training and education are essential for success in this
            field, while others believe that on-the-job experience and
            self-taught skills are enough.
          </BlogParagraph>
          <BlogParagraph>
            In general, most interior designers do have at least some formal
            training or education in the field. This may come in the form of a
            bachelor’s or master’s degree from an accredited university, as well
            as professional certification or accreditation through industry
            associations.
          </BlogParagraph>
          <BlogParagraph>
            However, there are many successful designers who do not hold a
            degree in interior design. These individuals typically acquire their
            skills and knowledge by working with experienced professionals.
          </BlogParagraph>

          <BlogImage image={image8} alt="" caption="" />
          <BlogH2 title="Designing Interior Spaces" />
          <BlogParagraph>
            Designing interior spaces is all about creating a functional and
            visually appealing space.
          </BlogParagraph>
          <BlogParagraph>
            The first step is to determine the purpose of the space. Is it a
            living room where people will gather to relax and socialize? A
            kitchen where family members will prepare meals and eat together? Or
            a bedroom where you can retreat to at the end of the day? Once you
            know how the space will be used, you can start to think about the
            layout.
          </BlogParagraph>
          <BlogParagraph>
            For example, in a living room, you might want to create separate
            areas for conversation and TV watching. In a kitchen, you’ll need to
            account for major appliances, work surfaces, and storage. And in a
            bedroom, you’ll need to consider the placement of the bed, dresser,
            and other furniture. Once you have a general idea of the layout, you
            can start to think about finishes like flooring, wall coverings, and
            window treatments.
          </BlogParagraph>
          <BlogParagraph>
            With careful planning, designing interior spaces can be both fun and
            rewarding!
          </BlogParagraph>

          <BlogImage image={image9} alt="" caption="" />
          <BlogH2 title="Wrapping It Up" />
          <BlogParagraph>
            Although interior design and architecture may share some
            similarities, it is important to understand their differences.
            Interior designers are responsible for the aesthetics of a space,
            while architects are more concerned with the structural integrity
            and functionality of a building or space. Architects also have more
            training in engineering principles than interior designers.
          </BlogParagraph>
          <BlogParagraph>
            If you’re interested in pursuing either career path, be sure to do
            your research and understand what each profession entails.
          </BlogParagraph>

          <BlogInlineAd2 />
        </BlogContentContainer>
        <Footer />
      </BlogParallaxLayerFront>
    </BlogParallaxRoot>
  );
}

export default BlogPost3;
