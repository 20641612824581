import React, { useState, useEffect, useContext } from "react";
import { Box, Slider, TextField } from "@mui/material";
import { createTheme, styled } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import PropTypes from "prop-types";
import NumberFormat from "react-number-format";
import { AnalyticsBrowser } from "@segment/analytics-next";

// import components
import mixpanel from "../../../components/atoms/Mixpanel/Mixpanel";
import ContinueSection from "../../../components/molecules/ContinueSection/ContinueSection";
import Text from "../../../components/atoms/Text/Text";
import Loading from "../../../components/atoms/Loading/Loading";
import { segmentWriteKey } from "../../../components/atoms/Segment/Segment";

// import context
import { UserContext } from "../../../App";

// import utils
import { calculateMinMaxCostForBudgets } from "../../../utils/utils";

// import global store
import { Context } from "../../../context/context";

const customTheme = createTheme({});
const analytics = AnalyticsBrowser.load({ writeKey: segmentWriteKey });
const CraftleSlider = styled(Slider)({
  color: "#2A2A2A",
  height: 8,
  "& .MuiSlider-track": {
    border: "none",
  },
  "& .MuiSlider-thumb": {
    height: 50,
    width: 50,
    backgroundColor: "#fff",
    border: "12px solid currentColor",
    "&:focus, &:hover, &.Mui-active, &.Mui-focusVisible": {
      boxShadow: "inherit",
    },
    "&:before": {
      display: "none",
    },
  },
});

function CMSBudget({ propName, onNextPress }) {
  const [globalState] = useContext(Context);
  const [budget, setBudget] = useState(globalState.budget);
  const [isLoading, setIsLoading] = useState(true);
  const [minimumBudget, setMinimumBudget] = useState(0);
  const [maximumBudget, setMaximumBudget] = useState(1000);

  const { user } = React.useContext(UserContext);
  const email = user?.email || "N/A";

  function getMinMaxBudgetCall() {
    const minMaxBudgetObj = calculateMinMaxCostForBudgets({ ...globalState });
    const roundedMinBudget =
      minMaxBudgetObj.minBudget + 20 - (minMaxBudgetObj.minBudget % 10);

    setMinimumBudget(roundedMinBudget);
    setMaximumBudget(minMaxBudgetObj.maxBudget);

    const minBudget = roundedMinBudget + 100 - (roundedMinBudget % 100);

    setBudget(minBudget);
    setIsLoading(false);
  }

  useEffect(() => {
    getMinMaxBudgetCall();
  }, []);

  const parentBoxStyle = {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    height: "100vh",
    [customTheme.breakpoints.down("md")]: {
      height: "inherit",
      paddingTop: "80px",
    },
    [customTheme.breakpoints.down("sm")]: {
      paddingTop: "40px",
    },
  };

  const isPhoneScreen = useMediaQuery(customTheme.breakpoints.down("sm"));

  return isLoading ? (
    <Box sx={parentBoxStyle}>
      <Loading />
    </Box>
  ) : (
    <Box sx={parentBoxStyle}>
      <Box
        sx={{
          width: "100%",
          marginBottom: "100px",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          [customTheme.breakpoints.down("sm")]: {
            marginBottom: "67px",
          },
        }}
      >
        <NumberFormat
          variant="standard"
          InputProps={{
            disableUnderline: true,
            inputProps: {
              style: {
                textAlign: "center",
                marginBottom: "10px",
                fontWeight: "bold",
                lineHeight: 1.5,
                fontSize: isPhoneScreen ? "30px" : "50px",
              },
            },
          }}
          customInput={TextField}
          thousandSeparator={true}
          prefix={"$"}
          value={budget}
          isAllowed={(values) => {
            const { formattedValue, value } = values;
            const floatValue = parseInt(value, 10);

            return formattedValue === "" || floatValue <= maximumBudget;
          }}
          onValueChange={(values) => {
            const { value } = values;
            const floatValue = parseInt(value, 10);
            if (value === "") {
              setBudget(0);
              return;
            }
            if (floatValue <= maximumBudget) {
              setBudget(floatValue);
            }
          }}
        />

        <Box
          sx={{
            width: "80%",
            maxWidth: "465px",
            paddingBottom: "24px",
          }}
        >
          <CraftleSlider
            value={budget}
            min={minimumBudget}
            max={maximumBudget}
            step={10}
            onChange={(event, value) => {
              setBudget(value);
            }}
          />
        </Box>

        <Text
          sx={{
            color: "#757575",
            fontSize: "16px",
            textAlign: "center",
            fontWeight: "bold",
            lineHeight: 1.5,
          }}
        >
          Minimum budget is $
          {parseInt(minimumBudget, 10).toLocaleString("en-US")}
        </Text>
      </Box>

      <ContinueSection
        position="relative"
        buttonText="Show Recommendations"
        disabled={budget < minimumBudget}
        onClick={() => {
          analytics.track("Budget Declared", {
            user_email: user?.email,
            user_name: user?.first_name,
            flow: "Craft My Style",
            budget_amount: budget,
          });
          mixpanel.track("(CMS) Selected Budget", {
            email: email,
            budget_amount: budget,
          });
          onNextPress(propName, budget);
        }}
      />
    </Box>
  );
}

const propTypes = {
  propName: PropTypes.string.isRequired,
  onNextPress: PropTypes.func.isRequired,
  callAlert: PropTypes.func,
};

const defaultProps = {
  callAlert: () => {},
};

CMSBudget.propTypes = propTypes;
CMSBudget.defaultProps = defaultProps;

export default CMSBudget;
