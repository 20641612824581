export default async function BrightDataCallToBackend(url) {
  return new Promise((resolve, reject) => {
    const ws = new WebSocket(
      "wss://h4lnzz91ri.execute-api.us-east-2.amazonaws.com/production/"
    );
    let receivedChunks = [];
    let totalChunks = 0;

    ws.onopen = () => {
      const message = { action: "startCrawl", body: { url: url } };
      ws.send(JSON.stringify(message));
    };

    ws.onmessage = (event) => {
      const chunk = JSON.parse(event.data);

      if (chunk.chunkIndex !== undefined) {
        // Handle chunked data
        receivedChunks[chunk.chunkIndex] = chunk.data;

        if (totalChunks === 0) {
          totalChunks = chunk.totalChunks;
        }

        if (receivedChunks.filter(Boolean).length === totalChunks) {
          // All chunks have been received
          const completeData = receivedChunks.join("");
          // Handle the complete data
          resolve(completeData);
        }
      } else if (chunk.user_token) {
        // Handle other types of messages
        resolve(chunk.user_token);
        ws.close();
      }
    };

    ws.onclose = (event) => {
      console.log("Disconnected:", event);
    };

    ws.onerror = (error) => {
      console.error("WebSocket error:", error);
      reject(error);
    };
  });
}

// ----------------------------------- //
// Below can be used for local testing
// ----------------------------------- //

// import axios from "axios";

// // import components
// import { EndPoint } from "../../../components/atoms/EndPoint/EndPoint";

// const endPoints = {
//   prod: "https://la27cbl255.execute-api.us-east-2.amazonaws.com/default/craftle-lookalike-finder",
//   //   test: "https://wsr62w4eqb.execute-api.us-east-2.amazonaws.com/default/craftle-new-backend-for-testing",
//   local: "http://127.0.0.1:8080/lookalike-finder-calls",
// };

// export default async function BrightDataCallToBackend(urlInput) {
//   const endPointUrl = endPoints[EndPoint];

//   if (!endPointUrl) {
//     throw new Error(`Endpoint for ${EndPoint} is not defined.`);
//   }

//   try {
//     const response = await axios.post(
//       endPointUrl,
//       {
//         purpose: "bright_data_calling",
//         url: urlInput,
//       },
//       {
//         headers: {
//           "Content-Type": "application/json",
//         },
//       }
//     );

//     if (response?.data) {
//       console.log("ExistingCrateAndBarrelItemCall response:", response);

//       // Check if 'body' is present and is a string
//       if (response.data.body && typeof response.data.body === "string") {
//         const parsedBody = JSON.parse(response.data.body);
//         console.log("parsedBody:", parsedBody);
//         return parsedBody;
//       } else {
//         console.error("Unexpected response format:", response.data);
//         return {};
//       }
//     } else {
//       console.error("Scrape operation failed:", response.data?.body);
//       return {};
//     }
//   } catch (error) {
//     console.error("Error during the request:", error.message);
//     return {};
//   }
// }
