import React from "react";
import { createTheme, ThemeProvider, Typography } from "@mui/material";
import PropTypes from "prop-types";

const propTypes = {
  onClick: PropTypes.func,
  children: PropTypes.node,
  variant: PropTypes.string,
  sx: PropTypes.object,
};

const defaultProps = {
  onClick: null,
  children: null,
  variant: "body1",
  sx: {},
};

const customTheme = createTheme({});

function CraftleText({ onClick, children, variant, sx }) {
  const commonHeaderStyles = {
    fontWeight: "bold",
    lineHeight: 1.2,
    color: "#2a2a2a",
    fontFamily: "Poppins",
  };

  const commonBodyStyles = {
    lineHeight: 1.5,
    color: "#2A2A2A",
  };

  const combinedSx =
    typeof variant === "string" && variant.startsWith("h")
      ? { ...commonHeaderStyles, ...sx }
      : { ...commonBodyStyles, ...sx };

  return (
    <ThemeProvider theme={customTheme}>
      <Typography variant={variant} onClick={onClick} sx={combinedSx}>
        {children}
      </Typography>
    </ThemeProvider>
  );
}

CraftleText.propTypes = propTypes;
CraftleText.defaultProps = defaultProps;

export default CraftleText;
