import * as React from "react";
import { Box } from "@mui/material";
import PropTypes from "prop-types";
import { createTheme } from "@mui/material/styles";

const propTypes = {
  children: PropTypes.node,
};

const defaultProps = {
  children: null,
};

BlogParallaxLayerFront.propTypes = propTypes;
BlogParallaxLayerFront.defaultProps = defaultProps;

let customTheme = createTheme({});

export default function BlogParallaxLayerFront({ children }) {
  return (
    <Box
      sx={{
        position: "absolute",
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
        transform: "translateZ(0)",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        zIndex: 4,
        marginTop: "60vh",
        [customTheme.breakpoints.down("md")]: {
          position: "inherit",
          marginTop: "50%",
        },
      }}
    >
      {children}
    </Box>
  );
}
