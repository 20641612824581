import { customTheme } from "../../../components/atoms/CustomTheme/CustomTheme";

export const classes = {
  // guestModeNotification function styles
  container: {
    boxShadow: "none",
    borderRadius: 0,
    overflow: "visible",
    marginTop: "10px",
    marginRight: "8px",
    cursor: "pointer",
    [customTheme.breakpoints.down("md")]: {
      marginTop: 0,
      marginLeft: "8px",
    },
  },
  innerContainer: {
    position: "relative",
  },
  priceContainer: {
    position: "absolute",
    bottom: "16px",
    left: 0,
    padding: "8px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "rgba(255, 255, 255, 0.75)",
    borderRadius: "4px",
    // [customTheme.breakpoints.down("md")]: {
    //   width: "26vw",
    //   height: "26vw",
    //   backgroundColor: "inherit",
    // },
    // [customTheme.breakpoints.down("sm")]: {
    //   width: "45vw",
    //   height: "45vw",
    //   borderRadius: "10px",
    //   backgroundColor: "inherit",
    // },
  },
};
