import axios from "axios";

// import components
import { EndPoint } from "../../../components/atoms/EndPoint/EndPoint";

const END_POINTS = {
  prod: "https://bftmyfoam8.execute-api.us-east-2.amazonaws.com/default/craftle-favorites-call",
  test: "https://bftmyfoam8.execute-api.us-east-2.amazonaws.com/default/craftle-favorites-call",
  local: "http://127.0.0.1:8080/favorites",
};

export default async function DeleteFavoriteCallToBackend(
  email,
  favoritesList
) {
  const responseFromAWS = await (async () => {
    try {
      const response = await axios.post(END_POINTS[EndPoint], {
        request_method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        post_data: JSON.stringify({
          purpose: "replace_fav_list",
          email: email,
          favorites_list: favoritesList,
        }),
      });
      return response;
    } catch (e) {
      console.log(e.message);
    }
  })();

  return responseFromAWS?.data?.body?.success
    ? responseFromAWS.data.body.user_token
    : null;
}
