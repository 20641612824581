import React, { useState } from "react";
import { Box } from "@mui/material";
import CardMedia from "@mui/material/CardMedia";

// import components
import Button from "../../components/atoms/Button/Button";
import Text from "../../components/atoms/Text/Text";
import { customTheme } from "../../components/atoms/CustomTheme/CustomTheme";

// import icons
const CommentBox =
  "https://craftle-static.s3.us-east-2.amazonaws.com/lookalike-finder-page/message-square-yellow.svg";
const X = "https://craftle-static.s3.us-east-2.amazonaws.com/icons/x.svg";

export default function PayForFeedback() {
  const [closeFixedFeedback, setCloseFixedFeedback] = useState("flex");

  let classes = {
    // $20 for feedback button
    payForFeedbackContainer: {
      position: "fixed",
      right: "-2px",
      bottom: "80px",
      zIndex: 100,
      display: closeFixedFeedback,
      flexDirection: "row",
      alignItems: "center",
      backgroundColor: "#FFF",
      padding: "12px 8px 12px 16px",
      border: "solid 2px #BFBFBF",
      borderRadius: "8px 0 0 8px",
      [customTheme.breakpoints.down("md")]: {
        display: "none",
      },
    },
    payForFeedbackLink: {
      padding: 0,
      [customTheme.breakpoints.down("md")]: {
        display: "none",
      },
    },
    payForFeedbackInnerContainer: {
      backgroundColor: "#FFF",
      display: "flex",
      flexDirection: "row",
      [customTheme.breakpoints.down("md")]: {
        display: "none",
      },
    },
    helpText: {
      color: "#2a2a2a",
      fontWeight: "bold",
    },
    commentBox: {
      width: "24px",
      height: "24px",
      marginLeft: "8px",
    },
    payForFeedbackCloseButton: {
      backgroundColor: "transparent",
      border: 0,
      cursor: "pointer",
    },
  };

  return (
    <Box sx={{ ...classes.payForFeedbackContainer, display: "none" }}>
      <Button
        href="https://docs.google.com/forms/d/e/1FAIpQLSfizvrrD9EsD1cn5zIQXbjtCQWwjcwjr88_4VKxNKLTVYfqiw/viewform"
        target="_blank"
        rel="noreferrer"
        sx={classes.payForFeedbackLink}
      >
        <Box sx={classes.payForFeedbackInnerContainer}>
          <Text sx={classes.helpText}>Get $20 for giving feedback</Text>
          <CardMedia
            component="img"
            image={CommentBox}
            alt=""
            sx={classes.commentBox}
          />
        </Box>
      </Button>
      <button
        onClick={() => {
          setCloseFixedFeedback("none");
        }}
        style={classes.payForFeedbackCloseButton}
      >
        <CardMedia component="img" image={X} alt="" />
      </button>
    </Box>
  );
}
