import React from "react";
import { Route } from "react-router-dom";
import { Helmet } from "react-helmet";

// import components
import { MoodboardPage, ProductPage } from "../pages/index.jsx";
import SeeMore from "../pages/seeMore/SeeMore";
import WebAppBar from "../components/organisms/WebAppBar/WebAppBar";
import Footer from "../components/organisms/Footer/Footer";

// import images
const PopularIMG1 =
  "https://craftle-static.s3.us-east-2.amazonaws.com/landing-page/popular-1.jpeg";
const PopularIMG2 =
  "https://craftle-static.s3.us-east-2.amazonaws.com/landing-page/popular-2.jpeg";
const PopularIMG3 =
  "https://craftle-static.s3.us-east-2.amazonaws.com/landing-page/popular-3.jpeg";
const PopularIMG4 =
  "https://craftle-static.s3.us-east-2.amazonaws.com/landing-page/popular-4.jpeg";
const PopularIMG5 =
  "https://craftle-static.s3.us-east-2.amazonaws.com/landing-page/popular-5.jpeg";
const PopularIMG6 =
  "https://craftle-static.s3.us-east-2.amazonaws.com/landing-page/popular-6.jpeg";
const PopularIMG7 =
  "https://craftle-static.s3.us-east-2.amazonaws.com/landing-page/popular-7.jpeg";
const PopularIMG8 =
  "https://craftle-static.s3.us-east-2.amazonaws.com/landing-page/popular-8.jpeg";
const PopularIMG9 =
  "https://craftle-static.s3.us-east-2.amazonaws.com/landing-page/popular-9.jpeg";
const PopularIMG10 =
  "https://craftle-static.s3.us-east-2.amazonaws.com/landing-page/popular-10.jpeg";
const PopularIMG11 =
  "https://craftle-static.s3.us-east-2.amazonaws.com/landing-page/popular-11.jpeg";
const PopularIMG12 =
  "https://craftle-static.s3.us-east-2.amazonaws.com/landing-page/popular-12.jpeg";
const PopularIMG13 =
  "https://craftle-static.s3.us-east-2.amazonaws.com/landing-page/popular-13.jpeg";
const PopularIMG14 =
  "https://craftle-static.s3.us-east-2.amazonaws.com/landing-page/popular-14.jpeg";
const PopularIMG15 =
  "https://craftle-static.s3.us-east-2.amazonaws.com/landing-page/popular-15.jpeg";
const BTSIMG1 =
  "https://craftle-static.s3.us-east-2.amazonaws.com/landing-page/bts-1.jpeg";
const BTSIMG2 =
  "https://craftle-static.s3.us-east-2.amazonaws.com/landing-page/bts-2.jpeg";
const BTSIMG3 =
  "https://craftle-static.s3.us-east-2.amazonaws.com/landing-page/bts-3.jpeg";
const BTSIMG4 =
  "https://craftle-static.s3.us-east-2.amazonaws.com/landing-page/bts-4.jpeg";
const BTSIMG5 =
  "https://craftle-static.s3.us-east-2.amazonaws.com/landing-page/bts-5.jpeg";
const BTSIMG6 =
  "https://craftle-static.s3.us-east-2.amazonaws.com/landing-page/bts-6.jpeg";
const BTSIMG7 =
  "https://craftle-static.s3.us-east-2.amazonaws.com/landing-page/bts-7.jpeg";
const BTSPI1_1 =
  "https://craftle-static.s3.us-east-2.amazonaws.com/landing-page/bts-product-1-1.jpeg";
const BTSPI1_2 =
  "https://craftle-static.s3.us-east-2.amazonaws.com/landing-page/bts-product-1-2.jpeg";
const BTSPI2_1 =
  "https://craftle-static.s3.us-east-2.amazonaws.com/landing-page/bts-product-2-1.jpeg";
const BTSPI2_2 =
  "https://craftle-static.s3.us-east-2.amazonaws.com/landing-page/bts-product-2-2.jpeg";
const BTSPI3_1 =
  "https://craftle-static.s3.us-east-2.amazonaws.com/landing-page/bts-product-3-1.jpeg";
const BTSPI3_2 =
  "https://craftle-static.s3.us-east-2.amazonaws.com/landing-page/bts-product-3-2.jpeg";
const BTSPI4_1 =
  "https://craftle-static.s3.us-east-2.amazonaws.com/landing-page/bts-product-4-1.jpeg";
const BTSPI4_2 =
  "https://craftle-static.s3.us-east-2.amazonaws.com/landing-page/bts-product-4-2.jpeg";
const BTSPI5_1 =
  "https://craftle-static.s3.us-east-2.amazonaws.com/landing-page/bts-product-5-1.jpeg";
const BTSPI5_2 =
  "https://craftle-static.s3.us-east-2.amazonaws.com/landing-page/bts-product-5-2.jpeg";
const BTSPI6_1 =
  "https://craftle-static.s3.us-east-2.amazonaws.com/landing-page/bts-product-6-1.jpeg";
const BTSPI6_2 =
  "https://craftle-static.s3.us-east-2.amazonaws.com/landing-page/bts-product-6-2.jpeg";
const BTSPI7_1 =
  "https://craftle-static.s3.us-east-2.amazonaws.com/landing-page/bts-product-7-1.jpeg";
const BTSPI7_2 =
  "https://craftle-static.s3.us-east-2.amazonaws.com/landing-page/bts-product-7-2.jpeg";
const TrendingInstaIMG1 =
  "https://craftle-static.s3.us-east-2.amazonaws.com/landing-page/trending-instagram-1.jpeg";
const TrendingInstaIMG2 =
  "https://craftle-static.s3.us-east-2.amazonaws.com/landing-page/trending-instagram-2.jpeg";
const TrendingInstaIMG3 =
  "https://craftle-static.s3.us-east-2.amazonaws.com/landing-page/trending-instagram-3.jpeg";
const TrendingInstaIMG4 =
  "https://craftle-static.s3.us-east-2.amazonaws.com/landing-page/trending-instagram-4.jpeg";
const TrendingInstaIMG5 =
  "https://craftle-static.s3.us-east-2.amazonaws.com/landing-page/trending-instagram-5.jpeg";
const TrendingInstaIMG6 =
  "https://craftle-static.s3.us-east-2.amazonaws.com/landing-page/trending-instagram-6.jpeg";
const TrendingInstaProductPageIMG1 =
  "https://craftle-static.s3.us-east-2.amazonaws.com/landing-page/trending-insta-1.jpeg";
const TrendingInstaProductPageIMG2 =
  "https://craftle-static.s3.us-east-2.amazonaws.com/landing-page/trending-insta-2.jpeg";
const TrendingInstaProductPageIMG3 =
  "https://craftle-static.s3.us-east-2.amazonaws.com/landing-page/trending-insta-3.jpeg";
const DarkAcademiaIMG1 =
  "https://craftle-static.s3.us-east-2.amazonaws.com/landing-page/dark-academia-1.jpeg";
const DarkAcademiaIMG2 =
  "https://craftle-static.s3.us-east-2.amazonaws.com/landing-page/dark-academia-2.jpeg";
const DarkAcademiaIMG3 =
  "https://craftle-static.s3.us-east-2.amazonaws.com/landing-page/dark-academia-3.jpeg";
const DarkAcademiaIMG4 =
  "https://craftle-static.s3.us-east-2.amazonaws.com/landing-page/dark-academia-4.jpeg";
const DarkAcademiaIMG5 =
  "https://craftle-static.s3.us-east-2.amazonaws.com/landing-page/dark-academia-5.jpeg";
const DarkAcademiaIMG6 =
  "https://craftle-static.s3.us-east-2.amazonaws.com/landing-page/dark-academia-6.jpeg";
const DarkAcademiaIMG7 =
  "https://craftle-static.s3.us-east-2.amazonaws.com/landing-page/dark-academia-7.jpeg";
const DarkAcademiaIMG8 =
  "https://craftle-static.s3.us-east-2.amazonaws.com/landing-page/dark-academia-8.jpeg";
const DarkAcademiaIMG9 =
  "https://craftle-static.s3.us-east-2.amazonaws.com/landing-page/dark-academia-9.jpeg";
const DarkAcademiaIMG10 =
  "https://craftle-static.s3.us-east-2.amazonaws.com/landing-page/dark-academia-10.jpeg";
const DarkAcademiaIMG11 =
  "https://craftle-static.s3.us-east-2.amazonaws.com/landing-page/dark-academia-11.jpeg";
const DarkAcademiaIMG12 =
  "https://craftle-static.s3.us-east-2.amazonaws.com/landing-page/dark-academia-12.jpeg";
const DarkAcademiaIMG13 =
  "https://craftle-static.s3.us-east-2.amazonaws.com/landing-page/dark-academia-13.jpeg";
const DarkAcademiaIMG14 =
  "https://craftle-static.s3.us-east-2.amazonaws.com/landing-page/dark-academia-14.jpeg";
const DarkAcademiaIMG15 =
  "https://craftle-static.s3.us-east-2.amazonaws.com/landing-page/dark-academia-15.jpeg";
const DarkAcademiaIMG16 =
  "https://craftle-static.s3.us-east-2.amazonaws.com/landing-page/dark-academia-16.jpeg";
const DarkAcademiaIMG17 =
  "https://craftle-static.s3.us-east-2.amazonaws.com/landing-page/dark-academia-17.jpeg";
const DarkAcademiaIMG18 =
  "https://craftle-static.s3.us-east-2.amazonaws.com/landing-page/dark-academia-18.jpeg";
const DarkAcademiaIMG19 =
  "https://craftle-static.s3.us-east-2.amazonaws.com/landing-page/dark-academia-19.jpeg";
const DarkAcademiaIMG20 =
  "https://craftle-static.s3.us-east-2.amazonaws.com/landing-page/dark-academia-20.jpeg";
const DarkAcademiaIMG21 =
  "https://craftle-static.s3.us-east-2.amazonaws.com/landing-page/dark-academia-21.jpeg";
const DarkAcademiaIMG22 =
  "https://craftle-static.s3.us-east-2.amazonaws.com/landing-page/dark-academia-22.jpeg";
const DarkAcademiaIMG23 =
  "https://craftle-static.s3.us-east-2.amazonaws.com/landing-page/dark-academia-23.jpeg";
const MeanGirls1 =
  "https://craftle-static.s3.us-east-2.amazonaws.com/landing-page/mean-girls-1.jpeg";
const MeanGirls2 =
  "https://craftle-static.s3.us-east-2.amazonaws.com/landing-page/mean-girls-2.jpeg";
const MeanGirls3 =
  "https://craftle-static.s3.us-east-2.amazonaws.com/landing-page/mean-girls-3.jpeg";
const MeanGirls4 =
  "https://craftle-static.s3.us-east-2.amazonaws.com/landing-page/mean-girls-4.jpeg";
const MeanGirls5 =
  "https://craftle-static.s3.us-east-2.amazonaws.com/landing-page/mean-girls-5.jpeg";
const MeanGirls6 =
  "https://craftle-static.s3.us-east-2.amazonaws.com/landing-page/mean-girls-6.jpeg";
const MeanGirls7 =
  "https://craftle-static.s3.us-east-2.amazonaws.com/landing-page/mean-girls-7.jpeg";
const MeanGirlsProductPageIMG1 =
  "https://craftle-static.s3.us-east-2.amazonaws.com/landing-page/mean-girls-product-1.jpeg";
const MeanGirlsProductPageIMG2 =
  "https://craftle-static.s3.us-east-2.amazonaws.com/landing-page/mean-girls-product-2.jpeg";
const MeanGirlsProductPageIMG3 =
  "https://craftle-static.s3.us-east-2.amazonaws.com/landing-page/mean-girls-product-3.jpeg";
const MeanGirlsProductPageIMG4 =
  "https://craftle-static.s3.us-east-2.amazonaws.com/landing-page/mean-girls-product-4.jpeg";
const MeanGirlsProductPageIMG5 =
  "https://craftle-static.s3.us-east-2.amazonaws.com/landing-page/mean-girls-product-5.jpeg";
const MeanGirlsProductPageIMG6 =
  "https://craftle-static.s3.us-east-2.amazonaws.com/landing-page/mean-girls-product-6.jpeg";
const MeanGirlsProductPageIMG7 =
  "https://craftle-static.s3.us-east-2.amazonaws.com/landing-page/mean-girls-product-7.jpeg";
const WeirdIMG1 =
  "https://craftle-static.s3.us-east-2.amazonaws.com/landing-page/weird-1.jpeg";
const WeirdIMG2 =
  "https://craftle-static.s3.us-east-2.amazonaws.com/landing-page/weird-2.jpeg";
const WeirdIMG3 =
  "https://craftle-static.s3.us-east-2.amazonaws.com/landing-page/weird-3.jpeg";
const WeirdIMG4 =
  "https://craftle-static.s3.us-east-2.amazonaws.com/landing-page/weird-4.jpeg";
const WeirdIMG5 =
  "https://craftle-static.s3.us-east-2.amazonaws.com/landing-page/weird-5.jpeg";
const WeirdIMG6 =
  "https://craftle-static.s3.us-east-2.amazonaws.com/landing-page/weird-6.jpeg";
const WeirdIMG7 =
  "https://craftle-static.s3.us-east-2.amazonaws.com/landing-page/weird-7.jpeg";
const WeirdIMG8 =
  "https://craftle-static.s3.us-east-2.amazonaws.com/landing-page/weird-8.jpeg";
const WeirdIMG9 =
  "https://craftle-static.s3.us-east-2.amazonaws.com/landing-page/weird-9.jpeg";
const WeirdIMG10 =
  "https://craftle-static.s3.us-east-2.amazonaws.com/landing-page/weird-10.jpeg";
const WeirdIMG11 =
  "https://craftle-static.s3.us-east-2.amazonaws.com/landing-page/weird-11.jpeg";
const WeirdIMG12 =
  "https://craftle-static.s3.us-east-2.amazonaws.com/landing-page/weird-12.jpeg";
const WeirdIMG13 =
  "https://craftle-static.s3.us-east-2.amazonaws.com/landing-page/weird-13.jpeg";
const WeirdIMG14 =
  "https://craftle-static.s3.us-east-2.amazonaws.com/landing-page/weird-14.jpeg";
const WeirdIMG15 =
  "https://craftle-static.s3.us-east-2.amazonaws.com/landing-page/weird-15.jpeg";
const CoolIMG1 =
  "https://craftle-static.s3.us-east-2.amazonaws.com/landing-page/cool-1.jpg";
const CoolIMG2 =
  "https://craftle-static.s3.us-east-2.amazonaws.com/landing-page/cool-2.jpg";
const CoolIMG3 =
  "https://craftle-static.s3.us-east-2.amazonaws.com/landing-page/cool-3.jpg";
const CoolIMG4 =
  "https://craftle-static.s3.us-east-2.amazonaws.com/landing-page/cool-4.jpg";
const CoolIMG5 =
  "https://craftle-static.s3.us-east-2.amazonaws.com/landing-page/cool-5.jpg";
const CoolIMG6 =
  "https://craftle-static.s3.us-east-2.amazonaws.com/landing-page/cool-6.jpg";
const CoolIMG7 =
  "https://craftle-static.s3.us-east-2.amazonaws.com/landing-page/cool-7.jpg";
const CoolIMG8 =
  "https://craftle-static.s3.us-east-2.amazonaws.com/landing-page/cool-8.jpg";
const CoolIMG9 =
  "https://craftle-static.s3.us-east-2.amazonaws.com/landing-page/cool-9.jpg";
const CoolIMG10 =
  "https://craftle-static.s3.us-east-2.amazonaws.com/landing-page/cool-10.jpg";
const CoolIMG11 =
  "https://craftle-static.s3.us-east-2.amazonaws.com/landing-page/cool-11.jpg";
const CoolIMG12 =
  "https://craftle-static.s3.us-east-2.amazonaws.com/landing-page/cool-12.jpg";
const CoolIMG13 =
  "https://craftle-static.s3.us-east-2.amazonaws.com/landing-page/cool-13.jpg";
const CoolIMG14 =
  "https://craftle-static.s3.us-east-2.amazonaws.com/landing-page/cool-14.jpg";
const CoolIMG15 =
  "https://craftle-static.s3.us-east-2.amazonaws.com/landing-page/cool-15.jpg";
const CoolIMG16 =
  "https://craftle-static.s3.us-east-2.amazonaws.com/landing-page/cool-16.jpg";
const CoolIMG17 =
  "https://craftle-static.s3.us-east-2.amazonaws.com/landing-page/cool-17.jpg";
const CoolIMG18 =
  "https://craftle-static.s3.us-east-2.amazonaws.com/landing-page/cool-18.jpg";
const CoolIMG19 =
  "https://craftle-static.s3.us-east-2.amazonaws.com/landing-page/cool-19.jpg";
const HarryPotter1 =
  "https://craftle-static.s3.us-east-2.amazonaws.com/landing-page/harry-potter-1.jpg";
const HarryPotter2 =
  "https://craftle-static.s3.us-east-2.amazonaws.com/landing-page/harry-potter-2.jpg";
const HarryPotter3 =
  "https://craftle-static.s3.us-east-2.amazonaws.com/landing-page/harry-potter-3.jpg";
const HarryPotter4 =
  "https://craftle-static.s3.us-east-2.amazonaws.com/landing-page/harry-potter-4.jpg";
const HarryPotter5 =
  "https://craftle-static.s3.us-east-2.amazonaws.com/landing-page/harry-potter-5.jpg";
const HarryPotter6 =
  "https://craftle-static.s3.us-east-2.amazonaws.com/landing-page/harry-potter-6.jpg";
const HarryPotter7 =
  "https://craftle-static.s3.us-east-2.amazonaws.com/landing-page/harry-potter-7.jpg";
const HarryPotter8 =
  "https://craftle-static.s3.us-east-2.amazonaws.com/landing-page/harry-potter-8.jpg";
const HarryPotter9 =
  "https://craftle-static.s3.us-east-2.amazonaws.com/landing-page/harry-potter-9.jpg";
const HarryPotter10 =
  "https://craftle-static.s3.us-east-2.amazonaws.com/landing-page/harry-potter-10.jpg";
const HarryPotter11 =
  "https://craftle-static.s3.us-east-2.amazonaws.com/landing-page/harry-potter-11.jpg";
const HarryPotter12 =
  "https://craftle-static.s3.us-east-2.amazonaws.com/landing-page/harry-potter-12.jpg";
const HarryPotter13 =
  "https://craftle-static.s3.us-east-2.amazonaws.com/landing-page/harry-potter-13.jpg";
const HarryPotter14 =
  "https://craftle-static.s3.us-east-2.amazonaws.com/landing-page/harry-potter-14.jpg";
const HarryPotter15 =
  "https://craftle-static.s3.us-east-2.amazonaws.com/landing-page/harry-potter-15.jpg";
const HarryPotter16 =
  "https://craftle-static.s3.us-east-2.amazonaws.com/landing-page/harry-potter-16.jpg";
const HarryPotter17 =
  "https://craftle-static.s3.us-east-2.amazonaws.com/landing-page/harry-potter-17.jpg";
const HarryPotter18 =
  "https://craftle-static.s3.us-east-2.amazonaws.com/landing-page/harry-potter-18.jpg";
const HarryPotter19 =
  "https://craftle-static.s3.us-east-2.amazonaws.com/landing-page/harry-potter-19.jpg";
const HarryPotter20 =
  "https://craftle-static.s3.us-east-2.amazonaws.com/landing-page/harry-potter-20.jpg";
const ZodiacProfile1 =
  "https://craftle-static.s3.us-east-2.amazonaws.com/landing-page/zodiac-profile-1.png";
const Zodiac1 =
  "https://craftle-static.s3.us-east-2.amazonaws.com/landing-page/zodiac-moodboard-1.png";
const Capricorn1 =
  "https://craftle-static.s3.us-east-2.amazonaws.com/landing-page/capricorn1.png";
const Capricorn2 =
  "https://craftle-static.s3.us-east-2.amazonaws.com/landing-page/capricorn2.png";
const Capricorn3 =
  "https://craftle-static.s3.us-east-2.amazonaws.com/landing-page/capricorn3.png";
const Capricorn4 =
  "https://craftle-static.s3.us-east-2.amazonaws.com/landing-page/capricorn4.png";
const Capricorn5 =
  "https://craftle-static.s3.us-east-2.amazonaws.com/landing-page/capricorn5.png";
const Capricorn6 =
  "https://craftle-static.s3.us-east-2.amazonaws.com/landing-page/capricorn6.png";
const Capricorn7 =
  "https://craftle-static.s3.us-east-2.amazonaws.com/landing-page/capricorn7.png";
const Capricorn8 =
  "https://craftle-static.s3.us-east-2.amazonaws.com/landing-page/capricorn8.png";
const Capricorn9 =
  "https://craftle-static.s3.us-east-2.amazonaws.com/landing-page/capricorn9.png";
const ZodiacProfile2 =
  "https://craftle-static.s3.us-east-2.amazonaws.com/landing-page/zodiac-profile-2.png";
const Zodiac2 =
  "https://craftle-static.s3.us-east-2.amazonaws.com/landing-page/zodiac-moodboard-2.png";
const Aquarius1 =
  "https://craftle-static.s3.us-east-2.amazonaws.com/landing-page/aquarius1.png";
const Aquarius2 =
  "https://craftle-static.s3.us-east-2.amazonaws.com/landing-page/aquarius2.png";
const Aquarius3 =
  "https://craftle-static.s3.us-east-2.amazonaws.com/landing-page/aquarius3.png";
const Aquarius4 =
  "https://craftle-static.s3.us-east-2.amazonaws.com/landing-page/aquarius4.png";
const Aquarius5 =
  "https://craftle-static.s3.us-east-2.amazonaws.com/landing-page/aquarius5.png";
const Aquarius6 =
  "https://craftle-static.s3.us-east-2.amazonaws.com/landing-page/aquarius6.png";
const Aquarius7 =
  "https://craftle-static.s3.us-east-2.amazonaws.com/landing-page/aquarius7.png";
const Aquarius8 =
  "https://craftle-static.s3.us-east-2.amazonaws.com/landing-page/aquarius8.png";
const Aquarius9 =
  "https://craftle-static.s3.us-east-2.amazonaws.com/landing-page/aquarius9.png";
const ZodiacProfile3 =
  "https://craftle-static.s3.us-east-2.amazonaws.com/landing-page/zodiac-profile-3.png";
const Zodiac3 =
  "https://craftle-static.s3.us-east-2.amazonaws.com/landing-page/zodiac-moodboard-3.png";
const Pisces1 =
  "https://craftle-static.s3.us-east-2.amazonaws.com/landing-page/pisces1.png";
const Pisces2 =
  "https://craftle-static.s3.us-east-2.amazonaws.com/landing-page/pisces2.png";
const Pisces3 =
  "https://craftle-static.s3.us-east-2.amazonaws.com/landing-page/pisces3.png";
const Pisces4 =
  "https://craftle-static.s3.us-east-2.amazonaws.com/landing-page/pisces4.png";
const Pisces5 =
  "https://craftle-static.s3.us-east-2.amazonaws.com/landing-page/pisces5.png";
const Pisces6 =
  "https://craftle-static.s3.us-east-2.amazonaws.com/landing-page/pisces6.png";
const Pisces7 =
  "https://craftle-static.s3.us-east-2.amazonaws.com/landing-page/pisces7.png";
const Pisces8 =
  "https://craftle-static.s3.us-east-2.amazonaws.com/landing-page/pisces8.png";
const Pisces9 =
  "https://craftle-static.s3.us-east-2.amazonaws.com/landing-page/pisces9.png";
const ZodiacProfile4 =
  "https://craftle-static.s3.us-east-2.amazonaws.com/landing-page/zodiac-profile-4.png";
const Zodiac4 =
  "https://craftle-static.s3.us-east-2.amazonaws.com/landing-page/zodiac-moodboard-4.png";
const Aries1 =
  "https://craftle-static.s3.us-east-2.amazonaws.com/landing-page/aries1.png";
const Aries2 =
  "https://craftle-static.s3.us-east-2.amazonaws.com/landing-page/aries2.png";
const Aries3 =
  "https://craftle-static.s3.us-east-2.amazonaws.com/landing-page/aries3.png";
const Aries4 =
  "https://craftle-static.s3.us-east-2.amazonaws.com/landing-page/aries4.png";
const Aries5 =
  "https://craftle-static.s3.us-east-2.amazonaws.com/landing-page/aries5.png";
const Aries6 =
  "https://craftle-static.s3.us-east-2.amazonaws.com/landing-page/aries6.png";
const Aries7 =
  "https://craftle-static.s3.us-east-2.amazonaws.com/landing-page/aries7.png";
const Aries8 =
  "https://craftle-static.s3.us-east-2.amazonaws.com/landing-page/aries8.png";
const Aries9 =
  "https://craftle-static.s3.us-east-2.amazonaws.com/landing-page/aries9.png";
const ZodiacProfile5 =
  "https://craftle-static.s3.us-east-2.amazonaws.com/landing-page/zodiac-profile-5.png";
const Zodiac5 =
  "https://craftle-static.s3.us-east-2.amazonaws.com/landing-page/zodiac-moodboard-5.png";
const Taurus1 =
  "https://craftle-static.s3.us-east-2.amazonaws.com/landing-page/taurus1.png";
const Taurus2 =
  "https://craftle-static.s3.us-east-2.amazonaws.com/landing-page/taurus2.png";
const Taurus3 =
  "https://craftle-static.s3.us-east-2.amazonaws.com/landing-page/taurus3.png";
const Taurus4 =
  "https://craftle-static.s3.us-east-2.amazonaws.com/landing-page/taurus4.png";
const Taurus5 =
  "https://craftle-static.s3.us-east-2.amazonaws.com/landing-page/taurus5.png";
const Taurus6 =
  "https://craftle-static.s3.us-east-2.amazonaws.com/landing-page/taurus6.png";
const Taurus7 =
  "https://craftle-static.s3.us-east-2.amazonaws.com/landing-page/taurus7.png";
const Taurus8 =
  "https://craftle-static.s3.us-east-2.amazonaws.com/landing-page/taurus8.png";
const Taurus9 =
  "https://craftle-static.s3.us-east-2.amazonaws.com/landing-page/taurus9.png";

const popularFurnitureRoutes = (navLinks) => (
  <>
    <Route
      path="/furniture/wayfair/araceli-30-wide-tufted-armchair"
      element={
        <>
          <Helmet>
            <title>
              Araceli 30&apos;&apos; Wide Tufted Armchair • Craftle • Craft your
              dream home
            </title>
            <meta
              name="description"
              content="Bring a bit of mod style to your living room or den with this stylish 30'' wide armchair."
            />
          </Helmet>
          <WebAppBar urls={navLinks} />
          <ProductPage
            name="Araceli 30'' Wide Tufted Armchair"
            description="Bring a bit of mod style to your living room or den with this stylish 30'' wide armchair. It's built on an engineered wood frame, and it features four flared solid wood legs in a dark espresso finish for a hint of mid-century modern style. The wide back and deep seat are upholstered in polyester fabric in a variety of colors to choose from, and it's stuffed with foam and synthetic fiber filling to give you support while you sit. Plus, we love that it's dotted with button tufting on the back for a touch of retro charm."
            image={PopularIMG2}
            brand="Hastag Home"
            type="Accent Chair"
            price="326.99"
            url="https://www.wayfair.com/furniture/pdp/hashtag-home-araceli-30-wide-tufted-armchair-w006110246.html?piid=82920358#seemoreoptions-jlkuqy4a7fo"
          />
          <Footer />
        </>
      }
    />
    <Route
      path="/furniture/westelm/savannah-rattan-chair"
      element={
        <>
          <Helmet>
            <title>
              Savannah Rattan Chair • Craftle • Craft your dream home
            </title>
            <meta name="description" content="" />
          </Helmet>
          <WebAppBar urls={navLinks} />
          <ProductPage
            name="Savannah Rattan Chair"
            description=""
            image={PopularIMG3}
            brand="West Elm"
            type="Accent Chair"
            price="699"
            url="https://www.westelm.com/products/savannah-rattan-chair-h7846/?pkey=cliving-room-chairs"
          />
          <Footer />
        </>
      }
    />
    <Route
      path="/furniture/westelm/cozy-swivel-chair"
      element={
        <>
          <Helmet>
            <title>Cozy Swivel Chair • Craftle • Craft your dream home</title>
            <meta name="description" content="" />
          </Helmet>
          <WebAppBar urls={navLinks} />
          <ProductPage
            name="Cozy Swivel Chair"
            description=""
            image={PopularIMG4}
            brand="West Elm"
            type="Accent Chair"
            price="1,748"
            url="https://www.westelm.com/products/cozy-swivel-chair-h3797/?cm_src=WsiSubCategory1&recstrat=Shop-Recs-2%7CB-CAT-GRP-TOPN-REV"
          />
          <Footer />
        </>
      }
    />
    <Route
      path="/furniture/wayfair/ettaavenue-cleo-26-wide-velvet-armchair"
      element={
        <>
          <Helmet>
            <title>
              Cleo 26&apos;&apos; Wide Velvet Armchair • Craftle • Craft your
              dream home
            </title>
            <meta
              name="description"
              content="Anchor your living room or den in glam style with this velvet armchair!"
            />
          </Helmet>
          <WebAppBar urls={navLinks} />
          <ProductPage
            name="Cleo 26'' Wide Velvet Armchair"
            description="Anchor your living room or den in glam style with this velvet armchair! It's made from solid larch wood and engineered wood, and it's built on slender, gold-polished metal legs for a luxe look. This armchair features flared arms and a wide back, all upholstered in luxurious velvet in your choice of color. It's also filled with foam to give you the right amount of support during happy hour, or while you watch your favorite movie. Plus, when it comes time to clean this chair, all you need is a simple spot treatment."
            image={PopularIMG1}
            brand="Etta Avenue™"
            type="Accent Chair"
            price="239.99"
            url="https://www.wayfair.com/furniture/pdp/etta-avenue-cleo-26-wide-velvet-armchair-w005012489.html?piid=370804791"
          />
          <Footer />
        </>
      }
    />
    <Route
      path="/furniture/crateandbarrel/harper-shearling-accent-chair"
      element={
        <>
          <Helmet>
            <title>
              Harper Shearling Accent Chair • Craftle • Craft your dream home
            </title>
            <meta
              name="description"
              content="Sculptural and exuberant, our Harper chair cozies up to playful texture and proportion."
            />
          </Helmet>
          <WebAppBar urls={navLinks} />
          <ProductPage
            name="Harper Shearling Accent Chair"
            description="Sculptural and exuberant, our Harper chair cozies up to playful texture and proportion. Upholstered in feathery white shearling, the chair's mounded seat and curved back hugs and snuggles for a seat you won't want to leave. Low and chunky, the oversized round ash legs feature beautiful cathedral graining and a low-sheen finish to enhance the chair's earthy, organic appeal. A room-stealer alone, Harper amps up the impact when paired."
            image={PopularIMG5}
            brand="CRATE & BARREL"
            type="Accent Chair"
            price="1,299"
            url="https://www.crateandbarrel.com/harper-shearling-accent-chair/s233494"
          />
          <Footer />
        </>
      }
    />
    <Route
      path="/furniture/westelm/volume-round-storage-drum-coffee-table"
      element={
        <>
          <Helmet>
            <title>
              Volume Round Storage Drum Coffee Table (36&apos;&apos;) • Craftle
              • Craft your dream home
            </title>
            <meta name="description" content="" />
          </Helmet>
          <WebAppBar urls={navLinks} />
          <ProductPage
            name="Volume Round Storage Drum Coffee Table (36'')"
            description=""
            image={PopularIMG6}
            brand="West Elm"
            type="Coffee Table"
            price="699"
            url="https://www.westelm.com/products/volume-round-storage-coffee-table-h8796/?pkey=ccoffee-tables"
          />
          <Footer />
        </>
      }
    />
    <Route
      path="/furniture/westelm/naomi-rattan-coffee-table"
      element={
        <>
          <Helmet>
            <title>
              Naomi Rattan Coffee Table (28&apos;&apos;–40&apos;&apos;) •
              Craftle • Craft your dream home
            </title>
            <meta
              name="description"
              content="Bring a bit of mod style to your living room or den with this stylish 30'' wide armchair."
            />
          </Helmet>
          <WebAppBar urls={navLinks} />
          <ProductPage
            name="Naomi Rattan Coffee Table (28''–40'')"
            description=""
            image={PopularIMG7}
            brand="West Elm"
            type="Coffee Table"
            price="259"
            url="https://www.westelm.com/products/naomi-rattan-coffee-table-h10814/?pkey=ccoffee-tables"
          />
          <Footer />
        </>
      }
    />
    <Route
      path="/furniture/wayfair/black-lift-top-extendable-coffee-table-with-storage"
      element={
        <>
          <Helmet>
            <title>
              Black Lift Top Extendable Coffee Table With Storage • Craftle •
              Craft your dream home
            </title>
            <meta
              name="description"
              content="Our lift-top coffee table features a pop-up tabletop that can be easily and smoothly lifted or lowered by a high-quality metal mechanism and spring."
            />
          </Helmet>
          <WebAppBar urls={navLinks} />
          <ProductPage
            name="Black Lift Top Extendable Coffee Table With Storage"
            description="Our lift-top coffee table features a pop-up tabletop that can be easily and smoothly lifted or lowered by a high-quality metal mechanism and spring. The tabletop can be adjusted to a suitable height to use a laptop, write or dining while sitting on the couch."
            image={PopularIMG8}
            brand="Latitude Run®"
            type="Coffee Table"
            price="199.99"
            url="https://www.wayfair.com/furniture/pdp/latitude-run-black-lift-top-extendable-coffee-table-with-storage-w006989343.html?piid=1318643237"
          />
          <Footer />
        </>
      }
    />
    <Route
      path="/furniture/crateandbarrel/dillon-spalted-primavera-round-wood-coffee-table"
      element={
        <>
          <Helmet>
            <title>
              Dillon Spalted Primavera Round Wood Coffee Table • Craftle • Craft
              your dream home
            </title>
            <meta
              name="description"
              content="Prized for its high-contrast colors and swirling grain, spalted wood is timber that has undergone a certain degree of decay to develop these striking characteristics."
            />
          </Helmet>
          <WebAppBar urls={navLinks} />
          <ProductPage
            name="Dillon Spalted Primavera Round Wood Coffee Table"
            description="Prized for its high-contrast colors and swirling grain, spalted wood is timber that has undergone a certain degree of decay to develop these striking characteristics. The Dillon coffee table puts this organic showstopper on full display in a handcrafted cylinder floating on a recessed base of oxidized iron. Each table will exhibit its own unique markings."
            image={PopularIMG9}
            brand="CRATE & BARREL"
            type="Coffee Table"
            price="1,399.20"
            url="https://www.crateandbarrel.com/dillon-spalted-primavera-round-wood-coffee-table/s336379"
          />
          <Footer />
        </>
      }
    />
    <Route
      path="/furniture/crateandbarrel/dev-coffee-table"
      element={
        <>
          <Helmet>
            <title>Dev Coffee Table • Craftle • Craft your dream home</title>
            <meta
              name="description"
              content="A true work of art, sculptural Dev perfectly balances two sliced spheres to create this gorgeous coffee table."
            />
          </Helmet>
          <WebAppBar urls={navLinks} />
          <ProductPage
            name="Dev Coffee Table"
            description="A true work of art, sculptural Dev perfectly balances two sliced spheres to create this gorgeous coffee table. Hammered for light-catching texture, the grey-finished aluminum base curves in and out to support an inset tabletop of creamy taupe marble. We love Dev's visual interplay of rustic metal and dynamically veined smooth marble."
            image={PopularIMG10}
            brand="CRATE & BARREL"
            type="Coffee Table"
            price="999"
            url="https://www.crateandbarrel.com/dev-coffee-table/s157763"
          />
          <Footer />
        </>
      }
    />
    <Route
      path="/furniture/wayfair/almarin-frame-coffee-table"
      element={
        <>
          <Helmet>
            <title>
              Almarin Frame Coffee Table • Craftle • Craft your dream home
            </title>
            <meta
              name="description"
              content="Whether serving as an accent piece in your contemporary setting or providing a perch for a mug of joe, this coffee table is sure to be a stylish stage."
            />
          </Helmet>
          <WebAppBar urls={navLinks} />
          <ProductPage
            name="Almarin Frame Coffee Table"
            description="Whether serving as an accent piece in your contemporary setting or providing a perch for a mug of joe, this coffee table is sure to be a stylish stage. Defined by clean lines and a neutral finish, this piece puts a modern twist on an Old Hollywood design. The open metal frame gives this piece an airy feel, while the manufactured wood top showcases a rectangular silhouette that’s ideal for holding living room essentials."
            image={PopularIMG11}
            brand="Wrought Studio™"
            type="Coffee Table"
            price="191.99"
            url="https://www.wayfair.com/furniture/pdp/wrought-studio-almarin-frame-coffee-table-w005888046.html"
          />
          <Footer />
        </>
      }
    />
    <Route
      path="/furniture/wayfair/kearny-60-63-round-arm-loveseat"
      element={
        <>
          <Helmet>
            <title>
              Kearney 60.63&apos;&apos; Round Arm Loveseat • Craftle • Craft
              your dream home
            </title>
            <meta
              name="description"
              content="Make a statement in your living room with this Kearney 60'' Flared Arm Loveseat"
            />
          </Helmet>
          <WebAppBar urls={navLinks} />
          <ProductPage
            name="Kearney 60.63'' Round Arm Loveseat"
            description="Make a statement in your living room with this Kearney 60'' Flared Arm Loveseat. Its tuxedo arms create a solid back that extends all the way to the floor, and its seat cushion essentially floats in the middle of the frame to create a distinctive silhouette. This design is supported by a solid wood frame and is wrapped in an easy-to-clean polyester blend. This loveseat is padded with foam, and its tight seat cushion features an integrated web suspension that'll provide just the right amount of cushion as you kick it."
            image={PopularIMG12}
            brand="AllModern"
            type="Sofa"
            price="710"
            url="https://www.wayfair.com/furniture/pdp/allmodern-kearney-60-round-arm-loveseat-w001243679.html?piid=414931010"
          />
          <Footer />
        </>
      }
    />
    <Route
      path="/furniture/wayfair/murtagh-51-4-round-arm-curved-loveseat"
      element={
        <>
          <Helmet>
            <title>
              Murtagh 51.4&apos;&apos; Round Arm Curved Loveseat • Craftle •
              Craft your dream home
            </title>
            <meta
              name="description"
              content="This sofa can be freely matched into two sets and three sets, with matching chairs of the same style."
            />
          </Helmet>
          <WebAppBar urls={navLinks} />
          <ProductPage
            name="Murtagh 51.4'' Round Arm Curved Loveseat"
            description="This sofa can be freely matched into two sets and three sets, with matching chairs of the same style. Each chair is made of a wooden frame, and the back is composed of two semi-ellipses. The double-layer circular back is more reassuring, and the warm embrace back-arc extends to both sides, wrapping the body, enclosing design, full of supporting feeling."
            image={PopularIMG13}
            brand="VANTEN"
            type="Sofa"
            price="589.99"
            url="https://www.wayfair.com/furniture/pdp/vanten-murtagh-514-round-arm-curved-loveseat-lajw1291.html?piid=66413255"
          />
          <Footer />
        </>
      }
    />
    <Route
      path="/furniture/wayfair/84-3-velvet-recessed-arm"
      element={
        <>
          <Helmet>
            <title>
              84.3&apos;&apos; Velvet Recessed Arm • Craftle • Craft your dream
              home
            </title>
            <meta
              name="description"
              content="This sofa is here to anchor your living room with some eye-catching glam style."
            />
          </Helmet>
          <WebAppBar urls={navLinks} />
          <ProductPage
            name="84.3'' Velvet Recessed Arm"
            description="This sofa is here to anchor your living room with some eye-catching glam style. Crafted with a stainless steel frame and wrapped in foam-filled velvet upholstery for an inviting feel. This couch features a striking camelback with channel tufting and tapered legs for a look glam look with mid-century inspiration that's sure to grab glances. Plus, the wrap-around, recessed arms are perfect for leaning back against a good book, or snuggling up during movie night. This piece requires 36.6'' of doorway clearance. Hand-curated by kelly Clarkson"
            image={PopularIMG14}
            brand="Kelly Clarkson Home"
            type="Sofa"
            price="1,339.99"
            url="https://www.wayfair.com/furniture/pdp/kelly-clarkson-home-843-velvet-recessed-arm-sofa-w003380032.html?piid=1046298821"
          />
          <Footer />
        </>
      }
    />
    <Route
      path="/furniture/crateandbarrel/wells-leather-sofa"
      element={
        <>
          <Helmet>
            <title>Wells Leather Sofa • Craftle • Craft your dream home</title>
            <meta
              name="description"
              content="Indulge in the sumptuous leather surround of our Wells sofa."
            />
          </Helmet>
          <WebAppBar urls={navLinks} />
          <ProductPage
            name="Wells Leather Sofa"
            description='Indulge in the sumptuous leather surround of our Wells sofa. An updated version on the classic leather sofa, Wells looks rustic and modern as it tucks cushions onto a slim frame supported by iron legs. Tailored with top stitching and French seams on the arms, the beautiful top-grain leather glows with an heirloom patina that results when oils and waxes applied during the finishing process are stretched to create lighter tones known as "burst." Uniquely patterned with natural markings, each one-of-a-kind sofa is a Crate and Barrel exclusive.'
            image={PopularIMG15}
            brand="CRATE & BARREL"
            type="Sofa"
            price="2,899"
            url="https://www.crateandbarrel.com/wells-leather-sofa/s376304?oc=2,2142"
          />
          <Footer />
        </>
      }
    />

    {/* bts army */}
    <Route
      path="/furniture/1stdibs/french-giltwood-settee-sofa-style-louis-xvi-red-velvet-19th-century"
      element={
        <>
          <Helmet>
            <title>
              French Giltwood Settee Sofa, Style Louis XVI, Red Velvet, 19th
              Century • Craftle • Craft your dream home
            </title>
            <meta name="description" content="" />
          </Helmet>
          <WebAppBar urls={navLinks} />
          <ProductPage
            name="French Giltwood Settee Sofa, Style Louis XVI, Red Velvet, 19th Century"
            description=""
            image={BTSIMG1}
            brand="1stDibs"
            type="Loveseat"
            price="6,000"
            url="https://www.1stdibs.com/furniture/seating/sofas/french-giltwood-settee-sofa-style-louis-xvi-red-velvet-19th-century/id-f_24116512/"
            productImg1={BTSPI1_1}
            productImg2={BTSPI1_2}
            description2="This furniture piece was inspired by V's album photocard"
          />
          <Footer />
        </>
      }
    />
    <Route
      path="/furniture/mrswoods/hall-furniture-3-seat-classical-french-style-wedding-sofa"
      element={
        <>
          <Helmet>
            <title>
              Hall Furniture 3 Seat Classical French Style Wedding Sofa •
              Craftle • Craft your dream home
            </title>
            <meta name="description" content="" />
          </Helmet>
          <WebAppBar urls={navLinks} />
          <ProductPage
            name="Hall Furniture 3 Seat Classical French Style Wedding Sofa"
            description=""
            image={BTSIMG2}
            brand="mrswoods"
            type="Sofa"
            price="500"
            url="https://mrswoods.en.made-in-china.com/product/LsdQXwbCiVce/China-Hall-Furniture-3-Seat-Classical-French-Style-Wedding-Sofa.html"
            productImg1={BTSPI2_1}
            productImg2={BTSPI2_2}
            description2="This furniture piece was inspired by Jimin's album photocard"
          />
          <Footer />
        </>
      }
    />
    <Route
      path="/furniture/wayfair/catalina-85-square-arm-sofa-with-reversible-cushions"
      element={
        <>
          <Helmet>
            <title>
              Catalina 85&apos;&apos; Square Arm Sofa with Reversible Cushions •
              Craftle • Craft your dream home
            </title>
            <meta name="description" content="" />
          </Helmet>
          <WebAppBar urls={navLinks} />
          <ProductPage
            name="Catalina 85'' Square Arm Sofa with Reversible Cushions"
            description=""
            image={BTSIMG3}
            brand="Poshbin"
            type="Sofa"
            price="1,829.99"
            url="https://www.wayfair.com/furniture/pdp/poshbin-catalina-square-arm-sofa-with-reversible-cushions-w004181000.html?piid=554847342%2C554847353%2C554847356"
            productImg1={BTSPI3_1}
            productImg2={BTSPI3_2}
            description2="This furniture piece was inspired by RM's album photocard"
          />
          <Footer />
        </>
      }
    />
    <Route
      path="/furniture/homedepot/sadlier-75-5-in-3-seat-square-arm-faux-leather-straight-dark-brown-sofa"
      element={
        <>
          <Helmet>
            <title>
              Sadlier 75.5 in. 3-Seat Square Arm Faux Leather Straight Dark
              Brown Sofa • Craftle • Craft your dream home
            </title>
            <meta
              name="description"
              content="Catch the eye of any person who appreciates a dash of charming sophistication and delightful comfort."
            />
          </Helmet>
          <WebAppBar urls={navLinks} />
          <ProductPage
            name="Sadlier 75.5 in. 3-Seat Square Arm Faux Leather Straight Dark Brown Sofa"
            description="Catch the eye of any person who appreciates a dash of charming sophistication and delightful comfort. From its refined waffle stitching to its rich wood legs, our three-seater sofa offers your home a classic contemporary design. Featuring a low backrest and smooth upholstery, this sofa stands as a streamlined statement-piece that can change the whole flow of your living room, creating an open, clean space. Finished with button tufting and tuxedo arms, our sofa provides an elegant touch to any room."
            image={BTSIMG4}
            brand="Home Depot"
            type="Sofa"
            price="938.09"
            url="https://www.homedepot.com/p/Noble-House-Sadlier-75-5-in-3-Seat-Square-Arm-Faux-Leather-Straight-Dark-Brown-Sofa-107580/318818208"
            productImg1={BTSPI4_1}
            productImg2={BTSPI4_2}
            description2="This furniture piece was inspired by Jungkook's album photocard"
          />
          <Footer />
        </>
      }
    />
    <Route
      path="/furniture/ifurniture/evelyn-curved-flared-loveseat"
      element={
        <>
          <Helmet>
            <title>
              EVELYN CURVED FLARED LOVESEAT • Craftle • Craft your dream home
            </title>
            <meta
              name="description"
              content="Our Evelyn loveseat and accent chair are upholstered with a velvet, soft and easy-to-clean fabric."
            />
          </Helmet>
          <WebAppBar urls={navLinks} />
          <ProductPage
            name="EVELYN CURVED FLARED LOVESEAT"
            description="Our Evelyn loveseat and accent chair are upholstered with a velvet, soft and easy-to-clean fabric, the attention-grabbing design with its gold metal legs and detailed welting on the back and inside channels will instantly be the highlight to any room. Available in 4 impressive colors to match your room."
            image={BTSIMG5}
            brand="ifurniture"
            type="Loveseat"
            price="549"
            url="https://ifurniture.ca/evelyn-curved-flared-loveseat-velvet"
            productImg1={BTSPI5_1}
            productImg2={BTSPI5_2}
            description2="This furniture piece was inspired by Jin's album photocard"
          />
          <Footer />
        </>
      }
    />
    <Route
      path="/furniture/better-bed-company/julian-bowen-sandringham-3-seater-sofa-blue-velvet"
      element={
        <>
          <Helmet>
            <title>
              JULIAN BOWEN SANDRINGHAM 3 SEATER SOFA BLUE VELVET • Craftle •
              Craft your dream home
            </title>
            <meta
              name="description"
              content="This blue velvet sofa would provide a traditional sofa with a modern colour that's going to make a statement in the home."
            />
          </Helmet>
          <WebAppBar urls={navLinks} />
          <ProductPage
            name="JULIAN BOWEN SANDRINGHAM 3 SEATER SOFA BLUE VELVET"
            description="This blue velvet sofa would provide a traditional sofa with a modern colour that's going to make a statement in the home. With the button effect contemporary and the classically scroll arms this sofas trendy. The strong stitching and upholstery adds long term gains with low maintenance values. "
            image={BTSIMG6}
            brand="Better Bed Company"
            type="Loveseat"
            price="862.59"
            url="https://www.betterbedcompany.co.uk/products/julian-bowen-sandringham-3-seater-sofa-blue-velvet"
            productImg1={BTSPI6_1}
            productImg2={BTSPI6_2}
            description2="This furniture piece was inspired by Suga's album photocard"
          />
          <Footer />
        </>
      }
    />
    <Route
      path="/furniture/mojow/yomi-wood-et-table-basse"
      element={
        <>
          <Helmet>
            <title>
              YOMI WOOD ET TABLE BASSE • Craftle • Craft your dream home
            </title>
            <meta name="description" content="" />
          </Helmet>
          <WebAppBar urls={navLinks} />
          <ProductPage
            name="YOMI WOOD ET TABLE BASSE"
            description="Modern garden furniture with a timeless French design, produced in extremely resistant and eco-responsible materials, PEFC wood, the sleek and fun style of your Yomi Eko garden furniture will easily find its place by the pool or on your terrace.‎ Assemble the Mojow armchairs, sofas and coffee tables, to create your modern, elegant and original design garden furniture."
            image={BTSIMG7}
            brand="Mojow"
            type="Lounge Set"
            price="3,380.38"
            url="https://www.archiproducts.com/en/products/mojow/lounge-set-yomi-wood-et-table-basse-lounge-set_527937"
            productImg1={BTSPI7_1}
            productImg2={BTSPI7_2}
            description2="This furniture piece was inspired by J-Hope's album photocard"
          />
          <Footer />
        </>
      }
    />

    {/* trendng on instagram */}
    <Route
      path="/furniture/urban-outfitters/caroline-rattan-vanity"
      element={
        <>
          <Helmet>
            <title>
              Caroline Rattan Vanity • Craftle • Craft your dream home
            </title>
            <meta
              name="description"
              content="Start your day in a boho state of mind when you get ready at this rattan vanity."
            />
          </Helmet>
          <WebAppBar urls={navLinks} />
          <ProductPage
            name="Caroline Rattan Vanity"
            description="Start your day in a boho state of mind when you get ready at this rattan vanity. Topped with an ovular, built-in mirror, featuring caged detailing around the back and angular legs with support bars. This custom furniture piece will be made to order just for you. Assembly required. Exclusive to Urban Outfitters."
            image={TrendingInstaIMG1}
            brand="Urban Outfitters"
            type="Vanity"
            price="389"
            url="https://www.urbanoutfitters.com/shop/caroline-rattan-vanity?color=111&type=REGULAR&size=ONE%20SIZE&quantity=1&utm_medium=affiliates&utm_source=LS&utm_campaign=CNN&utm_term=1005739&utm_content=1&ranMID=43176&ranEAID=Fr%2F49%2F7rhGg&ranSiteID=Fr_49_7rhGg-ABMj9BxD6S716NNvvQ5s8A"
            productImg1={TrendingInstaProductPageIMG1}
            productImg1Credit1=""
            // productImg2={productImg2}
            productImg1Credit2=""
            description2="Optimism rugs with @loloirugs are giving me ALL THE VIBES! Now available for pre-order at @thejungalow ✨🌿✨ #justinaforloloi - @justinablakeney"
          />
          <Footer />
        </>
      }
    />
    <Route
      path="/furniture/serena-and-lily/hanging-rattan-chair"
      element={
        <>
          <Helmet>
            <title>
              Hanging Rattan Chair • Craftle • Craft your dream home
            </title>
            <meta
              name="description"
              content="Our iconic design features a sculptural frame with hand-bent rattan and a scooped seat that cradles you as you float."
            />
          </Helmet>
          <WebAppBar urls={navLinks} />
          <ProductPage
            name="Hanging Rattan Chair"
            description="Meet the natural crowd-pleaser. Our iconic design features a sculptural frame with hand-bent rattan and a scooped seat that cradles you as you float. Picture it adding a bit of fun to the living room or the library. Add pillows for a pop of color and sink-in comfort."
            image={TrendingInstaIMG2}
            brand="Serena & Lily"
            type="Accent Chair"
            price="548"
            url="https://www.serenaandlily.com/hanging-rattan-chair/m10499.html?utm_campaign=pjgeneric&publisherId=120661&clickId=3993852426&utm_source=pj&utm_medium=affiliate"
            productImg1={TrendingInstaProductPageIMG1}
            productImg1Credit1=""
            // productImg2={productImg2}
            productImg1Credit2=""
            description2="Optimism rugs with @loloirugs are giving me ALL THE VIBES! Now available for pre-order at @thejungalow ✨🌿✨ #justinaforloloi - @justinablakeney"
          />
          <Footer />
        </>
      }
    />
    <Route
      path="/furniture/lulu-and-georgia/tarek-pendant-light"
      element={
        <>
          <Helmet>
            <title>Tarek Pendant Light • Craftle • Craft your dream home</title>
            <meta
              name="description"
              content="Twisted jute is stretched over the wire frame that makes up our Tarek Pendant."
            />
          </Helmet>
          <WebAppBar urls={navLinks} />
          <ProductPage
            name="Tarek Pendant Light"
            description="Twisted jute is stretched over the wire frame that makes up our Tarek Pendant; a Bohemian choice for your dining or living room."
            image={TrendingInstaIMG3}
            brand="Lulu and Georgia"
            type="Pendant Light"
            price="325"
            url="https://www.luluandgeorgia.com/products/tarek-pendant-light?utm_source=21181&utm_medium=affiliate&utm_content=8-11565&utm_campaign=product&click=3993853215&clickId=3993853215&ar_clx=yes&ar_channel=pepperjam&ar_campaign=PepperJam&ar_adgroup=PepperJam&ar_ad=PepperJam&source=pepperjam&publisher=21181"
            productImg1={TrendingInstaProductPageIMG1}
            productImg1Credit1=""
            // productImg2={productImg2}
            productImg1Credit2=""
            description2="Optimism rugs with @loloirugs are giving me ALL THE VIBES! Now available for pre-order at @thejungalow ✨🌿✨ #justinaforloloi - @justinablakeney"
          />
          <Footer />
        </>
      }
    />
    <Route
      path="/furniture/cb2/gwyneth-ivory-boucle-chair"
      element={
        <>
          <Helmet>
            <title>
              GWYNETH IVORY BOUCLE CHAIR • Craftle • Craft your dream home
            </title>
            <meta
              name="description"
              content="Cozy chair from the goop x CB2 collection is &quot;proof that things don't have to be cold and hard to be chic."
            />
          </Helmet>
          <WebAppBar urls={navLinks} />
          <ProductPage
            name="GWYNETH IVORY BOUCLE CHAIR"
            description='Cozy chair from the goop x CB2 collection is "proof that things don&apos;t have to be cold and hard to be chic," says Gwyneth Paltrow. "We&apos;re taking the compromise out of the equation with chic furniture that&apos;s to be lived in and loved." Lush, texture-rich fabric feels like a true boucle (for a much lower price). Generously scaled with a mod silhouette that feels slightly vintage and totally high-end. CB2 exclusive.'
            image={TrendingInstaIMG4}
            brand="CB2"
            type="Accent Chair"
            price="999"
            url="https://www.cb2.com/gwyneth-ivory-boucle-chair/s265795"
            productImg1={TrendingInstaProductPageIMG2}
            productImg1Credit1=""
            // productImg2={productImg2}
            productImg1Credit2=""
            description2="Source: Kim Kardashian’s Vogue house tour"
          />
          <Footer />
        </>
      }
    />
    <Route
      path="/furniture/world-market/faux-sheepskin-ilana-upholstered-chair"
      element={
        <>
          <Helmet>
            <title>
              Faux Sheepskin Ilana Upholstered Chair • Craftle • Craft your
              dream home
            </title>
            <meta
              name="description"
              content="Make your seating area a reflection of your luxurious style with our faux sheepskin Ilana chair."
            />
          </Helmet>
          <WebAppBar urls={navLinks} />
          <ProductPage
            name="Faux Sheepskin Ilana Upholstered Chair"
            description="Make your seating area a reflection of your luxurious style with our faux sheepskin Ilana chair. Crafted with ultra-soft upholstery in a versatile natural hue, splayed pine wood legs in a light finish and a comfortable deep seat, its lounge-worthy profile is an excellent accent to any decor."
            image={TrendingInstaIMG5}
            brand="World Market"
            type="Accent Chair"
            price="849.99"
            url="https://www.worldmarket.com/product/faux-sheepskin-ilana-upholstered-chair.do"
            productImg1={TrendingInstaProductPageIMG2}
            productImg1Credit1=""
            // productImg2={productImg2}
            productImg1Credit2=""
            description2="Source: Kim Kardashian’s Vogue house tour"
          />
          <Footer />
        </>
      }
    />
    <Route
      path="/furniture/wayfair/kearny-60-63-round-arm-loveseat-2"
      element={
        <>
          <Helmet>
            <title>
              Kearney 60.63&apos;&apos; Round Arm Loveseat • Craftle • Craft
              your dream home
            </title>
            <meta
              name="description"
              content="Make a statement in your living room with this Kearney 60'' Flared Arm Loveseat."
            />
          </Helmet>
          <WebAppBar urls={navLinks} />
          <ProductPage
            name="Kearney 60.63'' Round Arm Loveseat"
            description="Make a statement in your living room with this Kearney 60'' Flared Arm Loveseat. Its tuxedo arms create a solid back that extends all the way to the floor, and its seat cushion essentially floats in the middle of the frame to create a distinctive silhouette. This design is supported by a solid wood frame and is wrapped in an easy-to-clean polyester blend. This loveseat is padded with foam, and its tight seat cushion features an integrated web suspension that'll provide just the right amount of cushion as you kick it."
            image={TrendingInstaIMG6}
            brand="AllModern"
            type="Loveseat"
            price="560"
            url="https://www.wayfair.com/furniture/pdp/allmodern-kearney-60-round-arm-loveseat-w001243679.html?piid=414931009"
            productImg1={TrendingInstaProductPageIMG3}
            productImg1Credit1=""
            // productImg2={productImg2}
            productImg1Credit2=""
            description2="Source: Kim Kardashian’s Vogue house tour"
          />
          <Footer />
        </>
      }
    />

    {/* dark academia section */}
    <Route
      path="/boutiques-de-musees/authentic-models-gl019-terrestrial-globe-with-compass"
      element={
        <>
          <Helmet>
            <title>
              Authentic Models GL019 Terrestrial Globe With Compass • Craftle •
              Craft your dream home
            </title>
            <meta
              name="description"
              content="Designed to mirror the unique beauty of antique objects and curiosities, Authentic Models' pieces come across as both functional and decorative, lovingly recreated from materials that don't break from tradition."
            />
          </Helmet>
          <WebAppBar urls={navLinks} />
          <ProductPage
            name="Authentic Models GL019 Terrestrial Globe With Compass"
            description="Wonder, intrigue, curiosity and discovery - qualities that are so often missing from our busy lives. That was before Authentic Models. They're interested in appealing to the mind as well as to the eye. Designed to mirror the unique beauty of antique objects and curiosities, Authentic Models' pieces come across as both functional and decorative, lovingly recreated from materials that don't break from tradition. Admittedly, Marie Antoinette wasn't remembered for her intellectual exploits. However, during the 18th Century, when she served as Queen of France, many scientists and explorers set off on expeditions around the globe on her behalf, mapping landmasses and oceans, as well as recording local flora and fauna. This exquisite Terrestrial Globe features a compass( a true replica of a 17th Century dry compass) built into its polished wooden base, with a bronzed metal stand that exudes authenticity and otherworldly charm. Made from paper wood, the beautifully detailed terrestrial ocean globe also gives an accurate glimpse into the world as it was known centuries ago. Terrestrial Globe with Compass features: Designed in the USA. Made from paperboard, with a polished wood and bronzed metal stand accents. A true replica of a 17th Century dry compass is built into the wooden base. An eye-catching display piece and desk accessory. Dimensions: 21cm (h) x 15.5cm (d)."
            image={DarkAcademiaIMG1}
            brand="boutiques de musées"
            type="globe"
            price="104.68"
            url="https://www.google.com/shopping/product/11522340944982548925?q=dark+academia+globe&biw=1382&bih=801&sxsrf=ALiCzsbbCS1aP8FxcllHGKF_d-oScVaRfw:1655407366733&uact=5&oq=dark+academia+globe&gs_lcp=Cgtwcm9kdWN0cy1jYxADMgUIABCABDoICAAQgAQQsAM6CwgAEB4QsAMQCBAKOgsIABAeELADEAgQGDoLCK4BEMoDELADECc6CAgAEB4QFhAYOgoIABAeEBYQChAYSgQIQRgBUPQBWNwGYKwHaAJwAHgAgAGaAYgBwgOSAQMxLjOYAQCgAQHIAQnAAQE&sclient=products-cc&prds=eto:1758398022086140498_0,pid:9733684320762589633,rsk:PC_16678223491672629545&sa=X&ved=0ahUKEwjRge6L2bL4AhWQjIkEHVYrBPAQ8wIIxws"
          />
          <Footer />
        </>
      }
    />
    <Route
      path="/northprint/moody-portrait-woman-painting-vintage-dark-academia-digital-printable-487"
      element={
        <>
          <Helmet>
            <title>
              Moody Portrait Woman Painting | Vintage Dark Academia Digital
              PRINTABLE #487 • Craftle • Craft your dream home
            </title>
            <meta name="description" content="Perfect dark academia decor." />
          </Helmet>
          <WebAppBar urls={navLinks} />
          <ProductPage
            name="Moody Portrait Woman Painting | Vintage Dark Academia Digital PRINTABLE #487"
            description="DIGITAL PRINTABLE | Original artwork c. 1800's
                  Instantly download, print and frame this moody dark vintage portrait digital oil painting. Perfect dark academia decor. Our artist-enhanced PRINTABLE artwork will reflect all the charm of the original prints, with characteristic grain imperfections, cracking and authenticity remaining visible."
            image={DarkAcademiaIMG2}
            brand="NorthPrint"
            type="Print"
            price="3.60"
            url="https://www.etsy.com/listing/932186964/moody-portrait-woman-painting-vintage?click_key=efa75351782da6615d1f78365b166e32e7fc0b20%3A932186964&click_sum=4e9fed17&ref=related-2&pro=1"
          />
          <Footer />
        </>
      }
    />
    <Route
      path="/vagabond-house/majestic-forest-three-taper-oak-leaf-metal-candelabra"
      element={
        <>
          <Helmet>
            <title>
              Majestic Forest Three Taper Oak Leaf Metal Candelabra • Craftle •
              Craft your dream home
            </title>
            <meta
              name="description"
              content="Add soft romantic lighting to your music of the night with Vagabond House's 3 socket pewter acorn and oak leaf candelabrum."
            />
          </Helmet>
          <WebAppBar urls={navLinks} />
          <ProductPage
            name="Majestic Forest Three Taper Oak Leaf Metal Candelabra"
            description="Add soft romantic lighting to your music of the night with Vagabond House's 3 socket pewter acorn and oak leaf candelabrum."
            image={DarkAcademiaIMG3}
            brand="Vagabond House"
            type="Candelabra"
            price="399.99"
            url="https://www.wayfair.com/Vagabond-House--Majestic-Forest-Three-Taper-Oak-Leaf-Metal-Candelabra-L101M-L6125-K~VJZ10797.html?refid=GX560448447253-VJZ10797&device=c&ptid=561575403794&network=g&targetid=aud-352581795486:pla-561575403794&channel=GooglePLA&ireid=126727388&fdid=1817&gclid=CjwKCAjwqauVBhBGEiwAXOepkVu0d1ZJPLyVhU3CtNgk2rcU0PaV-4fpAPOI3TEcKg2kbN7qaF_tERoCZIwQAvD_BwE"
          />
          <Footer />
        </>
      }
    />
    <Route
      path="/antique-beauty/20th-century-handheld-mirror-in-a-wooden-frame"
      element={
        <>
          <Helmet>
            <title>
              20th-Century Handheld Mirror in a Wooden Frame • Craftle • Craft
              your dream home
            </title>
            <meta
              name="description"
              content="We present you a small handheld mirror in a carved walnut frame."
            />
          </Helmet>
          <WebAppBar urls={navLinks} />
          <ProductPage
            name="20th-Century Handheld Mirror in a Wooden Frame"
            description="We present you a small handheld mirror in a carved walnut frame. The item was manufactured before 1939. The mirror is in very good condition."
            image={DarkAcademiaIMG4}
            brand="Antique Beauty"
            type="Mirror"
            price="380"
            url="https://www.chairish.com/product/2468311/20th-century-handheld-mirror-in-a-wooden-frame"
          />
          <Footer />
        </>
      }
    />
    <Route
      path="/fleur-de-lis-living/bust"
      element={
        <>
          <Helmet>
            <title>Bust • Craftle • Craft your dream home</title>
            <meta
              name="description"
              content="The Fleur De Lis Living Bust is the replica of the exquisite sculpture of the maiden bust designed by English based Italian artist Monti in 1850."
            />
          </Helmet>
          <WebAppBar urls={navLinks} />
          <ProductPage
            name="Bust"
            description="The Fleur De Lis Living Bust is the replica of the exquisite sculpture of the maiden bust designed by English based Italian artist Monti in 1850. This figurine is very beautiful and depicts the face of a maiden with slightly downcast eyes. She wears a garland of flowers on her head and her face is covered with a veil. The details of each of the flowers, leaves, and folds of veil make this sculpture very unique. This Victorian sculpture was once considered the ultimate test of the skill of a young artist. Today, the replica of the famous 1850 carving has been rendered in faithful details to increase the beauty and appeal of your home. The bust is mounted on a museum-style base made of black resin. This exquisite hand finished sculpture has a quality designer resin construction that faithfully conveys the beauty and accuracy of the original marble sculpture. Add a touch of classical glory and taste to the decor of your home with this exquisitely designed beautiful bust. Also, you can give the Fleur De Lis Living Bust to your loved ones on special occasions."
            image={DarkAcademiaIMG5}
            brand="Fleur De Lis Living"
            type="Statue"
            price="70.99"
            url="https://www.wayfair.com/Fleur-De-Lis-Living--Bust-X114999188-L1191-K~W003888077.html?refid=GX560448448444-W003888077&device=c&ptid=1333591459850&network=g&targetid=aud-352581795486:pla-1333591459850&channel=GooglePLA&ireid=131395056&fdid=1817&gclid=CjwKCAjwqauVBhBGEiwAXOepkZYTGifd6KEPpZ6jVUZ5C7C5zCEdYCkkYmXlZqAjiHFXAvjwwSztLRoCI2oQAvD_BwE"
          />
          <Footer />
        </>
      }
    />
    <Route
      path="/wayfair/akeem-29-52-wide-loung-chair-and-ottoman-steelside-leather-type-black-coffee-faux-leather"
      element={
        <>
          <Helmet>
            <title>
              Akeem 29.52&apos;&apos; Wide Lounge Chair and Ottoman Steelside
              Leather Type: Black Coffee Faux Leather • Craftle • Craft your
              dream home
            </title>
            <meta
              name="description"
              content="Recline from 90 to 180° with this vintage-inspired lounge chair."
            />
          </Helmet>
          <WebAppBar urls={navLinks} />
          <ProductPage
            name="Akeem 29.52'' Wide Lounge Chair and Ottoman Steelside Leather Type: Black Coffee Faux Leather"
            description="Recline from 90 to 180° with this vintage-inspired lounge chair. It comes with a matching ottoman and features mixed materials to give your space a rustic vibe. Both pieces are made in the USA, and they have a solid wood frame with flared and tapered dowel legs for a modern edge. They're wrapped in faux leather upholstery, with channel tufting and foam fill to provide plenty of support as you relax. The chair also sports recessed arms and a padded back, while a side storage compartment is ideal for magazines and remotes."
            image={DarkAcademiaIMG6}
            brand="Wayfair"
            type="Lounge Chair"
            price="325.99"
            url="https://www.google.com/shopping/product/13249498113223234045?q=dark+academia+furniture&sxsrf=ALiCzsb7ZL3mzlaimRmKCXCey9hXfxhGpg:1655406435553&biw=1382&bih=801&dpr=2.5&prds=opd:2885774659783174810,rsk:PC_18207498840583086963&sa=X&ved=0ahUKEwicueeT17L4AhVTjokEHZtKDmYQlIUHCDsoAQ"
          />
          <Footer />
        </>
      }
    />
    <Route
      path="/home-furniture/ashley-norcastle-dark-brown-sofa-table"
      element={
        <>
          <Helmet>
            <title>
              Ashley Norcastle Dark Brown Sofa Table • Craftle • Craft your
              dream home
            </title>
            <meta
              name="description"
              content="Dark brown finish on select birch veneers and hardwood solids."
            />
          </Helmet>
          <WebAppBar urls={navLinks} />
          <ProductPage
            name="Ashley Norcastle Dark Brown Sofa Table"
            description="Dark brown finish on select birch veneers and hardwood solids. Cast filigree under clear beveled float glass. Shapely legs and stretchers. Rta construction. With beautiful traditional styling and a rich dark finish, the norcastle accent table collection takes sophisticated design to the next level. The rich brown finish flows smoothly over the exquisite detailing adoring the shaped legs supported by a beautifully shaped stretcher. An elegant cast filigree is beautifully displayed under a clear beveled float glass table top creating a sophisticated atmosphere. Enhance your home's beauty with the elegance of the norcastle accent table collection. Product dimensions: 48in w x 19in d x 30in hbe sure to bookmark our store and sign up with your email to retrieve our special offers."
            image={DarkAcademiaIMG7}
            brand="Home Furniture"
            type="Table"
            price="369.99"
            url="https://www.google.com/shopping/product/2254463309018450886?q=dark+academia+furniture&sxsrf=ALiCzsbyO0-w4GDw6Q9ZlbVsh1wHNSKI6w:1655406602148&biw=1382&bih=801&dpr=2.5&prds=eto:729390655429284556_0,pid:11218993991437407517,rsk:PC_953740240928859488&sa=X&ved=0ahUKEwi0goTJ17L4AhW7kYkEHUHpDao4PBDyAgj0DygA"
          />
          <Footer />
        </>
      }
    />
    <Route
      path="/article/adjustable-walnut-side-table-black-metal-base-article-portima-modern-furniture"
      element={
        <>
          <Helmet>
            <title>
              Adjustable Walnut Side Table, Black Metal Base | Article Portima
              Modern Furniture • Craftle • Craft your dream home
            </title>
            <meta
              name="description"
              content="With its adjustable height, the Portima Side Table is always exactly where you need it."
            />
          </Helmet>
          <WebAppBar urls={navLinks} />
          <ProductPage
            name="Adjustable Walnut Side Table, Black Metal Base | Article Portima Modern Furniture"
            description="With its adjustable height, the Portima Side Table is always exactly where you need it. Crafted with a solid wood top big enough to hold a standard laptop, the Portima is also perfect for keeping your after-work refreshment of choice at arm's length. Plus, the slim yet sturdy powder-coated steel base slides conveniently under your sofa or chair so you can bring everything a little bit closer if needed."
            image={DarkAcademiaIMG8}
            brand="Article"
            type="Side Table"
            price="219"
            url="https://www.google.com/shopping/product/11191727386418829307?q=dark+academia+furniture&sxsrf=ALiCzsb7ZL3mzlaimRmKCXCey9hXfxhGpg:1655406435553&biw=1382&bih=801&dpr=2.5&prds=eto:17566511559738666887_0,pid:1835096425187704294&sa=X&ved=0ahUKEwiAy-qT1rL4AhVFg4kEHQyGB5cQ8gIIsRIoAA"
          />
          <Footer />
        </>
      }
    />
    <Route
      path="/at-home/home-chesterfield-tufted-brown-faux-leather-rolled-arm-chair-40-5-l-x-28-h-x-31-5-w-fabric"
      element={
        <>
          <Helmet>
            <title>
              Home Chesterfield Tufted Brown Faux Leather Rolled Arm Chair,
              40.5&apos;&apos;L x 28.0&apos;&apos;H x 31.5&apos;&apos;W, Fabric
              • Craftle • Craft your dream home
            </title>
            <meta
              name="description"
              content="Designed with the classic Chesterfield style in mind, our ever-so-stylish piece hits all the traditional design elements."
            />
          </Helmet>
          <WebAppBar urls={navLinks} />
          <ProductPage
            name="Home Chesterfield Tufted Brown Faux Leather Rolled Arm Chair, 40.5''L x 28.0''H x 31.5''W, Fabric"
            description="Designed with the classic Chesterfield style in mind, our ever-so-stylish piece hits all the traditional design elements. Featuring a combination of leather and faux leather upholstery, deep button tufting, nailhead accents, slightly scrolled arms, and turned bun feet in an elegant dark finish, perfect for the office or library, this rich brown upholstered arm chair is the ideal sitting spot for hours of work or relaxation (by the way, it’s perfect for the living room, too)."
            image={DarkAcademiaIMG9}
            brand="At Home"
            type="Arm Chair"
            price="399.99"
            url="https://www.google.com/shopping/product/14614532644858971112?q=dark+academia+furniture&sxsrf=ALiCzsb7ZL3mzlaimRmKCXCey9hXfxhGpg:1655406435553&biw=1382&bih=801&dpr=2.5&prds=eto:1656550031187495639_0,local:1,pid:14873709598407971824,prmr:2&sa=X&ved=0ahUKEwiAy-qT1rL4AhVFg4kEHQyGB5cQ8gII_RMoAA"
          />
          <Footer />
        </>
      }
    />
    <Route
      path="/hayneedle/home-interior-mission-style-chair-with-microfiber-covering-oak-olive"
      element={
        <>
          <Helmet>
            <title>
              Home Interior Mission-Style Chair with Microfiber Covering -
              Oak/Olive • Craftle • Craft your dream home
            </title>
            <meta
              name="description"
              content="Mission Style Accent Chair Olive Champion Home Furniture Living Room Furniture Accent Chairs Traditional Accent ChairsGraceful, well-made chairs like this Mission Oak chair, olive, were inspired by American and English classic wood-crafting styles from the Arts and Crafts era."
            />
          </Helmet>
          <WebAppBar urls={navLinks} />
          <ProductPage
            name="Home Interior Mission-Style Chair with Microfiber Covering - Oak/Olive"
            description="Mission Style Accent Chair Olive Champion Home Furniture Living Room Furniture Accent Chairs Traditional Accent ChairsGraceful, well-made chairs like this Mission Oak chair, olive, were inspired by American and English classic wood-crafting styles from the Arts and Crafts era. This chair updates that design and allows it to fit into today's modern decor. Constructed with Asian rubberwood in an oak finish, this Mission chair features olive-colored microfiber upholstery. It also features steam bent arms and a foam-padded innerspring seat for added comfort.Mission Oak Chair, Olive: Steam bent arms Olive microfiber upholstery Foam-padded innerspring seat Constructed of Asian rubberwood in an oak finish Assembly required Measures 32.75''L x 33.75''W x 34.5''H"
            image={DarkAcademiaIMG10}
            brand="Hayneedle"
            type="Arm Chair"
            price="419.99"
            url="https://www.google.com/shopping/product/17100656661206561396?q=dark+academia+furniture&sxsrf=ALiCzsb7ZL3mzlaimRmKCXCey9hXfxhGpg:1655406435553&biw=1382&bih=801&dpr=2.5&prds=eto:2733836990048418463_0,pid:11910662895531905898,rsk:PC_451577727082943&sa=X&ved=0ahUKEwiAy-qT1rL4AhVFg4kEHQyGB5cQ8wII9RU"
          />
          <Footer />
        </>
      }
    />
    <Route
      path="/overstock/middlebrook-glass-top-fluted-door-nightstand-dark-walnut"
      element={
        <>
          <Helmet>
            <title>
              Middlebrook Glass Top, Fluted Door Nightstand - Dark Walnut •
              Craftle • Craft your dream home
            </title>
            <meta
              name="description"
              content="Add the finishing touch to your bedside with this fluted door nightstand."
            />
          </Helmet>
          <WebAppBar urls={navLinks} />
          <ProductPage
            name="Middlebrook Glass Top, Fluted Door Nightstand - Dark Walnut"
            description="Add the finishing touch to your bedside with this fluted door nightstand. This modern take on a bedside table features a unique display-case-esque tempered glass top above an open shelf and one door."
            image={DarkAcademiaIMG11}
            brand="Overstock"
            type="Nightstand"
            price="203.99"
            url="https://www.google.com/shopping/product/14760028789706194322?q=dark+academia+furniture&sxsrf=ALiCzsb7ZL3mzlaimRmKCXCey9hXfxhGpg:1655406435553&biw=1382&bih=801&dpr=2.5&prds=eto:9950240093588098255_0,pid:598125463055966041&sa=X&ved=0ahUKEwiAy-qT1rL4AhVFg4kEHQyGB5cQ8wIIkRU"
          />
          <Footer />
        </>
      }
    />
    <Route
      path="/aspenhome/grand-classic-door-bookcase"
      element={
        <>
          <Helmet>
            <title>
              Grand Classic Door Bookcase • Craftle • Craft your dream home
            </title>
            <meta
              name="description"
              content="Inspired by the best traditional looks, the Grand Classic Home Office Collection by Aspen Home is a stunning yet elegant fit in any home."
            />
          </Helmet>
          <WebAppBar urls={navLinks} />
          <ProductPage
            name="Grand Classic Door Bookcase"
            description="Inspired by the best traditional looks, the Grand Classic Home Office Collection by Aspen Home is a stunning yet elegant fit in any home. Accented by a stunning tobacco finish and beautiful custom knob with serpentine pull hardware, the Grand Classic collection will make it easy to express your style, your way."
            image={DarkAcademiaIMG12}
            brand="Aspenhome"
            type="Bookcase"
            price="1,369"
            url="https://www.furniturepick.com/grand-classic-door-bookcase-aspenhome-ap-i91-332.html"
          />
          <Footer />
        </>
      }
    />
    <Route
      path="/walmart/benzara-bm213452-wooden-bookcase-shelf-with-carved-details-and-filigree-accents-brown"
      element={
        <>
          <Helmet>
            <title>
              Benzara BM213452 Wooden Bookcase Shelf with Carved Details and
              Filigree Accents Brown • Craftle • Craft your dream home
            </title>
            <meta
              name="description"
              content="Saltoro Sherpi Wooden Bookcase Shelf with Carved Details and Filigree Accents, Brown Display a curated collection of meaningful books, photos, and objects in this Bookcase shelf that has a pleasing traditional look."
            />
          </Helmet>
          <WebAppBar urls={navLinks} />
          <ProductPage
            name="Benzara BM213452 Wooden Bookcase Shelf with Carved Details and Filigree Accents Brown"
            description="Saltoro Sherpi Wooden Bookcase Shelf with Carved Details and Filigree Accents, Brown Display a curated collection of meaningful books, photos, and objects in this Bookcase shelf that has a pleasing traditional look. The piece is ornately carved with detailed spiral work and filigree accents and it features 4 shelves and 1 drawer Warning: This product can expose you to chemicals including Formaldehyde, which is known to the State of California to cause cancer or birth defects or other reproductive harm."
            image={DarkAcademiaIMG13}
            brand="Walmart"
            type="Bookcase"
            price="782.86"
            url="https://www.google.com/shopping/product/501509090072714181?q=traditional+bookshelf&biw=1382&bih=801&sxsrf=ALiCzsadpELBYBrA8gnLN6MLGWZ7GRoZNA:1655406982140&uact=5&oq=traditional+bookshelf&gs_lcp=Cgtwcm9kdWN0cy1jYxADMgUIABCABDIFCAAQgAQyBAgAEBgyCAgAEB4QFhAYMggIABAeEBYQGDIKCAAQHhAPEBYQGDIKCAAQHhAPEBYQGDIICAAQHhAWEBgyCAgAEB4QFhAYMggIABAeEBYQGDoHCCMQsAMQJzoHCAAQsAMQGDoJCAAQHhCwAxAIOgsIABAeELADEAgQGDoLCK4BEMoDELADECc6BAgAEEM6CggAELEDEIMBEEM6BAgAEAM6CwgAEIAEELEDEIMBOgYIABAeEBZKBAhBGAFQrxdYrCpgzStoBnAAeACAAegBiAGOFJIBBjYuMTIuMpgBAKABAcgBB8ABAQ&sclient=products-cc&prds=pid:4711050759776201992&sa=X&ved=0ahUKEwjI8qba17L4AhUZkYkEHXRiCi4Q8gII3BgoAA"
          />
          <Footer />
        </>
      }
    />
    <Route
      path="/wayfair/astoria-grand-dolson-traditional-sofa-red"
      element={
        <>
          <Helmet>
            <title>
              Astoria Grand Dolson Traditional Sofa, Red • Craftle • Craft your
              dream home
            </title>
            <meta
              name="description"
              content="Lark Manor Transform your space with this statement-making sofa."
            />
          </Helmet>
          <WebAppBar urls={navLinks} />
          <ProductPage
            name="Astoria Grand Dolson Traditional Sofa, Red"
            description="Lark Manor Transform your space with this statement-making sofa. Made in the USA, it features an exposed frame made of solid and engineered in a rich dark brown finish and is embellished with intricate and ornate carvings. The rolled arms and turned bun feet nod to classic, traditional styles, while the apron evokes Baroque design. Textured polyester upholstery in a solid with contrasting print covers foam cushions and sinuous springs, providing comfort and support while you relax with friends. What’s more, the seven toss pillows (included) layer in texture when propped up against the pillow back. This sofa has a weight capacity of 500 Ibs."
            image={DarkAcademiaIMG14}
            brand="Wayfair"
            type="Sofa"
            price="2,439.99"
            url="https://www.google.com/shopping/product/1217450497835995801?q=traditional+couch&biw=1382&bih=801&sxsrf=ALiCzsZmSa8q_OCg14BCCzj4aw3L1cE0ew:1655407018371&uact=5&oq=traditional+couch&gs_lcp=Cgtwcm9kdWN0cy1jYxADMgUIABCABDIFCAAQgAQyBQgAEIAEMgUIABCABDIECAAQGDIGCAAQHhAWMgYIABAeEBYyBggAEB4QFjIICAAQHhAWEBgyCAgAEB4QFhAYOggIABCABBCwAzoHCAAQsAMQGDoJCAAQHhCwAxAYOgsIABAeEA8QsAMQGDoLCAAQHhCwAxAFEBg6CwgAEB4QsAMQCBAYOgsIrgEQygMQsAMQJzoLCAAQgAQQsQMQgwFKBAhBGAFQ4wxYpRBgthJoBHAAeACAAV2IAZ8DkgEBNZgBAKABAcgBEMABAQ&sclient=products-cc&prds=eto:2840784286479846566_0,pid:6979541997483484738,rsk:PC_16011520220234949102&sa=X&ved=0ahUKEwikuqjz17L4AhVbjokEHfeKA5EQ8gII8REoAA"
          />
          <Footer />
        </>
      }
    />
    <Route
      path="/1stopbedrooms/coaster-victoria-classic-button-tufted-sofa-in-brown"
      element={
        <>
          <Helmet>
            <title>
              Coaster Victoria Classic Button Tufted Sofa in Brown • Craftle •
              Craft your dream home
            </title>
            <meta
              name="description"
              content="The romance of the Victorian era comes to life and is blended with bold leather upholstery. "
            />
          </Helmet>
          <WebAppBar urls={navLinks} />
          <ProductPage
            name="Coaster Victoria Classic Button Tufted Sofa in Brown"
            description="The romance of the Victorian era comes to life and is blended with bold leather upholstery. Identifying characteristics include the carved edging across the back and base, along with lowprofile cabriole legs. You'll love the warmth of chocolate tones, too! Traditional style. Top grain tritone leather upholstery. Flared rolled arms. Decorative nail head trim and wood molding wraps. Tufted back. Button tufted diamond pattern. Three plush seat cushions. Exposed cabriole wooden legs with carved detail. 1.8 cushion with dacron wrap. Warm brown color. 86 in. L x 39 in. W x 43 in. H. Warranty. Give your home a sophisticated look with this classic rolled arm sofa."
            image={DarkAcademiaIMG15}
            brand="1StopBedrooms"
            type="Sofa"
            price="2,123.95"
            url="https://www.google.com/shopping/product/10792621119575961946?q=traditional+couch&biw=1382&bih=801&sxsrf=ALiCzsZmSa8q_OCg14BCCzj4aw3L1cE0ew:1655407018371&uact=5&oq=traditional+couch&gs_lcp=Cgtwcm9kdWN0cy1jYxADMgUIABCABDIFCAAQgAQyBQgAEIAEMgUIABCABDIECAAQGDIGCAAQHhAWMgYIABAeEBYyBggAEB4QFjIICAAQHhAWEBgyCAgAEB4QFhAYOggIABCABBCwAzoHCAAQsAMQGDoJCAAQHhCwAxAYOgsIABAeEA8QsAMQGDoLCAAQHhCwAxAFEBg6CwgAEB4QsAMQCBAYOgsIrgEQygMQsAMQJzoLCAAQgAQQsQMQgwFKBAhBGAFQ4wxYpRBgthJoBHAAeACAAV2IAZ8DkgEBNZgBAKABAcgBEMABAQ&sclient=products-cc&prds=eto:10950498811076425245_0,pid:13906862639292814261,rsk:PC_2969889320145883560&sa=X&ved=0ahUKEwikuqjz17L4AhVbjokEHfeKA5EQ8gII8RMoAA"
          />
          <Footer />
        </>
      }
    />
    <Route
      path="/wayfair/albryna-26-75-wide-armchair"
      element={
        <>
          <Helmet>
            <title>
              Albryna 26.75&apos;&apos; Wide Armchair • Craftle • Craft your
              dream home
            </title>
            <meta
              name="description"
              content="Rounding out your decor while providing sensible seating arrangements in small spaces, this armchair offers style and versatility to any interior design. "
            />
          </Helmet>
          <WebAppBar urls={navLinks} />
          <ProductPage
            name="Albryna 26.75'' Wide Armchair"
            description="Rounding out your decor while providing sensible seating arrangements in small spaces, this armchair offers style and versatility to any interior design. Take this one for example: Crafted from solid wood, the frame features four cabriole legs and an arched silhouette all awash in a neutral dark cherry finish. Upholstered with a polyester blend and filled with foam, the seat, solid back, and armrest pads boast a beige coloring and sport a damask design for a pop of pattern."
            image={DarkAcademiaIMG16}
            brand="Wayfair"
            type="Armchair"
            price="589.99"
            url="https://www.wayfair.com/Astoria-Grand--Albryna-67.95Cm-Wide-Polyester-Armchair-X116593011-L54-K~W005482900.html?refid=GX527465635140-W005482900_202084474&device=c&ptid=1330755365947&network=g&targetid=aud-352581795486:pla-1330755365947&channel=GooglePLA&ireid=59811125&fdid=1817&PiID%5B%5D=202084474&campaignid=398251723&gclid=CjwKCAjwqauVBhBGEiwAXOepkXnopbp8hLk49cCcJfoWuLl_BMV_byev8F1YxKLgoi_2n9-09DdXzBoCShwQAvD_BwE"
          />
          <Footer />
        </>
      }
    />
    <Route
      path="/efurnish/traditional-accent-chair-velvet-cushioned-seat-with-button-tufted-back-grey"
      element={
        <>
          <Helmet>
            <title>
              Traditional Accent Chair, Velvet Cushioned Seat With Button Tufted
              Back, Grey • Craftle • Craft your dream home
            </title>
            <meta
              name="description"
              content="This Wingback club chair is a perfect addition for any room in your home."
            />
          </Helmet>
          <WebAppBar urls={navLinks} />
          <ProductPage
            name="Traditional Accent Chair, Velvet Cushioned Seat With Button Tufted Back, Grey"
            description="This Wingback club chair is a perfect addition for any room in your home; The tufting and curved sides add charm to the overall design; The plush seat is comfortable without compromise"
            image={DarkAcademiaIMG17}
            brand="eFurnish"
            type="Accent Chair"
            price="519"
            url="https://www.houzz.com/products/traditional-accent-chair-velvet-cushioned-seat-with-button-tufted-back-grey-prvw-vr~184062907?m_refid=PLA_HZ_184062907"
          />
          <Footer />
        </>
      }
    />
    <Route
      path="/wayfair/makale-black-decorative-boxes-lark-manor"
      element={
        <>
          <Helmet>
            <title>
              Makale Black Decorative Boxes Lark Manor • Craftle • Craft your
              dream home
            </title>
            <meta
              name="description"
              content="Lark Manor With unique yet coordinating patterns in rich colours and elegant designs, this set of three boxes from the collection adds beauty and function to any room."
            />
          </Helmet>
          <WebAppBar urls={navLinks} />
          <ProductPage
            name="Makale Black Decorative Boxes Lark Manor"
            description="Lark Manor With unique yet coordinating patterns in rich colours and elegant designs, this set of three boxes from the collection adds beauty and function to any room. Perfect for a classic office, vintage library, or globally-inspired living room, these boxes stylishly organize and contain clutter. Pair with the matching decorative trays to create a cohesive look in your space."
            image={DarkAcademiaIMG18}
            brand="Wayfair"
            type="Boxes"
            price="54.99"
            url="https://www.google.com/shopping/product/12700643328912726907?q=traditional+box&biw=1382&bih=801&sxsrf=ALiCzsZoI0trllYPYPlenOPAmy6L1dgl8w:1655407120259&uact=5&oq=traditional+box&gs_lcp=Cgtwcm9kdWN0cy1jYxADMgUIABCABDIFCAAQgAQyBQgAEIAEMgUIABCABDIFCAAQgAQyBQgAEIAEMgUIABCABDIECAAQGDIECAAQGDIECAAQGDoICAAQgAQQsAM6CQgAEB4QsAMQBzoJCAAQHhCwAxAFOgkIABAeELADEAg6CQgAEB4QsAMQGDoLCAAQHhAPELADEBg6CwiuARDKAxCwAxAnOgQIIxAnSgQIQRgBUNAHWPMJYIgLaAJwAHgAgAFZiAH4AZIBATOYAQCgAQHIARDAAQE&sclient=products-cc&prds=eto:13717041244781852935_0,pid:4098870992432654214,rsk:PC_8844350286627489659&sa=X&ved=0ahUKEwjL3u-z2LL4AhUOhIkEHQbhAUEQ8gIIiAsoAA"
          />
          <Footer />
        </>
      }
    />
    <Route
      path="/wayfair/alie-traditional-beveled-distressed-accent-mirror-finish-rust"
      element={
        <>
          <Helmet>
            <title>
              Alie Traditional Beveled Distressed Accent Mirror Finish: Rust •
              Craftle • Craft your dream home
            </title>
            <meta
              name="description"
              content="Designed to mimic antique porthole windows, this wall mirror brings a splash of nautical style to any space in your home."
            />
          </Helmet>
          <WebAppBar urls={navLinks} />
          <ProductPage
            name="Alie Traditional Beveled Distressed Accent Mirror Finish: Rust"
            description="Designed to mimic antique porthole windows, this wall mirror brings a splash of nautical style to any space in your home. Crafted from glass, this 22'' round piece is set in a metal frame with a distressed brown rust finish that enhances its vintage-inspired feel. Nailhead accents offer a dash of industrial influence, while beveled edges complete the look with a touch of classic character. Wall-mounting hardware is not included."
            image={DarkAcademiaIMG19}
            brand="Wayfair"
            type="Mirror"
            price="123"
            url="https://www.google.com/shopping/product/1817063172358171433?q=traditional+mirror&biw=1382&bih=801&sxsrf=ALiCzsaWootvmhwN8uzPnnZVVZpaj9GXZA:1655407206210&uact=5&oq=traditional+mirror&gs_lcp=Cgtwcm9kdWN0cy1jYxADMgUIABCABDIFCAAQgAQyBQgAEIAEMgUIABCABDIFCAAQgAQyBAgAEBgyBAgAEBgyBAgAEBgyBggAEB4QFjIGCAAQHhAWOggIABCABBCwAzoHCAAQsAMQGDoLCK4BEMoDELADECc6CwgAEIAEELEDEIMBSgQIQRgBUOMEWJMJYKgKaAJwAHgAgAGJAYgB5wSSAQMzLjOYAQCgAQHIARDAAQE&sclient=products-cc&prds=eto:4165158100908007824_0,pid:8444268806718031113,rsk:PC_10358024954413677484&sa=X&ved=0ahUKEwjmkJTQ2LL4AhWekYkEHQNxAMMQ8gIItRMoAA"
          />
          <Footer />
        </>
      }
    />
    <Route
      path="/wayfair/astoria-grand-rogan-wall-mirror-finish-classic-black"
      element={
        <>
          <Helmet>
            <title>
              Astoria Grand Rogan Wall Mirror Finish Classic Black • Craftle •
              Craft your dream home
            </title>
            <meta
              name="description"
              content="Warm and whimsical, this elegant wall mirror brims with vintaged allure."
            />
          </Helmet>
          <WebAppBar urls={navLinks} />
          <ProductPage
            name="Astoria Grand Rogan Wall Mirror Finish Classic Black"
            description="Warm and whimsical, this elegant wall mirror brims with vintaged allure. Lend worldly appeal to your study with stately arm chairs, scrolling side tables, and antiqued clocks, or let patterned pillows, blooming faux florals, and weathered wall art add."
            image={DarkAcademiaIMG20}
            brand="Wayfair"
            type="Mirror"
            price="599.99"
            url="https://www.google.com/shopping/product/10545679247649453726?q=traditional+mirror&biw=1382&bih=801&sxsrf=ALiCzsaWootvmhwN8uzPnnZVVZpaj9GXZA:1655407206210&uact=5&oq=traditional+mirror&gs_lcp=Cgtwcm9kdWN0cy1jYxADMgUIABCABDIFCAAQgAQyBQgAEIAEMgUIABCABDIFCAAQgAQyBAgAEBgyBAgAEBgyBAgAEBgyBggAEB4QFjIGCAAQHhAWOggIABCABBCwAzoHCAAQsAMQGDoLCK4BEMoDELADECc6CwgAEIAEELEDEIMBSgQIQRgBUOMEWJMJYKgKaAJwAHgAgAGJAYgB5wSSAQMzLjOYAQCgAQHIARDAAQE&sclient=products-cc&prds=eto:4824287901745241529_0,pid:550150075090472135,rsk:PC_3100816083338268538&sa=X&ved=0ahUKEwjmkJTQ2LL4AhWekYkEHQNxAMMQ8gII0hcoAA"
          />
          <Footer />
        </>
      }
    />
    <Route
      path="/wayfair/ringwold-27-5-wide-armchair"
      element={
        <>
          <Helmet>
            <title>
              Ringwold 27.5&apos;&apos; Wide Armchair • Craftle • Craft your
              dream home
            </title>
            <meta
              name="description"
              content="Bring modern comfort into your home with this armchair with solid wood legs."
            />
          </Helmet>
          <WebAppBar urls={navLinks} />
          <ProductPage
            name="Ringwold 27.5'' Wide Armchair"
            description="Bring modern comfort into your home with this armchair with solid wood legs. This stylish armchair features teal blue fabric with dark-finished wood legs for a beautiful and comfortable addition to any space. This mid-back chair is designed with dense foam padding to provide superior comfort over time. Constructed from durable fabric and wood, this living room chair is built to provide lasting use. For quick assembly and easy cleaning, this armchair includes step-by-step instructions. Purchase this chair alone or with a high-quality Ebern designs side table and lamp for a complete reading nook. For easy rearranging."
            image={DarkAcademiaIMG21}
            brand="Wayfair"
            type="Armchair"
            price="319.99"
            url="https://www.wayfair.com/Ebern-Designs--Ringwold-69.85Cm-Wide-Polyester-Armchair-X116516310-L54-K~W005405209.html?refid=GX523493935915-W005405209_1672461610&device=c&ptid=1304711861748&network=g&targetid=aud-352581795486:pla-1304711861748&channel=GooglePLA&ireid=40774324&fdid=1817&PiID%5B%5D=1672461610&campaignid=398251723&gclid=CjwKCAjwqauVBhBGEiwAXOepkZ57D847ARgJvanR6rqqXyTGEJRul_jt6_6DerbJXR_DdsprNWaDdhoCgXAQAvD_BwE"
          />
          <Footer />
        </>
      }
    />
    <Route
      path="/wayfair/dianalee-secretary-desk"
      element={
        <>
          <Helmet>
            <title>
              Dianalee Secretary Desk • Craftle • Craft your dream home
            </title>
            <meta name="description" content="" />
          </Helmet>
          <WebAppBar urls={navLinks} />
          <ProductPage
            name="Dianalee Secretary Desk"
            description=""
            image={DarkAcademiaIMG22}
            brand="Wayfair"
            type="Desk"
            price="3,299.99"
            url="https://www.wayfair.com/Bungalow-Rose--Dianalee-Secretary-Desk-X111352721-L32-K~W000241610.html?refid=GX444304079481-W000241610&device=c&ptid=902660081001&network=g&targetid=aud-352581795486:pla-902660081001&channel=GooglePLA&ireid=8613764&fdid=1817&gclid=CjwKCAjwqauVBhBGEiwAXOepkQjXHogV1lMa9wH2yav61j_V96F5rgFOR80O45XWIY2NgDQHRSAIHxoCZ_0QAvD_BwE"
          />
          <Footer />
        </>
      }
    />
    <Route
      path="/furniture-of-america/lewis-writing-desk-dark-walnut"
      element={
        <>
          <Helmet>
            <title>
              Lewis Writing Desk - Dark Walnut • Craftle • Craft your dream home
            </title>
            <meta
              name="description"
              content="The classic writing desk is a piece of furniture that doesn't seem to go out of style, doesn't matter if you're writing by hand or typing on a computer everyone needs a space to work."
            />
          </Helmet>
          <WebAppBar urls={navLinks} />
          <ProductPage
            name="Lewis Writing Desk - Dark Walnut"
            description="The classic writing desk is a piece of furniture that doesn't seem to go out of style, doesn't matter if you're writing by hand or typing on a computer everyone needs a space to work. This traditionally inspired design features a contour cut so you can sit just a bit closer to your work and adds a nice visual to the piece. This minimally adorned piece rests atop turned wooden legs to a final touch of classic design appeal."
            image={DarkAcademiaIMG23}
            brand="Furniture of America"
            type="Desk"
            price="1,215"
            url="https://www.topdrawerfurniturestore.com/products/lewis-writing-desk-dark-walnut?variant=39306707632212&currency=USD&gclid=CjwKCAjwqauVBhBGEiwAXOepkWZeGzzXVCxCJUsuS1HmzVCYBpmgjx49F0YaUIUS9YdJxshw96Hp_hoCFlEQAvD_BwE"
          />
          <Footer />
        </>
      }
    />

    {/* mean girls section */}
    <Route
      path="/furniture/zuri/mora-lounge-chair"
      element={
        <>
          <Helmet>
            <title>MORA LOUNGE CHAIR • Craftle • Craft your dream home</title>
            <meta
              name="description"
              content="The Mora Lounge Chair is an avant-garde masterpiece that will stand out anywhere you put it."
            />
          </Helmet>
          <WebAppBar urls={navLinks} />
          <ProductPage
            name="MORA LOUNGE CHAIR"
            description="The Mora Lounge Chair is an avant-garde masterpiece that will stand out anywhere you put it. Constructed from the highest quality Italian aniline-dyed top grain leather in exuberant red or classic off-white, this lounge chair exudes both comfort and durability. It features an eye-catching design with a wide T-shaped tufted back and a curved seat that tapers down to an inverted cone shape. This central support is offset by a black tinted base of polished stainless steel that swivels freely. The Mora Lounge chair defies the norm and is sure to spice up your living room or reception area!"
            image={MeanGirls1}
            brand="Zuri"
            type="Loung Chair"
            price="1,665"
            url="https://www.zurifurniture.com/living/mora-swivel-chair?gclid=Cj0KCQjwwJuVBhCAARIsAOPwGATLTtLf5nzv3tf4dQ5_UCKuVfXjkfNLZl5pHg86BgqWvi_6xqmOIloaAmHAEALw_wcB"
            productImg1={MeanGirlsProductPageIMG1}
            productImg1Credit1="Source: Paramount Pictures"
            productImg1Credit2=""
            description2="Fashion forward with its own unique style, this lounge chair perfectly encapsulates Cady Heron's character and fashion sense. And doesn't it look like the red lips on the burn book? 💋"
          />
          <Footer />
        </>
      }
    />
    <Route
      path="/furniture/wayfair/stellar-39-5-wide-velvet-lounge-chair"
      element={
        <>
          <Helmet>
            <title>
              Steller 39.5&apos;&apos; Wide Velvet Lounge Chair • Craftle •
              Craft your dream home
            </title>
            <meta
              name="description"
              content="Includes one (1) shoe chair. Made of manufactured wood. Upholstered in either faux leather or velvet."
            />
          </Helmet>
          <WebAppBar urls={navLinks} />
          <ProductPage
            name="Steller 39.5'' Wide Velvet Lounge Chair"
            description="Includes one (1) shoe chair. Made of manufactured wood. Upholstered in either faux leather or velvet. Has firm comfort support. The top and back panel comes tufted with crystal-like studs. It is embellished with stitched around the detailing with supportive legs to prevent the floor from being scratched. Fully assembled dimension 19''L x 40'' D x 40''H. Seat height 17. Seat depth 20."
            image={MeanGirls2}
            brand="House of Hampton®"
            type="Lounge Chair"
            price="349.99"
            url="https://www.wayfair.com/furniture/pdp/house-of-hampton-steller-395-wide-velvet-lounge-chair-w004193535.html?piid=291204119"
            productImg1={MeanGirlsProductPageIMG2}
            productImg1Credit1="Source: Paramount Pictures"
            productImg1Credit2=""
            description2='Pinkish-white with a flare of drama, this "high heel" lounge chair is the definition of Regina George. Don&apos;t turn away, because one of these days this shoe will walk all over you 👠'
          />
          <Footer />
        </>
      }
    />
    <Route
      path="/furniture/lamps-plus/uptown-ancient-stewart-red-fabric-swoop-armchair"
      element={
        <>
          <Helmet>
            <title>
              Uptown Ancient Stewart Red Fabric Swoop Armchair • Craftle • Craft
              your dream home
            </title>
            <meta
              name="description"
              content="A classic sophisticated appeal and tasteful modern vibe follows from this contemporary swoop armchair."
            />
          </Helmet>
          <WebAppBar urls={navLinks} />
          <ProductPage
            name="Uptown Ancient Stewart Red Fabric Swoop Armchair"
            description="A classic sophisticated appeal and tasteful modern vibe follows from this contemporary swoop armchair."
            image={MeanGirls3}
            brand="Lamps Plus"
            type="Accent Chair"
            price="577.98"
            url="https://www.lampsplus.com/sfp/12R66/?cm_mmc=GOO-SH-_-NA-_-NA-_-12R66&sourceid=DFGPD12R66&gclid=Cj0KCQjwwJuVBhCAARIsAOPwGAT-ieK3XBR4i4nrXl6csx3TZhFsCdqgu8z-t7uvSeMDgBNdFlpko9gaAne5EALw_wcB"
            productImg1={MeanGirlsProductPageIMG3}
            productImg1Credit1="Source: Paramount Pictures"
            productImg1Credit2=""
            description2="This plaid and red combo is so fetch, even Gretchen Wieners can't stay away from it. But shhh...this is just our little secret 👩🏼‍🦰"
          />
          <Footer />
        </>
      }
    />
    <Route
      path="/furniture/wayfair/shurtz-67-velvet-curved-loveseat"
      element={
        <>
          <Helmet>
            <title>
              Shurtz 67&apos;&apos; Velvet Curved Loveseat • Craftle • Craft
              your dream home
            </title>
            <meta
              name="description"
              content="This loveseat's curving design high-low back give it some major Old Hollywood vibes that we're honestly all about."
            />
          </Helmet>
          <WebAppBar urls={navLinks} />
          <ProductPage
            name="Shurtz 67'' Velvet Curved Loveseat"
            description="Real talk: This loveseat's curving design high-low back give it some major Old Hollywood vibes that we're honestly all about. It's made with a solid wood frame, and has a curving seat and a back that's higher on one end and lower on the other for a look that breaks up blocky silhouettes. Plus, with the pocket spring seat construction and foam-filled velvet upholstery, it's not just stylish, it's supportive too. This loveseat requires 24'' of doorway clearance."
            image={MeanGirls4}
            brand="Willa Arlo™ Interiors"
            type="Loveseat"
            price="1,189.99"
            url="https://www.wayfair.com/furniture/pdp/willa-arlo-interiors-shurtz-67-velvet-curved-loveseat-w005270346.html?piid=630631664"
            productImg1={MeanGirlsProductPageIMG4}
            productImg1Credit1="Source: Paramount Pictures"
            productImg1Credit2=""
            description2='Karen Smith is all about the cutesy, girly things in life, and this pink velvet loveseat is what I&apos;d expect to see in her house. Especially on a Wednesday, because "on Wedensdays, we wear pink!"'
          />
          <Footer />
        </>
      }
    />
    <Route
      path="/furniture/zuri/malaga-swing-chair-black"
      element={
        <>
          <Helmet>
            <title>
              MALAGA SWING CHAIR - BLACK • Craftle • Craft your dream home
            </title>
            <meta
              name="description"
              content="Curl up with a good book and your favorite drink inside the airy Malaga Swing Chair."
            />
          </Helmet>
          <WebAppBar urls={navLinks} />
          <ProductPage
            name="MALAGA SWING CHAIR - BLACK"
            description="Curl up with a good book and your favorite drink inside the airy Malaga Swing Chair. The cozy design features a unique imitation rattan basket with waterproof polyester padded cushion. A great accent piece for any living room, deck, or patio. The openwork weaving allows for unobstructed conversation with friends so feel free to pair them up! Ideal for the modern tropical or retro homes. The swing chair is supported by a heavy steel frame. The basket is attached with a heavy duty chain that can be adjusted to accommodate a variety of seat heights most comfortable for you. If using outdoors, we recommend storing the cushions inside or covered to preserve the colors. The Malaga is available in either a white or black basket and frame with a variety of cushion colors! Suggested weight capacity is 265 pounds."
            image={MeanGirls5}
            brand="Zuri"
            type="Swing Chair"
            price="661"
            url="https://www.zurifurniture.com/outdoor/malaga-swing-chair-black"
            productImg1={MeanGirlsProductPageIMG5}
            productImg1Credit1="Source: Paramount Pictures"
            productImg1Credit2=""
            description2="Is this not the perfect chair for Janice Ian to be swinging on while plotting her revenge?"
          />
          <Footer />
        </>
      }
    />
    <Route
      path="/furniture/wayfair/koopman-54-recessed-arm-loveseat"
      element={
        <>
          <Helmet>
            <title>
              Koopman 54&apos;&apos; Recessed Arm Loveseat • Craftle • Craft
              your dream home
            </title>
            <meta
              name="description"
              content="Modern loveseat sofa to suit any office or home."
            />
          </Helmet>
          <WebAppBar urls={navLinks} />
          <ProductPage
            name="Koopman 54'' Recessed Arm Loveseat"
            description="Modern loveseat sofa to suit any office or home."
            image={MeanGirls6}
            brand="House of Hampton®"
            type="Loveseat"
            price="829.99"
            url="https://www.wayfair.com/furniture/pdp/house-of-hampton-koopman-54-recessed-arm-loveseat-hcrd1473.html?piid=27103993"
            productImg1={MeanGirlsProductPageIMG6}
            productImg1Credit1="Source: Paramount Pictures"
            productImg1Credit2=""
            description2="This loveseat's perfect blend of feminine and masculine matches Damian's personality and fashion. Cause it's beautiful, in every single way!"
          />
          <Footer />
        </>
      }
    />
    <Route
      path="/furniture/mojow/yomi-outdoor-metal-patio-sofa"
      element={
        <>
          <Helmet>
            <title>
              Yomi Outdoor Metal Patio Sofa • Craftle • Craft your dream home
            </title>
            <meta
              name="description"
              content="Modern sofa with a timeless French design, produced in hyper resistant and eco-responsible materials, the sleek and fun style of your Yomi Eko will introduce a unique and trendy look to your interior."
            />
          </Helmet>
          <WebAppBar urls={navLinks} />
          <ProductPage
            name="Yomi Outdoor Metal Patio Sofa"
            description="Modern sofa with a timeless French design, produced in hyper resistant and eco-responsible materials, the sleek and fun style of your Yomi Eko will introduce a unique and trendy look to your interior. This indoor furniture, but also outdoor, is easy to maintain and will easily find its place at the edge of the swimming pool. On your terrace, a comfortable designer sofa, in a unique piece, will enhance your garden furniture. Assemble the Mojow design outdoor armchairs and sofas, to create your modern, elegant and original design garden furniture."
            image={MeanGirls7}
            brand="Mojow"
            type="Sofa"
            price="820"
            url="https://www.perigold.com/Mojow--Yomi-59-Wide-Outdoor-Patio-Sofa-with-Cushions-CYC-L118-K~MOJW1044.html?refid=GX583870985161-MOJW1044_66655388_66655389&device=c&ptid=1637972322378&targetid=aud-1644276160773:pla-1637972322378&ireid=187245989&PiID%5B%5D=66655388&PiID%5B%5D=66655389&gclid=Cj0KCQjwwJuVBhCAARIsAOPwGASzjDMXtbIBnWhqTv5TeHc2xySHiiMDlWgzVPbf8kfEfU7t2-_T5BgaAnVUEALw_wcB"
            productImg1={MeanGirlsProductPageIMG7}
            productImg1Credit1="Source: Paramount Pictures"
            productImg1Credit2=""
            description2="Aaron Samuels is the golden boy who often wears different shades of blue. This blue patio sofa will forever be timeless and our love interest...like Aaron Samuel's hair 🙋‍♂️"
          />
          <Footer />
        </>
      }
    />

    {/* weird as f*** section */}
    <Route
      path="/furniture/hollypalmerfurniture/tea-cup-stool-alice-in-wonderland-designer-seating"
      element={
        <>
          <Helmet>
            <title>
              Tea Cup Stool - Alice in Wonderland - Designer Seating • Craftle •
              Craft your dream home
            </title>
            <meta
              name="description"
              content="Inspired by Alice in Wonderland and surrealism, the tea cup stool is a quirky statement piece that has functionality as a seating solution."
            />
          </Helmet>
          <WebAppBar urls={navLinks} />
          <ProductPage
            name="Tea Cup Stool - Alice in Wonderland - Designer Seating"
            description="Inspired by Alice in Wonderland and surrealism, the tea cup stool is a quirky statement piece that has functionality as a seating solution."
            image={WeirdIMG1}
            brand="HollyPalmerFurniture"
            type="Accent Chair"
            price="233.41"
            url="https://www.etsy.com/listing/230211323/tea-cup-stool-alice-in-wonderland?gpla=1&gao=1&"
          />
          <Footer />
        </>
      }
    />
    <Route
      path="/furniture/fatty-bee/face-vase"
      element={
        <>
          <Helmet>
            <title>Face Vase • Craftle • Craft your dream home</title>
            <meta
              name="description"
              content="If you are looking for an aesthetic vase, look no further."
            />
          </Helmet>
          <WebAppBar urls={navLinks} />
          <ProductPage
            name="Face Vase"
            description="🌷Aesthetic Home Decor🌷: If you are looking for an aesthetic vase, look no further. This is the original body vase, perfect for your aesthetic bathroom decor or artsy room decor. This bathroom decor boho piece is inspired by Female form/feminist decor."
            image={WeirdIMG2}
            brand="Fatty Bee"
            type="Vase"
            price="36.95"
            url="https://www.amazon.com/Modern-Minimalism-Ceramic-Sculpture-Decoration/dp/B07ZMQ13RJ/ref=sr_1_69?keywords=weird%2Bfurniture&qid=1654966756&sr=8-69&th=1"
          />
          <Footer />
        </>
      }
    />
    <Route
      path="/furniture/homary-acrylic-end-table/homary-acrylic-end-table"
      element={
        <>
          <Helmet>
            <title>
              Homary Acrylic End Table • Craftle • Craft your dream home
            </title>
            <meta
              name="description"
              content="Boasting a graceful, sleek design, the glamorous round acrylic top is adorned by three geometrical legs to showcase a cleverish silhouette."
            />
          </Helmet>
          <WebAppBar urls={navLinks} />
          <ProductPage
            name="Homary Acrylic End Table"
            description="Unique Design: Boasting a graceful, sleek design, the glamorous round acrylic top is adorned by three geometrical legs to showcase a cleverish silhouette."
            image={WeirdIMG3}
            brand="Homary"
            type="Side Table"
            price="191.99"
            url="https://www.amazon.com/Homary-Acrylic-Modern-Accent-Iridescent/dp/B08B3PYFMC/ref=sr_1_54?keywords=weird+furniture&qid=1654966589&sr=8-54"
          />
          <Footer />
        </>
      }
    />
    <Route
      path="/furniture/woodcurve/handmade-4-ft-curved-bookshelf-oak-stained"
      element={
        <>
          <Helmet>
            <title>
              Handmade 4ft Curved Bookshelf Oak Stained • Craftle • Craft your
              dream home
            </title>
            <meta
              name="description"
              content="Beautiful handmade in the USA, curved bookshelf...Built of 3/4'' oak plywood with a stain and flat black enamel finish. This piece is original and will compliment any room."
            />
          </Helmet>
          <WebAppBar urls={navLinks} />
          <ProductPage
            name="Handmade 4ft Curved Bookshelf Oak Stained"
            description="Beautiful handmade in the USA, curved bookshelf...Built of 3/4'' oak plywood with a stain and flat black enamel finish. This piece is original and will compliment any room."
            image={WeirdIMG4}
            brand="WoodCurve"
            type="Bookshelf"
            price="799.99"
            url="https://www.etsy.com/listing/63344489/free-shippinghandmade-4ft-curved?ga_order=most_relevant&ga_search_type=all&ga_view_type=gallery&ga_search_query=unique+furniture&ref=sr_gallery-1-9&frs=1"
          />
          <Footer />
        </>
      }
    />
    <Route
      path="/furniture/ilikepar/mysterious-gummy-bear-scented-candle"
      element={
        <>
          <Helmet>
            <title>
              Mysterious Gummy Bear Scented Candle • Craftle • Craft your dream
              home
            </title>
            <meta
              name="description"
              content="Pack of 1 candle with character Gummy Bear(Secret Hidden Inside) (Material: vegetable wax 85%±5%,beeswax 15%±5%) It smells sweet like candy."
            />
          </Helmet>
          <WebAppBar urls={navLinks} />
          <ProductPage
            name="Mysterious Gummy Bear Scented Candle"
            description="Pack of 1 candle with character Gummy Bear(Secret Hidden Inside) (Material: vegetable wax 85%±5%,beeswax 15%±5%) It smells sweet like candy."
            image={WeirdIMG5}
            brand="iLikePar"
            type="Candle"
            price="15.99"
            url="https://www.amazon.com/Mysterious-Scented-Candle-Secret-Hidden/dp/B08HXRRGYJ/ref=sr_1_71_sspa?keywords=weird%2Bfurniture&qid=1654966756&sr=8-71-spons&smid=A4WQTF6RNMSBT&spLa=ZW5jcnlwdGVkUXVhbGlmaWVyPUExU0pDWjQyR01TUDNRJmVuY3J5cHRlZElkPUEwMjc0MTcwSDJLSFlZWFJBNFhYJmVuY3J5cHRlZEFkSWQ9QTA4OTc4NDUyQk1HOTk3WUsxTDUzJndpZGdldE5hbWU9c3BfbXRmJmFjdGlvbj1jbGlja1JlZGlyZWN0JmRvTm90TG9nQ2xpY2s9dHJ1ZQ&th=1"
          />
          <Footer />
        </>
      }
    />
    <Route
      path="/furniture/rogue-wolf/witch-couldron-coffee-mug-in-gift-box-by-rogue"
      element={
        <>
          <Helmet>
            <title>
              Witch Cauldron Coffee Mug in Gift Box by Rogue • Craftle • Craft
              your dream home
            </title>
            <meta
              name="description"
              content="Wickedly detailed Witches Cauldron Mug for your coffee, tea, pumpkin latte or magical brew!"
            />
          </Helmet>
          <WebAppBar urls={navLinks} />
          <ProductPage
            name="Witch Cauldron Coffee Mug in Gift Box by Rogue"
            description="Wickedly detailed Witches Cauldron Mug for your coffee, tea, pumpkin latte or magical brew! Sculpted with amazing mystical designs into a shape of caldron on a base of fire logs to remind of an actual cast iron cauldron. The details are amazing, it's very spacious 14 oz / 400ml and made of black porcelain. If you’re a promising wizard, witch or any other magical being, this spacious coffee mug will be your fave drinking accessory and a precious addition to your wiccan altar supplies."
            image={WeirdIMG6}
            brand="Rogue + Wolf"
            type="Coffee Mug"
            price="22.70"
            url="https://www.amazon.com/Rogue-Wolf-Porcelain-Halloween-Witchcraft/dp/B074W5XDMF/ref=sr_1_72_sspa?keywords=weird+furniture&qid=1654966756&sr=8-72-spons&psc=1&spLa=ZW5jcnlwdGVkUXVhbGlmaWVyPUExU0pDWjQyR01TUDNRJmVuY3J5cHRlZElkPUEwMjc0MTcwSDJLSFlZWFJBNFhYJmVuY3J5cHRlZEFkSWQ9QTAwNzQxNjUxMEpINTZRQVRKMVJXJndpZGdldE5hbWU9c3BfbXRmJmFjdGlvbj1jbGlja1JlZGlyZWN0JmRvTm90TG9nQ2xpY2s9dHJ1ZQ=="
          />
          <Footer />
        </>
      }
    />
    <Route
      path="/furniture/hroome/unique-beside-table-lamp-cat"
      element={
        <>
          <Helmet>
            <title>
              Unique Bedside Table Lamp Cat • Craftle • Craft your dream home
            </title>
            <meta
              name="description"
              content="The Cat desk lamp with 3-Way light temperature of settings (Low, Medium, High, On/Off)."
            />
          </Helmet>
          <WebAppBar urls={navLinks} />
          <ProductPage
            name="Unique Bedside Table Lamp Cat"
            description="DIMMABLE TOUCH SENSOR CONTROL SWITCH: The Cat desk lamp with 3-Way light temperature of settings (Low, Medium, High, On/Off). Low setting light is perfect for nightstand mode; Medium setting light is suitable for daily use; High setting light is very bright for reading mode. Lightly tap the desk lamp Cat head to dim the light levels to meet your various needs. Add a warm white lamp and comfortable atmosphere to your bedroom or living room."
            image={WeirdIMG7}
            brand="HROOME"
            type="Table Lamp"
            price="49.98"
            url="https://www.amazon.com/HROOME-Bedside-Adjustable-Dimmable-Bookcase/dp/B091FSBCB1/ref=sr_1_74?keywords=weird%2Bfurniture&qid=1654966756&sr=8-74&th=1"
          />
          <Footer />
        </>
      }
    />
    <Route
      path="/furniture/helenromanenko/alice-in-wonderland-furniture-personalized-baby-book-box"
      element={
        <>
          <Helmet>
            <title>
              Alice in Wonderland furniture personalized baby book box • Craftle
              • Craft your dream home
            </title>
            <meta
              name="description"
              content="The chest is like a selection of books about Alice's adventures in Wonderland in calm colors, but, as usual, with interesting accents."
            />
          </Helmet>
          <WebAppBar urls={navLinks} />
          <ProductPage
            name="Alice in Wonderland furniture personalized baby book box"
            description="The chest is like a selection of books about Alice's adventures in Wonderland in calm colors, but, as usual, with interesting accents. It will fit especially harmoniously into the interior where books are loved, especially Fairy Tales. Mini chest of drawers for true lovers of stories about Alice in Wonderland! All the items that are on the mini chest of drawers are included in the kit. A table with a bottle on it is not included in the set.
                  There four drawers in the form of old books held together. Artificially aged. Completed in decoupage technique (Alice's picture in a frame), 3D modeling. The ends are covered with voluminous relief in the form of handwriting in English. Accessories metal, toned in color antique bronze."
            image={WeirdIMG8}
            brand="HelenRomanenko"
            type="Jewelry Box"
            price="280"
            url="https://www.etsy.com/listing/1221098371/alice-in-wonderland-furniture?ga_order=most_relevant&ga_search_type=all&ga_view_type=gallery&ga_search_query=alice+in+wonderland+furniture&ref=sr_gallery-1-20&sts=1"
          />
          <Footer />
        </>
      }
    />
    <Route
      path="/furniture/design-toscano/vintage-decor-stacked-books-end-table-with-glass-top"
      element={
        <>
          <Helmet>
            <title>
              Vintage Decor Stacked Books End Table with Glass Top • Craftle •
              Craft your dream home
            </title>
            <meta
              name="description"
              content="Enjoy the look of worn leather spined books with faux gold gilding when you place this clever table at your bedside as a bedroom night stand."
            />
          </Helmet>
          <WebAppBar urls={navLinks} />
          <ProductPage
            name="Vintage Decor Stacked Books End Table with Glass Top"
            description="FURNITURE FOR BIBLIOPHILES - Enjoy the look of worn leather spined books with faux gold gilding when you place this clever table at your bedside as a bedroom night stand"
            image={WeirdIMG9}
            brand="Design Toscano"
            type="End Table"
            price="238.26"
            url="https://www.amazon.com/Design-Toscano-Vintage-Stacked-Polyresin/dp/B002VM47SG/ref=sr_1_38?keywords=weird+furniture&qid=1654966589&sr=8-38"
          />
          <Footer />
        </>
      }
    />
    <Route
      path="/furniture/1st-dibs/black-velvet-lounge-chair-with-solid-brass-legs-and-hardware"
      element={
        <>
          <Helmet>
            <title>
              Black Velvet Lounge Chair with Solid Brass Legs and Hardware •
              Craftle • Craft your dream home
            </title>
            <meta
              name="description"
              content="The Star lounger represents the highest form of design, art and function."
            />
          </Helmet>
          <WebAppBar urls={navLinks} />
          <ProductPage
            name="Black Velvet Lounge Chair with Solid Brass Legs and Hardware"
            description="The Star lounger represents the highest form of design, art and function. Made with the best materials and craftsmanship the lounge chair marries humour and functionality. Not meant to just be observed like a statue the Star Lounger is also meant to be enjoyed, lounged on and used."
            image={WeirdIMG10}
            brand="1st Dibs"
            type="Lounge Chair"
            price="45,000"
            url="https://www.1stdibs.com/furniture/seating/lounge-chairs/black-velvet-lounge-chair-solid-brass-legs-hardware/id-f_20093082/?utm_content=condensed&allowUniversalLink=no&gclsrc=aw.ds&gclid=Cj0KCQjw-pCVBhCFARIsAGMxhAc2z8YFbKdCfSJGtLYzw-18BJZSFtU0V0Z61sUizR0utT0k6NBq3vsaAnYTEALw_wcB&gclsrc=aw.ds"
          />
          <Footer />
        </>
      }
    />
    <Route
      path="/furniture/wayfair/costanza-43-wide-tufted-genuine-leather-armchair"
      element={
        <>
          <Helmet>
            <title>
              Costanza 43&apos;&apos; Wide Tufted Genuine Leather Armchair •
              Craftle • Craft your dream home
            </title>
            <meta
              name="description"
              content="Give yourself the royal treatment with this distinctive leather armchair."
            />
          </Helmet>
          <WebAppBar urls={navLinks} />
          <ProductPage
            name="Costanza 43'' Wide Tufted Genuine Leather Armchair"
            description="Give yourself the royal treatment with this distinctive leather armchair. It’s made of solid pine with foam padding and genuine cow leather upholstery in white. This chair features a 72” high back with a scrolled top, rolled arms, and turned rubber wood legs in a silver finish. Mirrored panels on the sides of the back and on the chair front reflect the light in your space, while button tufting on the back and seat elevate the look."
            image={WeirdIMG11}
            brand="Willa Arlo™ Interiors"
            type="Armchair"
            price="1,699.99"
            url="https://www.wayfair.com/furniture/pdp/willa-arlo-interiors-costanza-43-wide-tufted-leather-match-armchair-w003806696.html"
          />
          <Footer />
        </>
      }
    />
    <Route
      path="/furniture/alibaba/antique-designer-strange-loung-chair-rabbit-fabric-chair"
      element={
        <>
          <Helmet>
            <title>
              Antique Designer Strange lounge chair Rabbit fabric chair •
              Craftle • Craft your dream home
            </title>
            <meta name="description" content="" />
          </Helmet>
          <WebAppBar urls={navLinks} />
          <ProductPage
            name="Antique Designer Strange lounge chair Rabbit fabric chair"
            description=""
            image={WeirdIMG12}
            brand="Alibaba"
            type="Loung Chair"
            price="350"
            url="https://www.alibaba.com/product-detail/Antique-Designer-Strange-lounge-chair-Rabbit_1600097123732.html?mark=google_shopping&seo=1"
          />
          <Footer />
        </>
      }
    />
    <Route
      path="/furniture/freezah/jotaro-kujo-from-Jojos-bizarre-adventure-affected-by-whitesnake-credenza"
      element={
        <>
          <Helmet>
            <title>
              Jotaro Kujo from Jojo&apos;s bizarre adventure affected by
              Whitesnake Credenza • Craftle • Craft your dream home
            </title>
            <meta name="description" content="A true statement maker." />
          </Helmet>
          <WebAppBar urls={navLinks} />
          <ProductPage
            name="Jotaro Kujo from Jojo's bizarre adventure affected by Whitesnake Credenza"
            description="A true statement maker. Our versatile mid-century modern inspired credenzas are great for use as TV stands, armoires, bars, office cabinets or the perfect complement to your bedroom set. The vibrant art printed on the doors will make your piece pop in any setting. Available in a warm, natural birch or a premium walnut finish."
            image={WeirdIMG13}
            brand="Freezah"
            type="Credenza"
            price="849.15"
            url="https://society6.com/product/jotaro-kujo-from-jojos-bizarre-adventure-affected-by-whitesnake_credenza?sku=s6-10780770p79a226v783a227v786&utm_campaign=2426&utm_source=google&utm_medium=organic"
          />
          <Footer />
        </>
      }
    />
    <Route
      path="/furniture/sergio-villa/pensiero-summer-armchair"
      element={
        <>
          <Helmet>
            <title>
              PENSIERO SUMMER ARMCHAIR • Craftle • Craft your dream home
            </title>
            <meta
              name="description"
              content="This fun yet elegant armchair just pops with color."
            />
          </Helmet>
          <WebAppBar urls={navLinks} />
          <ProductPage
            name="PENSIERO SUMMER ARMCHAIR"
            description="This fun yet elegant armchair just pops with color. The carved wooden structure, entirely hand-painted in swirls of blue and gold, gives this piece its low backrest and armrests and slightly curved legs. The backrest and seat cushion are upholstered in leather with a multicolored finish, for a piece that will hold its own in any room."
            image={WeirdIMG14}
            rand="Sergio Villa"
            type="Accest Chair"
            price="12,240"
            url="https://artemest.com/products/pensiero-summer-armchair"
          />
          <Footer />
        </>
      }
    />
    <Route
      path="/furniture/kukigifts/stainless-steel-vintage-liquor-cabinet"
      element={
        <>
          <Helmet>
            <title>
              Stainless Steel Vintage Liquor Cabinet • Craftle • Craft your
              dream home
            </title>
            <meta
              name="description"
              content="It's shape and functionality would make it an ideal fit as a minibar or a record stand or even as a planter."
            />
          </Helmet>
          <WebAppBar urls={navLinks} />
          <ProductPage
            name="Stainless Steel Vintage Liquor Cabinet"
            description="It's shape and functionality would make it an ideal fit as a minibar or a record stand or even as a planter.
                  Brown plexiglass drawer with a led lighting in it, a lucite top shelf, a lucite separator between the shelf and drawer and a stainless steel frame.
                  You can use the drawer as a record or liquor cabinet.
                  Very stylish and unique design."
            image={WeirdIMG15}
            brand="KukiGifts"
            type="Liquor Cabinet"
            price="2,796.50"
            url="https://www.etsy.com/listing/1238940655/impressive-and-unique-brown-acrylic-and?ga_order=most_relevant&ga_search_type=all&ga_view_type=gallery&ga_search_query=unique+furniture&ref=sc_gallery-1-11&pro=1&frs=1&plkey=98c534bef8c1fcc1129bc61d4b3a8484304c451f%3A1238940655"
          />
          <Footer />
        </>
      }
    />

    {/* cool as s*** section */}
    <Route
      path="/furniture/yesie/16-inch-cordless-led-cube-chair-light"
      element={
        <>
          <Helmet>
            <title>
              16-Inch Cordless LED Cube Chair Light • Craftle • Craft your dream
              home
            </title>
            <meta
              name="description"
              content="Removable Easy Charging LED Design; Super Bright 4.8W (NOT 12pcs); High Capacity Battery 4400; Waterproof IP67; 16 RGBW colors; 10-12hrs working time; Remote control & Manual Operation."
            />
          </Helmet>
          <WebAppBar urls={navLinks} />
          <ProductPage
            name="16-Inch Cordless LED Cube Chair Light"
            description="Removable Easy Charging LED Design; Super Bright 4.8W (NOT 12pcs); High Capacity Battery 4400; Waterproof IP67; 16 RGBW colors; 10-12hrs working time; Remote control & Manual Operation."
            image={CoolIMG1}
            brand="YESIE"
            type="Chair"
            price="139.99"
            url="https://www.amazon.com/dp/B07HWW8GN4/?coliid=IDVZVVQRGBXS5&colid=255TVYK0MHN2M&ref_=lv_ov_lig_dp_it&th=1"
          />
          <Footer />
        </>
      }
    />
    <Route
      path="/furniture/mousarris/rocket-table"
      element={
        <>
          <Helmet>
            <title>Rocket Table • Craftle • Craft your dream home</title>
            <meta
              name="description"
              content="The design is visually playful bringing cartoon-like clouds and aerial rockets from a personal toy collection to life, in the form of a table."
            />
          </Helmet>
          <WebAppBar urls={navLinks} />
          <ProductPage
            name="Rocket Table"
            description="The design is visually playful bringing cartoon-like clouds and aerial rockets from a personal toy collection to life, in the form of a table.
                  Combining various techniques from lathe to 3d printing, resin casting and traditional hand curved pieces, this table is fashioned to draw a smile on the face of nostalgic adults, children, and children trapped in adult bodies.
                  The rockets are not attached to the glass giving the opportunity to each owner to form their own desired structure of the table.
                  Take a trip down memory lane and enjoy your childhood once again.."
            image={CoolIMG2}
            brand="Mousarris"
            type="Coffee Table"
            price="5,827.94"
            url="https://www.mousarris.com/product-page/rocket-table"
          />
          <Footer />
        </>
      }
    />
    <Route
      path="/furniture/seatcraft/seatcraft-omega-home-theater-seating"
      element={
        <>
          <Helmet>
            <title>
              Seatcraft Omega Home Theater Seating • Craftle • Craft your dream
              home
            </title>
            <meta
              name="description"
              content="LEATHER GEL: Offers the look and feel of top grain leather that is both durable and elegant."
            />
          </Helmet>
          <WebAppBar urls={navLinks} />
          <ProductPage
            name="Seatcraft Omega Home Theater Seating"
            description="LEATHER GEL: Offers the look and feel of top grain leather that is both durable and elegant."
            image={CoolIMG3}
            brand="Seatcraft"
            type="Sofa"
            price="1,999"
            url="https://www.amazon.com/dp/B06XPGHMGF/?coliid=I3I9S840A3SW80&colid=255TVYK0MHN2M&ref_=lv_ov_lig_dp_it&th=1"
          />
          <Footer />
        </>
      }
    />
    <Route
      path="/furniture/fernando-mastrangelo-studio/drift-sofa"
      element={
        <>
          <Helmet>
            <title>Drift Sofa • Craftle • Craft your dream home</title>
            <meta
              name="description"
              content="Composed in a language of landscape-oriented abstraction, the made-to-order sofa is cast from hand-dyed sand and upholstered in silk velvet. The nuanced blue tones emulate a natural Patagonian landscape."
            />
          </Helmet>
          <WebAppBar urls={navLinks} />
          <ProductPage
            name="Drift Sofa"
            description="Composed in a language of landscape-oriented abstraction, the made-to-order sofa is cast from hand-dyed sand and upholstered in silk velvet. The nuanced blue tones emulate a natural Patagonian landscape."
            image={CoolIMG4}
            brand="Fernando Mastrangelo Studio"
            type="Sofa"
            price="--"
            url="https://fernandomastrangelo.com/limited-edition/drift-sofa.html"
          />
          <Footer />
        </>
      }
    />
    <Route
      path="/furniture/rpr/ollie-chair"
      element={
        <>
          <Helmet>
            <title>OLLIE CHAIR • Craftle • Craft your dream home</title>
            <meta
              name="description"
              content="The Ollie Chair is an ergonomically designed shape-shifting seat with lumbar support."
            />
          </Helmet>
          <WebAppBar urls={navLinks} />
          <ProductPage
            name="OLLIE CHAIR"
            description="The Ollie Chair is an ergonomically designed shape-shifting seat with lumbar support. The chair unfurls in a second and retracts with a simple pull of a string. Its articulated aluminum sheet metal body relies on origami techniques developed for folding thick materials. The metal chair body is attached to a flexible wood seating surface (i.e., a tambour) giving the Ollie Chair its patented transformability."
            image={CoolIMG5}
            brand="RPR"
            type="Chair"
            price="395"
            url="https://rockpaperrobot.com/collections/frontpage/products/ollie-chair"
          />
          <Footer />
        </>
      }
    />
    <Route
      path="/furniture/christopher-knight-home/ckh-wicker-tear-drop-hanging"
      element={
        <>
          <Helmet>
            <title>
              CKH Wicker Tear Drop Hanging • Craftle • Craft your dream home
            </title>
            <meta
              name="description"
              content="This Christopher Knight Home hanging basket Swing Chair adds a fun lounge vibe to your Outdoor space."
            />
          </Helmet>
          <WebAppBar urls={navLinks} />
          <ProductPage
            name="CKH Wicker Tear Drop Hanging"
            description="This Christopher Knight Home hanging basket Swing Chair adds a fun lounge vibe to your Outdoor space | Constructed with durable black-brown faux wicker and a sturdy iron frame, it’s easy to care for and built to last."
            image={CoolIMG6}
            brand="Christopher Knight Home"
            type="Chair"
            price="435.08"
            url="https://www.amazon.com/dp/B00IFQEMYG/?coliid=I2PLJ0NY7SHPNF&colid=255TVYK0MHN2M&psc=1&ref_=lv_ov_lig_dp_it"
          />
          <Footer />
        </>
      }
    />
    <Route
      path="/furniture/qazplm/modern-coffee-table-with-romote-multi-color"
      element={
        <>
          <Helmet>
            <title>
              Modern Coffee Table with Romote Multi-Colors • Craftle • Craft
              your dream home
            </title>
            <meta
              name="description"
              content="Modern coffee table: free space with LED lights, including color control and on/off remote control."
            />
          </Helmet>
          <WebAppBar urls={navLinks} />
          <ProductPage
            name="Modern Coffee Table with Romote Multi-Colors"
            description="Modern coffee table: free space with LED lights, including color control and on/off remote control. The LED coffee table has a super high gloss, and it is also very practical in daily life, easy to clean and maintain."
            image={CoolIMG7}
            brand="qazplm"
            type="Coffee Table"
            price="265"
            url="https://www.amazon.com/dp/B0998BMNYS/?coliid=I10ETI6ZURBULL&colid=255TVYK0MHN2M&psc=1&ref_=lv_ov_lig_dp_it"
          />
          <Footer />
        </>
      }
    />
    <Route
      path="/furniture/mousarris/wave-city-table"
      element={
        <>
          <Helmet>
            <title>Wave City Table • Craftle • Craft your dream home</title>
            <meta
              name="description"
              content="The piece illustrates an urban skyline scattered with skyscrapers that seemingly lifts into the air and finally folds back onto itself in a single, continuous curve."
            />
          </Helmet>
          <WebAppBar urls={navLinks} />
          <ProductPage
            name="Wave City Table"
            description="The piece illustrates an urban skyline scattered with skyscrapers that seemingly lifts into the air and finally folds back onto itself in a single, continuous curve. A series of meticulously carved buildings add a sense of realism to the otherwise surreal design object, which carefully balances on the lower section of the street. This piece’s inverted landscape uses the overlapping surface as the tabletop made from wood, steel and 3D printing technology."
            image={CoolIMG8}
            brand="Mousarris"
            type="Coffee Table"
            price="7,000"
            url="https://www.mousarris.com/product-page/wave-city-table"
          />
          <Footer />
        </>
      }
    />
    <Route
      path="/furniture/mousarris/carpet-chair"
      element={
        <>
          <Helmet>
            <title>Carpet Chair • Craftle • Craft your dream home</title>
            <meta
              name="description"
              content="Every carpet is handmade and takes up to four months to produce."
            />
          </Helmet>
          <WebAppBar urls={navLinks} />
          <ProductPage
            name="Carpet Chair"
            description="Every carpet is handmade and takes up to four months to produce. A beautifully designed chair that gives the illusion of a magic carpet floating in the air."
            image={CoolIMG9}
            brand="Mousarris"
            type="Chair"
            price="7,000"
            url="https://www.mousarris.com/product-page/carpet-chair"
          />
          <Footer />
        </>
      }
    />
    <Route
      path="/furniture/giantex-store/3-pcs-cushioned-outdoor-wicker-patio-set"
      element={
        <>
          <Helmet>
            <title>
              3 PCS Cushioned Outdoor Wicker Patio Set • Craftle • Craft your
              dream home
            </title>
            <meta
              name="description"
              content="Our 3 piece patio is made up of 1 wicker table with tempered glass top and 2 chairs with soft cushions."
            />
          </Helmet>
          <WebAppBar urls={navLinks} />
          <ProductPage
            name="3 PCS Cushioned Outdoor Wicker Patio Set"
            description="Our 3 piece patio is made up of 1 wicker table with tempered glass top and 2 chairs with soft cushions. The size of table is26”L×20”W×29”H. Space under the table is ample enough for you and your guests to sit in a comfortable way. Tempered glass tabletop is spacious enough for tea, coffee, glasses or dishes, and round corner is good enough for protecting your hands or clothes from scratches."
            image={CoolIMG10}
            brand="Giantex Store"
            type="Patio Set"
            price="219.99"
            url="https://www.amazon.com/dp/B00WWR2CWS/?coliid=IL5TEVNMTEI0S&colid=255TVYK0MHN2M&ref_=lv_ov_lig_dp_it&th=1"
          />
          <Footer />
        </>
      }
    />
    <Route
      path="/furniture/umbra-store/umbra-318211-040-sticks-multi-rack"
      element={
        <>
          <Helmet>
            <title>
              Umbra 318211-040 Sticks Multi Rack • Craftle • Craft your dream
              home
            </title>
            <meta
              name="description"
              content="Sticks Multi Hook Coat Rack is a unique wall mountable coat rack, hat rack, and clothing rack that provides 5 flip-down coat hooks and ample hanging space for your entryway, bedroom, office or dorm room."
            />
          </Helmet>
          <WebAppBar urls={navLinks} />
          <ProductPage
            name="Umbra 318211-040 Sticks Multi Rack"
            description="Sticks Multi Hook Coat Rack is a unique wall mountable coat rack, hat rack, and clothing rack that provides 5 flip-down coat hooks and ample hanging space for your entryway, bedroom, office or dorm room."
            image={CoolIMG11}
            brand="Umbra Store"
            type="Rack"
            price="23.97"
            url="https://www.amazon.com/Umbra-Sticks-Multi-Hook-Coat/dp/B005M8YWOK/ref=sxin_24_ac_d_rm?ac_md=3-3-bW9kZXJuIGZ1cm5pdHVyZQ%3D%3D-ac_d_rm_rm_rm&content-id=amzn1.sym.568df61d-e115-4cb1-a96a-ba070b8f0935%3Aamzn1.sym.568df61d-e115-4cb1-a96a-ba070b8f0935&cv_ct_cx=Unique%2BFurniture&keywords=Unique%2BFurniture&pd_rd_i=B005M8YWOK&pd_rd_r=a9610730-9bf8-4a16-a39e-1d6e07ed466b&pd_rd_w=wEd3v&pd_rd_wg=ICpK5&pf_rd_p=568df61d-e115-4cb1-a96a-ba070b8f0935&pf_rd_r=7FVNTGS3SK615XEDCYG9&qid=1656098608&sr=1-4-7d9bfb42-6e38-4445-b604-42cab39e191b&th=1"
          />
          <Footer />
        </>
      }
    />
    <Route
      path="/furniture/mousarris/willy-walnut"
      element={
        <>
          <Helmet>
            <title>Willy Walnut • Craftle • Craft your dream home</title>
            <meta
              name="description"
              content="Willy Walnut is Mousarris’ latest coffee table, inspired by the golden era of cartoons."
            />
          </Helmet>
          <WebAppBar urls={navLinks} />
          <ProductPage
            name="Willy Walnut"
            description="Willy Walnut is Mousarris’ latest coffee table, inspired by the golden era of cartoons. Willy’s gold arms and feet hold an oak surface up high with great stability, able to support your drinks, notepads, dreams and ideas. Add a little fun and nostalgia in your living room with this 1930’s inspired modern classic."
            image={CoolIMG12}
            brand="Mousarris"
            type="Coffee Table"
            price="4,000"
            url="https://www.mousarris.com/product-page/willy-walnut"
          />
          <Footer />
        </>
      }
    />
    <Route
      path="/furniture/deco-79-store/deco-79-silver-aluminum-contemporary-accent-table"
      element={
        <>
          <Helmet>
            <title>
              Deco 79 Silver Aluminum Contemporary Accent Table • Craftle •
              Craft your dream home
            </title>
            <meta
              name="description"
              content="Ideal decor that works best in contemporary themed homes."
            />
          </Helmet>
          <WebAppBar urls={navLinks} />
          <ProductPage
            name="Deco 79 Silver Aluminum Contemporary Accent Table"
            description="Ideal decor that works best in contemporary themed homes."
            image={CoolIMG13}
            brand="Deco 79 Store"
            type="Accent Table"
            price="220.39"
            url="https://www.amazon.com/Deco-79-Accent-Table-Silver/dp/B097QGTZ4J/ref=sr_1_5?keywords=Unique%2BFurniture&qid=1656098608&sr=8-5&th=1"
          />
          <Footer />
        </>
      }
    />
    <Route
      path="/furniture/duffy-london/abyss-kraken-coffee-table"
      element={
        <>
          <Helmet>
            <title>
              Abyss Kraken Coffee Table • Craftle • Craft your dream home
            </title>
            <meta
              name="description"
              content="Abyss Kraken is a dramatic new statement coffee table by Christopher Duffy, played out over two pieces."
            />
          </Helmet>
          <WebAppBar urls={navLinks} />
          <ProductPage
            name="Abyss Kraken Coffee Table"
            description="Abyss Kraken is a dramatic new statement coffee table by Christopher Duffy, played out over two pieces.  Abyss Kraken continues Duffy’s explorations of the concept of depth with a mesmerising depiction of the Earth’s seabed, played out in vivid turquoise colours.  Each table has an individual pattern, which when placed together, flows from one table across to the other."
            image={CoolIMG14}
            brand="Duffy London"
            type="Coffee Table"
            price="61,460.57"
            url="https://duffylondon.com/collections/furniture/tables/kraken-abyss-table/"
          />
          <Footer />
        </>
      }
    />
    <Route
      path="/furniture/nouhaus-store/classic-massage-chair-with-ottoman"
      element={
        <>
          <Helmet>
            <title>
              Classic Massage Chair with Ottoman • Craftle • Craft your dream
              home
            </title>
            <meta
              name="description"
              content="Upgrade your relaxing head-to-thigh massage to a 3-Level ThermoMassage with our new Classic Massage Chairs with Heat! This 2020 Red Dot Award Winner is a sexy “Everyday Chair with Big Massage Chair Technology” and hot accent chair that delivers luxury to your body, and your living room."
            />
          </Helmet>
          <WebAppBar urls={navLinks} />
          <ProductPage
            name="Classic Massage Chair with Ottoman"
            description="Things Are Really Heating Up! Upgrade your relaxing head-to-thigh massage to a 3-Level ThermoMassage with our new Classic Massage Chairs with Heat! This 2020 Red Dot Award Winner is a sexy “Everyday Chair with Big Massage Chair Technology” and hot accent chair that delivers luxury to your body, and your living room."
            image={CoolIMG15}
            brand="Nouhaus Store"
            type="Chair and Ottoman"
            price="2,499.99"
            url="https://www.amazon.com/Nouhaus-Ottoman-Percussive-Bluetooth-Recliner/dp/B089RS5FGH/ref=sr_1_16_sspa?keywords=Unique%2BFurniture&qid=1656098608&sr=8-16-spons&smid=A1DPRB9NBV0XDD&spLa=ZW5jcnlwdGVkUXVhbGlmaWVyPUEzMlA0M0U4UE9FTFk3JmVuY3J5cHRlZElkPUEwNTA4MDk4M0RNMU1IVEpXVTBPTiZlbmNyeXB0ZWRBZElkPUEwODQ2OTc4MjYyVDZWWlNLVkJLWCZ3aWRnZXROYW1lPXNwX210ZiZhY3Rpb249Y2xpY2tSZWRpcmVjdCZkb05vdExvZ0NsaWNrPXRydWU&th=1"
          />
          <Footer />
        </>
      }
    />
    <Route
      path="/furniture/deco-79-store/deco-79-industrial-wood-and-metal-wheel-console-table"
      element={
        <>
          <Helmet>
            <title>
              Deco 79 Industrial Wood and Metal Wheel Console Table • Craftle •
              Craft your dream home
            </title>
            <meta
              name="description"
              content="Rectangular wood table with black metal bicycle wheels."
            />
          </Helmet>
          <WebAppBar urls={navLinks} />
          <ProductPage
            name="Deco 79 Industrial Wood and Metal Wheel Console Table"
            description="Rectangular wood table with black metal bicycle wheels."
            image={CoolIMG16}
            brand="Deco 79 Store"
            type="Console Table"
            price="218.54"
            url="https://www.amazon.com/Deco-79-Metal-Wheel-Console/dp/B01MV3DEAA/ref=sr_1_55?keywords=Unique+Furniture&qid=1656098608&sr=8-55"
          />
          <Footer />
        </>
      }
    />
    <Route
      path="/furniture/fitueyes/corner-floor-tv-stand-for-55-65-inch-tv"
      element={
        <>
          <Helmet>
            <title>
              Corner Floor TV Stand for 55 65 Inch TV • Craftle • Craft your
              dream home
            </title>
            <meta
              name="description"
              content="This TV display stand has a sophisticated and modern design that transform a TV furniture into an decoration TV stand, I believe you see the artistic appearance, sturdy wood and iron combination, original design."
            />
          </Helmet>
          <WebAppBar urls={navLinks} />
          <ProductPage
            name="Corner Floor TV Stand for 55 65 Inch TV"
            description="This TV display stand has a sophisticated and modern design that transform a TV furniture into an decoration TV stand, I believe you see the artistic appearance, sturdy wood and iron combination, original design."
            image={CoolIMG17}
            brand="FITUEYES"
            type="TV Stand"
            price="169.99"
            url="https://www.amazon.com/FITUEYES-Display-Concealed-Furniture-Original/dp/B092LYH4JB/ref=sr_1_50_sspa?keywords=Unique+Furniture&qid=1656098738&sr=8-50-spons&psc=1&smid=A2T4RI5JPP2USG&spLa=ZW5jcnlwdGVkUXVhbGlmaWVyPUFSWlJYVUZaNVVEVEsmZW5jcnlwdGVkSWQ9QTA0NDYwMDFJVEwxS0o0N05TUzcmZW5jcnlwdGVkQWRJZD1BMDc2OTk1NTFSMEpGRTlPRkdJTzUmd2lkZ2V0TmFtZT1zcF9hdGZfbmV4dCZhY3Rpb249Y2xpY2tSZWRpcmVjdCZkb05vdExvZ0NsaWNrPXRydWU="
          />
          <Footer />
        </>
      }
    />
    <Route
      path="/furniture/villa-p/air-lounge"
      element={
        <>
          <Helmet>
            <title>Air Lounge • Craftle • Craft your dream home</title>
            <meta
              name="description"
              content="In no time you can conjure up plenty of comfortable space for many guests - and an attractive focal point at garden parties, company celebrations, trade fair receptions, ... Also ideal on the beach in summer - and in winter for a mulled wine get-together."
            />
          </Helmet>
          <WebAppBar urls={navLinks} />
          <ProductPage
            name="Air Lounge"
            description="In no time you can conjure up plenty of comfortable space for many guests - and an attractive focal point at garden parties, company celebrations, trade fair receptions, ... Also ideal on the beach in summer - and in winter for a mulled wine get-together."
            image={CoolIMG18}
            brand="Villa P."
            type="Lounge Seating"
            price="5,720.89"
            url="https://www.proidee.de/P=HPN205012?HI=produktuebersicht&ID_KATEGORIE=120307"
          />
          <Footer />
        </>
      }
    />
    <Route
      path="/furniture/mousarris/saita"
      element={
        <>
          <Helmet>
            <title>Saita • Craftle • Craft your dream home</title>
            <meta
              name="description"
              content="Elevate your space with Mousarris’ latest desktop light."
            />
          </Helmet>
          <WebAppBar urls={navLinks} />
          <ProductPage
            name="Saita"
            description="Elevate your space with Mousarris’ latest desktop light. Heavily inspired from childhood, crafting a paper plane and the joy of watching it glide. Saita is the newest addition to the Mousarri's collection, a functional yet imaginative piece that will not only bring your workspace a touch of ambience but it will also take you on a journey of reminiscence. Using 3d printed technology and hand crafted techniques we have developed this product into a functional desktop light with a slick and airy design that will surely transform your space."
            image={CoolIMG19}
            brand="Mousarris"
            type="Table Lamp"
            price="529.76"
            url="https://www.mousarris.com/product-page/saita"
          />
          <Footer />
        </>
      }
    />

    {/* hogwarts houses' magical decor section */}
    <Route
      path="/furniture/native-interiors/nativa-interiors-london-tufted-sofa"
      element={
        <>
          <Helmet>
            <title>
              NATIVA INTERIORS - LONDON TUFTED SOFA • Craftle • Craft your dream
              home
            </title>
            <meta
              name="description"
              content="Handmade using old world craftsmanship, the Nativa Interiors London Tufted Sofa features a gorgeous European linen upholstery, supported by premium foam and metal springs to provide unparalleled comfort and design."
            />
          </Helmet>
          <WebAppBar urls={navLinks} />
          <ProductPage
            name="NATIVA INTERIORS - LONDON TUFTED SOFA"
            description="Handmade using old world craftsmanship, the Nativa Interiors London Tufted Sofa features a gorgeous European linen upholstery, supported by premium foam and metal springs to provide unparalleled comfort and design. This sofa is crafted with careful precision and made to be enjoyed for its beauty as well as its practicality. Relax in style on this quality piece of furniture and enjoy a feeling of supreme indulgence. With its classic lines, this sofa suits a variety of home and matches well with many types of accompanying furniture."
            image={HarryPotter1}
            brand="Nativa Interiors"
            type="Sofa"
            price="1,610.00"
            url="https://www.afastores.com/nativa-interiors-london-tufted-sofa-72-in-red.html?utm_source=google&utm_medium=cse&utm_term=SOF-LONDON-72-CL-MF-RED"
          />
          <Footer />
        </>
      }
    />
    <Route
      path="/furniture/perigold/highland-peele-bolster-pillow-cover-and-insert"
      element={
        <>
          <Helmet>
            <title>
              Hyland Peele Bolster Pillow Cover & Insert • Craftle • Craft your
              dream home
            </title>
            <meta name="description" content="" />
          </Helmet>
          <WebAppBar urls={navLinks} />
          <ProductPage
            name="Hyland Peele Bolster Pillow Cover & Insert"
            description=""
            image={HarryPotter2}
            brand="Perigold"
            type="Pillow Cover"
            price="235"
            url="https://www.perigold.com/Eastern-Accents--Hyland-Peele-Bolster-Pillow-Cover-and-Insert-HYL02-L1227-K~OWBF1026.html?refid=GX204792852953-OWBF1026&device=c&ptid=330116196935&targetid=pla-330116196935&ireid=47443218&gclid=EAIaIQobChMI37Xbn7nO-AIVi77ICh0CgQ5GEAQYASABEgLzuvD_BwE"
          />
          <Footer />
        </>
      }
    />
    <Route
      path="/furniture/vcf/orville-side-table"
      element={
        <>
          <Helmet>
            <title>Orville Side Table • Craftle • Craft your dream home</title>
            <meta name="description" content="" />
          </Helmet>
          <WebAppBar urls={navLinks} />
          <ProductPage
            name="Orville Side Table"
            description=""
            image={HarryPotter3}
            brand="VCF"
            type="Side Table"
            price="199.99"
            url="https://www.valuecityfurniture.com/product/group/living-room/tables/end-tables/orville-side-table-hazelnut/2388018/2385650?mc=yes&region_id=401323&gclid=EAIaIQobChMI-prYzbzO-AIVkWtvBB0fWA0vEAsYAyABEgKy0fD_BwE&gclsrc=aw.ds"
          />
          <Footer />
        </>
      }
    />
    <Route
      path="/furniture/lane-furniture/glenrock-tawny-hi-leg-recliner"
      element={
        <>
          <Helmet>
            <title>
              GLENROCK TAWNY HI LEG RECLINER • Craftle • Craft your dream home
            </title>
            <meta
              name="description"
              content="This Lane High-Leg Recliner looks like a regular classic armchair but it is actually a comfortable recliner that opens easily with a push on the arms."
            />
          </Helmet>
          <WebAppBar urls={navLinks} />
          <ProductPage
            name="GLENROCK TAWNY HI LEG RECLINER"
            description="This Lane High-Leg Recliner looks like a regular classic armchair but it is actually a comfortable recliner that opens easily with a push on the arms. The stately appearance is accentuated by the turned legs with a cherry finish and matching welt around the arms. And the distinctive wingback sides with a rolled arm create that classic library look. Now you can enjoy the style of a stationary chair with comfort of a recliner."
            image={HarryPotter4}
            brand="Lane Furniture"
            type="Armchair"
            price="443"
            url="https://www.afastores.com/lane-furniture-glenrock-tawny-hi-leg-recliner.html?utm_source=googlepla&utm_medium=cse&utm_term=6002-11-Glenrock-Tawny&gclid=EAIaIQobChMI1NDM0rXO-AIVyxbUAR2NxgEqEAQYFiABEgKFvPD_BwE"
          />
          <Footer />
        </>
      }
    />
    <Route
      path="/furniture/well-woven/sultan-sarouk-red-traditional-poriental-persian-floral-formal-area-rug"
      element={
        <>
          <Helmet>
            <title>
              Sultan Sarouk Red Traditional Oriental Persian Floral Formal Area
              Rug • Craftle • Craft your dream home
            </title>
            <meta
              name="description"
              content="Sultan Sarouk Red is a traditional rug for the modern home."
            />
          </Helmet>
          <WebAppBar urls={navLinks} />
          <ProductPage
            name="Sultan Sarouk Red Traditional Oriental Persian Floral Formal Area Rug"
            description="Sultan Sarouk Red is a traditional rug for the modern home. With a classic yet trendy Persian Oriental floral design and a multi color palette of warm, jewel tone colors, dominated by deep red. A plush 0.5'' pile of heat polypropylene lends an exceptionally soft hand. This yarn is stain resistant, and doesn't shed or fade over time. The 100% jute backing is safe for wood floors and all four sides of the rug are serged for improved durability. Perfectly suited for the luxurious yet active lifestyle and built to last for years."
            image={HarryPotter5}
            brand="Well Woven"
            type="Rug"
            price="170"
            url="https://www.houzz.com/products/sultan-sarouk-red-traditional-oriental-persian-floral-formal-area-rug-6-7x9-prvw-vr~121337068?m_refid=PLA_HZ_121337068"
          />
          <Footer />
        </>
      }
    />
    <Route
      path="/furniture/homeroots/homeroots-green-velvet-loveseat"
      element={
        <>
          <Helmet>
            <title>
              Homeroots Green Velvet Loveseat • Craftle • Craft your dream home
            </title>
            <meta
              name="description"
              content="Upgrade your living room with this loveseat in dark gray velvet finish."
            />
          </Helmet>
          <WebAppBar urls={navLinks} />
          <ProductPage
            name="Homeroots Green Velvet Loveseat"
            description="Upgrade your living room with this loveseat in dark gray velvet finish. The charming appeal can easily match any decor and its inspiring tones will add a pretty visual vibes to your beautiful living room. The soft velvet fabric provides a soft and cosy seating. The seat can be integrated in your home or office to make a erfect setting. It is beautifully crafted and designed in an adorable manner with button tufted profile. The vintage styled piece has a weight capacity of 220lbs. It features chrome finish, metal bracket legs, and tight back and loose seat cushion. Get an unforgettable experience with this loveseat in your home today."
            image={HarryPotter6}
            brand="Homeroots"
            type="Loveseat"
            price="1437.41"
            url="https://www.1stopbedrooms.com/homeroots-green-velvet-loveseat-318849?cq_src=google_ads&cq_cmp=16773501769&cq_con=136847008964&cq_term=&cq_med=pla&cq_plac=&cq_net=g&cq_pos=&cq_plt=gp&gclid=EAIaIQobChMIs8DX0L3O-AIVD4zICh2gUg8FEAQYBSABEgLY1_D_BwE"
          />
          <Footer />
        </>
      }
    />
    <Route
      path="/furniture/wayfair/beaulah-30-wide-velvet-tufted-round-storage-ottoman-with-storage"
      element={
        <>
          <Helmet>
            <title>
              Beulah 30&apos;&apos; Wide Velvet Tufted Round Storage Ottoman
              with Storage • Craftle • Craft your dream home
            </title>
            <meta
              name="description"
              content="This round cocktail ottoman adds some glam appeal and gives you a spot to store various items in your living room."
            />
          </Helmet>
          <WebAppBar urls={navLinks} />
          <ProductPage
            name="Beulah 30'' Wide Velvet Tufted Round Storage Ottoman with Storage"
            description="This round cocktail ottoman adds some glam appeal and gives you a spot to store various items in your living room. It has a solid and engineered wood frame, wrapped in velvet for a luxe look. Deep button tufting decorates all sides for an extra dose of elegance. With a diameter of 30'', it's ideal at the foot of your armchair or the foot of your bed. Its easy lift-off lid reveals plenty of room to keep blankets, puzzles, games, and other items. It's also easily concealed when closed for a sleek, clean-lined look that's ideal in modern-inspired spaces."
            image={HarryPotter7}
            brand="Wayfair"
            type="Ottoman"
            price="296.99"
            url="https://www.wayfair.com/Kelly-Clarkson-Home--Beulah-30-Wide-Velvet-Tufted-Round-Storage-Ottoman-X118879334-L310-K~W007768223.html?refid=FR49-W007768223_1701520078&PiID%5B%5D=1701520078"
          />
          <Footer />
        </>
      }
    />
    <Route
      path="/furniture/wayfair/pittsburgh-terracotta-pot-planter"
      element={
        <>
          <Helmet>
            <title>
              Pittsburgh Terracotta Pot Planter • Craftle • Craft your dream
              home
            </title>
            <meta
              name="description"
              content="Add a touch of the Mediterranean to your home with a planter in the Pittsburgh Planter Collection."
            />
          </Helmet>
          <WebAppBar urls={navLinks} />
          <ProductPage
            name="Pittsburgh Terracotta Pot Planter"
            description="Add a touch of the Mediterranean to your home with a planter in the Pittsburgh Planter Collection. Handcrafted of terra cotta, kiln-fired and then individually hand painted, these planters will transform your outdoor or indoor space in minutes. Sturdy enough to hold small vegetables, herb garden, plants or flowers."
            image={HarryPotter8}
            brand="Wayfair"
            type="Planter"
            price="256.99"
            url="https://www.wayfair.com/outdoor/pdp/bungalow-rose-pittsburgh-terracotta-pot-planter-w005107565.html?piid=1510183272"
          />
          <Footer />
        </>
      }
    />
    <Route
      path="/furniture/TalaveraWithLove/handmade-wooden-chess-set"
      element={
        <>
          <Helmet>
            <title>
              Handmade Wooden Chess Set • Craftle • Craft your dream home
            </title>
            <meta
              name="description"
              content="Board made of wood and resin, calcite and marble granite."
            />
          </Helmet>
          <WebAppBar urls={navLinks} />
          <ProductPage
            name="Handmade Wooden Chess Set"
            description="Board made of wood and resin, calcite and marble granite. Pieces made of marble granite and calcite."
            image={HarryPotter9}
            brand="TalaveraWithLove"
            type="Chess Set"
            price="295"
            url="https://www.etsy.com/listing/1050006089/handmade-wooden-chess-set-luxury-stone?gpla=1&gao=1&&utm_source=google&utm_medium=cpc&utm_campaign=shopping_us_a-toys_and_games-games_and_puzzles-board_games-chess&utm_custom1=_k_EAIaIQobChMInZ7T3L7O-AIVyMmUCR3wsgtYEAsYAiABEgIZKfD_BwE_k_&utm_content=go_12569403118_122509905347_507439707251_pla-314262775387_c__1050006089_471034953&utm_custom2=12569403118&gclid=EAIaIQobChMInZ7T3L7O-AIVyMmUCR3wsgtYEAsYAiABEgIZKfD_BwE"
          />
          <Footer />
        </>
      }
    />
    <Route
      path="/furniture/wayfair/plumerville-14-57-bankers-lamp"
      element={
        <>
          <Helmet>
            <title>
              Plumerville 14.57&apos;&apos; Bankers Lamp • Craftle • Craft your
              dream home
            </title>
            <meta
              name="description"
              content="Recreating the classic look of a traditional mid-century banker's lamp, this light combines excellent lighting with beautiful style to create a timeless decorative piece."
            />
          </Helmet>
          <WebAppBar urls={navLinks} />
          <ProductPage
            name="Plumerville 14.57'' Bankers Lamp"
            description="Recreating the classic look of a traditional mid-century banker's lamp, this light combines excellent lighting with beautiful style to create a timeless decorative piece. The elegant design carefully disguises the carefully selected, durable material, letting this lamp last a long time."
            image={HarryPotter10}
            brand="Wayfair"
            type="Table Lamp"
            price="94.99"
            url="https://www.wayfair.com/lighting/pdp/charlton-home-plumerville-1457-bankers-lamp-trch1025.html?piid=44677483"
          />
          <Footer />
        </>
      }
    />
    <Route
      path="/furniture/wayfair/kannon-tufted-right-arm-chaise-lounge"
      element={
        <>
          <Helmet>
            <title>
              Kannon Tufted Right-Arm Chaise Lounge • Craftle • Craft your dream
              home
            </title>
            <meta
              name="description"
              content="The Kannon collection is the perfect addition to any living space looking to add a bit of a traditional flair."
            />
          </Helmet>
          <WebAppBar urls={navLinks} />
          <ProductPage
            name="Kannon Tufted Right-Arm Chaise Lounge"
            description="The Kannon collection is the perfect addition to any living space looking to add a bit of a traditional flair. The fabric selected for the collection features polyester blends engineered for long life. Our wood frames are made from kiln-dried birch wood which provides exceptional support and stability. This unique design is accented with hand tufts along the single rolled arm, back panel, and seat. The Samuel is an ideal choice for the entryway, hallway, or living areas. This right-arm-facing chaise brings class and lounging comfort to any room in the home. We offer a unique versatility in design and make use of a variety of trend-inspired color palettes and textures. Our products bring new life to the classic American home!"
            image={HarryPotter11}
            brand="Wayfair"
            type="Chaise"
            price="769.99"
            url="https://www.wayfair.com/furniture/pdp/willa-arlo-interiors-kannon-tufted-right-arm-chaise-lounge-w004769075.html?piid=1346369929"
          />
          <Footer />
        </>
      }
    />
    <Route
      path="/furniture/dover/5-x-5-dover-round-rug"
      element={
        <>
          <Helmet>
            <title>
              5&apos; x 5&apos; Dover Round Rug • Craftle • Craft your dream
              home
            </title>
            <meta
              name="description"
              content="Classic sophistication meets contemporary design."
            />
          </Helmet>
          <WebAppBar urls={navLinks} />
          <ProductPage
            name="5' x 5' Dover Round Rug"
            description="Classic sophistication meets contemporary design. Marrying traditional tribal medallion patterns with a modern vintage design sensibility, the Dover Collection is the perfect blend of old and new. A wide range of color options from dark navy to warm neutrals to bright pastel pink, green, and yellow makes it an easy fit with any style. A subtle abrash gives the rugs a distinctive distressed quality for that chic lived-in look as soon as you roll it out. The medium-height polypropylene pile is soft underfoot and easy to clean, perfect for medium to low traffic areas like living rooms and bedrooms where you need a little extra style."
            image={HarryPotter12}
            brand="Dover"
            type="Rug"
            price="69"
            url="https://rugs.com/navy-blue-5-ft-round-bexley-area-rug-6288354?gclid=EAIaIQobChMI6capisDQ-AIV5_HjBx3SDw0UEAQYASABEgJkrPD_BwE"
          />
          <Footer />
        </>
      }
    />
    <Route
      path="/furniture/unique-art-since-1996/gemstone-world-globe-with-silver-tripod-stand"
      element={
        <>
          <Helmet>
            <title>
              Gemstone World Globe with Silver Tripod Stand • Craftle • Craft
              your dream home
            </title>
            <meta
              name="description"
              content="This unique gemstone globe is brand new and in perfect condition."
            />
          </Helmet>
          <WebAppBar urls={navLinks} />
          <ProductPage
            name="Gemstone World Globe with Silver Tripod Stand"
            description="This unique gemstone globe is brand new and in perfect condition. It is painstakingly handcrafted by the most talented artists by, using more than 30 different semi-precious gemstones from all over the world! This globe have used large amount of Lapis that pulverized, and mixed With an epoxy And Poured to make the ocean part of the globe (it is a very complicated and expensive procedure, precise control of temperature is the most essential factor in the processing cycle). This is a huge amount of striking and dramatic hand-crafted stone work. This globe is built with the beautiful ornate, 3-leg heavy Silver stand into which the globe has been carefully mounted. At the bottom of the stand, a precise glass-topped integral brass compass is incorporated to complement the globe"
            image={HarryPotter13}
            brand="Unique Art Since 1996"
            type="Globe"
            price="95.55"
            url="https://www.amazon.com/Unique-Art-10-Inch-Gemstone-Silver/dp/B007VC4NX0?source=ps-sl-shoppingads-lpcontext&ref_=fplfs&psc=1&smid=ATVPDKIKX0DER"
          />
          <Footer />
        </>
      }
    />
    <Route
      path="/furniture/sauder/sauder-5-shelf-split-bookcase"
      element={
        <>
          <Helmet>
            <title>
              Sauder 5-Shelf Split Bookcase • Craftle • Craft your dream home
            </title>
            <meta
              name="description"
              content="Store your stuff in style with this 5-shelf bookcase from the Sauder Select collection."
            />
          </Helmet>
          <WebAppBar urls={navLinks} />
          <ProductPage
            name="Sauder 5-Shelf Split Bookcase"
            description="Store your stuff in style with this 5-shelf bookcase from the Sauder Select collection. With three adjustable shelves, it is ideal for holding and displaying all your books, crafts, and knick-knacks. Finished in Oiled Oak, this library will give any room a fresh new look."
            image={HarryPotter14}
            brand="Sauder"
            type="Bookcase"
            price="149.24"
            url="https://www.amazon.com/Sauder-410367-5-Shelf-Split-Bookcase/dp/B004HBCVGE?source=ps-sl-shoppingads-lpcontext&ref_=fplfs&smid=ATVPDKIKX0DER&th=1"
          />
          <Footer />
        </>
      }
    />
    <Route
      path="/furniture/wayfair/evianna-79-5-velvet-rolled-arm-sofa"
      element={
        <>
          <Helmet>
            <title>
              Evianna 79.5&apos;&apos; Velvet Rolled Arm Sofa • Craftle • Craft
              your dream home
            </title>
            <meta
              name="description"
              content="The perfect piece to reflect your regal tastes, this sofa anchors your seating ensemble in timeless appeal."
            />
          </Helmet>
          <WebAppBar urls={navLinks} />
          <ProductPage
            name="Evianna 79.5'' Velvet Rolled Arm Sofa"
            description="The perfect piece to reflect your regal tastes, this sofa anchors your seating ensemble in timeless appeal. Crafted from the wood frame, this dapper design strikes an updated, clean-lined silhouette with tight pleated rolled arms and wood round tapered feet. Crushed velvet sofa’s glamorous brings out this sofa’s glamorous side, while exquisite details including high-density foam fill with spring support, piped seat cushions, matching bolster pillows, and a button-tufted backrest elevates the design. With coordinating throw pillows."
            image={HarryPotter15}
            brand="Wayfair"
            type="Sofa"
            price="899.99"
            url="https://www.wayfair.com/Rosdorf-Park--Evianna-79.5-Velvet-Rolled-Arm-Sofa-X116516470-L305-K~W005405369.html?refid=GX522955358536-W005405369_14619790&device=c&ptid=1318622065591&network=g&targetid=pla-1318622065591&channel=GooglePLA&ireid=121927893&fdid=1817&PiID%5B%5D=14619790&campaignid=398251723&gclid=EAIaIQobChMI9_eygbzQ-AIVpHNvBB262QHAEAQYASABEgIjHPD_BwE"
          />
          <Footer />
        </>
      }
    />
    <Route
      path="/furniture/jayden-creation/mustard-polyester-2-seats-loveseats"
      element={
        <>
          <Helmet>
            <title>
              Mustard Polyester 2-Seats Loveseats • Craftle • Craft your dream
              home
            </title>
            <meta
              name="description"
              content="This loveseat chair will provide a fresh and pleasing look to your space and will be the center of attention with an open-back design."
            />
          </Helmet>
          <WebAppBar urls={navLinks} />
          <ProductPage
            name="Mustard Polyester 2-Seats Loveseats"
            description="This loveseat chair will provide a fresh and pleasing look to your space and will be the center of attention with an open-back design. The medium density foam filling with a solid and manufactured wood frame provides comfort and support. Fluffy lambskin sherpa upholstery brings the comfort of sitting on the clouds. The tapered wire-brushed solid legs in driftwood finish form a combination of cozy style in a more antique way which inspires your home. The elegant curved wing back with skin-friendly and breathe surface expresses the comfort. This small loveseat sofa for you and your loved one brings the soft and warm enjoyment at home. Whether you are looking for an intimate conversation with loved one or a place to stretch out in style, you will be well covered. This loveseat will have no problems blending into your home decoration with its natural and modern style and incredible durability. It's the perfect pick for bedroom, living room and other scenes."
            image={HarryPotter16}
            brand="JAYDEN CREATION"
            type="Loveseat"
            price="374.34"
            url="https://www.homedepot.com/p/JAYDEN-CREATION-Irvington-Lambskin-Sherpa-45-in-Retro-Color-Legs-Mustard-Polyester-2-Seats-Loveseats-with-Wood-Base-HSFS0102-MUSTARD/315832203?source=shoppingads&locale=en-US#overlay"
          />
          <Footer />
        </>
      }
    />
    <Route
      path="/furniture/threshold/round-decorative-baskets-natural"
      element={
        <>
          <Helmet>
            <title>
              Round Decorative Baskets Natural • Craftle • Craft your dream home
            </title>
            <meta
              name="description"
              content="Bring natural style to your everyday storage with this Round Rattan Decorative Basket from Project 62™."
            />
          </Helmet>
          <WebAppBar urls={navLinks} />
          <ProductPage
            name="Round Decorative Baskets Natural"
            description="Bring natural style to your everyday storage with this Round Rattan Decorative Basket from Project 62™. This decorative basket adds a stylish element to your storage needs while also providing organization. Its rattan construction features a clear-coated finish that adds natural texture and charm. The multipurpose basket may be used to store everything from toss pillows to linens and more. The open-top design makes it simple to access your stuff, and the side handles make it simple to carry. Place it anywhere you wish for storage purposes or for decorative use."
            image={HarryPotter17}
            brand="Threshold"
            type="Basket"
            price="36"
            url="https://www.target.com/p/round-rattan-tall-decorative-baskets-natural-threshold-8482/-/A-76556248?ref=tgt_adv_XS000000&AFID=google_pla_df_free_local&CPNG=Storage%2BOrganization&adgroup=2-7"
          />
          <Footer />
        </>
      }
    />
    <Route
      path="/furniture/noble-house/spencer-yellow-round-knit-pouf"
      element={
        <>
          <Helmet>
            <title>
              Spencer Yellow Round Knit Pouf • Craftle • Craft your dream home
            </title>
            <meta
              name="description"
              content="Accent your living space with a versatile accessory that is sure to shine."
            />
          </Helmet>
          <WebAppBar urls={navLinks} />
          <ProductPage
            name="Spencer Yellow Round Knit Pouf"
            description="Accent your living space with a versatile accessory that is sure to shine. With a delightfully versatile design, this pouf is easy to store in a corner or by the bed when not in use. The round compact shape and classic cable knit make this pouf not only cute but also functional. Whether you're looking to put your feet up after a long day or if you're in need of extra plush seating, this pouf offers a variety of functions for you and your guests to discover."
            image={HarryPotter18}
            brand="Noble House"
            type="Ottoman"
            price="52.76"
            url="https://www.homedepot.com/p/Noble-House-Spencer-Yellow-Round-Knit-Pouf-94223/315450943?source=shoppingads&locale=en-US#overlay"
          />
          <Footer />
        </>
      }
    />
    <Route
      path="/furniture/rugshop/geometric-boho-area-rug-5-x-7-yellow"
      element={
        <>
          <Helmet>
            <title>
              Geometric Boho Area Rug 5&apos; x 7&apos; Yellow • Craftle • Craft
              your dream home
            </title>
            <meta
              name="description"
              content="The perfect union of tasteful design and understated multi-colored motifs power loomed in Turkey of high quality, long-wearing synthetic fibers."
            />
          </Helmet>
          <WebAppBar urls={navLinks} />
          <ProductPage
            name="Geometric Boho Area Rug 5' x 7' Yellow"
            description="The perfect union of tasteful design and understated multi-colored motifs power loomed in Turkey of high quality, long-wearing synthetic fibers. Even in the busiest areas of your home, these rugs will last for years with proper care for long-lasting beauty."
            image={HarryPotter19}
            brand="Rugshop"
            type="Rug"
            price="70.99"
            url="https://www.amazon.com/Rugshop-Geometric-Bohemian-Design-Yellow/dp/B086DTDPXY?source=ps-sl-shoppingads-lpcontext&ref_=fplfs&smid=ATVPDKIKX0DER&th=1"
          />
          <Footer />
        </>
      }
    />
    <Route
      path="/furniture/salvador-brown/hanging-indoor-outdoor-planter"
      element={
        <>
          <Helmet>
            <title>
              HANGING INDOOR/OUTDOOR PLANTER • Craftle • Craft your dream home
            </title>
            <meta
              name="description"
              content="Terracotta bowl with rich brown finish opens wide for fresh greens to spill out."
            />
          </Helmet>
          <WebAppBar urls={navLinks} />
          <ProductPage
            name="HANGING INDOOR/OUTDOOR PLANTER"
            description="Terracotta bowl with rich brown finish opens wide for fresh greens to spill out. Heavy jute rope weaves in and out of three thick terracotta loops to keep the hanging planter level and secure. Hangs alone or in groups with the large planter. CB2 exclusive."
            image={HarryPotter20}
            brand="SALVADOR BROWN"
            type="Planer"
            price="24.95"
            url="https://www.cb2.com/salvador-brown-hanging-indoor-outdoor-planter-small/s503035?localedetail=US"
          />
          <Footer />
        </>
      }
    />

    {/* zodiac furniture moodboards section */}
    <Route
      path="/moodboards/capricorn"
      element={
        <>
          <Helmet>
            <title>Capricorn Moodboard • Craftle • Craft your dream home</title>
            <meta
              name="description"
              content="We created this furniture moodboard specifically for Capricorns."
            />
          </Helmet>
          <WebAppBar urls={navLinks} />
          <MoodboardPage
            profile={ZodiacProfile1}
            name="Capricorn"
            dates="Dec 22 - Jan 19"
            moodboard={Zodiac1}
            description="Capricorns are ambitious and hardworking. These two factors combine to make the Capricorn an extremely driven, enthusiastic, and capable individual. Those who know them well know that they are actually very passionate about life and deeply committed to their goals."
            image1={Capricorn1}
            url1="https://www.walmart.com/ip/Walsunny-Modern-L-Shaped-Sectional-Sofa-with-Linen-Fabric-Dark-Beige/724457819?wmlspartner=wlpa&selectedSellerId=101077653"
            price1="329.99"
            image2={Capricorn2}
            url2="https://www.urbanoutfitters.com/shop/modern-hilo-tufted-rug?inventoryCountry=US&color=111&size=3X5&type=REGULAR&quantity=1"
            price2="69"
            image3={Capricorn3}
            url3="https://www.ashleyfurniture.com/p/drakelle_accent_chair/A3000051.html?mrkgcl=1069&product_id=A3000051&"
            price3="319.99"
            image4={Capricorn4}
            url4="https://www.walmart.com/ip/Better-Homes-Gardens-15-5-x-15-5-x-21-8-Black-Wood-and-Metal-Plant-Stand/649009269?wl13=5844&selectedSellerId=0"
            price4="29.97"
            image5={Capricorn5}
            url5="https://www.etsy.com/listing/834621345/large-beige-abstract-painting?gpla=1&gao=1&"
            price5="174.30"
            image6={Capricorn6}
            url6="https://www.walmart.com/ip/Better-Homes-Gardens-5-Geographic-Globe-Black/54134653?wl13=5048&selectedSellerId=0"
            price6="10.94"
            image7={Capricorn7}
            url7="https://www.houzz.com/products/lomasi-table-lamp-with-shade-copper-prvw-vr~53749683?m_refid=PLA_HZ_53749683"
            price7="211"
            image8={Capricorn8}
            url8="https://www.interiorhomescapes.com/French-Leather-Bound_p_28755.html?gclid=Cj0KCQjw5ZSWBhCVARIsALERCvwOC8zvMBe2BzL5-iC2y80rdXFHM37f7aMcmDynLEvEGaGVjHch15YaAhPpEALw_wcB"
            price8="47.50"
            image9={Capricorn9}
            url9="https://www.wayfair.com/furniture/pdp/canora-grey-console-table-traditional-design-with-two-drawers-and-bottom-shelf-w007803687.html?piid=108215194"
            price9="389.99"
          />
          <Footer />
        </>
      }
    />
    <Route
      path="/moodboards/aquarius"
      element={
        <>
          <Helmet>
            <title>Aquarius Moodboard • Craftle • Craft your dream home</title>
            <meta
              name="description"
              content="We created this furniture moodboard specifically for Aquarius."
            />
          </Helmet>
          <WebAppBar urls={navLinks} />
          <MoodboardPage
            profile={ZodiacProfile2}
            name="Aquarius"
            dates="Jan 20 - Feb 18"
            moodboard={Zodiac2}
            description="The Aquarius zodiac sign is known for being independent, original, and humanitarian. People born under this sign are often unique and have a rebellious streak. They are also humanitarian and often fight for causes they believe in. Aquarius is an air sign, and those born under this sign are often seen as intelligent and quick-witted."
            image1={Aquarius1}
            url1="https://www.homary.com/item/luxury-72-vertical-channel-tufted-curved-performance-velvet-sofa-in-blue-37140.html"
            price1="1,379.99"
            image2={Aquarius2}
            url2="https://www.decormarket.com/safavieh-astrid-faceted-coffee-table-brushed-brass.html"
            price2="464"
            image3={Aquarius3}
            url3="https://www.wayfair.com/rugs/pdp/wrought-studio-aldwell-abstract-handmade-tufted-wool-cream-area-rug-w004592645.html?piid=73174656"
            price3="113.99"
            image4={Aquarius4}
            url4="https://www.wayfair.com/furniture/pdp/everly-quinn-modern-faux-fur-vanity-accent-chair-elegant-furry-chair-w006677810.html?piid=1709024003"
            price4="299.99"
            image5={Aquarius5}
            url5="https://www.homedepot.com/p/Home-Decorators-Collection-Round-Gold-Metal-Accent-Table-16-5-in-W-x-17-75-in-H-DC16-96610/309804598?source=shoppingads&locale=en-US#overlay"
            price5="96.75"
            image6={Aquarius6}
            url6="https://www.accentcollection.com/products/abstract-blue-painting-wth-gold-foil-100-hand-painted-oil-painting-on-canvas-handmade-modern-wall-art-pictures-for-home-decor?variant=42744414666988&currency=CAD&utm_medium=product_sync&utm_source=google&utm_content=sag_organic&utm_campaign=sag_organic"
            price6="187.99"
            image7={Aquarius7}
            url7="https://www.overstock.com/Home-Garden/Nicole-75-TV-Stand/35499678/product.html?opre=1&option=68098509"
            price7="739.99"
            image8={Aquarius8}
            url8="https://www.homedepot.com/p/20-in-Blue-Garden-Tall-White-Lotus-Shoulder-Vase-32029/306057948?source=shoppingads&locale=en-US#overlay"
            price8="91.27"
            image9={Aquarius9}
            url9="https://www.amazon.com/Coferset-Artificial-Eucalyptus-Potted-Sprayed/dp/B08ZSXD1BR?source=ps-sl-shoppingads-lpcontext&ref_=fplfs&smid=A16BQRBRF2J1Q0&th=1"
            price9="15.99"
          />
          <Footer />
        </>
      }
    />
    <Route
      path="/moodboards/pisces"
      element={
        <>
          <Helmet>
            <title>Pisces Moodboard • Craftle • Craft your dream home</title>
            <meta
              name="description"
              content="We created this furniture moodboard specifically for Pisces."
            />
          </Helmet>
          <WebAppBar urls={navLinks} />
          <MoodboardPage
            profile={ZodiacProfile3}
            name="Pisces"
            dates="Feb 19 - Mar 20"
            moodboard={Zodiac3}
            description="Pisces is a water sign, and those born under this sign are often seen as compassionate and imaginative. People born under this sign are often very creative and have a strong connection to the emotional realm. Pisces is also known for being very intuitive and in tune with the needs of others."
            image1={Pisces1}
            url1="https://www.walmart.com/ip/Noble-House-Thelen-Velvet-Channel-Stitch-3-Seater-Shell-Sofa-Turquoise/699520552?wmlspartner=wlpa&selectedSellerId=0"
            price1="449.66"
            image2={Pisces2}
            url2="https://www.amazon.com/Modway-Admire-Scalloped-Performance-Velvet/dp/B07PG3SCL3?source=ps-sl-shoppingads-lpcontext&ref_=fplfs&psc=1&smid=ATVPDKIKX0DER"
            price2="387.94"
            image3={Pisces3}
            url3="https://www.wayfair.com/rugs/pdp/union-rustic-annia-striped-handwoven-cotton-beige-area-rug-w004554949.html?piid=1783947213"
            price3="84.99"
            image4={Pisces4}
            url4="https://www.amazon.com/Safavieh-Collection-Alphonse-Coffee-Table/dp/B00LUTZ9JM?source=ps-sl-shoppingads-lpcontext&ref_=fplfs&psc=1&smid=ATVPDKIKX0DER"
            price4="203.99"
            image5={Pisces5}
            url5="https://www.aliexpress.com/item/3256803526139821.html?_randl_currency=USD&_randl_shipto=US&src=google&aff_fcid=af051551bf9e42018fd279be6397ed38-1657585900382-02133-UneMJZVf&aff_fsk=UneMJZVf&aff_platform=aaf&sk=UneMJZVf&aff_trace_key=af051551bf9e42018fd279be6397ed38-1657585900382-02133-UneMJZVf&terminal_id=c7fec2a2ce4946dda037ab2803d58979&afSmartRedirect=y&gatewayAdapt=4itemAdapt"
            price5="125.79"
            image6={Pisces6}
            url6="https://www.etsy.com/listing/1232433007/large-3d-textured-ocean-acrylic-painting?gpla=1&gao=1&"
            price6="118"
            image7={Pisces7}
            url7="https://www.wayfair.com/decor-pillows/pdp/willa-arlo-interiors-east-clevedon-coffee-table-tray-w001307296.html?piid="
            price7="103.99"
            image8={Pisces8}
            url8="https://www.lowes.com/pd/Grayson-Lane-Contemporary-Style-Large-Round-Indoor-Outdoor-Metallic-Gold-Metal-Planters-in-Gold-Stands-Set-of-2-10-8221-x-16-8221-8-8221-x-13-8221/5000865157?user=shopping"
            price8="63.98"
            image9={Pisces9}
            url9="https://www.bonanza.com/listings/Bluetooth-Portable-Suitcase-Record-Player-with-3-speed-Turntable/1177690629?goog_pla=1"
            price9="59.28"
          />
          <Footer />
        </>
      }
    />
    <Route
      path="/moodboards/aries"
      element={
        <>
          <Helmet>
            <title>Aries Moodboard • Craftle • Craft your dream home</title>
            <meta
              name="description"
              content="We created this furniture moodboard specifically for Aries."
            />
          </Helmet>
          <WebAppBar urls={navLinks} />
          <MoodboardPage
            profile={ZodiacProfile4}
            name="Aries"
            dates="Mar 21 - Apr 19"
            moodboard={Zodiac4}
            description="Aries is a fire sign, and those born under this sign are often seen as passionate and enthusiastic. People born under this sign are often leaders and are known for being very competitive. Aries is also known for being impulsive and sometimes impatient."
            image1={Aries1}
            url1="https://www.afastores.com/nativa-interiors-cornell-chesterfield-tufted-sofa-deep-plush-103-in-red.html?utm_source=google&utm_medium=cse&utm_term=SOF-CORNELL-103-DP-MF-RED"
            price1="2,292"
            image2={Aries2}
            url2="https://www.walmart.com/ip/BELLEZE-Erik-Accent-Chair-Tufted-Linen-with-Bolster-Pillows-Brick/765142649"
            price2="259.99"
            image3={Aries3}
            url3="https://www.decormarket.com/safavieh-leather-shag-rug-collection-lsg601a-black.html?size=23633"
            price3="250"
            image4={Aries4}
            url4="https://www.wayfair.com/furniture/pdp/brayden-studio-waterville-sled-coffee-table-w006113604.html?piid="
            price4="167.99"
            image5={Aries5}
            url5="https://www.elephantstock.com/products/red-is-her-color?utm_source=google&utm_medium=free_listing&utm_campaign=feed_paintings&utm_content=feed_paintings&srsltid=AdGWZVS94-TGGq93hNwn5MwrgovbX8EnpAUeIXbMjX0VRGFdFxEXfiqqIxw&layout=3-piece"
            price5="49.95"
            image6={Aries6}
            url6="https://modernartisans.com/classic-horsehair-pottery-vases/?sku=HD0080-Red"
            price6="99.99"
            image7={Aries7}
            url7="https://www2.hm.com/en_us/productpage.0930338001.html"
            price7="17.99"
            image8={Aries8}
            url8="https://www.wayfair.com/decor-pillows/pdp/allmodern-thames-ivory-863-stoneware-table-vase-w005019627.html?piid="
            price8="27"
            image9={Aries9}
            url9="https://www.amazon.com/American-Atelier-Leather-Serving-Handles/dp/B00I4KANKU?source=ps-sl-shoppingads-lpcontext&ref_=fplfs&smid=ATVPDKIKX0DER&th=1"
            price9="26.79"
          />
          <Footer />
        </>
      }
    />
    <Route
      path="/moodboards/taurus"
      element={
        <>
          <Helmet>
            <title>Taurus Moodboard • Craftle • Craft your dream home</title>
            <meta
              name="description"
              content="We created this furniture moodboard specifically for Taurus."
            />
          </Helmet>
          <WebAppBar urls={navLinks} />
          <MoodboardPage
            profile={ZodiacProfile5}
            name="Taurus"
            dates="Apr 20 - May 20"
            moodboard={Zodiac5}
            description="Taurus is an earth sign, and those born under this sign are often seen as practical and reliable. People born under this sign are often very down to earth and are known for their stability. Taurus is also known for being stubborn and sometimes inflexible."
            image1={Taurus1}
            url1="https://www.urbanoutfitters.com/shop/elliot-sofa?inventoryCountry=US&color=102&size=ONE%20SIZE&type=REGULAR&quantity=1"
            price1="549.99"
            image2={Taurus2}
            url2="https://www.wayfair.com/furniture/pdp/george-oliver-cristyle-29-wide-linen-armchair-w006530418.html?piid=1604128847"
            price2="209.99"
            image3={Taurus3}
            url3="https://sweaterpicks.com/products/simple-shaped-irregular-rug-1?variant=40751587754031"
            price3="129.95"
            image4={Taurus4}
            url4="https://www.ballarddesigns.com/bd/659000?listIndex=0&offers_sku=ME109%20WWM%20DKO&SourceCode=BDG007&utm_source=google&utm_medium=organic&utm_campaign=unpaid&intlShippingCtx=US%7CUSD&CAWELAID=120245430001035405"
            price4="1,599.20"
            image5={Taurus5}
            url5="https://www.wayfair.com/decor-pillows/pdp/uttermost-na-by-na-floater-frame-painting-on-canvas-w003086177.html?piid="
            price5="723.80"
            image6={Taurus6}
            url6="https://www.target.com/p/10-34-concrete-planter-gray-project-62-8482/-/A-79784825?ref=tgt_adv_XS000000&AFID=google_pla_df_free_local&CPNG=Patio%2BGarden&adgroup=84-15"
            price6="40.50"
            image7={Taurus7}
            url7="https://www.westelm.com/products/volume-side-table-wood-h6054/?catalogId=71&sku=4139628&cm_ven=FreePLA&cm_cat=Google&cm_pla=Furniture%20%3E%20Side%20Tables&region_id=668460"
            price7="279"
            image8={Taurus8}
            url8="https://www.amazon.com/CosmoLiving-Cosmopolitan-54247-Contemporary-Sculptures/dp/B01LKCBIE8/ref=sr_1_18_sspa?crid=3U0F3T71YQKS3&keywords=CosmoLiving+by+Cosmopolitan+Contemporary+Metal+Round+Sculptures&qid=1661647661&sprefix=cosmoliving+by+cosmopolitan+contemporary+metal+round+sculptures%2Caps%2C79&sr=8-18-spons&psc=1&spLa=ZW5jcnlwdGVkUXVhbGlmaWVyPUExQTMwQ1k4REZaMkg2JmVuY3J5cHRlZElkPUEwNjkzMjgwNjdMSzdYRFM4TDZNJmVuY3J5cHRlZEFkSWQ9QTAyNjc4OTEzQ1k5OU40TU5IN0VDJndpZGdldE5hbWU9c3BfbXRmJmFjdGlvbj1jbGlja1JlZGlyZWN0JmRvTm90TG9nQ2xpY2s9dHJ1ZQ=="
            price8="41.48"
            image9={Taurus9}
            url9="https://www.michaels.com/frosted-sage-green-garden-mint-scented-jar-candle-by-ashland/10680085.html?r=g"
            price9="2.99"
          />
          <Footer />
        </>
      }
    />

    {/* see more page */}
    <Route
      path="/see-more"
      element={
        <>
          <Helmet>
            <title>See More Craftle</title>
            <meta name="description" content="Want to see more at Craftle?" />
          </Helmet>
          <WebAppBar urls={navLinks} />
          <SeeMore />
          <Footer />
        </>
      }
    />
  </>
);

export default popularFurnitureRoutes;
