import React, { useState } from "react";
import { Box } from "@mui/material";
import CardMedia from "@mui/material/CardMedia";

// import components
import { classes } from "./AIFurnitureShopperResults1Style";
import Text from "../../components/atoms/Text/Text";
import Button from "../../components/atoms/Button/Button";
import Textfield from "../../components/molecules/Textfield/Textfield";

// import images

// import icons
const sunIcon =
  "https://craftle-static.s3.us-east-2.amazonaws.com/icons/sun.svg";
const minimizeIcon =
  "https://craftle-static.s3.us-east-2.amazonaws.com/icons/minus.svg";
const externalLink =
  "https://craftle-static.s3.us-east-2.amazonaws.com/icons/external-link.svg";
const picture6 =
  "https://craftle-static.s3.us-east-2.amazonaws.com/ai-furniture-shopper/6.jpg";
const picture7 =
  "https://craftle-static.s3.us-east-2.amazonaws.com/ai-furniture-shopper/7.jpg";
const picture8 =
  "https://craftle-static.s3.us-east-2.amazonaws.com/ai-furniture-shopper/8.jpg";
const picture9 =
  "https://craftle-static.s3.us-east-2.amazonaws.com/ai-furniture-shopper/9.jpg";
const selectedHeart =
  "https://craftle-static.s3.us-east-2.amazonaws.com/icons/heart-filled.svg";
const unselectedHeart =
  "https://craftle-static.s3.us-east-2.amazonaws.com/icons/heart-not-filled.svg";
const loader =
  "https://craftle-static.s3.us-east-2.amazonaws.com/animations/loader-dual-ring.gif";

export default function AIFurnitureShopperResults2() {
  const [loaderDisplay, setLoaderDisplay] = useState("none");
  const [favorited1, setFavorited1] = useState(false);
  const [favorited2, setFavorited2] = useState(false);
  const [favorited3, setFavorited3] = useState(false);
  const [favorited4, setFavorited4] = useState(false);
  const [input, setInput] = useState("");

  return (
    <Box sx={classes.container}>
      <Box sx={{ ...classes.loadingSection, display: loaderDisplay }}>
        <Box sx={classes.loadingModal}>
          <CardMedia
            component="img"
            image={loader}
            alt=""
            sx={classes.loaderIcon}
          />
          <Text sx={classes.loaderText}>
            Looking for awesome products. Hang tight!
          </Text>
        </Box>
      </Box>

      <Box sx={classes.topSeparator} />

      <Box sx={classes.content}>
        <Box sx={classes.leftColumn}>
          <Box sx={classes.sticky}>
            {/* Commands */}
            <Box sx={classes.commandsContainer}>
              <Box sx={classes.commandButton}>
                <Text sx={classes.commandText}>Start Over</Text>
              </Box>
              <Box sx={{ ...classes.commandButton, padding: "10px 24px" }}>
                <Text sx={classes.commandText}>Chat History</Text>
                <Box sx={classes.numberBubbleContainer}>
                  <Text sx={classes.commandText}>2</Text>
                </Box>
              </Box>
              <Box sx={classes.commandButton}>
                <Text sx={classes.commandText}>Share</Text>
              </Box>
            </Box>

            {/* Left column content */}
            <Box sx={classes.leftColumnContent}>
              <Box sx={classes.matchingItemsContainer}>
                <Text sx={classes.matchingItemsText}>4 matching items</Text>
              </Box>
              <Text variant="h1" sx={classes.title}>
                Try these on for size 🛋️
              </Text>
              <Text sx={classes.subtitle}>
                If you want me to find sofas that are more specific, tell me
                more below.
              </Text>
              <Box sx={classes.searchContainer}>
                <Textfield
                  type="text"
                  id="email"
                  placeholder="Try: Show me sofas that go with a beige wall"
                  onChange={(event) => setInput(event.target.value)}
                  value={input}
                  sx={classes.textfield}
                />
                <Button
                  variant="primary"
                  onClick={() => {
                    setLoaderDisplay("inherit");
                    // setTimeout(() => {
                    //   navigate("/ai-furniture-shopper/search/e/679");
                    // }, 3000);
                  }}
                  sx={classes.searchButton}
                >
                  Send
                </Button>
              </Box>
              <Box sx={classes.tipsContainer}>
                <Box sx={classes.tipsTitleRow}>
                  <Box sx={classes.tipsTitleRowLeft}>
                    <CardMedia
                      component="img"
                      image={sunIcon}
                      alt="Tips icon"
                      sx={classes.tipsIcon}
                    />
                    <Text sx={classes.tipsTitleText}>Tips to consider</Text>
                  </Box>
                  <CardMedia
                    component="img"
                    image={minimizeIcon}
                    alt="Minimize icon"
                    sx={classes.minimizeIcon}
                  />
                </Box>
                <Text sx={classes.tipsContent}>
                  <>
                    <ol style={{ margin: 0, paddingLeft: "24px" }}>
                      <li>
                        Are you interested in a specific style? For example,
                        modern, vintage, rustic, etc.?
                      </li>
                      <li>
                        Are there any additional features you&apos;d like? For
                        example, reclining seats, built-in USB ports, etc.?
                      </li>
                    </ol>
                  </>
                </Text>
                <Text sx={classes.tipsMoreButton}>Click for more</Text>
              </Box>
            </Box>
          </Box>
        </Box>

        <Box sx={classes.separator} />

        <Box sx={classes.rightColumn}>
          <Box sx={classes.itemContainer}>
            <Box sx={classes.imageContainer}>
              <CardMedia
                component="img"
                image={picture6}
                alt="Minimize icon"
                sx={classes.itemPicture}
              />
              <Box
                onClick={() => {
                  favorited1 ? setFavorited1(false) : setFavorited1(true);
                }}
                sx={classes.favoritesContainer}
              >
                {favorited1 ? (
                  <CardMedia
                    component="img"
                    image={selectedHeart}
                    alt=""
                    sx={classes.favoriteIcon}
                  />
                ) : (
                  <CardMedia
                    component="img"
                    image={unselectedHeart}
                    alt=""
                    sx={classes.favoriteIcon}
                  />
                )}
              </Box>
            </Box>
            <Text sx={classes.itemBrand}>WAYFAIR</Text>
            <Text sx={classes.itemPrice}>$1,900.00</Text>
            <Text sx={classes.itemDescription}>
              I chose this green sofa for its clean, modern shape. It&apos;s
              similar to one I found for you before but with a different color.
            </Text>
            <Button
              variant="secondary"
              icon={externalLink}
              onClick={() => {
                window.open(
                  "https://www.wayfair.com/furniture/pdp/joss-main-alpine-90-upholstered-sofa-stmh2303.html?piid=59670576"
                );
              }}
              sx={classes.checkItOutButton}
            >
              Shop Now
            </Button>
          </Box>

          <Box sx={classes.itemContainer}>
            <Box sx={classes.imageContainer}>
              <CardMedia
                component="img"
                image={picture7}
                alt="Minimize icon"
                sx={classes.itemPicture}
              />
              <Box
                onClick={() => {
                  favorited2 ? setFavorited2(false) : setFavorited2(true);
                }}
                sx={classes.favoritesContainer}
              >
                {favorited2 ? (
                  <CardMedia
                    component="img"
                    image={selectedHeart}
                    alt=""
                    sx={classes.favoriteIcon}
                  />
                ) : (
                  <CardMedia
                    component="img"
                    image={unselectedHeart}
                    alt=""
                    sx={classes.favoriteIcon}
                  />
                )}
              </Box>
            </Box>
            <Text sx={classes.itemBrand}>WAYFAIR</Text>
            <Text sx={classes.itemPrice}>$1,900.00</Text>
            <Text sx={classes.itemDescription}>
              This curved sofa is similar to the previous one I found you, but I
              wanted to give you another color option. This one is brown-velvet.
            </Text>
            <Button
              variant="secondary"
              icon={externalLink}
              onClick={() => {
                window.open(
                  "https://www.wayfair.com/furniture/pdp/joss-main-alpine-90-upholstered-sofa-stmh2303.html?piid=59670575"
                );
              }}
              sx={classes.checkItOutButton}
            >
              Shop Now
            </Button>
          </Box>

          <Box sx={classes.itemContainer}>
            <Box sx={classes.imageContainer}>
              <CardMedia
                component="img"
                image={picture8}
                alt="Minimize icon"
                sx={classes.itemPicture}
              />
              <Box
                onClick={() => {
                  favorited3 ? setFavorited3(false) : setFavorited3(true);
                }}
                sx={classes.favoritesContainer}
              >
                {favorited3 ? (
                  <CardMedia
                    component="img"
                    image={selectedHeart}
                    alt=""
                    sx={classes.favoriteIcon}
                  />
                ) : (
                  <CardMedia
                    component="img"
                    image={unselectedHeart}
                    alt=""
                    sx={classes.favoriteIcon}
                  />
                )}
              </Box>
            </Box>
            <Text sx={classes.itemBrand}>HOMEDIRECT365</Text>
            <Text sx={classes.itemPrice}>$2,375.92</Text>
            <Text sx={classes.itemDescription}>
              For a little more variety, try out this gray sofa from
              HOMEDIRECT365 that goes with any modern or contemporary room. The
              curved design will make for a stunning showpiece in your room.
            </Text>
            <Button
              variant="secondary"
              icon={externalLink}
              onClick={() => {
                window.open(
                  "https://www.homesdirect365.co.uk/sanza-sofa-grey-velvet-p67163"
                );
              }}
              sx={classes.checkItOutButton}
            >
              Shop Now
            </Button>
          </Box>

          <Box sx={classes.itemContainer}>
            <Box sx={classes.imageContainer}>
              <CardMedia
                component="img"
                image={picture9}
                alt="Minimize icon"
                sx={classes.itemPicture}
              />
              <Box
                onClick={() => {
                  favorited4 ? setFavorited4(false) : setFavorited4(true);
                }}
                sx={classes.favoritesContainer}
              >
                {favorited4 ? (
                  <CardMedia
                    component="img"
                    image={selectedHeart}
                    alt=""
                    sx={classes.favoriteIcon}
                  />
                ) : (
                  <CardMedia
                    component="img"
                    image={unselectedHeart}
                    alt=""
                    sx={classes.favoriteIcon}
                  />
                )}
              </Box>
            </Box>
            <Text sx={classes.itemBrand}>TOV FURNITURE</Text>
            <Text sx={classes.itemPrice}>$1,349.00</Text>
            <Text sx={classes.itemDescription}>
              If you are going for a room design that is a little less heavy,
              try out this blush curved sofa. It&apos;s got a sturdy pine frame
              to give you long-lasting support and comfort.
            </Text>
            <Button
              variant="secondary"
              icon={externalLink}
              onClick={() => {
                window.open(
                  "https://tovfurniture.com/product/kendall-blush-velvet-sofa/"
                );
              }}
              sx={classes.checkItOutButton}
            >
              Shop Now
            </Button>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
