const misc_mappings = {
  // gather: "modular",
  // horizon: "modular",
  // axis: "modular",
  // lounge: "modular",
  // oceanside: "modular",
  // tidal: "modular",
  // "barett II": "modular",
  // lotus: "modular",
  "wells leather": "midcentury modern",
  // peyton: "modular",
  // "gather wood base": "modular",
  // pacific: "modular",
  // unwind: "modular tuxedo",
  avondale: "midcentury modern",
  // ever: "modular",
  petrie: "tufted midcentury modern",
  // aris: "modular tuxedo",
  // willow: "modular tuxedo",
  // angolare: "modular tuxedo deep",
  sinuous: "curved",
  infiniti: "curved",
  // notch: "modular",
  // cambria: "modular tuxedo",
  "wells fabric": "midcentury modern",
  chiltern: "biscuit tuxedo",
  timson: "midcentury modern",
  "pacific leather": "midcentury modern",
  hague: "midcentury modern",
  // fuller: "modular",
  strom: "midcentury modern",
  // bedford: "modular",
  bowen: "tufted midcentury modern",
  // leisure: "modular",
  monahan: "midcentury modern",
  cavett: "midcentury modern 2 seat loveseat",
  jesper: "tuxedo",
  rollins: "tufted tuxedo",
  // mansfield: "modular rolled arm",
  // costes: "modular",
  // cosima: "modular vertical tufted",
  velvet: "velvet",
  outdoor: "outdoor",
};

export default function MiscellaneousClassifier(productName) {
  const productNameLowerCase = productName.toLocaleLowerCase();
  let allMisc = "";
  for (let i = 0; i < Object.keys(misc_mappings).length; i++) {
    let name = Object.keys(misc_mappings)[i];
    if (productNameLowerCase.includes(name)) {
      if (allMisc === "") {
        allMisc = Object.values(misc_mappings)[i];
      } else {
        allMisc = allMisc + " " + Object.values(misc_mappings)[i];
      }
    }
  }

  return allMisc;
}
