export const Icons = {
  titleIcon:
    "https://craftle-static.s3.us-east-2.amazonaws.com/favorites/favorites-title-icon.svg",
  plusIcon:
    "https://craftle-static.s3.us-east-2.amazonaws.com/icons/plus-gray.svg",
  dashedSquarePlus:
    "https://craftle-static.s3.us-east-2.amazonaws.com/icons/dashed-square-plus.svg",
  dotsVertical:
    "https://craftle-static.s3.us-east-2.amazonaws.com/icons/dots-vertical.svg",
  trash:
    "https://craftle-static.s3.us-east-2.amazonaws.com/icons/trash-red.svg",
  trashWhite:
    "https://craftle-static.s3.us-east-2.amazonaws.com/icons/trash-white.svg",
  trashRed:
    "https://craftle-static.s3.us-east-2.amazonaws.com/icons/trash-red.svg",
  dimensions:
    "https://craftle-static.s3.us-east-2.amazonaws.com/icons/ruler-measure.svg",
  plus: "https://craftle-static.s3.us-east-2.amazonaws.com/icons/plus-gray.svg",
  pencil:
    "https://craftle-static.s3.us-east-2.amazonaws.com/icons/pencil-white.svg",
  dotsHorizontal:
    "https://craftle-static.s3.us-east-2.amazonaws.com/icons/dots-horizontal.svg",
};
