import React, { useState } from "react";
import { Box, Typography, TextField } from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { Grid } from "@mui/material";
// import PropTypes from "prop-types";
// import { Link } from "react-router-dom";

// import components
import Button from "../../components/atoms/Button/Button";
import Text from "../../components/atoms/Text/Text";
import signUpForMailing from "../../services/signUpForMailing";

// import images

// import icons
import { ReactComponent as LinkIcon } from "../../assets/icons/link.svg";

// Import utils
import { validateEmail } from "../../utils/utils";

let customTheme = createTheme({
  breakpoints: {
    values: {
      customMd: 1300,
      xl: 1536,
      lg: 1200,
      md: 900,
      sm: 600,
      xs: 0,
    },
  },
});

const propTypes = {};
const defaultProps = {};

ProductPage.propTypes = propTypes;
ProductPage.defaultProps = defaultProps;

function ProductPage() {
  const [email, setEmail] = useState("");
  const [errorMsg, setErrorMsg] = useState("");
  const [successMsg, setSuccessMsg] = useState("");

  const [copyLinkPressed, setCopyLinkPressed] = useState(false);

  const handleChange = (e) => {
    setEmail(e.target.value);
  };

  const handleSubmit = () => {
    setErrorMsg("");
    setSuccessMsg("");
    if (email === "") {
      setErrorMsg("Please enter an email.");
      return;
    }

    if (validateEmail(email)) {
      signUpForMailing({
        email,
        b2b: false,
      })
        .then((response) => {
          setSuccessMsg(response);
          console.log(response);
        })
        .catch((error) => {
          setErrorMsg(error);
          console.log(error);
        });
    } else {
      setErrorMsg(`${email} is an invalid email.`);
    }
  };

  return (
    <ThemeProvider theme={customTheme}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          margin: "120px 0",
          [customTheme.breakpoints.down("md")]: {
            margin: "80px 0",
            padding: "0 32px",
          },
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            maxWidth: "753px",
            width: "100%",
            textAlign: "center",
          }}
        >
          <Typography
            variant="h1"
            sx={{ fontSize: "40px", lineHeight: 1.2, fontWeight: "bold" }}
          >
            hello 👋 want to see more?
          </Typography>

          <Typography sx={{ marginTop: "16px", fontSize: "20px" }}>
            We’re a team of 3 friends that started this project because it’s
            something we also wanted. However, we made a goal of only sticking
            with this if 10,000 people show interest. So if this is something
            you’d like to see more of, just sign up below!
          </Typography>

          {/* email form */}
          <Box
            sx={{
              marginTop: "40px",
            }}
          >
            <form
              id="email-form"
              name="email-form"
              data-name="Email Form"
              className="form"
            >
              <Grid
                container
                sx={{
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <Grid
                  item
                  md={8}
                  sx={{
                    // paddingBottom: "20px !important",
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                    [customTheme.breakpoints.down("md")]: {},
                    [customTheme.breakpoints.down("sm")]: {
                      flexDirection: "column",
                      alignItems: "center",
                      width: "100%",
                    },
                  }}
                >
                  <TextField
                    placeholder="Email address"
                    label="Enter email"
                    variant="outlined"
                    color="secondary"
                    value={email}
                    onChange={handleChange}
                    autoFocus={true}
                    fullWidth={true}
                    required={true}
                    sx={{
                      backgroundColor: "white",
                      maxWidth: "500px",
                      marginRight: "10px",
                      minWidth: "250px",
                      [customTheme.breakpoints.down("lg")]: {
                        gap: "10px",
                      },
                      [customTheme.breakpoints.down("sm")]: {
                        marginRight: 0,
                      },
                      "& fieldset": {
                        borderRadius: "100px",
                      },
                    }}
                  />
                </Grid>

                <Grid
                  item
                  md={4}
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    [customTheme.breakpoints.down("sm")]: {
                      width: "100%",
                      paddingTop: "24px",
                    },
                  }}
                >
                  <Button
                    color="secondary"
                    onButtonClick={handleSubmit}
                    sx={{
                      height: "50px",
                      minWidth: "160px",
                      width: "100%",
                    }}
                  >
                    Sign Up
                  </Button>
                </Grid>
              </Grid>
            </form>
          </Box>
          <Text
            sx={{
              fontWeight: "bold",
              marginTop: "20px",
              color: successMsg ? "#28a745" : errorMsg ? "#ff0000" : "",
            }}
          >
            {successMsg || errorMsg || ""}
          </Text>

          <Box sx={{ display: "flex", flexDirection: "row", marginTop: "0px" }}>
            <Typography sx={{ fontWeight: "bold" }}>1,241 signed up</Typography>

            <Typography>&nbsp;•&nbsp;Unsubscribe at any time ❤️</Typography>
          </Box>

          {/* share link */}
          <Box
            sx={{
              marginTop: "40px",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            {copyLinkPressed && (
              <Box
                sx={{
                  position: "absolute",
                  marginTop: "-75px",
                  zIndex: 1,
                }}
              >
                <Box
                  sx={{
                    backgroundColor: "#E5F6FD",
                    position: "relative",
                    paddingTop: "15px",
                    paddingBottom: "15px",
                    paddingLeft: "30px",
                    paddingRight: "30px",
                    borderRadius: "8px",
                  }}
                >
                  <Text sx={{ textAlign: "center" }}>Link copied</Text>
                </Box>
                <Box
                  sx={{
                    borderLeft: "10px solid transparent",
                    borderRight: "10px solid transparent",
                    borderTop: "20px solid #E5F6FD",
                    position: "absolute",
                    right: "60px",
                  }}
                ></Box>
              </Box>
            )}

            <Box
              sx={{
                height: "50px",
                borderStyle: "solid",
                borderWidth: "1px",
                borderColor: "#979797",
                borderRadius: "50px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                cursor: "pointer",
                padding: "0 16px",
                "&:hover": {
                  backgroundColor: "rgba(60, 64, 67, 0.1)",
                },
              }}
              onClick={() => {
                setCopyLinkPressed(true);
                navigator.clipboard.writeText(`https://craftle.app/see-more`);
                setTimeout(() => {
                  setCopyLinkPressed(false);
                }, 1000);
              }}
            >
              <LinkIcon />
              <Text sx={{ marginLeft: "10px" }}>Share this page</Text>
            </Box>
          </Box>
        </Box>
      </Box>
    </ThemeProvider>
  );
}

export default ProductPage;
