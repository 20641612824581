import { customTheme } from "../../components/atoms/CustomTheme/CustomTheme";

export const classes = {
  blogContentContainer: {
    maxWidth: "808px",
  },
  subtitle: {
    marginTop: "16px",
    fontSize: "20px",
  },
  craftleAdContainer: {
    width: "100%",
    border: "4px solid #2A2A2A",
    borderRadius: "16px",
    padding: "16px",
    marginTop: "32px",
  },
  craftleAdItem1: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  craftleAdGraphic: {
    width: "76px",
    height: "62px",
    [customTheme.breakpoints.down("md")]: {},
  },
  craftleAdItem2: {
    [customTheme.breakpoints.down("sm")]: {
      marginTop: "16px",
    },
  },
  craftleAdTitle: {
    fontSize: "24px",
    fontWeight: "bold",
    marginBottom: "4px",
  },
  craftleAdItem3: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    [customTheme.breakpoints.down("sm")]: {
      marginTop: "16px",
    },
  },
  faqTitle: {
    marginTop: "64px",
    fontSize: "40px",
  },
  faqSubtitle: {
    marginTop: "24px",
    fontSize: "20px",
  },
  faqDescription: {
    marginTop: "16px",
  },
};
