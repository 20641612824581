// Import icons
const lookalikeFinderMenuIcon =
  "https://craftle-static.s3.us-east-2.amazonaws.com/blog/menu-furniture-lookalike-finder-icon.svg";
const craftMyStyleMenuIcon =
  "https://craftle-static.s3.us-east-2.amazonaws.com/blog/menu-craft-my-style-icon.svg";
const favoritesMenuIcon =
  "https://craftle-static.s3.us-east-2.amazonaws.com/favorites/favorites-title-icon.svg";
// const aiRoomDesignerMenuIcon =
//   "https://craftle-static.s3.us-east-2.amazonaws.com/blog/menu-ai-room-designer-icon.svg";

export default function getNavItems(firstName) {
  return [
    {
      text: "Design & Discover",
      variant: "dropdownTertiary",
      subLink: [
        {
          dropdownVariant: "Design & Discover",
          icon: craftMyStyleMenuIcon,
          text: "Craft My Style",
          url: "/designs",
          description:
            "Answer a few simple questions and get a furniture mood board curated just for you.",
        },
        {
          dropdownVariant: "Design & Discover",
          icon: favoritesMenuIcon,
          text: "Compare Favorites",
          url: "/favorites",
          description:
            "Easily compare your favorite products side-by-side to make the best choice for your space.",
        },
        {
          dropdownVariant: "Design & Discover",
          icon: lookalikeFinderMenuIcon,
          text: "Furniture Lookalike Finder",
          url: "/lookalike-finder",
          description:
            "Find furniture that are similar and cheaper to the one you like.",
        },
        // {
        //   dropdownVariant: "Style & Match",
        //   icon: aiRoomDesignerMenuIcon,
        //   text: "AI Room Designer",
        //   url: "/ai-room-designer",
        //   description:
        //     "Upload an image of your room and see it transformed into different themes.",
        // },
      ],
    },
    {
      text: "Shop",
      url: "/living-room",
      variant: "navTertiary",
    },
    {
      text: "Favorites",
      url: "/favorites",
      variant: "navTertiary",
    },
    {
      text: "Log In / Sign Up",
      url: "/log-in",
      variant: "navFourth",
    },
    {
      text: firstName,
      variant: "dropdownSecondary",
      subLink: [
        { text: "My Designs", url: "/designs" },
        { text: "Profile", url: "/profile" },
        { text: "Log out", url: "/" },
      ],
    },
  ];
}
