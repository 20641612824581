import axios from "axios";

// import components
import { EndPoint } from "../../atoms/EndPoint/EndPoint";

const END_POINTS = {
  prod: "https://5a07i93np8.execute-api.us-east-2.amazonaws.com/default/craftle-shop-categories-call",
  test: "https://5a07i93np8.execute-api.us-east-2.amazonaws.com/default/craftle-shop-categories-call",
  local: "http://127.0.0.1:8080/shop/categories",
};

export default async function ShopCategoryBackendCall(categoryID) {
  const responseFromAWS = await (async () => {
    try {
      const response = await axios.get(END_POINTS[EndPoint], {
        params: {
          category_id: categoryID,
        },
        headers: {
          "Content-Type": "application/json",
        },
      });
      return response;
    } catch (e) {
      console.log(e.message);
    }
  })();

  if (responseFromAWS?.data) {
    if (responseFromAWS.data.subcategories) {
      return responseFromAWS.data.subcategories;
    } else if (responseFromAWS.data.body) {
      const body = JSON.parse(responseFromAWS.data.body);
      if (body?.subcategories) {
        return body.subcategories;
      }
    }
  }
  return null;
}
