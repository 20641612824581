import { customTheme } from "../../atoms/CustomTheme/CustomTheme";

export const classes = {
  container: {
    position: "relative",
    [customTheme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  label: {
    marginBottom: "4px",
    fontWeight: "bold",
  },
  dropdownBtn: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    boxShadow: "inset 0 0 0 2px #BFBFBF",
    fontSize: "16px",
    fontWeight: "bold",
    padding: "12px 24px",
    borderRadius: "100px",
    boxSizing: "border-box",
    mozBoxSizing: "border-box",
    webkitBoxSizing: "border-box",
    cursor: "pointer",
    backgroundColor: "#FFFFFF",
    [customTheme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  selectedItem: {
    fontWeight: "bold",
  },
  triangle: {
    width: "12px",
    height: "100%",
    marginLeft: "8px",
  },
  dropdownContent: {
    position: "absolute",
    backgroundColor: "#FFF",
    boxShadow: "0 4px 15px rgba(0,0,0,0.15)",
    borderRadius: "16px",
    zIndex: 10,
    [customTheme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  dropdownItem: {
    padding: "12px 24px",
    borderRadius: "16px",
    width: "100%",
    textAlign: "left",
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    fontSize: "16px",
    "&:hover": {
      backgroundColor: "#FAFAFA",
    },
  },
  footerText: {
    padding: "16px 24px",
    color: "#757575",
    fontStyle: "italic",
    lineHeight: 1.2,
  },
};
