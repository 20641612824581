import React, { useState } from "react";
import { Box, TextField, Divider } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { AnalyticsBrowser } from "@segment/analytics-next";

// import components
import { classes } from "./SignUpStyle";
import Text from "../../components/atoms/Text/Text";
import Button from "../../components/atoms/Button/Button";
import SignUpCallToBackend from "./SignUpCallToBackend";
import GoogleSignUpButton from "./GoogleSignUpButton";
import { segmentWriteKey } from "../../components/atoms/Segment/Segment";

const analytics = AnalyticsBrowser.load({ writeKey: segmentWriteKey });

export default function SignUp() {
  const [showFirstNameError, setShowFirstNameError] = useState(false);
  const [showEmailError, setShowEmailError] = useState(false);
  const [showPasswordError, setShowPasswordError] = useState(false);
  const [showExistingAccountError, setShowExistingAccountError] =
    useState(false);

  let navigate = useNavigate();

  async function saveUserToken(token) {
    return new Promise((resolve, reject) => {
      try {
        localStorage.setItem("user", JSON.stringify(token));
        resolve();
      } catch (error) {
        reject(error);
      }
    });
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    setShowFirstNameError(false);
    setShowEmailError(false);
    setShowPasswordError(false);

    const currentDateTime = new Date(); // Current to the user's timezone

    // Assign the values from the inputs
    let userInfo = {
      firstName: document.getElementById("firstName").value || "",
      email: document.getElementById("email").value || "",
      password: document.getElementById("password").value || "",
      accountCreatedDateTime: currentDateTime.toLocaleString() || "",
    };

    if (userInfo.firstName === "") {
      setShowFirstNameError(true);
    }
    if (userInfo.email === "") {
      setShowEmailError(true);
    }
    if (userInfo.password === "") {
      setShowPasswordError(true);
    }

    if (
      userInfo.firstName !== "" &&
      userInfo.email !== "" &&
      userInfo.password !== ""
    ) {
      const signUp = async () => {
        // Send data to the DB
        return await SignUpCallToBackend(userInfo);
      };
      signUp().then((backendResponse) => {
        if (backendResponse) {
          analytics.track("Account Created", {
            user_email: userInfo.email,
            user_name: userInfo.firstName,
          });
          // Store the user in localStorage
          saveUserToken(backendResponse.data.body.user_token).then(() => {
            document.location.href = "/";
          });
        } else {
          // User already exists
          setShowExistingAccountError(true);
        }
      });
    }
  };

  // Equivalent to StartSearch(), but if the user presses "Enter" on the keyboard
  async function handleKeyDown(event) {
    if (event.key === "Enter") {
      handleSubmit();
    }
  }

  return (
    <Box sx={classes.container}>
      <Box sx={classes.backgroundGradient} />
      <Box sx={classes.content}>
        <Text variant="h1" sx={classes.h1}>
          Sign Up
        </Text>

        <GoogleSignUpButton />

        <Box sx={classes.orContainer}>
          <Divider sx={classes.orDivider} />
          <Text sx={classes.orText}>OR</Text>
          <Divider sx={classes.orDivider} />
        </Box>

        {/*<div
          id="g_id_onload"
          data-client_id="510488826764-1d9dquloi17heo3nfbik17svto28i821.apps.googleusercontent.com"
          data-context="signup"
          data-ux_mode="popup"
          data-login_uri="https://craftle.app"
          data-auto_prompt="false"
        ></div>
        <div
          className="g_id_signin"
          data-type="standard"
          data-shape="pill"
          data-theme="outline"
          data-text="signup_with"
          data-size="large"
          data-logo_alignment="left"
  ></div>*/}
        <form onSubmit={handleSubmit} style={classes.sectionInputForm}>
          <TextField
            label="(required) First Name"
            variant="standard"
            id="firstName"
            onKeyDown={handleKeyDown}
            sx={classes.textField}
          />
          <TextField
            label="(required) Email"
            type="email"
            variant="standard"
            id="email"
            onKeyDown={handleKeyDown}
            sx={classes.textField}
          />
          <TextField
            label="(required) Password"
            type="password"
            variant="standard"
            id="password"
            onKeyDown={handleKeyDown}
            sx={classes.textField}
          />
          {showFirstNameError ? (
            <Text sx={classes.errorMsg}>Your first name is required.</Text>
          ) : null}
          {showEmailError ? (
            <Text sx={classes.errorMsg}>Your email is required.</Text>
          ) : null}
          {showPasswordError ? (
            <Text sx={classes.errorMsg}>Your password is required.</Text>
          ) : null}
          {showExistingAccountError ? (
            <Text sx={classes.errorMsg}>
              An account associated with this email already exists. Log in below
              to access your account.
            </Text>
          ) : null}
          <Box sx={{ marginBottom: "24px" }} />
          <Button type="submit" sx={classes.signUpButton}>
            Sign Up
          </Button>
        </form>

        <Box sx={classes.signUpContainer}>
          <Text sx={classes.newtoCraftleText}>Already have an account? </Text>
          <Button
            variant="fourth"
            onClick={() => {
              navigate("/log-in");
            }}
            sx={classes.logInButton}
          >
            Log in
          </Button>
        </Box>

        <Text sx={classes.legalNotice}>
          By continuing, you agree to Craftle&apos;s{" "}
          <Button variant="fourth" href="/terms" sx={classes.legalBtns}>
            Terms of Use
          </Button>
          . Read our{" "}
          <Button variant="fourth" href="/policy" sx={classes.legalBtns}>
            Privacy Policy
          </Button>
          .
        </Text>
      </Box>
    </Box>
  );
}
