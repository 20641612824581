import React, { createContext } from "react";
import { Box } from "@mui/material";
import PropTypes from "prop-types";

// Import components
import Text from "../../atoms/Text/Text";
import MenuDropdown from "../../molecules/MenuDropdown/MenuDropdown";
import { classes } from "./WebAppBarStyle";
import { CMSResultsContext } from "../../../pages/CraftMyStyle/pages/CMSResults";

export const WebAppBarScrollChanges = createContext();

const propTypes = {
  url: PropTypes.object.isRequired,
};
const defaultProps = {
  url: {},
};

GlobalNavButtons.propTypes = propTypes;
GlobalNavButtons.defaultProps = defaultProps;

export default function GlobalNavButtons({ url }) {
  const { urlPlanId, setShowLoginModal } =
    React.useContext(CMSResultsContext) ?? {};

  if (url.variant == "navPrimary") {
    return (
      <a
        href={url.url}
        target={url.target}
        style={classes.linkStyles}
        rel="noreferrer"
      >
        <Box sx={[classes.navButtonCommonStyles, classes.navPrimaryButton]}>
          {url.text}
        </Box>
      </a>
    );
  } else if (url.variant == "navSecondary") {
    return (
      <a
        href={url.url}
        target={url.target}
        style={classes.linkStyles}
        rel="noreferrer"
      >
        <Box sx={[classes.navButtonCommonStyles, classes.navSecondaryButton]}>
          {url.text} <Text sx={classes.newText}>{url.newText}</Text>
        </Box>
      </a>
    );
  } else if (url.variant == "navTertiary") {
    return (
      <a
        href={url.url}
        target={url.target}
        style={classes.linkStyles}
        rel="noreferrer"
      >
        <Box sx={[classes.navButtonCommonStyles, classes.navTertiaryButton]}>
          {url.text}
        </Box>
      </a>
    );
  } else if (url.variant == "navFourth") {
    return url.text === "Log In / Sign Up" && urlPlanId ? (
      <Box
        onClick={() => {
          setShowLoginModal?.(true);
        }}
        sx={classes.linkStyles}
      >
        <Box sx={[classes.navButtonCommonStyles, classes.navFourthButton]}>
          {url.text}
        </Box>
      </Box>
    ) : (
      <a
        href={url.url}
        target={url.target}
        style={classes.linkStyles}
        rel="noreferrer"
      >
        <Box sx={[classes.navButtonCommonStyles, classes.navFourthButton]}>
          {url.text}
        </Box>
      </a>
    );
  } else if (url.variant == "dropdownSecondary") {
    return <MenuDropdown url={url} variant="secondary" />;
  } else if (url.variant == "dropdownTertiary") {
    return <MenuDropdown url={url} variant="tertiary" />;
  } else {
    return;
  }
}
