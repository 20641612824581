import React, { useRef, useState, useContext, useEffect } from "react";
import {
  Box,
  CardMedia,
  TextField,
  Menu,
  MenuItem,
  CircularProgress,
} from "@mui/material";
import PropTypes from "prop-types";
import { v4 as uuidv4 } from "uuid";

// import components
import { classes } from "./FavoriteItemStyle";
import mixpanel from "../../atoms/Mixpanel/Mixpanel";
import Text from "../../atoms/Text/Text";
import Button from "../../atoms/Button/Button";
import { Icons } from "../../atoms/Icon/Icon";
import IsMobileOrTablet from "../../atoms/IsMobileOrTablet/IsMobileOrTablet";
import DeleteFavoriteCallToBackend from "./DeleteFavoriteCallToBackend";
import UploadFavImgCallToBackend from "./UploadFavImgCallToBackend";
import DeleteFavImgCallToBackend from "./DeleteFavImgCallToBackend";

// import contexts
import { UserContext } from "../../../App";
import { FavoritesContext } from "../../../pages/profileRelated/favorites/Favorites";

const FavoriteItem = ({ item, multipleItems, itemPosition, lastPosition }) => {
  const { user } = useContext(UserContext);
  const {
    favoritesList,
    setFavoritesList,
    hoveredFieldKey,
    setHoveredFieldKey,
  } = useContext(FavoritesContext);

  const formatPrice = (number) => {
    let numStr = number.toString().replace(/\D/g, ""); // Remove all non-numeric characters
    if (numStr.length === 0) return "0.00";

    numStr = (parseInt(numStr, 10) / 100).toFixed(2); // Convert to decimal format
    return numStr.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","); // Add commas
  };

  const capitalizeWords = (string) => {
    if (typeof string === "string") {
      return string
        .split(/[\s-]+/)
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
        .join(" ");
    }
    return string;
  };

  const updateFavoriteDimensions = (product_id, newDimensions) => {
    setFavoritesList((prev) =>
      prev.map((product) => {
        if (product.product_id === product_id) {
          const updatedOtherFields = product.other_fields.map((otherField) => {
            if (otherField.field_label.toLowerCase() === "dimensions") {
              return {
                ...otherField,
                field_value: newDimensions,
              };
            }
            return otherField;
          });
          return {
            ...product,
            dimensions: "",
            length: "",
            width: "",
            height: "",
            other_fields: updatedOtherFields,
          };
        }
        return product;
      })
    );
  };

  const productDimensions = (item) => {
    // Check if other_fields exists and is an array
    if (!Array.isArray(item.other_fields)) {
      return item;
    }

    // Map over other_fields to update dimensions if necessary
    const updatedOtherFields = item.other_fields.map((otherField) => {
      if (otherField.field_label.toLowerCase() === "dimensions") {
        let dimensions;
        if (
          (item.length !== "0" && item.length) ||
          (item.width !== "0" && item.width) ||
          (item.height !== "0" && item.height)
        ) {
          dimensions = `${item.length}"L x ${item.width}"W x ${item.height}"H`;
          updateFavoriteDimensions(item.product_id, dimensions);
        } else if (item.dimensions) {
          dimensions = item.dimensions;
          updateFavoriteDimensions(item.product_id, dimensions);
        } else {
          return otherField;
        }
        return {
          ...otherField,
          field_value: dimensions,
        };
      }
      return otherField;
    });

    return {
      ...item,
      other_fields: updatedOtherFields,
    };
  };

  const [itemOptionsDisplay, setItemOptionsDisplay] = useState(false);
  const [itemMenuHovered, setItemMenuHovered] = useState(false);
  const [moreItemOptionsMenu, setMoreItemOptionsMenu] = useState(null);
  const [errorMessage, setErrorMessage] = useState("");
  const [imgIsHovered, setImgIsHovered] = useState(false);
  const [isEditingBrand, setIsEditingBrand] = useState(false);
  const [isEditingPrice, setIsEditingPrice] = useState(false);
  const [isEditingLink, setIsEditingLink] = useState(false);
  const [priceValue, setPriceValue] = useState(
    formatPrice(item.price) || "0.00"
  );
  const [linkValue, setLinkValue] = useState(item.link || "");
  const [brandValue, setBrandValue] = useState(
    capitalizeWords(item.brand || "")
  );
  const [otherFieldValues, setOtherFieldValues] = useState(
    productDimensions(item).other_fields || []
  );
  const [editingField, setEditingField] = useState({});
  const [editingLabelField, setEditingLabelField] = useState({});
  const [fieldMenuHovered, setFieldMenuHovered] = useState(null);
  const [moreFieldOptionsMenu, setMoreFieldOptionsMenu] = useState(null);
  const [loading, setLoading] = useState(false);

  const ref = useRef(null);

  const isMobileOrTablet = IsMobileOrTablet();
  let capitalizedBrand = brandValue ? capitalizeWords(brandValue) : "";

  useEffect(() => {
    setOtherFieldValues(productDimensions(item).other_fields);
  }, [item]);

  const handleImageUpload = async (event, item) => {
    const file = event.target.files[0];
    if (file) {
      const fileType = file.type;
      if (
        fileType !== "image/jpeg" &&
        fileType !== "image/png" &&
        fileType !== "image/webp"
      ) {
        setErrorMessage("Only JPG, PNG, and WEBP files are allowed.");
        return;
      }
      setErrorMessage(""); // Clear any previous error messages
      setLoading(true);

      // Generate a unique name for the file
      const uniqueFileName = `${uuidv4()}.${fileType.split("/")[1]}`;
      const formData = new FormData();
      formData.append("file", file, uniqueFileName);

      try {
        const response = await UploadFavImgCallToBackend(formData);

        if (response && response.data.success) {
          const imageUrl = response.data.imageUrl;
          setFavoritesList((prev) => {
            const updatedList = prev.map((product) => {
              if (product.product_id === item.product_id) {
                return {
                  ...product,
                  allImages: [...(product.allImages || []), imageUrl],
                };
              }
              return product;
            });
            return updatedList;
          });
        } else {
          console.error("Image upload failed");
        }
      } catch (error) {
        console.error("Error uploading image:", error);
        setErrorMessage(`Error uploading image: ${error.message}`);
      } finally {
        setLoading(false);
      }
    }
  };

  const handleImageDelete = async (item) => {
    const product = favoritesList.find(
      (product) => product.product_id === item.product_id
    );
    const imgName = product?.allImages[0];

    if (imgName) {
      try {
        const response = await DeleteFavImgCallToBackend(imgName);

        if (response && response.success) {
          setFavoritesList((prev) => {
            const updatedList = prev.map((product) => {
              if (product.product_id === item.product_id) {
                return {
                  ...product,
                  allImages: [],
                };
              }
              return product;
            });
            return updatedList;
          });
        } else {
          console.error("Image delete failed");
        }
      } catch (error) {
        console.error("Error deleting image:", error);
        setErrorMessage(`Error deleting image: ${error.message}`);
      }
    }
  };

  const handleClickOutside = (e) => {
    if (ref.current && !ref.current.contains(e.target)) {
      if (isEditingBrand) {
        saveField("brand", brandValue);
        setIsEditingBrand(false);
      }
      if (isEditingPrice) {
        saveField("price", priceValue);
        setIsEditingPrice(false);
      }
      if (isEditingLink) {
        saveField("link", linkValue);
        setIsEditingLink(false);
      }
      otherFieldValues.forEach((field) => {
        if (editingLabelField[field.field_id]) {
          saveField("otherFieldLabel", field.field_label, field.field_id);
          setEditingLabelField({
            ...editingLabelField,
            [field.field_id]: false,
          });
        }
        if (editingField[field.field_id]) {
          saveField("otherFieldValue", field.field_value, field.field_id);
          setEditingField({ ...editingField, [field.field_id]: false });
        }
      });
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [
    isEditingBrand,
    isEditingPrice,
    isEditingLink,
    editingField,
    brandValue,
    priceValue,
    linkValue,
    otherFieldValues,
    editingLabelField,
  ]);

  const saveField = (field, value, key = null) => {
    setFavoritesList((prev) => {
      const updatedList = prev.map((product) => {
        if (product.product_id === item.product_id) {
          if (field === "otherFieldLabel") {
            const updatedOtherFields = product.other_fields.map((otherField) =>
              otherField.field_id === key
                ? { ...otherField, field_label: value }
                : otherField
            );
            return {
              ...product,
              other_fields: updatedOtherFields,
            };
          } else if (field === "otherFieldValue") {
            const updatedOtherFields = product.other_fields.map((otherField) =>
              otherField.field_id === key
                ? { ...otherField, field_value: value }
                : otherField
            );
            return {
              ...product,
              other_fields: updatedOtherFields,
            };
          } else {
            return {
              ...product,
              [field]: value,
            };
          }
        }
        return product;
      });
      return updatedList;
    });
  };

  const handleKeyDown = (e, field, key = null) => {
    if (e.key === "Enter") {
      if (field === "otherFieldLabel") {
        saveField(
          field,
          key
            ? otherFieldValues.find((f) => f.field_id === key).field_label
            : e.target.value,
          key
        );
      } else {
        saveField(
          field,
          key
            ? otherFieldValues.find((f) => f.field_id === key).field_value
            : e.target.value,
          key
        );
      }
      setIsEditingBrand(false);
      setIsEditingPrice(false);
      setIsEditingLink(false);
      setEditingField({ ...editingField, [key]: false });
      setEditingLabelField((prev) => ({
        ...prev,
        [key]: false,
      }));
    }
  };

  const handlePriceChange = (e) => {
    const formattedPrice = formatPrice(e.target.value);
    setPriceValue(formattedPrice);
  };

  const handlePricePaste = (e) => {
    e.preventDefault();
    const pasteData = e.clipboardData.getData("text");
    const cleanedData = pasteData.replace(/[^0-9.]/g, ""); // Remove all non-numeric characters except for decimal point
    const parsedValue = parseFloat(cleanedData).toFixed(2); // Convert to decimal format
    setPriceValue(parsedValue);
  };

  const handleBrandChange = (e) => {
    setBrandValue(e.target.value);
  };

  const handleLinkChange = (e) => {
    setLinkValue(e.target.value);
  };

  const handleOtherFieldLabelChange = (e, key) => {
    setOtherFieldValues((prev) =>
      prev.map((field) =>
        field.field_id === key
          ? { ...field, field_label: e.target.value }
          : field
      )
    );
  };

  const handleOtherFieldValueChange = (e, key) => {
    setOtherFieldValues((prev) =>
      prev.map((field) =>
        field.field_id === key
          ? { ...field, field_value: e.target.value }
          : field
      )
    );
  };

  const handleLinkClick = () => {
    mixpanel.track("(Favorites) Clicked on Product", {
      link: linkValue,
    });
    const formattedLink =
      linkValue.startsWith("http://") || linkValue.startsWith("https://")
        ? linkValue
        : `https://${linkValue}`;
    window.open(formattedLink, "_blank");
  };

  const handleMoreFieldOptions = (event, key) => {
    setFieldMenuHovered(key);
    setMoreFieldOptionsMenu(
      moreFieldOptionsMenu === null
        ? {
            mouseX: event.clientX + 2,
            mouseY: event.clientY - 6,
            fieldKey: key,
          }
        : null
    );
  };

  const handleClose = () => {
    setFieldMenuHovered(null);
    setMoreFieldOptionsMenu(null);
    setMoreItemOptionsMenu(null);
    setItemOptionsDisplay(false);
    setItemMenuHovered(false);
  };

  const handleDeleteItem = async (product_id) => {
    try {
      setLoading(true);

      // Call backend to delete the item
      const response = await DeleteFavoriteCallToBackend(
        user.email,
        item.link,
        product_id
      );
      if (response) {
        // Update the state to remove the item
        setFavoritesList((prevFavoritesList) => {
          const updatedList = prevFavoritesList.filter(
            (item) => item.product_id !== product_id
          );
          return updatedList;
        });
      } else {
        console.error("Failed to delete item on backend");
      }

      setLoading(false);
      handleClose();
    } catch (error) {
      console.error("Error deleting favorite:", error);
      setLoading(false);
    }
  };

  const handleDeleteRow = (key, groupId) => {
    setFavoritesList((prevFavoritesList) =>
      prevFavoritesList.map((item) => {
        if (item.group_id === groupId) {
          return {
            ...item,
            other_fields: item.other_fields.filter((_, index) => index !== key),
          };
        }
        return item;
      })
    );
    handleClose();
  };

  const handleMoreItemOptions = (event) => {
    setItemMenuHovered(true);
    setMoreItemOptionsMenu(
      moreItemOptionsMenu === null
        ? {
            mouseX: event.clientX + 2,
            mouseY: event.clientY - 6,
          }
        : null
    );
  };

  return (
    <Box
      onMouseEnter={() => setItemOptionsDisplay(true)}
      onMouseLeave={() => setItemOptionsDisplay(false)}
      ref={ref}
      sx={classes.container}
    >
      {/* More actions for item */}
      {(itemOptionsDisplay || itemMenuHovered || isMobileOrTablet) && (
        <Box sx={classes.moreItemOptionsContainer}>
          <Box
            onClick={(e) => handleMoreItemOptions(e)}
            sx={classes.horizontalMoreOptions}
          >
            <CardMedia
              component="img"
              image={Icons.dotsHorizontal}
              alt=""
              sx={classes.icon}
            />
          </Box>
        </Box>
      )}

      {/* product image */}
      <Box
        onMouseEnter={() => setImgIsHovered(true)}
        onMouseLeave={() => setImgIsHovered(false)}
        sx={{
          margin: item?.allImages?.length > 0 ? "0" : "1px",
          border: item?.allImages?.length > 0 ? "solid 1px #BFBFBF" : "0",
          backgroundImage:
            item?.allImages?.length > 0
              ? "inherit"
              : `linear-gradient(to right, #757575 33%, rgba(255, 255, 255, 0) 0%),
                 linear-gradient(to bottom, #757575 33%, rgba(255, 255, 255, 0) 0%),
                 linear-gradient(to left, #757575 33%, rgba(255, 255, 255, 0) 0%),
                 linear-gradient(to top, #757575 33%, rgba(255, 255, 255, 0) 0%)`,
          backgroundSize:
            item?.allImages?.length > 0
              ? "inherit"
              : "20px 1px, 1px 20px, 20px 1px, 1px 20px",
          backgroundPosition:
            item?.allImages?.length > 0
              ? "inherit"
              : "top, right, bottom, left",
          backgroundRepeat:
            item?.allImages?.length > 0
              ? "inherit"
              : "repeat-x, repeat-y, repeat-x, repeat-y",
          "&:hover": {
            backgroundColor:
              item?.allImages?.length > 0 ? "inherit" : "#F3F3F3",
          },
          ...classes.imgContainer,
        }}
      >
        {item?.allImages?.length > 0 ? (
          <>
            <Box
              sx={{
                display:
                  !isMobileOrTablet && imgIsHovered
                    ? "flex"
                    : isMobileOrTablet
                    ? "flex"
                    : "none",
                top: isMobileOrTablet ? "16px" : "none",
                right: isMobileOrTablet && "16px",
                ...classes.deleteContainer,
              }}
            >
              <CardMedia
                component="img"
                image={Icons.trashWhite}
                alt="Delete button"
                sx={classes.trashIcon}
                onClick={() => handleImageDelete(item)}
              />
            </Box>
            <CardMedia
              component="img"
              image={item.allImages[0]}
              alt="Product image"
              ref={ref}
              sx={{
                filter: imgIsHovered ? "brightness(80%)" : "inherit",
                ...classes.categoryImage,
              }}
            />
          </>
        ) : (
          <label style={classes.imgLabel}>
            <input
              type="file"
              accept="image/*"
              onChange={(e) => !loading && handleImageUpload(e, item)}
              style={{ display: "none" }}
              disabled={loading}
            />
            <Box sx={classes.noImageContainer}>
              {loading ? (
                <CircularProgress size={24} />
              ) : (
                <>
                  <CardMedia
                    component="img"
                    image={Icons.plus}
                    alt=""
                    sx={classes.plusIcon}
                  />
                  <Text sx={classes.uploadImgTxt}>Upload Product Image</Text>
                </>
              )}
            </Box>
          </label>
        )}
      </Box>

      {errorMessage && <Text sx={classes.errorMsg}>{errorMessage}</Text>}

      <Box sx={classes.itemInfo}>
        {/* product brand */}
        {isEditingBrand ? (
          <TextField
            value={capitalizedBrand}
            onChange={handleBrandChange}
            onKeyDown={(e) => handleKeyDown(e, "brand")}
            autoFocus
            onBlur={() => {
              saveField("brand", brandValue);
              setIsEditingBrand(false);
            }}
            sx={classes.brandTextfield}
          />
        ) : (
          <Text
            onClick={() => setIsEditingBrand(true)}
            sx={{
              ...getTextStyle(!capitalizedBrand, 24, 14, "#F3F3F3", "#ECECEC"),
              ...classes.brand,
            }}
          >
            {capitalizedBrand || "Add Brand"}
          </Text>
        )}

        {/* product price */}
        {isEditingPrice ? (
          <TextField
            value={priceValue}
            onChange={handlePriceChange}
            onPaste={handlePricePaste}
            onKeyDown={(e) => handleKeyDown(e, "price")}
            autoFocus
            onBlur={() => {
              saveField("price", priceValue);
              setIsEditingPrice(false);
            }}
            sx={classes.priceTextField}
          />
        ) : (
          <Text
            onClick={() => setIsEditingPrice(true)}
            sx={{
              ...getTextStyle(
                priceValue === "0.00",
                36,
                14,
                "#F3F3F3",
                "#ECECEC"
              ),
              color: priceValue === "0.00" ? "#757575" : null,
              fontSize: priceValue === "0.00" ? "14px" : "24px",
              ...classes.price,
            }}
          >
            {priceValue === "0.00" ? "Add Price" : `$${priceValue}`}
          </Text>
        )}

        {/* product link */}
        {isEditingLink ? (
          <TextField
            value={linkValue}
            onChange={handleLinkChange}
            onKeyDown={(e) => handleKeyDown(e, "link")}
            autoFocus
            onBlur={() => {
              saveField("link", linkValue);
              setIsEditingLink(false);
            }}
            sx={classes.linkBtnTextfield}
          />
        ) : linkValue ? (
          <Button
            variant="primary"
            onClick={handleLinkClick}
            sx={classes.linkBtnExists}
          >
            Buy Now
            <Box
              onClick={(e) => {
                e.stopPropagation();
                setIsEditingLink(true);
              }}
              sx={classes.editLinkBtn}
            >
              <CardMedia
                component="img"
                image={Icons.pencil}
                alt="Edit link"
                sx={classes.editLinkIcon}
              />
            </Box>
          </Button>
        ) : (
          <Text
            onClick={() => setIsEditingLink(true)}
            sx={classes.linkBtnNoExists}
          >
            Add Link
          </Text>
        )}

        {/* other fields */}
        {otherFieldValues.map((field, key) => (
          <Box
            key={field.field_id}
            onMouseEnter={() => {
              setHoveredFieldKey(key);
            }}
            onMouseLeave={() => {
              setHoveredFieldKey(-1);
            }}
            sx={classes.otherFieldsContainer}
          >
            {/* More actions for field */}
            {((itemPosition === 0 && key === hoveredFieldKey) ||
              (itemPosition === 0 && isMobileOrTablet) ||
              fieldMenuHovered === key) && (
              <Box sx={classes.morefieldOptionsContainer}>
                <Box
                  onClick={(e) => handleMoreFieldOptions(e, key)}
                  sx={classes.verticalMoreOptions}
                >
                  <CardMedia
                    component="img"
                    image={Icons.dotsVertical}
                    alt=""
                    sx={classes.icon}
                  />
                </Box>
              </Box>
            )}

            <Box
              sx={{
                width:
                  multipleItems && lastPosition
                    ? "100%"
                    : multipleItems
                    ? "calc(100% + 16px)"
                    : "100%",
                ...classes.separator,
              }}
            />

            {/* label field */}
            {itemPosition === 0 ? (
              <Box sx={classes.otherFieldLabelRow}>
                {/* Icon */}
                {(field.field_label === "dimensions" ||
                  field.field_label === "Dimensions") && (
                  <CardMedia
                    component="img"
                    image={Icons.dimensions}
                    alt=""
                    sx={classes.otherFieldIcon}
                  />
                )}

                {!editingLabelField[field.field_id] ? (
                  <Text
                    onClick={() => {
                      setEditingLabelField({
                        ...editingLabelField,
                        [field.field_id]: true,
                      });
                    }}
                    sx={{
                      ...getTextStyle(
                        !field.field_label,
                        24,
                        14,
                        "#F3F3F3",
                        "#ECECEC"
                      ),
                      color: field.field_label ? "#2a2a2a" : "#757575",
                      ...classes.otherFieldLabel,
                    }}
                  >
                    {field.field_label || "Add Section Label"}
                  </Text>
                ) : (
                  <TextField
                    value={field.field_label}
                    onChange={(e) =>
                      handleOtherFieldLabelChange(e, field.field_id)
                    }
                    onKeyDown={(e) =>
                      handleKeyDown(e, "otherFieldLabel", field.field_id)
                    }
                    autoFocus
                    onBlur={() => {
                      saveField(
                        "otherFieldLabel",
                        field.field_label,
                        field.field_id
                      );
                      setEditingLabelField({
                        ...editingLabelField,
                        [field.field_id]: false,
                      });
                    }}
                    sx={classes.otherFieldsLabelTextField}
                  />
                )}
              </Box>
            ) : (
              <Box sx={classes.otherFieldsLabelRowPlaceholder} />
            )}

            {/* value field */}
            {!editingField[field.field_id] ? (
              <Text
                onClick={() => {
                  setEditingField({ ...editingField, [field.field_id]: true });
                }}
                sx={{
                  ...getTextStyle(
                    !field.field_value,
                    24,
                    14,
                    "#F3F3F3",
                    "#ECECEC"
                  ),
                  color: !field.field_value ? "#757575" : null,
                  alignItems: !field.field_value && "center",
                  fontWeight: !field.field_value && "bold",
                  ...classes.dimensionsTxt,
                }}
              >
                {!field.field_value &&
                (field.field_label === "dimensions" ||
                  field.field_label === "Dimensions")
                  ? "Add Dimensions"
                  : !field.field_value
                  ? "Add Section Value"
                  : field.field_value}
              </Text>
            ) : (
              <TextField
                value={field.field_value}
                onChange={(e) => handleOtherFieldValueChange(e, field.field_id)}
                onKeyDown={(e) =>
                  handleKeyDown(e, "otherFieldValue", field.field_id)
                }
                autoFocus
                onBlur={() => {
                  saveField(
                    "otherFieldValue",
                    field.field_value,
                    field.field_id
                  );
                  setEditingField({ ...editingField, [field.field_id]: false });
                }}
                sx={classes.otherFieldsValueTextField}
              />
            )}
          </Box>
        ))}
      </Box>

      <Menu
        open={moreItemOptionsMenu !== null}
        onClose={() => {
          handleClose();
        }}
        anchorReference="anchorPosition"
        anchorPosition={
          moreItemOptionsMenu !== null
            ? {
                top: moreItemOptionsMenu.mouseY,
                left: moreItemOptionsMenu.mouseX,
              }
            : undefined
        }
      >
        <MenuItem
          onClick={() => {
            handleDeleteItem(item.product_id);
          }}
        >
          <CardMedia
            component="img"
            image={Icons.trashRed}
            alt=""
            sx={{ marginRight: "8px", ...classes.icon }}
          />
          <Text sx={{ color: "#D12626", ...classes.menuItemTxt }}>
            Delete Product
          </Text>
        </MenuItem>
      </Menu>

      <Menu
        open={moreFieldOptionsMenu !== null}
        onClose={() => {
          handleClose();
          setFieldMenuHovered(null);
        }}
        anchorReference="anchorPosition"
        anchorPosition={
          moreFieldOptionsMenu !== null
            ? {
                top: moreFieldOptionsMenu.mouseY,
                left: moreFieldOptionsMenu.mouseX,
              }
            : undefined
        }
      >
        <MenuItem
          onClick={() => {
            handleDeleteRow(moreFieldOptionsMenu.fieldKey, item.group_id);
          }}
        >
          <CardMedia
            component="img"
            image={Icons.trashRed}
            alt=""
            sx={{ marginRight: "8px", ...classes.icon }}
          />
          <Text sx={{ color: "#D12626", ...classes.menuItemTxt }}>
            Delete Row
          </Text>
        </MenuItem>
      </Menu>
    </Box>
  );
};

const getTextStyle = (condition, height, fontSize, bgColor, hoverColor) => ({
  backgroundColor: condition ? bgColor : null,
  width: condition ? "100%" : null,
  height: condition ? `${height}px` : `${height}px`,
  borderRadius: condition ? "8px" : null,
  textAlign: condition ? "center" : null,
  fontSize: `${fontSize}px`,
  "&:hover": {
    backgroundColor: condition ? hoverColor : "#F3F3F3",
  },
});

FavoriteItem.propTypes = {
  itemPosition: PropTypes.number,
  item: PropTypes.object,
  multipleItems: PropTypes.bool,
  lastPosition: PropTypes.bool,
};

FavoriteItem.defaultProps = {
  itemPosition: -1,
  item: {},
  multipleItems: false,
  lastPosition: false,
};

export default FavoriteItem;
