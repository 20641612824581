import React, { useRef } from "react";
import { Box, Typography } from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import CardMedia from "@mui/material/CardMedia";

// import components
import LandingFurnitureBTS from "../../molecules/LandingFurnitureHighlighted/LandingFurnitureHighlighted";
// import LandingSeeMore from "../../molecules/LandingSeeMore/LandingSeeMore";
import ScrollButtonLeft from "../../molecules/ScrollButtonLeft/ScrollButtonLeft";
import ScrollButtonRight from "../../molecules/ScrollButtonRight/ScrollButtonRight";

// import icons

// import images
const BTSHeart =
  "https://craftle-static.s3.us-east-2.amazonaws.com/landing-page/bts-heart.png";
const BTSIMG1 =
  "https://craftle-static.s3.us-east-2.amazonaws.com/landing-page/bts-1.jpeg";
const BTSIMG2 =
  "https://craftle-static.s3.us-east-2.amazonaws.com/landing-page/bts-2.jpeg";
const BTSIMG3 =
  "https://craftle-static.s3.us-east-2.amazonaws.com/landing-page/bts-3.jpeg";
const BTSIMG4 =
  "https://craftle-static.s3.us-east-2.amazonaws.com/landing-page/bts-4.jpeg";
const BTSIMG5 =
  "https://craftle-static.s3.us-east-2.amazonaws.com/landing-page/bts-5.jpeg";
const BTSIMG6 =
  "https://craftle-static.s3.us-east-2.amazonaws.com/landing-page/bts-6.jpeg";
const BTSIMG7 =
  "https://craftle-static.s3.us-east-2.amazonaws.com/landing-page/bts-7.jpeg";
const BTSChibi1 =
  "https://craftle-static.s3.us-east-2.amazonaws.com/landing-page/bts-chibi-1.svg";
const BTSChibi2 =
  "https://craftle-static.s3.us-east-2.amazonaws.com/landing-page/bts-chibi-2.svg";
const BTSChibi3 =
  "https://craftle-static.s3.us-east-2.amazonaws.com/landing-page/bts-chibi-3.svg";
const BTSChibi4 =
  "https://craftle-static.s3.us-east-2.amazonaws.com/landing-page/bts-chibi-4.svg";
const BTSChibi5 =
  "https://craftle-static.s3.us-east-2.amazonaws.com/landing-page/bts-chibi-5.svg";
const BTSChibi6 =
  "https://craftle-static.s3.us-east-2.amazonaws.com/landing-page/bts-chibi-6.svg";
const BTSChibi7 =
  "https://craftle-static.s3.us-east-2.amazonaws.com/landing-page/bts-chibi-7.svg";

let customTheme = createTheme({
  breakpoints: {
    values: {
      customMd: 1300,
      xl: 1536,
      lg: 1200,
      md: 900,
      sm: 600,
      xs: 0,
    },
  },
});

function LandingBTSArmy() {
  const ref = useRef(null);

  return (
    <ThemeProvider theme={customTheme}>
      {/* popular furniture section */}
      <Box
        sx={{
          background:
            "-webkit-linear-gradient(225deg, #9FC6E5, #D0AED2, #F0BBC6)",
          marginTop: "48px",
          width: "100vw",
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          padding: "40px 0",
          position: "relative",
          // overflowX: "scroll",
        }}
      >
        <Box
          sx={{
            width: "90vw",
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
          }}
        >
          <Typography
            variant="h2"
            sx={{
              color: "#2a2a2a",
              fontSize: "32px",
              fontWeight: "bold",
            }}
          >
            💟 BTS ARMY
          </Typography>

          <Typography
            sx={{
              color: "#2a2a2a",
              fontSize: "16px",
              marginTop: "16px",
              // fontWeight: "bold",
            }}
          >
            We’re huge BTS fans! This list is inspired by BTS album’s photocards
            (fanart by najinichan) #btscraftle
          </Typography>

          <ScrollButtonLeft scrollRef={ref} left="5.5vw" top="50%" />
          <Box
            ref={ref}
            sx={{
              display: "flex",
              flexDirection: "row",
              marginTop: "16px",
              width: "95vw",
              marginLeft: "-5vw",
              overflowX: "scroll",
              paddingLeft: "5vw",
              scrollBehavior: "smooth",
              "&::-webkit-scrollbar": {
                display: "none",
              },
            }}
          >
            <LandingFurnitureBTS
              name="V"
              profile={BTSChibi1}
              image={BTSIMG1}
              brand="1stDibs"
              type="Loveseat"
              price="6,000"
              url="https://www.wayfair.com/furniture/pdp/hashtag-home-araceli-30-wide-tufted-armchair-w006110246.html?piid=82920358#seemoreoptions-jlkuqy4a7fo"
              path="/furniture/1stdibs/french-giltwood-settee-sofa-style-louis-xvi-red-velvet-19th-century"
            />
            <LandingFurnitureBTS
              name="Jimin"
              profile={BTSChibi2}
              image={BTSIMG2}
              brand="Kelly Clarkson Home"
              type="Loveseat"
              price="829.99"
              url="https://www.wayfair.com/furniture/pdp/kelly-clarkson-home-cali-495-standard-loveseat-w005404821.html"
              path="/furniture/mrswoods/hall-furniture-3-seat-classical-french-style-wedding-sofa"
            />
            <LandingFurnitureBTS
              name="RM"
              profile={BTSChibi3}
              image={BTSIMG3}
              brand="Poshbin"
              type="Sofa"
              price="1,829.99"
              url="https://www.wayfair.com/furniture/pdp/poshbin-catalina-square-arm-sofa-with-reversible-cushions-w004181000.html?piid=554847342%2C554847353%2C554847356"
              path="/furniture/wayfair/catalina-85-square-arm-sofa-with-reversible-cushions"
            />
            <Box
              sx={{
                marginRight: "16px",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                [customTheme.breakpoints.down("sm")]: {
                  alignItems: "flex-start",
                },
              }}
            >
              <Box
                sx={{
                  // padding: "16px 0",
                  backgroundColor: "#FFFFFF",
                  borderRadius: "16px",
                  boxSizing: "border-box",
                  marginTop: "40px",
                  height: "100%",
                  width: "240px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <CardMedia
                  component="img"
                  image={BTSHeart}
                  alt=""
                  sx={{
                    width: "80%",
                    height: "60%",
                    borderRadius: "16px",
                    objectFit: "contain",
                    [customTheme.breakpoints.down("sm")]: {
                      width: "200px",
                      height: "200px",
                    },
                  }}
                />
              </Box>
            </Box>
            <LandingFurnitureBTS
              name="Jungkook"
              profile={BTSChibi4}
              image={BTSIMG4}
              brand="Home Depot"
              type="Sofa"
              price="938.09"
              url="https://www.homedepot.com/p/Noble-House-Sadlier-75-5-in-3-Seat-Square-Arm-Faux-Leather-Straight-Dark-Brown-Sofa-107580/318818208"
              path="/furniture/homedepot/sadlier-75-5-in-3-seat-square-arm-faux-leather-straight-dark-brown-sofa"
            />
            <LandingFurnitureBTS
              name="Jin"
              profile={BTSChibi5}
              image={BTSIMG5}
              brand="ifurniture"
              type="Loveseat"
              price="549"
              url="https://ifurniture.ca/evelyn-curved-flared-loveseat-velvet"
              path="/furniture/ifurniture/evelyn-curved-flared-loveseat"
            />
            <LandingFurnitureBTS
              name="Suga"
              profile={BTSChibi6}
              image={BTSIMG6}
              brand="Better Bed Company"
              type="Loveseat"
              price="862.59"
              url="https://www.betterbedcompany.co.uk/products/julian-bowen-sandringham-3-seater-sofa-blue-velvet"
              path="/furniture/better-bed-company/julian-bowen-sandringham-3-seater-sofa-blue-velvet"
            />
            <LandingFurnitureBTS
              name="J-Hope"
              profile={BTSChibi7}
              image={BTSIMG7}
              brand="Mojow"
              type="Lounge Set"
              price="3,380.38"
              url="https://www.archiproducts.com/en/products/mojow/lounge-set-yomi-wood-et-table-basse-lounge-set_527937"
              path="/furniture/mojow/yomi-wood-et-table-basse"
            />
          </Box>
          <ScrollButtonRight scrollRef={ref} right="5.5vw" top="50%" />

          {/* <LandingSeeMore
            text="See more BTS Army"
            mixpanelTracking="See More BTS Army"
          /> */}
        </Box>
      </Box>
    </ThemeProvider>
  );
}

export default LandingBTSArmy;
