export const SAMPLE_INPUT = {
  budget: 1430,
  designWeights: {
    bohemian: 3,
    eclectic: 1,
    industrial: 1,
    "mid-century-modern": 1,
    modern: 1,
    rustic: 1,
    scandinavian: 1,
    traditional: 1,
  },
  furnitureOptions: [
    "sofas-and-couches",
    "coffee-tables",
    "area-rugs",
    "accent-chairs",
  ],
  roomType: "living-room",
};

function getRandomInt(min, max) {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min) + min); //The maximum is exclusive and the minimum is inclusive
}

export function generateSampleInput(minCost, maxCost) {
  if (!maxCost) {
    maxCost = minCost + 500;
  }
  const randomInput = {};

  randomInput["roomType"] = "living-room";
  randomInput["furnitureOptions"] = [
    "sofas-and-couches",
    "coffee-tables",
    "area-rugs",
    "accent-chairs",
    "end-tables",
    "decorative-pillows",
    "throw-blankets",
    "wall-art",
    "floor-lamps",
    "artificial-plants",
  ];
  // randomInput["budget"] = getRandomInt(minCost, maxCost);
  randomInput["budget"] = maxCost;
  randomInput["designWeights"] = {
    bohemian: getRandomInt(1, 10),
    eclectic: getRandomInt(1, 10),
    industrial: getRandomInt(1, 10),
    "mid-century-modern": getRandomInt(1, 10),
    modern: getRandomInt(1, 10),
    rustic: getRandomInt(1, 10),
    scandinavian: getRandomInt(1, 10),
    traditional: getRandomInt(1, 10),
  };

  return randomInput;
}

export const roomCards = [
  {
    label: "Living Room",
    labelKey: "living-room",
    imageUrl:
      "https://craftle-static.s3.us-east-2.amazonaws.com/room-type/living-room.png",
  },
  {
    label: "Home Office (Coming)",
    labelKey: "office",
    imageUrl:
      "https://craftle-static.s3.us-east-2.amazonaws.com/room-type/home-office.png",
    disabled: true,
  },
  {
    label: "Bedroom (Coming)",
    labelKey: "bedroom",
    imageUrl:
      "https://craftle-static.s3.us-east-2.amazonaws.com/room-type/bedroom.png",
    disabled: true,
  },
  {
    label: "Bathroom (Coming)",
    labelKey: "bathroom",
    imageUrl:
      "https://craftle-static.s3.us-east-2.amazonaws.com/room-type/bathroom.png",
    disabled: true,
  },
  {
    label: "Kitchen (Coming)",
    labelKey: "kitchen",
    imageUrl:
      "https://craftle-static.s3.us-east-2.amazonaws.com/room-type/kitchen.png",
    disabled: true,
  },
  {
    label: "Dining Room (Coming)",
    labelKey: "dining-room",
    imageUrl:
      "https://craftle-static.s3.us-east-2.amazonaws.com/room-type/dining-room.png",
    disabled: true,
  },
  {
    label: "Baby & Kids (Coming)",
    labelKey: "baby-and-kids",
    imageUrl:
      "https://craftle-static.s3.us-east-2.amazonaws.com/room-type/baby-and-kids.png",
    disabled: true,
  },
  {
    label: "Entry & Mudroom (Coming)",
    labelKey: "entry-and-mudroom",
    imageUrl:
      "https://craftle-static.s3.us-east-2.amazonaws.com/room-type/entry-and-mudroom.png",
    disabled: true,
  },
  {
    label: "Outdoor & Patio (Coming)",
    labelKey: "outdoor-and-patio",
    imageUrl:
      "https://craftle-static.s3.us-east-2.amazonaws.com/room-type/outdoor-and-patio.png",
    disabled: true,
  },
];

// Import furniture images
export const furnitureOptions = [
  {
    imageUrl:
      "https://craftle-static.s3.us-east-2.amazonaws.com/furniture-images/cms-sofas-and-couches.jpg",
    labelKey: "sofas-and-couches",
    label: "Sofa and Couch",
  },
  {
    imageUrl:
      "https://craftle-static.s3.us-east-2.amazonaws.com/furniture-images/cms-accent-chairs.jpg",
    labelKey: "accent-chairs",
    label: "Accent Chair",
  },
  {
    imageUrl:
      "https://craftle-static.s3.us-east-2.amazonaws.com/furniture-images/cms-coffee-tables.jpg",
    labelKey: "coffee-tables",
    label: "Coffee Table",
  },
  // {
  //   imageUrl:
  //     "https://craftle-static.s3.us-east-2.amazonaws.com/furniture-images/cms-tv-stands.jpg",
  //   labelKey: "tv-stands",
  //   label: "TV Stand",
  // },
  {
    imageUrl:
      "https://craftle-static.s3.us-east-2.amazonaws.com/furniture-images/cms-end-tables.jpg",
    labelKey: "end-tables",
    label: "End Table",
  },
  // {
  //   imageUrl:
  //     "https://craftle-static.s3.us-east-2.amazonaws.com/furniture-images/cms-console-tables.jpg",
  //   labelKey: "console-tables",
  //   label: "Console Table",
  // },
  // {
  //   imageUrl:
  //     "https://craftle-static.s3.us-east-2.amazonaws.com/furniture-images/cms-ottomans.jpg",
  //   labelKey: "ottomans",
  //   label: "Ottoman",
  // },
];

// Import more furniture images
export const decorSoftFurnishingsOptions = [
  {
    imageUrl:
      "https://craftle-static.s3.us-east-2.amazonaws.com/decor-and-soft-furnishing-images/cms-decorative-pillows.jpg",
    labelKey: "decorative-pillows",
    label: "Decorative Pillow",
  },
  {
    imageUrl:
      "https://craftle-static.s3.us-east-2.amazonaws.com/decor-and-soft-furnishing-images/cms-throw-blankets.jpg",
    labelKey: "throw-blankets",
    label: "Throw Blanket",
  },
  {
    imageUrl:
      "https://craftle-static.s3.us-east-2.amazonaws.com/decor-and-soft-furnishing-images/cms-area-rugs.jpg",
    labelKey: "area-rugs",
    label: "Area Rug",
  },
  {
    imageUrl:
      "https://craftle-static.s3.us-east-2.amazonaws.com/decor-and-soft-furnishing-images/cms-wall-art.jpg",
    labelKey: "wall-art",
    label: "Wall Art",
  },
  // {
  //   imageUrl:
  //     "https://craftle-static.s3.us-east-2.amazonaws.com/decor-and-soft-furnishing-images/cms-table-lamps.jpg",
  //   labelKey: "table-lamps",
  //   label: "Table Lamp",
  // },
  {
    imageUrl:
      "https://craftle-static.s3.us-east-2.amazonaws.com/decor-and-soft-furnishing-images/cms-floor-lamps.jpg",
    labelKey: "floor-lamps",
    label: "Floor Lamp",
  },
  // {
  //   imageUrl:
  //     "https://craftle-static.s3.us-east-2.amazonaws.com/decor-and-soft-furnishing-images/cms-pendants-and-chandeliers.jpg",
  //   labelKey: "pendants-and-chandeliers",
  //   label: "Pendants and Chandeliers",
  // },
  // {
  //   imageUrl:
  //     "https://craftle-static.s3.us-east-2.amazonaws.com/decor-and-soft-furnishing-images/cms-sconces.jpg",
  //   labelKey: "sconces",
  //   label: "Sconces",
  // },
  /**
  {
    imageUrl:
      "https://craftle-static.s3.us-east-2.amazonaws.com/decor-and-soft-furnishing-images/cms-baskets-and-storage.jpg",
    labelKey: "baskets-and-storage",
    label: "Basket and Storage",
  },
  */
  /**
  {
    imageUrl:
      "https://craftle-static.s3.us-east-2.amazonaws.com/decor-and-soft-furnishing-images/cms-candles.jpg",
    labelKey: "candles",
    label: "Candles",
  },
  */
  /**
  {
    imageUrl:
      "https://craftle-static.s3.us-east-2.amazonaws.com/decor-and-soft-furnishing-images/cms-live-plants.jpg",
    labelKey: "live-plants",
    label: "Live Plants",
    disabled: true,
  },
  */
  {
    imageUrl:
      "https://craftle-static.s3.us-east-2.amazonaws.com/decor-and-soft-furnishing-images/cms-artificial-plants.jpg",
    labelKey: "artificial-plants",
    label: "Artificial Plants",
  },
  /**
  {
    imageUrl:
      "https://craftle-static.s3.us-east-2.amazonaws.com/decor-and-soft-furnishing-images/cms-planters.jpg",
    labelKey: "planters", // Zander: changed to planters-and-plant-stands
    label: "Planters",
  },
  */
  /**
  {
    imageUrl:
      "https://craftle-static.s3.us-east-2.amazonaws.com/decor-and-soft-furnishing-images/cms-decorative-accessories.jpg",
    labelKey: "decorative-accessories",
    label: "Decorative Accessories",
    disabled: true,
  },
  */
  // {
  //   imageUrl:
  //     "https://craftle-static.s3.us-east-2.amazonaws.com/decor-and-soft-furnishing-images/cms-window-coverings.jpg",
  //   labelKey: "window-coverings",
  //   label: "Window Coverings",
  // },
  // {
  //   imageUrl:
  //     "https://craftle-static.s3.us-east-2.amazonaws.com/decor-and-soft-furnishing-images/cms-decorative-mirrors.jpg",
  //   labelKey: "decorative-mirrors",
  //   label: "Decorative Mirrors",
  // },
];

// Import decor and soft furnishings images
export const sofasCouchesOptions = [
  {
    imageUrl:
      "https://craftle-static.s3.us-east-2.amazonaws.com/sofas-and-couches-images/cms-sectional-sofas.jpg",
    labelKey: "sectional-sofas",
    label: "Sectional Sofas",
  },
  {
    imageUrl:
      "https://craftle-static.s3.us-east-2.amazonaws.com/sofas-and-couches-images/cms-sleeper-sofas.jpg",
    labelKey: "sleeper-sofas",
    label: "Sleeper Sofas",
  },
  {
    imageUrl:
      "https://craftle-static.s3.us-east-2.amazonaws.com/sofas-and-couches-images/cms-reclining-sofas.jpg",
    labelKey: "reclining-sofas",
    label: "Reclining Sofas",
  },
  {
    imageUrl:
      "https://craftle-static.s3.us-east-2.amazonaws.com/sofas-and-couches-images/cms-loveseats.jpg",
    labelKey: "loveseats",
    label: "Loveseats",
  },
  {
    imageUrl:
      "https://craftle-static.s3.us-east-2.amazonaws.com/sofas-and-couches-images/cms-futons.jpg",
    labelKey: "futons",
    label: "Futons",
  },
  {
    imageUrl:
      "https://craftle-static.s3.us-east-2.amazonaws.com/sofas-and-couches-images/cms-settees.jpg",
    labelKey: "settees",
    label: "Settees",
  },
  {
    imageUrl:
      "https://craftle-static.s3.us-east-2.amazonaws.com/sofas-and-couches-images/cms-convertibles.jpg",
    labelKey: "convertibles",
    label: "Convertibles",
  },
];

export const baseUrl =
  "https://craftle-static.s3.us-east-2.amazonaws.com/design-style-images/";

export const exampleImageBaseUrl =
  "https://craftle-static.s3.us-east-2.amazonaws.com/craft-my-style-results-page/";

export const DesignStyleDescription = {
  bohemian: {
    title: "Bohemian",
    imageUrl: baseUrl + "bohemian/1.png",
    description: `• Bold accent colors \n• Functional over ornament \n• Less frills and more simple geometric lines`,
    exampleImages: {
      img1: {
        img: exampleImageBaseUrl + "cms-result-example-img-bohemian-1.jpg",
        cap: "blackandblooms",
        capLink:
          "https://www.blackandblooms.com/blog/creating-a-jungle-record-corner",
      },
      img2: {
        img: exampleImageBaseUrl + "cms-result-example-img-bohemian-2.jpg",
        cap: "Displate",
        capLink:
          "https://blog.displate.com/wp-content/uploads/2021/12/Boho-Living-Room-Ideas_hanging-chair_2-891x1024.jpg",
      },
      img3: {
        img: exampleImageBaseUrl + "cms-result-example-img-bohemian-3.jpg",
        cap: "Thebeautyrevival",
        capLink: "https://thebeautyrevival.com/",
      },
    },
  },
  eclectic: {
    title: "Eclectic",
    imageUrl: baseUrl + "eclectic/1.png",
    description: `• Mix of design styles \n• Bright colors, art decors \n• Cohesive incohesiveness`,
    exampleImages: {
      img1: {
        img: exampleImageBaseUrl + "cms-result-example-img-eclectic-1.jpg",
        cap: "Christopherlonggroup",
        capLink:
          "https://christopherlonggroup.com/blog/create-a-happy-hygge-home-interior",
      },
      img2: {
        img: exampleImageBaseUrl + "cms-result-example-img-eclectic-2.jpg",
        cap: "Istitutomarangoni",
        capLink:
          "https://www.instagram.com/istitutomarangoni_miami/p/Cu9zMbpOlYN/",
      },
      img3: {
        img: exampleImageBaseUrl + "cms-result-example-img-eclectic-3.jpg",
        cap: "Digsdigs",
        capLink:
          "https://www.digsdigs.com/photos/2019/06/a-colorful-eclectic-living-room-with-a-green-statement-wall-with-artworks-a-boho-rug-a-mustard-sofa-and-wicker-touches-1.jpg",
      },
    },
  },
  industrial: {
    title: "Industrial",
    imageUrl: baseUrl + "industrial/1.png",
    description: `• Inspired by lofts, factories \n• Stripped-back, architectural \n• Metals, wood, brick elements`,
    exampleImages: {
      img1: {
        img: exampleImageBaseUrl + "cms-result-example-img-industrial-1.jpg",
        cap: "Houzz",
        capLink: "https://www.houzz.com/",
      },
      img2: {
        img: exampleImageBaseUrl + "cms-result-example-img-industrial-2.jpg",
        cap: "Poltronafrau",
        capLink: "https://www.poltronafrau.com/us/en.html",
      },
      img3: {
        img: exampleImageBaseUrl + "cms-result-example-img-industrial-3.jpg",
        cap: "Pinterest",
        capLink: "https://www.pinterest.com/pin/527906387575708901/",
      },
    },
  },
  "mid-century-modern": {
    title: "Mid-Century Modern",
    imageUrl: baseUrl + "mid-century-modern/1.png",
    description: `• Minimal decor, organic shapes \n• Fusing materials and aesthetics \n• Natural hues, minimalism`,
    exampleImages: {
      img1: {
        img:
          exampleImageBaseUrl +
          "cms-result-example-img-mid-century-modern-1.jpg",
        cap: "Joybird",
        capLink: "https://joybird.com/sofas/briar-leather-sofa/",
      },
      img2: {
        img:
          exampleImageBaseUrl +
          "cms-result-example-img-mid-century-modern-2.jpg",
        cap: "Livingspaces",
        capLink:
          "https://www.livingspaces.com/globalassets/images/inspiration/styles/mid-century/mid-century-small-apartment-malika-sofa_2.jpg?w=580&h=440&mode=pad",
      },
      img3: {
        img:
          exampleImageBaseUrl +
          "cms-result-example-img-mid-century-modern-3.jpg",
        cap: "Apartmenttherapy",
        capLink:
          "https://www.apartmenttherapy.com/cheap-mid-century-modern-style-36713253",
      },
    },
  },
  modern: {
    title: "Modern",
    imageUrl: baseUrl + "modern/1.png",
    description: `• Monochromatic, minimalist \n• Clean lines, functionality \n• Artistic, no-fuss design`,
    exampleImages: {
      img1: {
        img: exampleImageBaseUrl + "cms-result-example-img-modern-1.jpg",
        cap: "Beatifulhomes",
        capLink: "https://www.beautifulhomes.com/",
      },
      img2: {
        img: exampleImageBaseUrl + "cms-result-example-img-modern-2.jpg",
        cap: "Decormatters",
        capLink:
          "https://decormatters.com/blog/spruce-up-your-decor-5-modern-living-room-and-bedroom-interior-design-ideas-for-your-home",
      },
      img3: {
        img: exampleImageBaseUrl + "cms-result-example-img-modern-3.jpg",
        cap: "Eymericwidling",
        capLink: "https://eymericwidling.com/",
      },
    },
  },
  rustic: {
    title: "Rustic",
    imageUrl: baseUrl + "rustic/1.png",
    description: `• Natural beauty, laid-back \n• Wood, stone, autumn colors \n• Versatile, welcoming style`,
    exampleImages: {
      img1: {
        img: exampleImageBaseUrl + "cms-result-example-img-rustic-1.jpg",
        cap: "Countryliving",
        capLink:
          "https://www.countryliving.com/home-design/house-tours/g24849024/tennessee-fixer-upper/",
      },
      img2: {
        img: exampleImageBaseUrl + "cms-result-example-img-rustic-2.jpg",
        cap: "Countryliving",
        capLink:
          "https://www.countryliving.com/home-design/decorating-ideas/g29237051/rustic-living-room-ideas/?slide=13",
      },
      img3: {
        img: exampleImageBaseUrl + "cms-result-example-img-rustic-3.jpg",
        cap: "Countryliving",
        capLink:
          "https://www.countryliving.com/home-design/decorating-ideas/g29237051/rustic-living-room-ideas/?slide=9",
      },
    },
  },
  scandinavian: {
    title: "Scandinavian",
    imageUrl: baseUrl + "scandinavian/1.png",
    description: `• Clean, bright, soft design \n• Natural light, muted colors \n• Minimalistic, functional`,
    exampleImages: {
      img1: {
        img: exampleImageBaseUrl + "cms-result-example-img-scandinavian-1.jpg",
        cap: "",
        capLink: "",
      },
      img2: {
        img: exampleImageBaseUrl + "cms-result-example-img-scandinavian-2.jpg",
        cap: "",
        capLink: "",
      },
      img3: {
        img: exampleImageBaseUrl + "cms-result-example-img-scandinavian-3.jpg",
        cap: "",
        capLink: "",
      },
    },
  },
  traditional: {
    title: "Traditional",
    imageUrl: baseUrl + "traditional/1.png",
    description: `• Timeless, elegant beauty \n• Neutral colors, delicate details \n• Classic elegance, fancy lights`,
    exampleImages: {
      img1: {
        img: exampleImageBaseUrl + "cms-result-example-img-traditional-1.jpg",
        cap: "Rinfretltd",
        capLink: "https://www.rinfretltd.com/",
      },
      img2: {
        img: exampleImageBaseUrl + "cms-result-example-img-traditional-2.jpg",
        cap: "",
        capLink: "",
      },
      img3: {
        img: exampleImageBaseUrl + "cms-result-example-img-traditional-3.jpg",
        cap: "",
        capLink: "",
      },
    },
  },
};

export const furnitureResultOrder = furnitureOptions
  .concat(decorSoftFurnishingsOptions)
  .map((n) => n.value);

export const sortFurnitureResult = (originalArray) => {
  const sorted = originalArray.sort(
    (a, b) => furnitureResultOrder.indexOf(a) - furnitureResultOrder.indexOf(b)
  );
  return sorted;
};

// export const FurnitureTypeKeyToName = {
//   "sectional-sofas": "Sectional Sofas",
//   "sleeper-sofas": "Sleeper Sofas",
//   "reclining-sofas": "Reclining Sofas",
//   loveseats: "Love Seats",
//   futons: "Futons",
//   settees: "Settees",
//   convertibles: "Convertibles",
//   "decorative-pillows": "Decorative Pillows",
//   "throw-blankets": "Throw Blankets",
//   "area-rugs": "Area Rugs",
//   "wall-art": "Wall Art",
//   "table-lamps": "Table Lamps",
//   "floor-lamps": "Floor Lamps",
//   "pendants-and-chandeliers": "Pendants and Chandeliers",
//   sconces: "Sconces",
//   "baskets-and-storage": "Baskets and Storage",
//   candles: "Candles",
//   "live-plants": "Live Plants",
//   "artificial-plants": "Artificial Plants",
//   planters: "Planters",
//   "decorative-accessories": "Decorative Accessories",
//   "window-coverings": "Window Coverings",
//   "decorative-mirrors": "Decorative Mirrors",
//   "sofas-and-couches": "Sofas and Couches",
//   "accent-chairs": "Accent Chairs",
//   "coffee-tables": "Coffee Tables",
//   "tv-stands": "TV Stands",
//   "end-tables": "End Tables",
//   "console-tables": "Console Tables",
//   ottomans: "Ottomans",
// };

export const LivingRoomMoodBoardPlanLocation = {
  livingRoom1: [
    {
      key: "sofas-and-couches",
      position: [0, -0.5, 0.1],
      imageUrl: "",
      scale: 1.2,
    },
    {
      key: "decorative-pillows",
      position: [2.8, 0, 0.2],
      imageUrl: "",
      scale: 0.35,
    },
    {
      key: "throw-blankets",
      position: [2.8, 2, 0.2],
      imageUrl: "",
      scale: 0.5,
    },
    {
      key: "area-rugs",
      position: [2.8, -3, 0],
      imageUrl: "",
      scale: 0.6,
      // transform: "rotate(60deg)",
    },
    {
      key: "wall-art",
      position: [0, 1.55, 0],
      imageUrl: "",
      scale: 0.65,
    },
    // {
    //   key: "pendants-and-chandeliers",
    //   position: [0, 3.2, 0.1],
    //   imageUrl: "",
    //   scale: 0.5,
    // },
    // {
    //   key: "sconces",
    //   position: [-3, 2, 0],
    //   imageUrl: "",
    //   scale: 0.3,
    // },
    // {
    //   key: "sconces",
    //   position: [3.6, 2, 0],
    //   imageUrl: "",
    //   scale: 0.4,
    // },
    {
      key: "coffee-tables",
      position: [-2, -2.75, 0.1],
      imageUrl: "",
      scale: 0.8,
    },
    {
      key: "artificial-plants",
      position: [-3, 0.4, 0.1],
      imageUrl: "",
      scale: 0.3,
    },
    {
      key: "end-tables",
      position: [-3, -0.8, 0],
      imageUrl: "",
      scale: 0.3,
    },
    // {
    //   key: "artificial-plants",
    //   position: [-3.6, 0.2, 0],
    //   imageUrl: "",
    //   scale: 0.4,
    // },
    // {
    //   key: "end-tables",
    //   position: [3.6, -1, 0],
    //   imageUrl: "",
    //   scale: 0.5,
    // },
    // {
    //   key: "artificial-plants",
    //   position: [3.6, 0.2, 0],
    //   imageUrl: "",
    //   scale: 0.4,
    // },
  ],
  livingRoom2: [
    {
      key: "accent-chairs",
      position: [-1.9, -0.4, 0.1],
      imageUrl: "",
      scale: 0.75,
    },
    // {
    //   key: "ottomans",
    //   position: [-1.9, -2.75, 0.1],
    //   imageUrl: "",
    //   scale: 0.5,
    // },
    // {
    //   key: "window-coverings",
    //   position: [-1.9, 1.5, -0.1],
    //   imageUrl: "",
    //   scale: 1,
    // },
    {
      key: "floor-lamps",
      position: [-3, 0, 0],
      imageUrl: "",
      scale: 1,
    },
    // {
    //   key: "baskets-and-storage",
    //   position: [2.5, 0, 0],
    //   imageUrl: "",
    //   scale: 0.5,
    // },
    // {
    //   key: "decorative-mirrors",
    //   position: [0.5, 1.9, 0],
    //   imageUrl: "",
    //   scale: 0.5,
    // },
    // {
    //   key: "tv-stands",
    //   position: [0.5, 0.2, 0.1],
    //   imageUrl: "",
    //   scale: 0.75,
    // },
    // {
    //   key: "table-lamps",
    //   position: [2.6, 0, 0.3],
    //   imageUrl: "",
    //   scale: 0.5,
    // },
    // {
    //   key: "console-tables",
    //   position: [2.6, -2, 0.2],
    //   imageUrl: "",
    //   scale: 0.8,
    // },
    // {
    //   key: "candles",
    //   position: [1.5, 1, 0.5],
    //   imageUrl: "",
    //   scale: 0.3,
    // },
  ],
  livingRoom3: [],
};

export const HomePageLivingRoomMoodBoardPlanLocation = [
  {
    key: "area-rugs",
    positionX: "70%",
    positionY: "65%",
    width: "30%",
  },
  {
    key: "accent-chairs",
    positionX: "5%",
    positionY: "67%",
    width: "28%",
  },
  {
    key: "artificial-plants",
    positionX: "23%",
    positionY: "25%",
    width: "20%",
  },
  {
    key: "end-tables",
    positionX: "5%",
    positionY: "45%",
    width: "20%",
  },
  {
    key: "decorative-pillows",
    positionX: "78%",
    positionY: "20%",
    width: "18%",
  },
  {
    key: "throw-blankets",
    positionX: "78%",
    positionY: "2%",
    width: "18%",
  },
  {
    key: "wall-art",
    positionX: "40%",
    positionY: "2%",
    width: "32%",
  },
  {
    key: "floor-lamps",
    positionX: "-4%",
    positionY: "5%",
    width: "35%",
  },
  {
    key: "sofas-and-couches",
    positionX: "38%",
    positionY: "24%",
    width: "52%",
  },
  {
    key: "coffee-tables",
    positionX: "36%",
    positionY: "65%",
    width: "34%",
  },
];
