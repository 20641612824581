import { customTheme } from "../../../components/atoms/CustomTheme/CustomTheme";

export const classes = {
  container: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    height: "100vh",
    [customTheme.breakpoints.down("md")]: {
      height: "inherit",
      paddingTop: "50px",
    },
    [customTheme.breakpoints.down("sm")]: {
      paddingTop: "20px",
    },
  },
  cardContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginBottom: "20px",
    marginTop: "80px",
    [customTheme.breakpoints.down("md")]: {
      // marginBottom: "20px",
      marginTop: "8px",
    },
  },
  imageBox: {
    position: "relative",
    zIndex: "2",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  contentPositioning: {
    position: "absolute",
    bottom: "25px",
    width: "210px",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  xIconButton: {
    width: "80px",
    height: "80px",
    backgroundColor: "rgba(174, 181, 195, 0.85)",
    borderRadius: "40px",
    alignItems: "center",
    justifyContent: "center",
    display: "flex",
    cursor: "pointer",
    transitionDuration: "0.4s",
    "&:hover": {
      backgroundColor: "rgba(174, 181, 195, 1)",
    },
  },
  heartIconBtn: {
    width: "80px",
    height: "80px",
    backgroundColor: "rgba(246, 125, 126, 0.85)",
    borderRadius: "40px",
    alignItems: "center",
    justifyContent: "center",
    display: "flex",
    cursor: "pointer",
    transitionDuration: "0.4s",
    "&:hover": {
      backgroundColor: "rgba(246, 125, 126, 1)",
    },
  },

  // enoughCardsSwiped styling
  enoughCardsContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    [customTheme.breakpoints.down("sm")]: {},
  },
  enoughCardsInnerContainer: {
    position: "relative",
    zIndex: "3",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  cardImageMediaStyle: {
    width: "60vh",
    height: "60vh",
    maxWidth: "500px",
    maxHeight: "500px",
    borderRadius: "20px",
    // boxShadow: "0px 40px 60px rgba(0, 0, 0, 0.15)",
    [customTheme.breakpoints.down("sm")]: {
      width: "90vw",
      height: "90vw",
    },
    [customTheme.breakpoints.down("450")]: {
      // width: "350px",
      // height: "350px",
    },
  },
  enoughCardsContentContainer: {
    position: "absolute",
    height: "100%",
    padding: "20px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  thumbsUpContainer: {
    [customTheme.breakpoints.down("xs")]: {
      display: "none",
    },
  },
  weHaveEnoughText: {
    marginTop: "30px",
    fontWeight: "bold",
    textAlign: "center",
    fontSize: "20px",
    color: "#FFFFFF",
    [customTheme.breakpoints.down("xs")]: {
      marginTop: "16px",
      fontSize: "16px",
    },
  },
  selectMoreButton: {
    maxWidth: "255px",
    width: "100%",
    borderRadius: "128px",
    height: "50px",
    marginTop: "30px",
    backgroundColor: "#FFFFFF",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer",
    color: "#2A2A2A",
    "&:hover": {
      backgroundColor: "rgba(60, 64, 67, 0.1)",
      color: "#FFFFFF",
    },
    [customTheme.breakpoints.down("xs")]: {
      marginTop: "16px",
    },
  },
};
