import React, { createContext, useState } from "react";
import { Box } from "@mui/material";
import CardMedia from "@mui/material/CardMedia";

// import components
import { classes } from "./AIFurnitureShopperStyle";
import Text from "../../components/atoms/Text/Text";
import AIConvoSearch from "../../components/organisms/AIConvoSearch/AIConvoSearch";

// import images
const wallpaper =
  "https://craftle-static.s3.us-east-2.amazonaws.com/ai-furniture-shopper/wallpaper-couch-pattern.png";

// import gifs
const loaderIcon =
  "https://craftle-static.s3.us-east-2.amazonaws.com/animations/loader-dual-ring.gif";

export const AIFurnitureShopperContext = createContext();

export default function AIFurnitureShopper() {
  const [loaderDisplay, setLoaderDisplay] = useState(false);

  return (
    <AIFurnitureShopperContext.Provider value={{ setLoaderDisplay }}>
      <Box sx={classes.container}>
        {/* Loader */}
        {loaderDisplay && (
          <Box sx={{ ...classes.loadingSection, display: "inherit" }}>
            <Box sx={classes.loadingModal}>
              <CardMedia
                component="img"
                image={loaderIcon}
                alt=""
                sx={classes.loaderIcon}
              />
              <Text sx={classes.loaderText}>
                Searching for the answer you seek. Hang tight!
              </Text>
            </Box>
          </Box>
        )}

        {/* Background gradient */}
        <Box sx={classes.backgroundGradient} />
        <CardMedia
          component="img"
          sx={classes.wallpaper}
          image={wallpaper}
          alt="background wallpaper"
        />

        {/* Content */}
        <Box sx={classes.content}>
          <Text variant="h1" sx={classes.h1}>
            Find your perfect furniture
          </Text>
          <AIConvoSearch />
        </Box>
      </Box>
    </AIFurnitureShopperContext.Provider>
  );
}
