import { useEffect, useState } from "react";

// Import components
import { customTheme } from "../../../components/atoms/CustomTheme/CustomTheme";

function WebAppBackgroundUpdate() {
  const [webAppBackground, setWebAppBackground] = useState("");
  useEffect(() => {
    setInterval(() => {
      const scrollCheck = document.documentElement.scrollTop;
      if (scrollCheck > 16) {
        setWebAppBackground("#FFFFFF");
      } else {
        setWebAppBackground("");
      }
    });
  }, []);

  return webAppBackground;
}

function WebAppBoxShadowUpdate() {
  const [webAppBoxShadow, setWebAppBoxShadow] = useState("");
  useEffect(() => {
    setInterval(() => {
      const scrollCheck = document.documentElement.scrollTop;
      if (scrollCheck > 50) {
        setWebAppBoxShadow("0 15px 15px 0 rgba(0, 0, 0, 0.05)");
      } else {
        setWebAppBoxShadow("");
      }
    });
  }, []);

  return webAppBoxShadow;
}

export let classes = {
  webAppBarContainer: {
    position: "fixed",
    width: "100%",
    top: 0,
    padding: "16px 0",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    zIndex: 100,
    transition: "0.2s",
    backgroundColor: WebAppBackgroundUpdate,
    boxShadow: WebAppBoxShadowUpdate,
    [customTheme.breakpoints.down("md")]: {
      paddingTop: "8px",
      paddingBottom: "8px",
    },
    [customTheme.breakpoints.down("sm")]: {
      paddingTop: "0px",
      paddingBottom: "0px",
    },
  },
  contentContainer: {
    position: "relative",
    width: "90vw",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    paddingLeft: "24px",
    paddingRight: "24px",
    maxWidth: customTheme.breakpoints.values.lg,
    [customTheme.breakpoints.down("sm")]: {
      paddingRight: "8px",
    },
    [customTheme.breakpoints.down("xs")]: {
      paddingTop: 0,
      paddingBottom: 0,
    },
  },

  // Logo
  leftContentContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  verticalAlign: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  logoStyle: {
    display: "inline-block",
    marginLeft: "10px",
    [customTheme.breakpoints.down("xs")]: {
      marginLeft: "5px",
    },
  },

  mdUpDisplay: {
    [customTheme.breakpoints.down("md")]: {
      display: "none",
    },
  },
  centerNavs: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
  },

  buttonContainer: {
    display: "inline-block",
    verticalAlign: "middle",
  },

  // Mobile styling
  mdDownDisplay: {
    [customTheme.breakpoints.up("md")]: {
      display: "none",
    },
  },
  mobileMenuContainer: {
    display: "flex",
    verticalAlign: "middle",
    borderRadius: 0,
    padding: "18px",
  },
  menuPaper: {
    width: "100%",
  },
  mobileMenuItem: {
    height: "50px",
    width: "100vw",
    fontWeight: "bold",
  },

  // Global navigation styles button
  linkStyles: {
    textDecoration: "none",
    cursor: "pointer",
  },
  navButtonCommonStyles: {
    fontSize: "16px",
    fontWeight: "bold",
    padding: "16px 24px",
    borderRadius: "100px",
    boxSizing: "border-box",
    mozBoxSizing: "border-box",
    webkitBoxSizing: "border-box",
  },
  navPrimaryButton: {
    color: "#FFFFFF",
    backgroundColor: "#2A2A2A",
  },
  navSecondaryButton: {
    color: "#2A2A2A",
    border: "solid 2px #2A2A2A",
    marginLeft: "8px",
    transition: "1.2s",
    backgroundColor: "#FFE9AF",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    gap: "8px",
    padding: "16px 24px",
  },
  newText: {
    fontStyle: "italic",
    fontWeight: "bold",
    color: "#D12626",
    lineHeight: "16px",
  },
  navTertiaryButton: {
    color: "#2A2A2A",
  },
  navFourthButton: {
    color: "#2A2A2A",
    border: "solid 2px #2A2A2A",
    marginLeft: "16px",
  },
};
